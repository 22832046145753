import { param } from 'src/assets/ueditor/third-party/jquery-1.10.2';
import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { JsonPipe } from '@angular/common';

let cnm;
let apiTmp;
let myMap;
@Component({
  selector: 'app-modal-staff-operation',
  templateUrl: './modal-staff-operation.component.html',
  styleUrls: ['./modal-staff-operation.component.less']
})
export class ModalStaffOperationComponent implements OnInit {
  params: any;
  fileList: any;
  uploadParams: any;

  actualPaymentDate = null;
  contractUrl: string;
  optionDesc = null;
  leaveDate = null;
  // 离职
  leaveReason1 = null;
  leaveReason2 = null;
  leaveReasonList = [
    { type: 'COMPANY_DISMISSED', name: '公司辞退' },
    { type: 'COMPANY_SENT', name: '公司派遣' },
    { type: 'CONTRACT_EXPIRES', name: '合同到期不续签' },
    { type: 'LOW_SALARY', name: '工作原因-薪酬福利待遇低' },
    { type: 'HIGH_WORKING', name: '工作原因-工作强度大' },
    { type: 'LACK_LEADERSHIP', name: '工作原因-上级领导力不足' },
    { type: 'BAD_TEAM_ATMOSPHERE', name: '工作原因-团队氛围不好' },
    { type: 'LACK_DEVELOPMENT', name: '工作原因-缺乏晋升及职业发展机会' },
    { type: 'ENTREPRENEURSHIP', name: '个人原因-创业/进修' },
    { type: 'FAMILY_REASONS', name: '个人原因-家庭原因' },
    { type: 'HEALTH_REASONS', name: '个人原因-健康原因' },
    { type: 'POOR_TRANSPORTATION', name: '个人原因-交通不便' },
    { type: 'OTHER', name: '其它' },
  ];
  // 所属组织
  organization = [
    { type: "总裁办", name: '总裁办' },
    // { type: "总部", name: '总部' },
    { type: "财务部", name: '财务部' },
    { type: "人才资本经营部", name: '人才资本经营部' },
    { type: "研发部", name: '研发部' },
    { type: "产品运营部", name: '产品运营部' },
    { type: "数字营销部", name: '数字营销部' },
    { type: "增值产品部", name: '增值产品部' },
    { type: "战略系统项目部", name: '战略系统项目部' },
    { type: "深圳分公司", name: '深圳分公司' },
    { type: "广州分公司", name: '广州分公司' },
    { type: "成都分公司", name: '成都分公司' },
    { type: "重庆分公司", name: '重庆分公司' },
    { type: "西安分公司", name: '西安分公司' },
    { type: "眉山分公司", name: '眉山分公司' },
    { type: "绵阳分公司", name: '绵阳分公司' },
    { type: "海口分公司", name: '海口分公司' },
    { type: "加盟事业部", name: '加盟事业部' },
  ];

  sysUserInductionOptionDetail = {
    //parentDeptName: null, //新上级部门
    //deptName: null, //新 当前部门
    positionName: null, //新 当前岗位
    baseAmount: Number,//新 基础工资（元/月）
    rankAmount: Number,//新 职级工资（元/月）
    jobsAmount: Number, //新 岗位工资（元/月）
    performanceAmount: Number, //新 绩效奖金（元/月）
    fullTimeAmount: Number, //新 全勤（元/月）
    organization: null, //新 所属组织
    oldParentDeptName: null, //旧上级部门
    oldDeptName: null, //旧 当前部门
    oldCompanyName: null, //旧 当前公司
    oldPositionName: null, //旧 当前岗位
    oldBaseAmount: Number,//旧 基础工资（元/月）
    oldRankAmount: Number,//旧 职级工资（元/月）
    oldJobsAmount: Number, //旧 岗位工资（元/月）
    oldPerformanceAmount: Number, //旧 绩效奖金（元/月）
    oldFullTimeAmount: Number, //旧 全勤（元/月）

    //oldOrganization: null, //旧 所属组织
    companyId: null,
    companyName: null,
    deptId: null,
    deptName: null,
    remark: null
  }

  //晋升、调岗
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
    apiTmp = this.api;
    myMap = new Map();
  }
  cancel() {
    this.modal.destroy();
  }

  submitForm() {
    if (this.leaveDate === null) {
      this.message.error('请选择日期');
      return
    }
    if (!this.sysUserInductionOptionDetail.baseAmount && this.params.type != 'lz') {
      this.message.error('请输入基础工资（元/月）');
      return
    }
    if (!this.sysUserInductionOptionDetail.rankAmount && this.params.type != 'lz') {
      this.message.error('请输入职级工资（元/月）');
      return
    }
    if (!this.sysUserInductionOptionDetail.jobsAmount && this.params.type != 'lz') {
      this.message.error('请输入岗位工资（元/月）');
      return
    }
    if (!this.sysUserInductionOptionDetail.performanceAmount && this.params.type != 'lz') {
      this.message.error('请输入绩效奖金（元/月）');
      return
    }
    if (!this.sysUserInductionOptionDetail.fullTimeAmount && this.params.type != 'lz') {
      this.message.error('请输入全勤（元/月）');
      return
    }
    this.actualPaymentDate = this.dataToStr(this.leaveDate);// 日期
    this.uploadReceiptUrls = this.uploadReceiptUrls;// 上传回单
    switch (this.params.type) {
      case 'zz'://转正
        this.zzCheck();
        break;
      case 'dg'://调岗
        this.dgCheck();
        break;
      case 'js'://晋升
        this.jsCheck();
        break;
      case 'tx'://调薪
        this.txCheck();
        break;
      case 'lz'://离职
        this.lzCheck();
        break;
    }
  }
  //转正审核
  zzCheck() {
    let params = {
      id: this.params.id,
      leaveDate: this.actualPaymentDate,
      optionDesc: this.optionDesc,
      attachmentUrls: this.uploadReceiptUrls,
      optionType: 'POSITIVE',
      sysUserInductionOptionDetail: this.sysUserInductionOptionDetail
    }
    params.sysUserInductionOptionDetail['remark'] = JSON.stringify(params.sysUserInductionOptionDetail['remark']);
    this.api.updateUserInductionPositive(params).subscribe(res => {
      this.message.success('操作成功');
      this.modal.destroy();
    });
  }

  //调岗审核
  dgCheck() {

    var sysUserInductionOptionDetail = JSON.parse(JSON.stringify(this.sysUserInductionOptionDetail));
    (this.companyData).map((item, index) => {
      if (item.id == sysUserInductionOptionDetail['companyId']) {
        sysUserInductionOptionDetail['companyName'] = item.name;
      }
    });
    sysUserInductionOptionDetail['deptId'] = sysUserInductionOptionDetail.remark[sysUserInductionOptionDetail.remark.length - 1]

    sysUserInductionOptionDetail['deptName'] = this.findDepartmentName(this.nzOptions, sysUserInductionOptionDetail['deptId']);
    sysUserInductionOptionDetail['remark'] = JSON.stringify(sysUserInductionOptionDetail['remark']);

    if (!sysUserInductionOptionDetail.companyId) {
      this.message.error('请选择申请调岗公司');
      return
    }
    if (!sysUserInductionOptionDetail.deptId) {
      this.message.error('请选择申请调岗部门');
      return
    }
    // if (!this.sysUserInductionOptionDetail.parentDeptName) {
    //   this.message.error('请输入上级部门');
    //   return
    // }
    // if (!this.sysUserInductionOptionDetail.deptName) {
    //   this.message.error('请输入当前部门');
    //   return
    // }
    if (!sysUserInductionOptionDetail.positionName) {
      this.message.error('请输入当前岗位');
      return
    }
    let params = {
      id: this.params.id,
      leaveDate: this.actualPaymentDate,
      optionDesc: this.optionDesc,
      attachmentUrls: this.uploadReceiptUrls,
      optionType: 'TRANSFER_POSITION',
      sysUserInductionOptionDetail: sysUserInductionOptionDetail
    }
    this.api.updateUserInductionPositive(params).subscribe(res => {
      this.message.success('操作成功');
      this.modal.destroy();
    });
  }

  //晋升审核
  jsCheck() {
    // if (!this.sysUserInductionOptionDetail.parentDeptName) {
    //   this.message.error('请输入上级部门');
    //   return
    // }
    // if (!this.sysUserInductionOptionDetail.deptName) {
    //   this.message.error('请输入当前部门');
    //   return
    // }
    var sysUserInductionOptionDetail = JSON.parse(JSON.stringify(this.sysUserInductionOptionDetail));

    (this.companyData).map((item, index) => {
      if (item.id == sysUserInductionOptionDetail['companyId']) {
        sysUserInductionOptionDetail['companyName'] = item.name;
      }
    });

    sysUserInductionOptionDetail['deptId'] = sysUserInductionOptionDetail.remark[sysUserInductionOptionDetail.remark.length - 1]

    sysUserInductionOptionDetail['deptName'] = this.findDepartmentName(this.nzOptions, sysUserInductionOptionDetail['deptId']);
    sysUserInductionOptionDetail['remark'] = JSON.stringify(sysUserInductionOptionDetail['remark']);
    if (!sysUserInductionOptionDetail.companyId) {
      this.message.error('请选择申请调岗公司');
      return
    }
    if (!sysUserInductionOptionDetail.deptId) {
      this.message.error('请选择申请调岗部门');
      return
    }
    if (!sysUserInductionOptionDetail.positionName) {
      this.message.error('请输入当前岗位');
      return
    }
    let params = {
      id: this.params.id,
      leaveDate: this.actualPaymentDate,
      optionDesc: this.optionDesc,
      attachmentUrls: this.uploadReceiptUrls,
      optionType: 'PROMOTION',
      sysUserInductionOptionDetail: sysUserInductionOptionDetail
    }
    this.api.updateUserInductionPositive(params).subscribe(res => {
      this.message.success('操作成功');
      this.modal.destroy();
    });
  }

  //调薪审核
  txCheck() {
    let params = {
      id: this.params.id,
      leaveDate: this.actualPaymentDate,
      optionDesc: this.optionDesc,
      attachmentUrls: this.uploadReceiptUrls,
      optionType: 'SALARY_INCREASE',
      sysUserInductionOptionDetail: this.sysUserInductionOptionDetail
    }
    params.sysUserInductionOptionDetail['remark'] = JSON.stringify(params.sysUserInductionOptionDetail['remark']);
    this.api.updateUserInductionPositive(params).subscribe(res => {
      this.message.success('操作成功');
      this.modal.destroy();
    });
  }
  //离职审核
  lzCheck() {
    let params = {
      id: this.params.id,
      leaveDate: this.actualPaymentDate,
      optionDesc: this.optionDesc,
      attachmentUrls: this.uploadReceiptUrls,
      optionType: 'LEAVE_OFFICE',
      leaveReason1: this.leaveReason1,
      leaveReason2: this.leaveReason2,
    }
    this.api.updateUserInductionPositive(params).subscribe(res => {
      this.message.success('操作成功');
      this.modal.destroy();
    });
  }
  uploadReceiptUrls = []; // 上传回单

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        attachmentUrls.push(item.response.data.fileUrl)
      })
      this.uploadReceiptUrls = attachmentUrls;

    }
  }
  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }
  allAmount = 0;
  changePrice() {
    this.allAmount = Number(this.sysUserInductionOptionDetail.baseAmount || 0) + Number(this.sysUserInductionOptionDetail.rankAmount || 0) + Number(this.sysUserInductionOptionDetail.jobsAmount || 0) + Number(this.sysUserInductionOptionDetail.performanceAmount || 0) + Number(this.sysUserInductionOptionDetail.fullTimeAmount || 0);
  }


  ngOnInit() {
    this.sysUserInductionOptionDetail = {
      //parentDeptName: null, //新上级部门
      //deptName: null, //新 当前部门
      positionName: null, //新 当前岗位
      baseAmount: null,//新 基础工资（元/月）
      rankAmount: null,//新 职级工资（元/月）
      jobsAmount: null, //新 岗位工资（元/月）
      performanceAmount: null, //新 绩效奖金（元/月）
      fullTimeAmount: null, //新 全勤（元/月）
      organization: null, //新 所属组织
      oldParentDeptName: this.params.parentDeptName, //旧上级部门
      oldDeptName: this.params.deptName, //旧 当前部门
      oldCompanyName: this.params.companyName, //旧 当前部门
      oldPositionName: this.params.positionName, //旧 当前岗位
      oldBaseAmount: this.params.baseAmount,//旧 基础工资（元/月）
      oldRankAmount: this.params.rankAmount,//旧 职级工资（元/月）
      oldJobsAmount: this.params.jobsAmount, //旧 岗位工资（元/月）
      oldPerformanceAmount: this.params.performanceAmount, //旧 绩效奖金（元/月）
      oldFullTimeAmount: this.params.fullTimeAmount, //旧 全勤（元/月）

      //oldOrganization: this.params.organization, //旧 所属组织
      companyId: this.params.companyId, // 新 公司
      deptId: this.params.deptId, // 新 部门
      companyName: this.params.companyName, //
      deptName: this.params.deptName, //
      remark: null, //

    }//晋升、调岗
    if (this.params['remark']) {
      this.sysUserInductionOptionDetail['remark'] = (JSON.parse(this.params['remark']) || []).map(Number);
    } else {
      this.sysUserInductionOptionDetail['remark'] = [this.params['deptId']];
    }
    this.getCompanyData(this.sysUserInductionOptionDetail.remark);
  }

  // 公司部门 start
  companyData = []; //公司列表
  nzOptions: any;

  findDepartmentName(data, deptId) {
    for (const item of (data || [])) {
      if (item.id === deptId) {
        return item.name; // 返回找到的部门名称
      }
      if (item.child) {
        // 如果当前项有子项，递归查找子项中的匹配项
        const name = this.findDepartmentName(item.child, deptId);
        if (name) {
          return name; // 如果在子项中找到匹配项，则返回名称
        }
      }
    }
    return null; // 如果没有找到匹配项，则返回null
  }
  //获取公司信息
  getCompanyData(remark?) {
    let data = {
      type: 0,
    };
    this.api.getCompanyOrDept(data).subscribe(res => {
      this.companyData = res['data'];
      if (this.params.companyId) {
        this.getDeptData(remark);
      }
    });
  }
  //获取部门信息
  getDeptData(remark?) {
    cnm = this.params.companyId;
    let data = {
      type: 1,
      companyId: cnm,
    }
    if (!remark) {
      // 首次进入不清楚
      this.params['remark'] = [];
      this.params['deptId'] = null;
    }
    // if (this.isEdit) { //如果是编辑
    this.api.getDept(data).subscribe(res => {
      this.nzOptions = res['data'];
    });
    // }

  }

  loadDeptData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      let data = {
        type: 1,
      }
      if (index < 0) {
        if (cnm == null) {
          return;
        }
        data['companyId'] = cnm;
      } else {
        data['companyId'] = node.id;
      }
      apiTmp.getDept(data).subscribe(res => {
        node.children = res['data'];
        res['data'].forEach(element => {
          myMap.set(element.id, element);
        });
        resolve();
      });
    });
  }

  /**
   * 部门修改
   * @param values
   */
  onDeptChanges(values: any): void {

    //获取子机构

  }

  validate(_index: number): boolean {
    return true;
  }
  // 公司部门 end
}
