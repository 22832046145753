import { Component, OnInit, Input  } from '@angular/core';
import { Api } from '@service/Api';
import {Utils} from '@service/Utils';

@Component({
  selector: 'app-thread-statistical',
  templateUrl: './thread-statistical.component.html',
  styleUrls: ['./thread-statistical.component.less']
})
export class ThreadStatisticalComponent implements OnInit {
  @Input() areaId: String;
  @Input() sourceFlag: String;
  listOfData = [];
  //地区数据
  city_Data = [];
  params = { // 页面查询参数
    areaIds:null,
    dateList:null,
    sourceFlag:null,
  }

  
  constructor(public api: Api,) { }
  /**
   * 获取统计数据
   */
  getCluesStatistics() {
    let param={};
    if(this.params.areaIds){
      param['areaId']=this.params.areaIds[1];
    }else {
      param['areaId']=this.areaId;
    }
    if(this.params.dateList && this.params.dateList.length>0){
      param['beginDate'] = Utils.dateFormat(this.params.dateList[0], 'yyyy-MM-dd');
      param['endDate'] = Utils.dateFormat(this.params.dateList[1], 'yyyy-MM-dd');
    }
    
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object:param,
    }
    if(this.sourceFlag == "HUI_HEART"){
      params.object['sourceFlag']="HUI_HEART"//惠用心
    }else{
      params.object['sourceFlag']="PRECISE"
    }
    this.api.getCluesStatistics(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }
//清空
reset(){
  this.params.dateList=null;
  this.getCluesStatistics()
}
  // #region tab1 分页相关
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getCluesStatistics();
  }
  // #region

  // #region tab2 查询条件初始化
  loadCityData() {
    this.api.getDimensionAreaData().subscribe(res => {
      this.city_Data = res['data'];
    });
  }

  // #endregion

  ngOnInit() {
    this.getCluesStatistics();
    this.loadCityData();
  }

}
