import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
    transform(value: any): any {
        if (value.name != null && value.name != '') {
            return value.name
        } else if (value.phoneNumber != null && value.phoneNumber != '') {
            return value.phoneNumber
        } else if (value.weChat != null && value.weChat != '') {
            return value.weChat
        } else if (value.qqNumber != null && value.qqNumber != '') {
            return value.qqNumber
        } else {
            value.landline
        }
    }
}




