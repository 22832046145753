import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import * as wangEditor from '../../../../../node_modules/wangeditor/release/wangEditor.js';
import { AuthService } from '../../../auth/auth.service';
import { Api } from '@service/Api';
import { environment } from '../../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-technique-work',
  templateUrl: './modal-technique-work.component.html',
  styleUrls: ['./modal-technique-work.component.less']
})
export class ModalTechniqueWorkComponent implements OnInit {
  @Input() params:any;

  canSubmit = true;
  @ViewChild('editroElement', { static: false }) editroElement: ElementRef;
  private editor: any;

  constructor(
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
    private auth: AuthService,
  ) {
  }
  
  workInfo :any = {}
  bsWorkOrderConnectList :any = [];
  cancal() {
    this.modal.destroy();
  }
  ngOnInit() {
    this.api.getAdminWorkOrderListDetail(this.params.id).subscribe(res => {
      this.workInfo = res['data'];
      this.bsWorkOrderConnectList = this.workInfo['bsWorkOrderConnectList'];
    });
  }
  ngAfterViewInit() {
    const authToken = this.auth.getAuthorizationToken() || '';
    const editordom = this.editroElement.nativeElement;
    this.editor = new wangEditor(editordom);
    this.editor.customConfig.uploadFileName = 'file'; // 设置文件上传的参数名称
    this.editor.customConfig.uploadImgHeaders = {
      'Admin-Token': authToken
    }
    this.editor.customConfig.uploadImgServer = environment.baseUrl + this.api.uplaodAttachment(); // 设置上传文件的服务器路径
    this.editor.customConfig.uploadImgHooks = {
      customInsert: (insertImg, result, editor) => {
        const url = result.data.fileUrl;
        insertImg(url);
      }
    }
    this.editor.create();
  }

  reply(){
    let params = {
      workOrderId:this.params.id,
      content:this.editor.txt.html()
    } 
    this.api.saveReceiveWorkOrderConnects(params).subscribe(res=>{
      this.message.success('回复成功');
      this.editor.txt.text('<p></p>')
      this.ngOnInit();
      
    })
  }
}
