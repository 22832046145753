import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import  * as differenceInCalendarDays  from 'date-fns/difference_in_calendar_days';
import  * as startOfMonth  from 'date-fns/start_of_month';
import  * as endOfMonth  from 'date-fns/end_of_month';

import { NzMessageService  } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { Api } from '@service/Api';
import { Utils } from '@service/Utils';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.less']
})
export class CalendarComponent implements OnInit {
  scheduleForm: FormGroup; // 创建日程表单

  constructor(
    public location: Location,
    fb: FormBuilder,
    private modalService: NzModalService,
    private message: NzMessageService,
    public api: Api,
  ) {
    this.scheduleForm = fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }

  // #region 日程
    scheduleList = []; // 日程列表
    ScheduleDateList = []; // 日程列表所包含的日期
    colors = ['#11c06c', '#0bb2d4', '#eb6709', '#ff4c52'];
    theme = '#11c06c';
    today = new Date();
    query = {
      stratTime: '',
      endTime: ''
    };
    detail = { // 当前日程
      title: '',
      content: ''
    };
    // 修改日程主题色
    changeTheme(theme) {
      this.theme = theme;
    }
    // 日期选择限制
    resetEndTime() {
      this.scheduleForm.controls['endTime'].reset();
    }
    disabledDate = (current: Date): boolean => {
      return differenceInCalendarDays(current, this.today) < 0;
    };
    disabledDateEnd = (current: Date): boolean => {
      return differenceInCalendarDays(current, this.scheduleForm.get('startTime').value) < 0;
    };

    // 设置查询参数
    setQueryParams(date: Date) {
      this.query.stratTime = Utils.dateFormat(startOfMonth(date), 'yyyy-MM-dd hh:mm:ss');
      this.query.endTime = Utils.dateFormat(endOfMonth(date), 'yyyy-MM-dd hh:mm:ss');
      this.getScheduleList();
    }
    // 设置日程表状态
    setScheduleStatus(date: Date): Array<object> {
      let arr = [];
      this.scheduleList.map(item => {
        let start = differenceInCalendarDays(date, item.startTime);
        let end = differenceInCalendarDays(date, item.endTime);
        if (start >= 0 && end <= 0) {
          arr.push(item)
        }
      })
      return arr;
    }
    panelChange(e) {
      console.log(e)
    }
    selectChange(date) {
      // this.setQueryParams(date);
      let i = this.scheduleList.find(item => {
        let start = differenceInCalendarDays(date, item.startTime);
        let end = differenceInCalendarDays(date, item.endTime);
        return (start >= 0 && end <= 0);
      })
      if (i) {
        this.detail.title = i.title;
        this.detail.content = i.content;
      } else {
        this.detail.title = this.detail.content = '';
      }
    }
    // 获取日程列表
    getScheduleList() {
      let params = {
        startTime: this.query.stratTime,
        endTime: this.query.endTime,
      }
      this.api.getScheduleList(params).subscribe(res => {
        this.scheduleList = res['data'];
      })
    }
    // 添加日程
    addSchedule(content) {
      this.modalService.create({
        nzTitle: '新建日程',
        nzContent: content,
        nzStyle: { top: '40px' },
        nzWidth: 700,
        nzMaskClosable: false,
        nzOnCancel: () => this.scheduleForm.reset(),
        nzOnOk: () => new Promise((resolve, reject) => {
          // 更新表单状态
          Object.keys(this.scheduleForm.controls).map((key) => {
            this.scheduleForm.controls[key].markAsDirty();
            this.scheduleForm.controls[key].updateValueAndValidity();
          });
          if (!this.scheduleForm.valid) {
            resolve(false)
            return
          }
          let params = this.scheduleForm.value;
          params.startTime = Utils.dateFormat(params.startTime, 'yyyy-MM-dd hh:mm:ss');
          params.endTime = Utils.dateFormat(params.endTime, 'yyyy-MM-dd hh:mm:ss');
          params.color = this.theme;
          this.api.addSchedule(this.scheduleForm.value).subscribe(res => {
            this.scheduleForm.reset();
            this.getScheduleList();
            this.message.success('日程添加成功');
            resolve(true)
          }, err => {
            resolve(false)
          });
        }).catch(() => {})
      });
    }
  // #endregion
  
  // 返回上一页
  onBack() {
    this.location.back();
  }

  ngOnInit() {
    this.getScheduleList();
  }

}
