import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-delivery',
  templateUrl: './modal-delivery.component.html',
  styleUrls: ['./modal-delivery.component.less']
})
export class ModalDeliveryComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) { }

  ngOnInit() {
    this.period = new Date();
    this.query();
  }
  period = null;
  params = {
    companyName: null,
    period: null,
  }

  canSubmit = true;
  arrayList = [
    // {
    //   salaryMonth: 11,
    //   service: [
    //     { label: 'Apple', value: 'Apple', disabled: true, checked: true },
    //     { label: 'Pear', value: 'Pear', disabled: true },
    //     { label: 'Orange', value: 'Orange' }
    //   ]
    // },
    // {
    //   salaryMonth: 2323,
    //   service: [
    //     { label: 'Apple', value: 'Apple', disabled: true, checked: true },
    //     { label: 'Orange', value: 'Orange' }
    //   ]
    // }
  ];
  month = null;
  // Utils.dateFormat(params.startPeriod, 'yyyy-MM');//月份
  save(value) {
    this.canSubmit = false;
    console.log(this.arrayList)
    var param = []
    this.arrayList.map((item, index) => {
      item.service.map((child, childIndex) => {
        console.log(child)
        if (!child.disabled && child.checked) {
          var obj = {
            id: child.value,
            periodStatus: 1,
          }
          param.push(obj)
        }
      })
    })
    if (param.length == 0) {
      this.message.error('请选择交付服务！');
      return
    }
    this.api.batchDeliver(param).subscribe(res => {
      this.modal.destroy();
      this.canSubmit = false
      this.message.success('交付成功！');
    }, () => this.canSubmit = false);
  }

  cancel() {
    this.modal.destroy();
  }
  // 转换时间格式
  dateToStr(date) {
    if (date) {
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      month = month < 10 ? '0' + month : month;
      return date = year + '-' + month;
    }
    else {
      return date = null;
    }
  }

  selectService(value: object[]): void {
    console.log(value);
  };
  query() {
    this.pageCtrl.nzPageIndex = 1;
    console.log(this.period)
    this.params.period = this.dateToStr(this.period);
    this.deliverPeriodList();
  }
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 999
  };
  deliverPeriodList() {
    var paramsData = JSON.parse(JSON.stringify(this.params));
    console.log(paramsData.period)
    if (!paramsData.period) {
      this.message.error('请选择月份！');
      return
    }
    paramsData.period = paramsData.period.replace("-", "");
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: paramsData
    }
    this.api.deliverPeriodList(params).subscribe(res => {
      this.arrayList = res['data'].records;
      this.arrayList.map((item, index) => {
        item['service'] = [];
        item.bsCompanyServerList.map((child, childIndex) => {
          var obj = {
            label: child.companyServerTypeName,
            value: child.periodId,
            disabled: child.periodStatus == 1,
            checked: child.periodStatus == 1
          }
          item['service'][childIndex] = obj;
        })
      })
      // this.pageCtrl.total = res['data'].total;
    });
  }
}
