import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { DomSanitizer } from '@angular/platform-browser'
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadFilter, UploadFile } from 'ng-zorro-antd/upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-offline-payment',
  templateUrl: './offline-payment.component.html',
  styleUrls: ['./offline-payment.component.less']
})
export class OfflinePaymentComponent implements OnInit {
  @Input() orderId: string;
  @Input() customerId: string;
  @Input() type: string;
  encryptionId;
  contractId;
  showQrcode = false;//充值页面
  fileList = []; // 文件列表
  // uploadFile: any; // 上传文件
  uploadParams = {};
  loading = false;
  form: FormGroup;
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => ~['image'].indexOf(w.type.slice(0, 5)));
        if (filterFiles.length !== fileList.length) {
          this.message.error(`包含文件格式不正确，只支持上传图片`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: UploadFile[]) => {
        return new Observable((observer: Observer<UploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];
  qrcodeUrl: any = "";
  rate: any = "";
  uploadUrl: string;
  account = 0;//充值金额
  serviceRate = 0;//服务费率
  serviceAmount = 0;//服务费
  jmRate = 0;//加盟占比
  jmAmount = 0;//加盟事业部结算金额
  numberAmount = 0;//数字营销部结算金额
  payDate = null;//付款日期
  actualPayAmount = 0;
  objId = '';
  actuaPrice = 0;
  canSubmit = false;

  formatterPercent = (value: number): string => `${value} %`;
  parserPercent = (value: string): string => value.replace(' %', '');

  companyList = [];

  constructor(
    private api: Api,
    private modal: NzModalRef,
    public sanitizer: DomSanitizer,
    public message: NzMessageService,
    fb: FormBuilder,
  ) {
    this.uploadUrl = this.api.uplaodAttachment();
    this.uploadParams['contractId'] = '';
  }

  accountRechargeCalculation() {
    // 充值金额  * 输入服务费率 % / 100 得出服务费1600
    this.serviceAmount = Number((this.account * (this.serviceRate / 100)).toFixed(2));

    // 需支付额 = 充值金额 + 服务费
    this.actualPayAmount = Number((this.account + this.serviceAmount).toFixed(2));

    // 旧版本：服务费1600，输入25%加盟占比 那么 1600 * 25% 等于加盟事业部结算金额，剩下的为数字营销部结算金额
    // 更改后： 营销收入 == 充值金额 * 百度返点%
    this.jmAmount = Number((this.account * (this.jmRate / 100)).toFixed(2));
    // this.numberAmount = Number((this.serviceAmount - this.jmAmount).toFixed(2));
  }

  cancel() {
    this.modal.destroy();
  }
  // 上传合同
  uploadChange(info) {
    if (info.file.status === 'done' && info.file.response.code === 200) {
      this.fileList[0].id = info.file.response.data.id;
      this.uploadParams['contractId'] = '';
      this.encryptionId = info.file.response.data;
      this.encryptionId = info.file.response.data.id;
    }
  }
  // 保存合同信息
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (this.fileList.length <= 0) {
      this.message.warning('请上传合同！');
      this.loading = false;
      return
    }
    if (!this.form.valid) {
      this.loading = false;
      return
    }

    let params = this.form.getRawValue();

    params.annexId = this.fileList[0].id;
    params.orderId = this.orderId;
    params.customerId = this.customerId;
    params.types = 0;
    params.encryptionId = this.encryptionId;

    let http;
    let msg = '';
    try {
      params.startTime = this.dataToStr(params.startTime);
      params.endTime = this.dataToStr(params.endTime);
    } catch (e) {

    }
    if (this.type == 'add') {
      http = this.api.saveContract(params);
      msg = '添加';
    } else {
      msg = '修改';
      params['id'] = this.contractId;
      http = this.api.editContract(params);
    }

    http.subscribe(res => {
      this.message.success(`合同${msg}成功！`);
      this.modal.destroy();
    }, err => {
      this.loading = false;
    })
  }

  add() {
    this.canSubmit = true

    if (this.objId == '') {
      this.message.error('选择充值的公司');
      this.canSubmit = false
      return
    }
    if (this.account == 0) {
      this.message.error('请输入充值金额');
      this.canSubmit = false
      return
    }
    if (this.account < 1000) {
      //充值金额不能值于1000
      this.message.error('充值金额不能低于1000');
      this.canSubmit = false
      return
    }
    if (this.serviceRate.toString() === "") {
      this.message.error('请输入服务费率');
      this.canSubmit = false
      return
    }
    if (this.jmRate.toString() === "") {
      this.message.error('请输入百度返点');
      this.canSubmit = false
      return
    }
    if (!this.payDate) {
      this.message.error('请选择付款时间');
      this.canSubmit = false
      return
    }

    // this.actuaPrice = this.account * 1.08;
    // this.actualPayAmount = Number(this.actuaPrice.toFixed(2));
    this.payDate = Utils.dateFormat(this.payDate, 'yyyy-MM-dd 00:00:00');

    let param = {
      account: this.account,
      actualPayAmount: this.actualPayAmount,
      objId: this.objId,
      payMode: "OFFLINE_MODE",
      attachmentId: this.encryptionId,

      serviceRate: this.serviceRate,
      serviceAmount: this.serviceAmount,
      jmRate: this.jmRate,
      jmAmount: this.jmAmount,
      numberAmount: this.numberAmount,
      payDate: this.payDate,
      flowBusinessStepDTOList: [],
    }
    console.log(param)
    // 审核模块 start
    var flowBusinessStepDTOList = [];
    var stepBool = true;
    (this.flowListStep || []).map((item, index) => {
      flowBusinessStepDTOList[index] = {
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        serviceNo: item.serviceNo,
        stepId: item.stepId,
        stepName: item.stepName,
        stepNo: item.stepNo,
      }
      // 循环用户列表，获取用户id 与名称
      var selected_user = [];
      var selectedFin = [];
      // 区分用户为多人或者单人
      if (item.userId instanceof Array) {
        if (item.userId.length == 0) {
          stepBool = false;
          return false;
        }
        item.userId.map((child, index) => {
          selected_user = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == child
          });
          selectedFin.push(Object.assign({}, ...selected_user))
        })
      } else {
        if (!item.userId) {
          stepBool = false;
          return false;
        }
        selectedFin = this.usersList.filter((obj) => {
          obj.userName = obj.name;
          return obj.userId == item.userId
        });
      }
      flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
    })
    if (!stepBool) {
      this.message.error('审核人员不能为空！');
      this.canSubmit = false;
      return;
    }
    param['flowBusinessStepDTOList'] = flowBusinessStepDTOList;
    // 审核模块 end

    this.api.offlineDeposit(param).subscribe(res => {
      if (res['code'] == 200) {
        this.message.success('充值申请已提交，请等待财务审核结果');
        this.modal.destroy();
        this.canSubmit = false
      }
    });
  }

  handleCancel() {
    this.showQrcode = false;
  }
  // 获取用户权限，公司或部门的权限
  getDataDimension() {
    this.api.getOfficeList({ type: 0 }).subscribe((res) => {
      this.companyList = res['data'];
    })
  }

  itemClass: any;
  dataToStr(date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }
  ngOnInit() {
    this.getDataDimension();
    // 审核模块 start
    this.getUserList();
    this.getServiceFlowList('recharge');
    // 审核模块 end
    let params = { parentCode: 'OFFICE_PAY' }
    this.api.getSysParameters(params).subscribe(res => {
      this.itemClass = res['data'];
    });
    if (this.type == 'edit') {
      this.api.getContract({ orderId: this.orderId }).subscribe(res => {
        const data = res['data'];
        this.contractId = data.id;
        Object.keys(data).map((key) => {
          if (this.form.get(key)) {
            this.form.get(key).setValue(data[key]);
          }
        })
        this.fileList = [{
          id: data.annexId,
          url: data.fileUrl,
          name: data.fileUrl
        }]
      })
    }
  }
  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end
}
