import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerBugetManageComponent } from '../../component/drawer-buget-manage/drawer-buget-manage.component';
import { DrawerProjectDetailComponent } from '../../component/drawer-project-detail/drawer-project-detail.component';
@Component({
  selector: 'app-drawer-reimbursements-apply',
  templateUrl: './drawer-reimbursements-apply.component.html',
  styleUrls: ['./drawer-reimbursements-apply.component.less']
})
export class DrawerReimbursementsApplyComponent implements OnInit {
  @Input() value = '';
  @Input() type = '';

  applyInfo;
  loading = false;
  contractUrl: string;
  constructor(
    public location: Location,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    public global: Global,
    private drawerService: NzDrawerService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  permissions = []; // 按钮权限数组
  ngOnInit() {
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
    });
    this.api.getUserReimbursementsApplyDetail(this.value).subscribe(res => {
      this.applyInfo = res['data'];
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.applyInfo.flowBusinessStepDTOList && this.applyInfo.flowBusinessStepDTOList.length > 0) {
        this.applyInfo.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    })
  }
  receiptAttachmentList = [];
  // 回单
  uploadChangeHd(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        var params = { "attachmentUrl": '' };
        params.attachmentUrl = item.response.data.fileUrl
        attachmentUrls.push(params)
      })
      this.receiptAttachmentList = attachmentUrls;
    }
  }

  uploadTem(temp) {
    this.modalService.create({
      nzTitle: '修改回单',
      nzContent: temp,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        if (this.receiptAttachmentList.length == 0) {
          this.message.error('请上传回单');
          return false;
        }
        this.api.userReimbursementsApplyPpdate({
          id: this.value,
          receiptAttachmentList: this.receiptAttachmentList
        }).subscribe(res => {
          this.ngOnInit();
          this.receiptAttachmentList = [];
        })
      }
    });
  }


  uploadChange(info, data) {
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      this.loading = true
      let obj = {
        attachmentUrl: info.file.response.data.fileUrl,
        attachmentKind: 0,
        userApplyItemId: data.id,
      }
      this.api.reimbursementsApplySaveFileurl(obj).subscribe(res => {
        this.ngOnInit();
        this.loading = false
      })
    }
  }


  /// 打开详情
  open(budgetConfigId) {
    if (!budgetConfigId) {
      this.message.info('此分摊中心没有配置预算！');
      return false;
    }
    const modal = this.drawerService.create<DrawerBugetManageComponent, { value: string }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerBugetManageComponent,
      nzContentParams: {
        value: budgetConfigId,
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }
  // 打开详情
  openProject(id) {
    const modal = this.drawerService.create<DrawerProjectDetailComponent, { value: string }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerProjectDetailComponent,
      nzContentParams: {
        value: id,
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }
}
