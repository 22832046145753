import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
@Component({
  selector: 'app-modal-verify-commissionlist',
  templateUrl: './modal-verify-commissionlist.component.html',
  styleUrls: ['./modal-verify-commissionlist.component.less']
})
export class ModalVerifyCommissionlist implements OnInit {
  @Input() month: String;
  @Input() userId: String;

  listOfData = [];

  constructor(public api: Api,) { }

  params = { // 页面查询参数
    type:1,
  }

  commissionVerifyList(){  
    this.params['month'] = this.month;
    this.params['userId'] = this.userId;
    this.api.commissionVerifyList(this.params).subscribe(res=>{
      this.listOfData=res['data'];
    })

  }


  ngOnInit() {
    this.commissionVerifyList();
  }

}
