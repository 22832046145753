import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { Location } from '@angular/common';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-payslip',
  templateUrl: './modal-payslip.component.html',
  styleUrls: ['./modal-payslip.component.less']
})
export class ModalPayslipComponent implements OnInit {

  @Input() params: any;
  constructor(
    public location: Location,
    public api: Api,
    private modal: NzModalRef,
  ) { }

  arrayList = [];
  ngOnInit() {
    this.api.getUserSalaryListAll(this.params).subscribe((res) => {
      this.arrayList = res['data'];
    })
  }

  submit() {
    // 提交重新计算
    (this.arrayList || []).map(data => {
      // 合计应发
      data.totalPayAmount =
        ((
          data.fullTimeAmount
          + data.baseAmount
          + data.rankAmount
          + data.jobsAmount
          + data.actualPerformanceAmount
          + data.allSubsidyAmount
          + (data.compensationBonusAmount || 0)
          + data.baseCommissionAmount
          + (data.annualBonusAmount || 0)
          + (data.honorBonusAmount || 0)
          + (data.projectBonusAmount || 0)
          + (data.achievementBonusAmount || 0)
          + (data.otherBonusAmount || 0)
        ) - (data.absenceDutyDeductionAmount + data.otherDeductionAmount + data.temporaryAmount || 0 + data.clueAmount || 0)).toFixed(2);

      // 合计实发 actualPayAmount = 合计应发 totalPayAmount-（个人社保 overallSocialSecurityAmount +公积金 fundAmount + 个税 personalTaxAmount ）
      data.actualPayAmount = (data.totalPayAmount - (data.overallSocialSecurityAmount + data.fundAmount + data.personalTaxAmount)).toFixed(2);
    })

    let str = this.arrayList;
    this.api.getUserSalaryListSave(str).subscribe((res) => {
      this.modal.destroy();
    })
  }

  cancel() {
    this.modal.destroy();
  }

  // 合计请款金额 与预扣
  getPriceByAmount(data) {
    console.log(data)

    //总社保 = 个人社保 +  公司社保
    // if(type == 'social'){
    //   data.allSocialSecurityAmount = data.overallSocialSecurityAmount + data.companySocialSecurityAmount
    // }

    //总公积金 = 个人公积金 + 公司公积金
    // if(type == 'fund'){
    //   data.allFundAmount = data.fundAmount + data.companyFundAmount
    // }

    let requiredOutDay = data.requiredOutDay || 0;
    let actualOutDay = data.actualOutDay || 0;

    let day = requiredOutDay ? (data.comprehensiveAmount / requiredOutDay) : 0;//处理除数不能为空，出勤天数为空 直接为0
    // 缺勤扣款 = 综合工资 除以 应出勤天数 乘以 （ 应出勤天数	减去 实际出勤天数 ）
    //data.absenceDutyDeductionAmount = day * (requiredOutDay - actualOutDay);

    //缺勤扣款 =（基本工资+职级工资+岗位工资+绩效基数）-（(基本工资+岗位工资+实际绩效）/ 应出勤) * 实际出勤
    // data.absenceDutyDeductionAmount = 
    //   (data.baseAmount + data.jobsAmount + data.performanceAmount) - ((data.baseAmount + data.jobsAmount + data.actualPerformanceAmount) / requiredOutDay) * actualOutDay;

    data.absenceDutyDeductionAmount = (data.baseAmount + data.rankAmount + data.jobsAmount + data.actualPerformanceAmount) * ((requiredOutDay - actualOutDay) / requiredOutDay)

    // 全勤 actualOutDay 实际出勤天数 == requiredOutDay 应出勤天数 data.fullTimeAmount = 200 else data.fullTimeAmount = 0
    // 全勤直接输入，不计算
    // if(actualOutDay == requiredOutDay){
    //   data.fullTimeAmount = 200
    // }else{
    //   data.fullTimeAmount = 0
    // }

    if (requiredOutDay == 0 || actualOutDay == 0) {
      data.totalPayAmount = 0
      data.actualPayAmount = 0
      return
    }

    // baseCommissionAmount
    //  全勤+基础工资+基础工资+职级工资+实际绩效奖金+    总补贴 + 补偿金   年度目标奖+荣誉奖金+项目奖金+业绩奖金+其他奖金+    业务提成总金额 - 缺勤扣款+其他扣款
    // 合计应发
    data.totalPayAmount =
      ((
        data.fullTimeAmount
        + data.baseAmount
        + data.rankAmount
        + data.jobsAmount
        + data.actualPerformanceAmount
        + data.allSubsidyAmount
        + (data.compensationBonusAmount || 0)
        + data.baseCommissionAmount
        + (data.annualBonusAmount || 0)
        + (data.honorBonusAmount || 0)
        + (data.projectBonusAmount || 0)
        + (data.achievementBonusAmount || 0)
        + (data.otherBonusAmount || 0)
      ) - (data.absenceDutyDeductionAmount + data.otherDeductionAmount + data.temporaryAmount || 0 + data.clueAmount || 0)).toFixed(2);

    // 合计实发 actualPayAmount = 合计应发 totalPayAmount-（个人社保 overallSocialSecurityAmount +公积金 fundAmount + 个税 personalTaxAmount ）
    data.actualPayAmount = (data.totalPayAmount - (data.overallSocialSecurityAmount + data.fundAmount + data.personalTaxAmount));
  }

}
