import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
@Component({
  selector: 'app-modal-renew-order',
  templateUrl: './modal-renew-order.component.html',
  styleUrls: ['./modal-renew-order.component.less']
})
export class ModalRenewOrderComponent implements OnInit {


  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {

  }
  productList;         // 产品列表

  sreach() {
    this.getSendServerListRenewSingle();
  }
  reset() {
    this.pageCtrl.current = 1;
    this.params = {
      companyServerNo: null,
      companyName: null,
      companyServerStatus: 1,
      renewStatus: null,
      typeFlag: 1,
    }
    this.getSendServerListRenewSingle();
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getSendServerListRenewSingle();
  }

  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    name: null,
  };
  total;
  params = {
    companyServerNo: null,
    companyName: null,
    companyServerStatus: null,
    renewStatus: null,
    typeFlag: 1,
  };

  // 获取列表
  getSendServerListRenewSingle() {
    const params = {
      current: this.pageCtrl.current,
      size: this.pageCtrl.size,
      object: this.params
    }
    this.api.getSendServerListRenewSingle(params).subscribe(res => {
      this.total = res['data'].total;
      this.productList = res['data'].records;
    })
  }

  cancel() {
    this.modal.destroy();
  }
  submit() {
    if (this.signTeplateData.length === 0) {
      this.message.warning('请选择客户！');
      return
    };
    this.modal.destroy(this.signTeplateData);
  }



  ngOnInit() {
    this.getSendServerListRenewSingle();
  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  signTeplateData;
  selectedCategory(e, id): void {
    // const sttings = cateIds.split(',');
    // this.levelOne = Number(sttings[0]);
    // this.getcategorySecond(sttings[0]);
    // this.levelTwo = Number(sttings[1]);
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.signTeplateData = this.productList.filter(item => item.id == id);
  }


}