import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-drawer-task-collect',
  templateUrl: './drawer-task-collect.component.html',
  styleUrls: ['./drawer-task-collect.component.less']
})
export class DrawerTaskCollectComponent implements OnInit {
  @Input() value = '';
  @Input() status = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      userId: [null, Validators.required],
    });
  }
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  form: FormGroup;
  listOfData = [];
  params = {
    userId: null,
    status: null,
  }
  ngOnInit() {
    this.params.userId = this.value;
    this.params.status = this.status;
    this.getOrderTaskList();
    if (this.status == '0') {
      this.getUserList();
    }
  }

  // 翻页
  toPage(page) {
    console.log(page)
    this.pageCtrl.nzPageIndex = page;
    this.getOrderTaskList();
  }

  // 获取客户列表
  getOrderTaskList() {
    // todo: 目前无筛选条件，暂时传入空值
    var paramsData = this.params;
    if (paramsData.userId == 'null') {
      delete paramsData.userId
    }
    if (paramsData.status == 'null') {
      delete paramsData.status
    }
    // 是否交付0否1是 已交付的工单属于完结
    if (paramsData.status == 'deliverStatus') {
      paramsData['deliverStatus'] = 1;
      delete paramsData.status;
    }
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    }
    this.api.userOrderTaskList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  // 打开客户信息详情
  open(id) {
    const drawerRef = this.drawerService.create<DrawerTaskComponent, { value: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id
      }
    });

    drawerRef.afterClose.subscribe((data) => {
    });
  }
  userList = []; // 用户列表
  // 获取用户列表
  getUserList() {
    // 不选择部门，直接选择人
    const params = {
      current: 1,
      size: 100,
      object: {
        objId: JSON.parse(localStorage.getItem('userInfo')).dept.id,
        type: 1,
      },
    }
    this.api.getUserOfficeList(params).subscribe(res => {
      this.userList = res['data'].records;
      // item['data'].records;
    })
  }
  // 选择负责人
  openAssign(content, id?) {

    let list = [];
    if (!id) {
      // this.listOfData
      //   .filter(item => this.mapOfCheckedId[item.id])
      //   .map(v => list.push(v.id));

      // if (list.length === 0) {
      //   this.message.warning('请先选择任务！');
      //   return
      // };
    } else {
      list.push(id);
    }

    this.modalService.create({
      nzTitle: '选择负责人',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 500,
      nzMaskClosable: false,
      nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.assignTask(list)
    });
  }
  // 指派任务
  assignTask(list) {
    this.form.controls.userId.markAsDirty();
    this.form.controls.userId.updateValueAndValidity();
    if (!this.form.valid) {
      return false;
    }
    // let params = this.form.value;
    let params = { userId: this.form.get('userId').value, ids: list };
    // params.ids = list;
    this.api.assignTask(params).subscribe((res) => {
      this.message.success('任务指派成功！');
      this.getOrderTaskList();
    })
  }

}
