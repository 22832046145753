import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { ModalCompanyComponent } from '../../component/modal-company/modal-company.component';
import { ModalServerConfigAddComponent } from '../../component/modal-server-config-add/modal-server-config-add.component';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-modal-addservice',
  templateUrl: './modal-addservice.component.html',
  styleUrls: ['./modal-addservice.component.less']
})
export class ModalAddserviceComponent implements OnInit {
  @Input() detailInfo: any; // 产品id
  // @Input() id: string; // 工单id
  // @Input() customerId: string; // 客户id
  // @Input() customerName: string; // 客户id
  // @Input() productNature: number; // 是否长期产品
  // @Input() period: number; // 期间数字
  // @Input() realPrice: number; // 实际销售价
  // @Input() companyId: number; // 客户公司id
  // @Input() actualStartDate: number; // 开启时间

  form: FormGroup;
  loading = false;
  priorityList = [];
  itemTypeFlag = null;
  findCompany = true;//控制 预估时间
  companyList: any = [];
  serverConfigList: any = [];
  saveConfigList: any = [];

  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      companyId: [null],
      customerType: [null, Validators.required],
      customerId: [null, Validators.required],
      channelFlag: [null, Validators.required],
      scale: [null, Validators.required],
      isHighTechnology: [null, Validators.required],
      actualStartDate: [null, Validators.required],
      // actualEndDate: [null, Validators.required],
      companyServerPeriod: [null, Validators.required],
    });
  }

  customerTypeList: any = [
    { id: 0, name: '客户' },
    { id: 1, name: '企业' },
  ]

  objectType: any = null;
  customerList: any = []
  serverRecordList: any = []
  userList: any = []
  findCompanyByObject(event) {
    this.objectType = event
  }
  productDetail;
  hasCompany = false;
  // 获取产品详情
  hasaServerPeriod = true;
  serverProductDetail() {
    this.api.serverProductDetail(this.detailInfo.id).subscribe((res) => {
      this.productDetail = res['data'];
      this.form.get('companyServerPeriod').setValue(this.productDetail.serverPeriod);
      this.hasaServerPeriod = this.productDetail.serverPeriod ? true : false;
      this.form.get('actualStartDate').setValue(this.productDetail.startDate);
      this.form.get('customerId').setValue(this.detailInfo.customerId);
      // 判断是否已经存在公司，没有则可以选择
      if (!this.productDetail.companyId) {
        // this.getUserCompanyIdsList(this.detailInfo.customerId)
      } else {
        this.hasCompany = true;
        this.form.get('companyId').setValue(this.detailInfo.companyId);
        this.isHighTechnology = this.detailInfo.isHighTechnology ? '1' : '0';
        console.log(this.isHighTechnology)
        this.form.get('isHighTechnology').setValue(this.detailInfo.isHighTechnology ? '1' : '0');
        this.scale = this.detailInfo.scale;
        this.form.get('scale').setValue(this.detailInfo.scale);
        // this.channelFlag = this.detailInfo.channelFlag;
      }
    })
  }
  // 订单产品关联的服务类型
  getServerType() {
    this.api.getServerType(this.detailInfo.id).subscribe((res) => {
      this.serverConfigList = res["data"];
      (this.serverConfigList || []).map((item, index) => {
        if (this.detailInfo.startDate) {
          item.actualStartDate = this.detailInfo.startDate;
          this.hasStartDate = true;
        }
        this.getServerEndDatesIndex(index);
      })
    })
  }
  ngOnInit() {
    this.serverProductDetail();
    this.getUserCompanyIdsList(this.detailInfo.customerId);
    // this.getServerType();
    this.form.get('customerType').setValue(1);
    this.objectType = 1;
    // this.getUserList();
  }
  getUserList() {
    const params = {
      current: 1,
      size: 1000,
      object: {
        // objId: JSON.parse(localStorage.getItem('userInfo')).dept.id,
        // type: 1,
      },
    }
    this.api.queryUserListByCompany(params).subscribe(res => {
      this.userList = res['data'].records;
    })
  }

  cancel() {
    this.modal.destroy();
  }

  // 提交任务
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    let _obj: any = {};
    if (params.customerType == 0) {
      _obj.customerId = params.customerId;
      _obj.companyId = null;
    }
    if (params.customerType == 1) {
      if (!params.companyId) {
        this.message.error('请选择客户公司！')
        this.loading = false
        return
      } else {
        _obj.companyId = params.companyId;
        _obj.customerId = params.customerId;
      }
    }

    // 单价按服务数量分摊
    // var singleRealPrice = this.detailInfo.realPrice;
    // if (this.serverConfigList.length > 0) {
    //   (this.serverConfigList).map(item => {
    //     if (!item.actualStartDate) {
    //       this.message.error('请选择开启时间！')
    //       this.loading = false
    //       return
    //     }
    //     if (!item.actualEndDate) {
    //       this.message.error('请选择结束时间！')
    //       this.loading = false
    //       return
    //     }
    //     // if (item.typeUnit > 0 && !item.orderProductPeriod) {
    //     //   this.message.error('请输入期间数！')
    //     //   this.loading = false
    //     //   return
    //     // }
    //     // if (!item.chargeId) {
    //     //   this.message.error('请选择服务负责人！')
    //     //   this.loading = false
    //     //   return
    //     // }
    //     // item.actualEndDate = Utils.dateFormat(item['actualEndDate'], 'yyyy-MM-dd')
    //     // item.actualStartDate = Utils.dateFormat(item['actualStartDate'], 'yyyy-MM-dd')
    //     item.companyServerTypeName = item.typeName
    //     item.companyServerTypeId = item.id
    //     // item.companyServerTypeItemId = item.id
    //     item.customerId = _obj.customerId
    //     item.companyId = _obj.companyId
    //     item.customerType = params.customerType
    //     item.orderProductRecordId = this.detailInfo.orderProductId;
    //     item.estimateAmount = (item.otherCost || 0) + (item.commissionAmount || 0);
    //     // item.orderTaskId = this.id
    //     // 客户服务每期价格
    //     // item.periodAmount = (singleRealPrice / this.form.value.companyServerPeriod).toFixed(2);
    //   })
    // } else {
    //   this.message.error('请添加服务类别！')
    //   this.loading = false
    //   return
    // }
    if (!this.loading) {
      return
    }
    let modelVlue = {
      // bsCompanyServerList: this.serverConfigList,
      channelFlag: Number(params.channelFlag),
      companyId: params.companyId,
      customerId: params.customerId,
      isHighTechnology: Number(params.isHighTechnology),
      scale: Number(params.scale),
      orderProductRecordId: this.detailInfo.id,
      companyServerPeriod: params.companyServerPeriod,
      actualStartDate: Utils.dateFormat(params['actualStartDate'], 'yyyy-MM-dd')
    }
    this.api.saveBatchServersNew(modelVlue).subscribe((res) => {
      this.message.success('创建成功！');
      this.modal.destroy(res);
    }, err => {
      this.loading = false
    })
  }
  /*
  //选择服务类型
  findCompanyByArea(event) {
    (this.priorityList || []).map((item) => {
      if (item.id == event) {
        this.itemTypeFlag = item.typeFlag;
        if (item.typeFlag) {
          //长期类型
          this.findByCompanyServerType();
        } else {
          this.findCompany = true;
        }
        return
      }
    })
  }
  renewList = {};
  findByCompanyServerType() {
    //选择长期类型,查询是否有开过服务
    let params = this.form.getRawValue();
    if (!params.companyId) {
      this.message.error('请先选择客户公司')
      return
    }
    this.api.findByCompanyIdAndServerType(
      {
        companyId: params.companyId,
        companyServerTypeId: params.companyServerTypeId,
      }
    ).subscribe((res) => {
      this.renewList = res['data'];
      this.findCompany = res['data'] ? false : true
    })
  }
  */
  //公司
  getUserCompanyIdsList(customerId) {
    this.api.getCompanySelectByCusIds({ customerId: customerId }).subscribe((res) => {
      this.companyList = res['data'];
      if (this.detailInfo.companyId) {
        this.companyChange(this.detailInfo.companyId);
      }
    })
  }

  scaleOptions = [
    { label: '小规模', value: '0' },
    { label: '一般纳税人', value: '1' },
  ];

  options = [
    { label: '是', value: '1' },
    { label: '否', value: '0' },
  ];

  scale: any = null;
  isHighTechnology: any = null;
  channelFlag: any = null;
  //选择公司 radio 赋值 
  companyChange(event) {
    let params = this.form.getRawValue();
    let _arr = this.companyList.filter(item => item.id === event)
    if (_arr[0].channelFlag !== null) {
      params.channelFlag = _arr[0].channelFlag.toString();
      this.channelFlag = _arr[0].channelFlag.toString();
    } else {
      params.channelFlag = null;
      this.channelFlag = null;
    }
    if (_arr[0].isHighTechnology !== null) {
      params.isHighTechnology = _arr[0].isHighTechnology ? '1' : '0';
      this.isHighTechnology = _arr[0].isHighTechnology ? '1' : '0';
    } else {
      params.isHighTechnology = null;
      this.isHighTechnology = null;
    }
    if (_arr[0].scale !== null) {
      params.scale = _arr[0].scale.toString();
      this.scale = _arr[0].scale.toString();
    } else {
      params.scale = null;
      this.scale = null;
    }
  }

  // 新建公司
  createComponentModal(): void {
    const title = "添加公司";
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.detailInfo.customerId,
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe(() => this.getUserCompanyIdsList(this.detailInfo.customerId));
  }
  hasStartDate = false;
  openServerConfig(): void {
    var params = this.form.getRawValue();
    if (params.customerType == null) {
      this.message.error('请选择服务对象！')
      return
    }
    if (params.customerType == 1 && !params.companyId) {
      this.message.error('请选择客户公司！')
      return
    }
    if (params.customerType == 0 && !params.customerId) {
      this.message.error('请选择客户！')
      return
    }

    const modal = this.modalService.create({
      nzTitle: '添加服务类别',
      nzContent: ModalServerConfigAddComponent,
      nzStyle: { top: '40px' },
      nzWidth: '1250',
      nzComponentParams: {
        orderChoiceList: this.serverConfigList,
      },
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        // 去重
        var mapOfCheckedItemInit = res;
        var finallRes = [];
        finallRes = mapOfCheckedItemInit.filter(item => !this.serverConfigList.some(ele => ele.typeNo === item.typeNo));
        var orderChoiceList = this.serverConfigList.concat(finallRes);
        (orderChoiceList || []).map((item) => {
          item.orderProductPeriod = this.detailInfo.serverPeriod;
        });
        this.serverConfigList = orderChoiceList;
        //查询当前公司,客户是否有服务
        if (this.serverConfigList.length > 0) {
          var serverRecordList = [];
          (this.serverConfigList || []).map((choice, index) => {
            // 客户
            if (params.customerType == 0) {
              this.api.findIsCustomerServerType({
                customerId: params.customerId,
                // companyServerTypeId: choice['typeId']
                companyServerTypeId: choice.id
              }).subscribe(res => {
                let findInfo = res['data']
                if (findInfo) {
                  serverRecordList.push(findInfo)
                }
              })
            }
            // 企业
            if (params.customerType == 1) {
              if (this.detailInfo.startDate) {
                choice.actualStartDate = this.detailInfo.startDate;
                this.hasStartDate = true;
              }
              this.getServerEndDatesIndex(index);
              // this.api.findIsCompanyServerType({
              //   companyId: params.companyId,
              //   // cateId: this.orderTaskDetail.cateId,
              //   companyServerTypeId: choice.id
              // }).subscribe(res => {
              //   let findInfo = res['data']
              //   if (findInfo) {
              //     serverRecordList.push(findInfo)
              //   }
              // })
            }
          })
          // setTimeout(() => {
          //   this.serverRecordList = serverRecordList;
          // }, 500)
        }
      }
    });
  };

  // 删除
  delete(id) {
    let newList = [];
    (this.serverConfigList || []).forEach((item, index) => {
      if (item.id !== id) {
        newList.push(item);
      }
    });
    this.serverConfigList = newList;
  }

  // getServerEndDatesIndex(index) {
  //   if (this.serverConfigList[index].actualStartDate && this.serverConfigList[index].orderProductPeriod) {
  //     this.api.getServerEndDate({
  //       actualStartDate: this.serverConfigList[index].actualStartDate,
  //       companyServerPeriod: this.serverConfigList[index].orderProductPeriod
  //     }).subscribe(res => {
  //       this.serverConfigList[index].actualEndDate = res['data'].actualEndDate
  //     })
  //   }
  // }
  getServerEndDatesIndex(index) {
    if (this.serverConfigList[index].actualStartDate && this.form.value.companyServerPeriod) {
      this.api.getServerEndDate({
        actualStartDate: this.serverConfigList[index].actualStartDate,
        companyServerPeriod: this.form.value.companyServerPeriod
      }).subscribe(res => {
        this.serverConfigList[index].actualEndDate = res['data'].actualEndDate
      })
    }
  }
  getServerEndDates() {
    if (this.form.value.actualStartDate && this.form.value.companyServerPeriod) {
      this.api.getServerEndDate({
        actualStartDate: this.form.value.actualStartDate,
        companyServerPeriod: this.form.value.companyServerPeriod
      }).subscribe(res => {
        this.form.get('actualEndDate').setValue(res['data'].actualEndDate);
      })
    }
  }

}
