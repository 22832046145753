import { Component, OnInit, Input } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DrawerProductCouponComponent } from '../../component/drawer-product-coupon/drawer-product-coupon.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-modal-sale-product-coupon',
  templateUrl: './modal-sale-product-coupon.component.html',
  styleUrls: ['./modal-sale-product-coupon.component.less']
})
export class ModalSaleProductCouponComponent implements OnInit {
  @Input() customerInfo: object; // 客户信息
  form: FormGroup;
  constructor(
    private drawerService: NzDrawerService,
    public api: Api,
    private modal: NzModalRef,
    public global: Global,
    private modalService: NzModalService,
    public message: NzMessageService,
    fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
    });
    this.getCouponConfigList();
  }

  listOfData = [];
  params = {
    couponTitle: null,
  };
  permissions = []; // 按钮权限数组
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getCouponConfigList();
  }
  reset() {
    this.params = {
      couponTitle: null,
    };
    this.pageCtrl.nzPageIndex = 1;
    this.getCouponConfigList();
  }
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getCouponConfigList();
  }
  //列表
  getCouponConfigList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    };
    this.api.couponConfigList(params).subscribe((res) => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }

  // 打开客户信息详情
  open(id) {
    const modal = this.drawerService.create<DrawerProductCouponComponent, { value: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#fff'
      },
      nzContent: DrawerProductCouponComponent,
      nzContentParams: {
        value: id,
      }
    });
  }
  send(id) {

    var params = {
      couponConfigId: id,
      shouldReceiveCustomerId: this.customerInfo['id']
    }
    this.api.getBsCouponInfo(params).subscribe((res) => {
      this.message.success('赠送成功！优惠券信息将以短信形式发送给客户，请客户注意查收哦！<br>（注：也可到“产品->赠送记录”中复制链接主动发送给客户！）', {
        nzDuration: 6000
      });
      this.modal.destroy();
    })
  }
}
