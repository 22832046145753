import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-server-my',
  templateUrl: './drawer-server-my.component.html',
  styleUrls: ['./drawer-server-my.component.less']
})
export class DrawerServerMyComponent implements OnInit {
  @Input() value = '';


  contractUrl: string;
  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
  }

  detailCompanyServer: any = {};
  bsCompanyServerDeliverItemList;
  getServerDetail() {
    this.api.deliverDetail(this.value).subscribe(res => {
      this.detailCompanyServer = res['data'];
      this.bsCompanyServerDeliverItemList = this.detailCompanyServer.bsCompanyServerDeliverItemList;
    });
  }
  orderHtmlIndex = 0;
  selectTabset(e) {
    this.orderHtmlIndex = e.index;
  }
  ngOnInit() {
    this.getServerDetail();
  }

}
