import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

import { DataDimensionService } from '@service/DataDimensionService';

let localApi;

@Component({
  selector: 'app-modal-editproduct',
  templateUrl: './modal-editproduct.component.html',
  styleUrls: ['./modal-editproduct.component.less']
})
export class ModalEditproductComponent implements OnInit {
  @Input() type: string;
  @Input() data: object;

  form: FormGroup;

  commissionParams = {
    commission: '', //提成百分比
    commissionPrice: null, //额外奖励
    name: ''
  }
  formatterPercent = (value: number) => `${value}`;
  parserPercent = (value: string) => value.replace('', '');
  // formatterPercent = (value: number) => `${value} %`;
  // parserPercent = (value: string) => value.replace(' %', '');

  queryProdList = [];   // 产品列表: 查询参数
  cateList = [];   // 产品属性列表: 查询参数
  areaList = [];    // 区域
  attrValList = []; // 产品属性值列表

  // #region 添加产品属性变量
  allProductArray: any = [];
  productNames: any;
  //产品计算方式
  productWay: any;
  //产品属性数组
  // productArray: any = [];
  productName: any = {
    name: '',
    price: '',
    sort: '0',
    selected: true,
    parentId: '0',
    arithmetic: this.productWay,
    cost: 0,
    settlementPrice: 0,
  }
  divideTypeList = [
    { value: '1', name: '百分比(%)' },
    { value: '0', name: '固定金额' },
  ]
  divide: any = null;
  commissionValue: any = null;

  // #endregin

  constructor(
    public fb: FormBuilder,
    private modalService: NzModalService,
    public message: NzMessageService,
    public api: Api,
    public requstService: DataDimensionService,
  ) {
    localApi = this.api;
    this.form = fb.group({
      areaIds: ['', Validators.required],
      cateId: [],
      // serviceUnits: ['', Validators.required],
      productSupplierType: ['', Validators.required],
      // bindingEnterprise: [0, Validators.required],
      //label: ['', Validators.required],
      // isFresh: ['', Validators.required],
      // isMainPush: ['', Validators.required],
      // sort: ['', Validators.required],
      cost: [''],
      commissionPrice: [''],
      scale: [null, Validators.required],
      isHighTechnology: [null, Validators.required],
      // leastPrice: ['', Validators.required],
      price: ['', Validators.required],
      // highestPrice: ['', Validators.required],
      status: ['', Validators.required],
      productNature: ['', Validators.required],
      serverPeriod: ['1'],
      productType: ['', Validators.required],
      remark: [''],
      commissionType: [null],
      // grossProfitValue: [100, Validators.required],
      amount: [null],
      commissionValue: [null],
      proportion: [null],
      cateIds: ['', Validators.required],
      name: ['', Validators.required],
      productId: [null],
    });
  }
  scaleOptions = [
    { label: '小规模', value: 0 },
    { label: '一般纳税人', value: 1 },
  ];

  options = [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ];

  divideTypeChange(e) {
    this.divide = e
  }

  // 获取[选择分类]
  getAllParameters() {
    this.api.getAllParameters({ parentCode: "CPFL" }).subscribe(res => {
      this.queryProdList = this.newsetTreeList(res['data'], false);
    });
  }
  // 递归改变树结构
  newsetTreeList(list, isLeaf) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let obj = {
        name: list[i].name,
        id: list[i].id,
        code: list[i].code,
        isLeaf: isLeaf
      }
      result.push(obj);
      if (list[i].parametersVO) {
        obj['children'] = this.newsetTreeList(list[i].parametersVO, true);
      }
    }
    return result;
  }

  // 递归改变树结构
  setTreeList(list) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let isLeaf = list[i].sysBaseCategoryList ? false : true;
      let obj = {
        label: list[i].name,
        value: list[i].id,
        isLeaf: isLeaf
      }
      result.push(obj);
      if (list[i].sysBaseCategoryList) {
        obj['children'] = this.setTreeList(list[i].sysBaseCategoryList);
      }
    }
    return result;
  }

  onChanges(values: any): void {
    if (!values) {
      return
    }
    this.form.get('cateId').setValue('');
    this.getCategoryList(values[values.length - 1]);
  }
  hasServerPeriod = null;
  productNatureChange(values) {
    console.log(values)
    this.hasServerPeriod = values;
  }

  getCategoryList(value) {
    const params = {
      current: 1,
      size: 9999,
      object: {
        parentId: value
      }
    }
    this.api.getCategoryList(params).subscribe(res => {
      this.cateList = res['data'].records;
    })
  }
  getProdAttr(value) {
    if (!value) {
      return
    }
    let price = this.cateList.find(v => v.id == value).price;
    let serviceUnits = this.cateList.find(v => v.id == value).serviceUnits;
    //this.form.get('leastPrice').setValue(price);
    //this.form.get('serviceUnits').setValue(serviceUnits);
    //this.getAttributesByProductId(value);
  }
  // 获取当前产品 属性列表
  // getAttributesByProductId(id) {
  //   this.api.getAttributesByProductId({ productId: id }).subscribe(res => {
  //     this.attrValList = res['data'];
  //     // this.updateEditCache();
  //     this.comboList = Array.from({ length: this.attrValList.length }, v => []);
  //   })
  // }


  comboList = [];
  titleList = []; // 表格title展示
  comboResultList = []; // 对产品属性排列组合后生成的数组
  resultList = []; // 用于提交表格
  attrList = []; // 已选产品属性
  /**
   * @description 选择产品属性，动态生成表格
   * @param {Array}e 当前产品属性选中的值数组
   * @param {string}i 属性列表的所修改属性的索引
   */
  onChangeAttrCheck(e: Array<number>, i: string) {
    console.log(e);
    this.attrValList[i].sysBaseAreaPropertyList.forEach(el => {
      el['selected'] = false;
    });
    if (this.type == 'edit') {
      e.map(item => {
        //将选中状态该为false
        this.attrValList[i].sysBaseAreaPropertyList.forEach(el => {
          if (el.id == item) {
            el['selected'] = true;
          }
        });
      });
      this.api.updateBatchProperty(this.attrValList[i].sysBaseAreaPropertyList).subscribe();
    } else {
      e.map(item => {
        //将选中状态该为false
        this.attrValList[i].sysBaseAreaPropertyList.forEach(el => {
          if (el.uuid == item) {
            el['selected'] = true;
          }
        });
      })
    }
  }

  // setAttrTableData() {
  //   this.titleList = [];
  //   this.comboList.map((item, i) => {
  //     if (item.length > 0) {
  //       this.titleList.push(this.attrValList[i].name);
  //     }
  //   });
  //   this.comboResultList = this.setArr(this.comboList);

  //   this.resultList = [];
  //   this.attrList = [];
  //   this.comboResultList.map((item) => {
  //     let proIds = [];
  //     let proNames = [];

  //     if (item instanceof Array) {
  //       item.forEach(v => proIds.push(v.id));
  //       item.forEach(v => proNames.push(v.name));
  //     } else {
  //       proIds.push(item.id)
  //       proNames.push(item.name)
  //     }
  //     this.attrList = [...this.attrList, ...proIds];

  //     let editItem: any;
  //     if (this.origin.resultList instanceof Array) {
  //       editItem = this.origin.resultList.find(val => proIds.join(',') == val.proIds);
  //     }
  //     if (editItem) {
  //       this.resultList.push({
  //         id: editItem.id,
  //         price: editItem.price,
  //         cost: editItem.cost,
  //         commissionPrice: editItem.commissionPrice,
  //         sort: editItem.sort,
  //         proNames: proNames.join(','),
  //         proNameList: proNames,
  //         proIds: proIds.join(','),
  //       })
  //     } else {
  //       this.resultList.push({
  //         price: '',
  //         cost: '',
  //         commissionPrice: '',
  //         sort: null,
  //         proNames: proNames.join(','),
  //         proNameList: proNames,
  //         proIds: proIds.join(','),
  //       })
  //     }
  //   })

  // }

  //设置表格数据
  setArr(arr) {
    let list = arr.filter(item => item.length !== 0);
    if (list.length == 0) {
      return []
    }
    if (list.length == 1) {
      return list[0]
    }
    let result = [];
    let results = [];
    // 对二维数组进行排列组合
    (function doExchange(arr, index) {
      for (var i = 0; i < arr[index].length; i++) {
        result[index] = arr[index][i];

        if (index != arr.length - 1) {
          doExchange(arr, index + 1);
        } else {
          results.push(JSON.parse(JSON.stringify(result)));
        }
      }
    })(list, 0)
    return results;
  }

  // #region 获取省市列表
  getCityList(level, code?) {
    const params = {
      level: level,
    }
    code ? params['parentCode'] = code : null;
    this.api.getCityList(params).subscribe((res) => {
      return res['data'];
    })
  }
  loadCityData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      const params = {}
      if (index < 0) {
        params['level'] = 1;
      } else if (index === 0) {
        params['level'] = 2;
        params['parentCode'] = node.code;
      }
      localApi.getCityList(params).subscribe(res => {
        node.children = res['data'];
        if (index === 0) {
          node.children.map((v) => {
            v['isLeaf'] = true;
          })
        }
        resolve();
      })
    });
  }
  onAreaChanges(values: any): void {
  }
  // #endregion

  // 编辑产品时，赋值操作
  origin = { // 保存原有产品属性，型号，用于判断其状态（新增、修改、删除）
    attrList: new Set(),
    attrIds: {},
    resultList: null
  }
  setData() {
    // Object.keys(this.data).map((key) => {
    //   if (this.form.get(key)) {
    //     this.form.get(key).setValue(this.data[key]);
    //   }
    // });
    // console.log(this.data);

    this.form.get('name').setValue(this.data['name']);
    this.form.get('productSupplierType').setValue(this.data['productSupplierType']);
    // this.form.get('bindingEnterprise').setValue(this.data['bindingEnterprise']);
    this.form.get('productType').setValue(this.data['productType']);
    this.form.get('cost').setValue(this.data['cost']);
    // this.form.get('commissionPrice').setValue(this.data['commissionPrice']);
    this.form.get('scale').setValue(this.data['scale']);
    this.form.get('isHighTechnology').setValue(this.data['isHighTechnology']);
    this.form.get('price').setValue(this.data['price']);
    this.form.get('status').setValue(this.data['status']);
    this.form.get('productNature').setValue(this.data['productNature']);
    this.hasServerPeriod = this.data['productNature'];
    this.form.get('serverPeriod').setValue(this.data['serverPeriod']);
    this.form.get('remark').setValue(this.data['remark']);
    this.form.get('areaIds').setValue(this.data['areaIds'].split(',').map(Number));
    this.form.get('cateIds').setValue(this.data['cateIds'].split(',').map(Number));

    this.divide = this.data['commissionType'];
    if (this.divide == 1) {
      this.form.get('proportion').setValue(this.data['commissionValue']);
    } else {
      this.form.get('amount').setValue(this.data['commissionValue']);
    }
    if (this.data['commissionType'] === null) {
      this.form.get('commissionType').setValue(null);
    } else {
      this.form.get('commissionType').setValue(this.data['commissionType'].toString());
    }

    // this.form.get('grossProfitValue').setValue(this.data['grossProfitValue']);
    this.form.get('commissionValue').setValue(this.data['commissionValue']);
    this.cateID = (this.data['cateIds'].split(',').map(Number))[0];

    let params = {
      // areaCateId: this.data['cateId'],
      id: this.data['id']
    }
    //获取产品属性信息
    // this.api.getProductAttributesList(params).subscribe(res => {
    //   this.attrValList = res['data'];
    //   this.attrValList.map(v => {
    //     v.sysBaseAreaPropertyList.map(i => {
    //       this.origin.attrIds[i.id] = i.baseAreaPropertyId
    //     })
    //   })
    //   this.attrValList.map((item, i) => {

    //     let arr = [];
    //     item.sysBaseAreaPropertyList.map(j => {
    //       if (j.selected) {
    //         arr.push(j);
    //       }
    //     });
    //     this.comboList[i] = arr;
    //     arr.map(v => {
    //       this.origin.attrList.add(v.id)
    //     });
    //   })
    //   console.log(this.attrValList)
    //   // this.setAttrTableData();
    //   this.api.getProductModels({ productId: this.data['id'] }).subscribe(res => {
    //     this.resultList = res['data'];
    //     this.origin.resultList = this.deepCopy(res['data']);
    //     this.resultList.map(v => v.proNameList = v.proNames.split(','));
    //   })
    //   // this.api.getCommission({ productId: this.data['id'] }).subscribe(res => {
    //   //   this.commissionParams = res['data'];
    //   // })
    // })
  }

  deepCopy(obj) {
    var result = Array.isArray(obj) ? [] : {};
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          result[key] = this.deepCopy(obj[key]);   //递归复制
        } else {
          result[key] = obj[key];
        }
      }
    }
    return result;
  }

  // //添加产品属性
  // addProductChild() {
  //   let areaCateId = 0;
  //   if (this.data) {
  //     areaCateId = this.data['id'];
  //   }
  //   let productName = this.getProductName(this.productWay, areaCateId, this.productName.parentId)
  //   this.productArray.push(productName);
  // }

  /**
   * 新增、修改属性信息
   * @param content
   * @param data
   * @param i
   */
  // addProduct(content, data?, i?) {
  //   console.log(this.data);
  //   if (data) {
  //     this.productNames = data.name;
  //     this.productWay = String(data.arithmetic);
  //     this.productArray = data.sysBaseAreaPropertyList;
  //     this.productName['areaCateId'] = data.areaCateId;
  //     this.productName.parentId = data.id;
  //     console.log(this.productWay)
  //   } else {
  //     this.productName['parentId'] = '0';
  //   }
  //   this.modalService.create({
  //     nzTitle: data ? '修改产品属性' : '添加产品属性',
  //     nzContent: content,
  //     nzStyle: { top: '50px' },
  //     nzWidth: 800,
  //     nzMaskClosable: false,
  //     nzOnCancel: () => {
  //       let id = 0;
  //       if (this.data) {
  //         id = this.data['id'];
  //       }
  //       let productName = this.getProductName(this.productWay, id, '0');
  //       this.productArray = [productName];
  //       this.productWay = '';
  //       this.productNames = '';
  //     },
  //     nzOnOk: () => {
  //       if (!this.productNames && !this.productWay) {
  //         this.message.info('请填写产品信息');
  //       } else {
  //         this.productArray.forEach(res => {
  //           res.arithmetic = this.productWay;
  //         });
  //         let params = {
  //           name: this.productNames,
  //           arithmetic: this.productWay,
  //           sort: '0',
  //           selected: true,
  //           parentId: '0',
  //           price: 0,
  //           // sysBaseAreaPropertyList: this.productArray
  //         }
  //         params['uuid'] = Math.ceil(Math.random() * 100);
  //         if (this.type == 'edit') {
  //           if (!data) {
  //             data = params;
  //             data.sysBaseAreaPropertyList = this.productArray
  //             data['areaCateId'] = this.data['id'];
  //             //修改产品属性信息
  //             // this.api.addAllAreaProperty([data]).subscribe(res => {
  //             //   this.afterSaveProperty();
  //             // });
  //           } else {
  //             var dataCode = JSON.parse(JSON.stringify(data))
  //             dataCode['name'] = this.productNames;
  //             dataCode['arithmetic'] = this.productWay;
  //             delete dataCode.sysBaseAreaPropertyList
  //             // data.sysBaseAreaPropertyList = this.productArray;
  //             //修改产品属性信息
  //             // this.api.updateParentCode(dataCode).subscribe(res => {
  //             //   // this.afterSaveProperty();
  //             //   //修改产品属性信息
  //             //   this.api.updateBatchProperty(this.productArray).subscribe(res => {
  //             //     this.afterSaveProperty();
  //             //   });
  //             // });
  //           }
  //         } else {
  //           // this.api.addAllProduct(params).subscribe();
  //           params['sysBaseAreaPropertyList'] = this.productArray
  //           if (this.data) {
  //             //console.log(params);
  //             params['areaCateId'] = this.data['id'];
  //             // this.api.addAllAreaProperty([params]).subscribe(res => {
  //             //   this.afterSaveProperty();
  //             // });
  //           } else {
  //             if (!this.attrValList || this.attrValList.length == 0) {
  //               this.attrValList.push(params);
  //             } else {
  //               //如果是未存数据库的编辑
  //               let f = true;
  //               this.attrValList.forEach((item, index) => {
  //                 if (item.name == params['name']) {
  //                   item = params;
  //                   this.attrValList[index].sysBaseAreaPropertyList = params['sysBaseAreaPropertyList'];
  //                   f = false;
  //                 }
  //               });
  //               if (f) {
  //                 this.attrValList.push(params);
  //               }
  //             }
  //             this.productArray = [];
  //             let productName = this.getProductName(this.productWay, this.type == 'edit' ? this.data['id'] : '0', '0');
  //             this.productArray.push(productName);
  //             this.productWay = '';
  //             this.productNames = '';
  //           }
  //         }
  //       }
  //     }
  //   });
  // }

  // deleteProductChild(index) {
  //   let newlist = [];
  //   for (let i = 0; i < this.productArray.length; i++) {
  //     if (i != index) {
  //       newlist.push(this.productArray[i]);
  //     }
  //   }
  //   this.productArray = newlist;
  //   console.log(this.productArray)
  // }
  // // //保存产品属性
  // afterSaveProperty() {
  //   let areaCateId = 0;
  //   if (this.data) {
  //     areaCateId = this.data['id'];
  //   }
  //   let productName = this.getProductName(this.productWay, areaCateId, '0');
  //   this.productArray = [productName];
  //   this.productWay = '';
  //   this.productNames = '';
  //   this.getProductAttributesList(this.data['id']);
  // }

  // getProductAttributesList(id) {
  //   this.api.getProductAttributesList({ id: id }).subscribe(res => {
  //     this.attrValList = res['data'];
  //     this.attrValList.map(v => {
  //       v.sysBaseAreaPropertyList.map(i => {
  //         this.origin.attrIds[i.id] = i.baseAreaPropertyId
  //       })
  //     })
  //     this.attrValList.map((item, i) => {

  //       let arr = [];
  //       item.sysBaseAreaPropertyList.map(j => {
  //         if (j.selected) {
  //           arr.push(j);
  //         }
  //       });
  //       this.comboList[i] = arr;
  //       arr.map(v => {
  //         this.origin.attrList.add(v.id)
  //       });
  //     })

  //     this.setAttrTableData();
  //     this.api.getProductModels({ productId: this.data['id'] }).subscribe(res => {
  //       this.resultList = res['data'];
  //       this.origin.resultList = this.deepCopy(res['data']);
  //       this.resultList.map(v => v.proNameList = v.proNames.split(','));
  //     })
  //     // this.api.getCommission({ productId: this.data['id'] }).subscribe(res => {
  //     //   this.commissionParams = res['data'];
  //     // })
  //   })

  // }
  //删除产品信息
  // deleteProduct(item) {
  //   if (item.id) {
  //     this.api.delProduct({ ids: item.id }).subscribe(res => {
  //       this.getProductAttributesList(item.areaCateId);
  //     });
  //   } else {
  //     let index = this.attrValList.findIndex(e => {
  //       if (e.uuid == item.uuid) {
  //         return true;
  //       }
  //     });
  //     this.attrValList.splice(index, 1);
  //   }
  // }

  //获取产品属性对象
  getProductName(productWay, areaCateId?, parentId?) {
    const uuid = Math.ceil(Math.random() * 100);
    let productName: any = {
      name: '',
      price: '',
      sort: '0',
      selected: true,
      parentId: parentId,
      arithmetic: productWay,
      uuid: uuid,
      cost: 0,
      settlementPrice: 0,
    }
    if (areaCateId) {
      productName['areaCateId'] = areaCateId
    }
    return productName;
  }

  // 产品的一级id
  // 全国的产品 525 写死 区域也是写死全国 [18534, 18535]
  cateID: number;
  changeProd(e) {
    this.cateID = e[0];
    if (this.cateID == 525) {
      this.form.get('areaIds').setValue([18555, 18556]);
    } else {
      this.form.get('areaIds').setValue([]);
    }
  }
  // 待上架产品
  changeProduct(e) {
    let productInfo = this.productList.filter(item => item.id == e)[0];
    this.form.get('name').setValue(productInfo['productName']);
    this.form.get('productType').setValue(productInfo['productType']);
    this.form.get('isHighTechnology').setValue(productInfo['isHighTechnology']);
    this.form.get('scale').setValue(productInfo['scale']);
    this.form.get('productNature').setValue(productInfo['productNature']);
    this.hasServerPeriod = productInfo['productNature'];
    this.form.get('remark').setValue(productInfo['remark']);
    this.form.get('cateIds').setValue([productInfo['oneCategoryId'], productInfo['twoCategoryId']]);
  }
  productList = [];
  ngOnInit() {
    // let productName = this.getProductName(this.productWay, this.type == 'edit' ? this.data['id'] : '0', '0');
    // this.productArray.push(productName);
    this.getAllParameters();
    this.requstService.getDataDimension();
    if (this.type == 'edit') {
      this.setData();
      // this.getByAreaCategoryId();
    }
    if (this.type == 'upProduct') {
      this.api.getProductUpList({}).subscribe(res => {
        this.productList = res['data'];
      })
    }

  }

  // // 服务类别
  // sysBaseAreaCategoryTypeList;
  // mapOfCheckedItem = [];
  // mapOfCheckedId: { [key: string]: boolean } = {};
  // // 服务类别配置
  // selectedCategory(id) {
  //   var choiceList = this.sysBaseAreaCategoryTypeList.filter(item => this.mapOfCheckedId[item.uuid])
  //   this.mapOfCheckedItem = choiceList;
  //   this.getPriceTotal();
  // }
  // getByAreaCategoryId() {
  //   this.api.getByAreaCategoryId(this.data['id']).subscribe(res => {
  //     this.sysBaseAreaCategoryTypeList = res['data'] || [];
  //     this.sysBaseAreaCategoryTypeList.map(item => {
  //       item.uuid = Math.random() * 100;
  //       if (item.id) {
  //         this.mapOfCheckedId[item.uuid] = true;
  //         this.mapOfCheckedItem.push(item);
  //       }
  //     })
  //     this.getPriceTotal();
  //   })
  // }

  // totalPayment = 0;
  // // 计算结算价
  // getPriceTotal() {
  //   this.totalPayment = 0;
  //   this.mapOfCheckedItem.map(item => {
  //     this.totalPayment += (Number(item.commissionAmount) + Number(item.otherCost));
  //   })
  //   this.form.get('commissionPrice').setValue(this.totalPayment);
  // }
}
