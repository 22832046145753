import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import * as wangEditor from '../../../../../node_modules/wangeditor/release/wangEditor.js';
import { AuthService } from '../../../auth/auth.service';
import { Api } from '@service/Api';
import { environment } from '../../../../environments/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-wangeditor',
  templateUrl: './modal-wangeditor.component.html',
  styleUrls: ['./modal-wangeditor.component.less']
})
export class ModalWangeditorComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  @Input() customerId: string;
  @Input() customerName: String;
  @Input() init: String;
  @Input() bindId: String;
  @Input() bindName: String;
  @Input() companyId: String;
  @Input() companyName: String;
  @Input() journalType: String;
  // @Output() onPostData = new EventEmitter();
  @ViewChild('editroElement', { static: false }) editroElement: ElementRef;
  private editor: any;

  // notice;
  params = {
    marketingModeId: null,
    marketingModeName: null,
    phoneStatusId: null,
    phoneStatusName: null,
    firstOrderIntentionStatusId: null,
    firstOrderIntentionStatusName: null,
    decisionMakerPainPoint: null,
    enterpriseType: null,
    companyLabelId: [],
    customerLabelId: [],
  }

  contractUrl: string;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    private auth: AuthService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }

  logObjeck = {
    logType: null, // 日志类型
    logFlag: 1, //0手机1pc
    bindId: null, //绑定id
    bindName: null, //绑定名称
    companyId: null, //公司id  关联 标签
    companyName: null,
    customerId: null, //客户id 关联 标签
    customerName: null,
    customerLabelList: [], //客户标签
    companyLabelList: [], //企业标签
    customerLogAttachmentList: [], //客户日志附件
    logContent: null, //跟进内容
  }
  jouranlList = [
    { value: 'customer', name: '客户' },
    { value: 'company', name: '客户公司' },
    { value: 'order', name: '订单' },
    { value: 'task', name: '工单' },
    { value: 'customerLoss', name: '客户流失' },
    { value: 'business', name: '商机' },
    { value: 'renew', name: '续费' },
  ]
  selectType = null;
  selectValue = null;

  getReferralUser() {
    this.selectValue = null;
    this.logObjeck.logType = this.selectType == 'customer' ? 0 :
      this.selectType == 'company' ? 1 :
        this.selectType == 'order' ? 2 :
          this.selectType == 'task' ? 3 :
            this.selectType == 'customerLoss' ? 4 :
              this.selectType == 'business' ? 5 :
                this.selectType == 'renew' ? 6 : 'null';

  }
  // 上传附件
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let _obj = {
          attachmentUrl: item.response.data.fileUrl
        }
        attachmentUrls.push(_obj)
      })
      this.logObjeck.customerLogAttachmentList = attachmentUrls;
    }
  }

  companyList = [];
  customerList = [];
  ngAfterViewInit() {


    this.logObjeck.bindId = this.bindId;
    this.logObjeck.bindName = this.bindName;
    this.logObjeck.logType = this.type == 'customer' ? 0 :
      this.type == 'company' ? 1 :
        this.type == 'order' ? 2 :
          this.type == 'task' ? 3 :
            this.type == 'customerLoss' ? 4 :
              this.type == 'business' ? 5 :
                this.type == 'renew' ? 6 : 'null';
    //企业
    if (this.type == 'company') {
      this.logObjeck.companyId = this.bindId;
      this.logObjeck.companyName = this.bindName;
      this.companyList = [
        { id: this.logObjeck.companyId, name: this.logObjeck.companyName }
      ];
      // 获取公司客户列表
      this.api.getCompanyCustmerLists(this.bindId).subscribe(res => {
        this.customerList = res['data'];
      })
    }
    //客户
    if (this.type == 'customer') {
      this.logObjeck.customerId = this.bindId;
      this.logObjeck.customerName = this.bindName;
      this.customerList = [
        { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
      ];
      // 获取客户公司列表
      this.api.getCompanyList({ id: this.bindId }).subscribe(res => {
        this.companyList = res['data'];
      })
    }
    //订单
    if (this.type == 'order') {
      this.logObjeck.customerId = this.customerId;
      this.logObjeck.customerName = this.customerName;
      this.customerList = [
        { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
      ];
      // 获取客户公司列表
      this.api.getCompanyList({ id: this.customerId }).subscribe(res => {
        this.companyList = res['data'];
      })
    }
    //工单
    if (this.type == 'task') {
      this.logObjeck.customerId = this.customerId;
      this.logObjeck.customerName = this.customerName;
      this.logObjeck.companyId = this.companyId;
      this.logObjeck.companyName = this.companyName;
      this.customerList = [
        { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
      ];
      this.companyList = [
        { id: this.logObjeck.companyId, name: this.logObjeck.companyName }
      ];
    }

    const authToken = this.auth.getAuthorizationToken() || '';
    const editordom = this.editroElement.nativeElement;
    this.editor = new wangEditor(editordom);

    this.editor.customConfig.uploadFileName = 'file'; // 设置文件上传的参数名称
    this.editor.customConfig.uploadImgHeaders = {
      'Admin-Token': authToken
    }
    this.editor.customConfig.uploadImgServer = environment.baseUrl + this.api.uplaodAttachment(); // 设置上传文件的服务器路径
    this.editor.customConfig.uploadImgHooks = {
      customInsert: (insertImg, result, editor) => {
        const url = result.data.fileUrl;
        insertImg(url);
      }
    }
    this.editor.create();
  }

  clickHandle(): any {
    let data = this.editor.txt.text();
    return data
  }

  submit() {
    console.log(this.journalType)
    if (this.journalType == 'add' && !this.selectType) {
      this.message.error('请选择日志类型')
      return
    }
    if (this.journalType == 'add' && !this.selectValue) {
      let val = this.selectType == 'customer' ? '请选择客户'
        : this.selectType == 'company' ? '请选择客户公司'
          : this.selectType == 'order' ? '请选择订单' : '请选择工单'
      this.message.error(val)
      return
    }

    if (!this.editor.txt.text()) {
      this.message.error('请输入跟进内容，或调取日志模板')
      return
    }
    this.logObjeck.logContent = this.editor.txt.html();
    this.addUserLabels();
    this.saveSysCompanyTagRecord();
    this.tagNumber = 0;
    this.modal.destroy(this.logObjeck);
    console.log(this.logObjeck)
  }

  saveSysCompanyTagRecord() {
    if (this.logObjeck.companyLabelList.length > 0) {
      let _arr = []
      this.logObjeck.companyLabelList.map((item, index) => {
        _arr.push(item.labelId)
      })
      let params = {
        companyId: this.logObjeck.companyId,
        labelId: _arr
      }
      this.api.saveSysCompanyTagRecord(params).subscribe(res => {
        console.log(res)
      })
    }
  }
  // addUserLabel
  //客户添加标签
  addUserLabels() {
    if (this.logObjeck.customerLabelList.length > 0) {
      let _arr = []
      this.logObjeck.customerLabelList.map((item, index) => {
        _arr.push(item.labelId)
      })
      let params = {
        customerId: this.logObjeck.customerId,
        labelId: _arr
      }
      this.api.addUserLabel(params).subscribe(res => {
        console.log(res)
      })
    }
  }

  cancel() {
    this.modal.destroy();
  }

  // 获取选项的列表 start
  // --初始化学历 educational_background
  // --初始化性格 user_character
  // --客户营销方式 marketing_mode
  // --首单意向状态 first_order_intention_status
  // --上次电话状态 phone_status
  marketing_mode: [];
  first_order_intention_status: [];
  phone_status: [];
  listParame = ['marketing_mode', 'first_order_intention_status', 'phone_status'];
  getParameters(code, func) {
    this.api.getSysParametersByParentCode({ parentCode: code }).subscribe(res => {
      let resp = res['data'];
      func(resp);
    });
  };
  getParametList() {
    (this.listParame).map(item => {
      switch (item) {
        case 'marketing_mode':
          this.getParameters(item, (resp) => {
            this.marketing_mode = resp;
          })
          break;
        case 'first_order_intention_status':
          this.getParameters(item, (resp) => {
            this.first_order_intention_status = resp;
          })
          break;
        case 'phone_status':
          this.getParameters(item, (resp) => {
            this.phone_status = resp;
          })
          break;
        default:
          break;
      }
    })
  }
  getParametName(list, id, code) {
    var name;
    (list || []).map((item, index) => {
      if (id == item.id) {
        console.log(item)
        if (code == 'marketing_mode') {
          this.params.marketingModeName = item.name;
          name = item.name;
        } else if (code == 'first_order_intention_status') {
          this.params.firstOrderIntentionStatusName = item.name;
          name = item.name;
        } else if (code == 'phone_status') {
          this.params.phoneStatusName = item.name;
          name = item.name;
        }
      }
    })
    return name;
  }
  // 获取选项的列表 end
  ngOnInit() {
    this.getParametList();
    this.getTemplateList();
  }

  templateList: any;//列表
  templateName: string;
  //分页
  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getTemplateList();
  };
  getTemplateList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        templateName: this.templateName,
      }
    }
    this.api.getUserJournalTemplateLists(params).subscribe(res => {
      this.templateList = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }
  //选择模板
  SendTeplateData;
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfControl = [];
  refreshStatus(e, id): void {
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.SendTeplateData = this.templateList.filter(item => item['id'] == id);
  }
  NoteListTemplateShow(NoteListTemplate) {
    this.listOfControl = [];
    this.getTemplateList();
    this.modalService.create({
      nzTitle: '选择模板',
      nzContent: NoteListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 820,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addParentNote()
    });
  }
  addParentNote() {
    let content = this.SendTeplateData[0].templateContent;
    this.editor.create();
    this.editor.txt.html(content)
  }
  //查询，清空
  inquireList() {
    this.getTemplateList();
  }
  resetList() {
    this.templateName = null;
    this.getTemplateList();
  }



  // 标签筛选 start
  searchTag(searchTagList, tag) {
    this.getLabelList(searchTagList, tag);
  }
  tag = null;
  tagNumber = 0;
  labelList = [];      // 标签列表
  userLabelList = [];  // 客户标签列表
  userParentLabelList = []; //选中多选的id
  // 查询标签列表
  getLabelList(searchTagList, tag) {
    //  tagType = 客户标签 0 企业标签 1
    this.tagNumber++;
    this.tag = tag;//客户标签 0 企业标签 1
    this.modalService.create({
      nzTitle: tag == 0 ? '客户标签' : '企业标签',
      nzContent: searchTagList,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => this.saveLabel()
    });
    this.labelList = [];
    if (this.tag == 0 && this.logObjeck.customerId) {
      this.getLabelListAll();
    }
    if (this.tag == 1 && this.logObjeck.companyId) {
      this.getLabelListAll();
    }
  }

  getLabelListAll() {
    var params = {
      tagType: this.tag
    }
    this.api.getLabelList(params).subscribe(res => {
      this.labelList = res['data'];
      this.getUserLabel();
    })
  }
  // 获取客户标签
  getUserLabel() {
    this.userParentLabelList = []; //选中多选的id
    console.log(this.logObjeck)

    if (this.tag == '1' && this.logObjeck.companyId) {
      if (this.params.companyLabelId.length == 0) {
        this.api.getCompanyLabel({ companyId: this.logObjeck.companyId }).subscribe(res => {
          this.userLabelList = res['data'];
          this.setTag()
        })
      } else {
        this.userLabelList = JSON.parse(JSON.stringify(this.params.companyLabelId));
        this.setTag()
      }
    }
    if (this.tag == '0' && this.logObjeck.customerId) {
      if (this.params.customerLabelId.length == 0) {
        this.api.getUserLabel({ customerId: this.logObjeck.customerId }).subscribe(res => {
          this.userLabelList = res['data'];
          this.setTag()
        })
      } else {
        this.userLabelList = JSON.parse(JSON.stringify(this.params.customerLabelId));
        this.setTag()
      }
    }
  }

  setTag() {
    const len = this.labelList.length;
    for (let i = 0; i < len; i++) {
      const tagList = this.labelList[i]['sysCustomerTagConfigVOS'];
      if (!tagList) {
        continue
      }
      for (let j = 0; j < tagList.length; j++) {
        // 如果标签存在于客户标签数组，添加标记
        // userLabelList 选中的id
        console.log(this.userLabelList)
        if (this.userLabelList.includes(tagList[j].id)) {
          // 多选
          if (!this.labelList[i].radio) {
            tagList[j].checked = true;
            // 多选
            this.userParentLabelList.push(tagList[j].id);
          } else {
            // 单选
            this.labelList[i].checkId = tagList[j].id;
          }
        } else {
          tagList[j].checked = false;
        }
      }
    }
  }
  // 标记客户标签 多选
  checkChange(checked, id) {
    if (checked) {
      this.userLabelList.push(id);
      this.userParentLabelList.push(id);
    } else {
      this.userLabelList = this.userLabelList.filter(t => t !== id);
      this.userParentLabelList = this.userParentLabelList.filter(t => t !== id);
    }
  }
  // checkChangeRadio 单选
  checkChangeRadio(checked) {
    console.log(checked)
  }
  saveLabel() {
    // 先保存多选id,再筛选出单选选中id
    const userLabelList = JSON.parse(JSON.stringify(this.userParentLabelList));
    for (let j = 0; j < this.labelList.length; j++) {
      if (this.labelList[j].radio && this.labelList[j].checkId) {
        userLabelList.push(this.labelList[j].checkId);
      }
    }
    this.params.customerLabelId = [];
    this.params.companyLabelId = [];
    if (this.tag == '0') {
      //客户标签
      //关联客户列表 为空 不能选择标签
      //关联客户列表有数据没有选择客户， 不能选择标签
      if (this.customerList.length == 0 || !this.logObjeck.customerId) {
        this.message.error('请选择关联客户');
        return
      }
      this.params.customerLabelId = userLabelList
    }
    if (this.tag == '1') {
      //企业标签
      //关联企业列表 为空 不能选择标签
      //关联企业列表有数据没有选择企业， 不能选择标签
      if (this.companyList.length == 0 || !this.logObjeck.companyId) {
        this.message.error('请选择关联企业');
        return
      }
      this.params.companyLabelId = userLabelList
    }

    var userLabelObj = [];
    (this.labelList || []).map((item, index) => {
      item.sysCustomerTagConfigVOS.map((child, i) => {
        (userLabelList || []).map((item, j) => {
          if (item == child.id) {
            userLabelObj.push({
              labelId: child.id,
              labelName: child.tagName,
            });
            if (this.tag == '0') {
              this.logObjeck.customerLabelList = userLabelObj
            }
            if (this.tag == '1') {
              this.logObjeck.companyLabelList = userLabelObj
            }
          }
        });
      });
    });
  }
  // 标签筛选 end
  searchTagLists(event, type) {
    if (type == 'company') {
      const _company = this.companyList.filter(item => item.id == event);
      this.logObjeck.companyName = _company[0].name;
    }
    if (type == 'customer') {
      const _customer = this.customerList.filter(item => item.id == event);
      this.logObjeck.customerName = _customer[0].name;
    }
    this.getLabelListAll();
  }

  typeList: any;//列表
  //分页
  pageCtrls = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  toPages(page) {
    this.pageCtrls.nzPageIndex = page;
    this.getTypeList();
  };

  getTypeList() {
    const params = {
      current: this.pageCtrls.nzPageIndex,
      size: this.pageCtrls.nzPageSize,
      object: {}
    }
    switch (this.selectType) {
      case 'customer':
        this.api.getMyCustomerList(params).subscribe(res => {
          this.typeList = res['data'].records;
          this.pageCtrls.total = res['data'].total;
        });
        break;
      case 'company':
        this.api.myCompanyList(params).subscribe(res => {
          this.typeList = res['data'].records;
          this.pageCtrls.total = res['data'].total;
        });
        break;
      case 'order':
        this.api.getMyOrderList(params).subscribe(res => {
          this.typeList = res['data'].records;
          this.pageCtrls.total = res['data'].total;
        });
        break;
      case 'task':
        this.api.getTaskList(params).subscribe(res => {
          this.typeList = res['data'].records;
          this.pageCtrls.total = res['data'].total;
        });
        break;
    }
  }
  //选择模板
  sendTeplateDatas;
  mapOfCheckedIds: { [key: string]: boolean } = {};

  refreshsStatus(e, id): void {
    for (let i in this.mapOfCheckedIds) {
      this.mapOfCheckedIds[i] = false;
    }
    this.mapOfCheckedIds[id] = true;
    this.sendTeplateDatas = this.typeList.filter(item => item['id'] == id);
  }
  selectTypeLists(typeListTemplate) {
    this.typeList = []
    this.getTypeList();
    this.modalService.create({
      nzTitle: '选择',
      nzContent: typeListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 820,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addParentNotes()
    });
  }

  addParentNotes() {
    console.log(this.sendTeplateDatas[0])
    this.selectValue = this.selectType == 'customer' ? this.sendTeplateDatas[0].name :
      this.selectType == 'company' ? this.sendTeplateDatas[0].name :
        this.selectType == 'order' ? this.sendTeplateDatas[0].orderNo :
          this.selectType == 'task' ? this.sendTeplateDatas[0].workOrderNo : '';
    switch (this.selectType) {
      case 'customer':
        this.logObjeck.bindId = this.sendTeplateDatas[0].id;
        this.logObjeck.bindName = this.sendTeplateDatas[0].name;
        this.logObjeck.customerId = this.sendTeplateDatas[0].id;
        this.logObjeck.customerName = this.sendTeplateDatas[0].name;

        this.logObjeck.companyId = null;
        this.logObjeck.companyName = null;
        this.customerList = [
          { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
        ];
        // 获取客户公司列表
        this.api.getCompanyList({ id: this.logObjeck.bindId }).subscribe(res => {
          this.companyList = res['data'];
        })
        break;
      case 'company':
        this.logObjeck.bindId = this.sendTeplateDatas[0].id;
        this.logObjeck.bindName = this.sendTeplateDatas[0].name;
        this.logObjeck.companyId = this.sendTeplateDatas[0].id;
        this.logObjeck.companyName = this.sendTeplateDatas[0].name;

        this.logObjeck.customerId = null;

        this.companyList = [
          { id: this.logObjeck.companyId, name: this.logObjeck.companyName }
        ];
        // 获取公司客户列表
        this.api.getCompanyCustmerLists(this.logObjeck.bindId).subscribe(res => {
          this.customerList = res['data'];
        })
        break;
      case 'order':
        this.logObjeck.bindId = this.sendTeplateDatas[0].id;
        this.logObjeck.bindName = this.sendTeplateDatas[0].orderNo;
        this.logObjeck.customerId = this.sendTeplateDatas[0].customerId;
        this.logObjeck.customerName = this.sendTeplateDatas[0].customerName;

        this.logObjeck.companyId = null;
        this.logObjeck.companyName = null;

        this.customerList = [
          { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
        ];
        // 获取客户公司列表
        this.api.getCompanyList({ id: this.logObjeck.customerId }).subscribe(res => {
          this.companyList = res['data'];
        })
        break;
      case 'task':
        this.logObjeck.bindId = this.sendTeplateDatas[0].id;
        this.logObjeck.bindName = this.sendTeplateDatas[0].workOrderNo;
        this.logObjeck.customerId = this.sendTeplateDatas[0].customerId;
        this.logObjeck.customerName = this.sendTeplateDatas[0].customerName;
        this.logObjeck.companyId = this.sendTeplateDatas[0].companyId;
        this.logObjeck.companyName = this.sendTeplateDatas[0].companyName;

        this.customerList = [
          { id: this.logObjeck.customerId, name: this.logObjeck.customerName }
        ];
        this.companyList = [
          { id: this.logObjeck.companyId, name: this.logObjeck.companyName }
        ];
        break;
    }
  }

}
