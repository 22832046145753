import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-work-report-user',
  templateUrl: './modal-work-report-user.component.html',
  styleUrls: ['./modal-work-report-user.component.less']
})
export class ModalWorkReportUserComponent implements OnInit {
  @Input() id: any;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) { }

  ngOnInit() {
    console.log(this.id)
  }

  scoreList = [
    { value: '10', name: '10分' },
    { value: '9', name: '9分' },
    { value: '8', name: '8分' },
    { value: '7', name: '7分' },
    { value: '6', name: '6分' },
    { value: '5', name: '5分' },
    { value: '4', name: '4分' },
    { value: '3', name: '3分' },
    { value: '2', name: '2分' },
    { value: '1', name: '1分' },
  ];
  cancel() {
    this.modal.destroy();
  }
  reportScore;
  reportDesc;
  submitForm() {
    if (!this.reportScore) {
      this.message.error("请选择评分数！");
      return false;
    }
    this.api.saveUserScore(
      {
        reportScore: this.reportScore,
        reportDesc: this.reportDesc,
        reportUserId: this.id
      }
    ).subscribe((res) => {
      this.message.success('评价成功');
      this.modal.destroy(true);
    })
  }
}
