import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';
@Component({
  selector: 'app-modal-add-outlookcust',
  templateUrl: './modal-add-outlookcust.component.html',
  styleUrls: ['./modal-add-outlookcust.component.less']
})
export class ModalAddOutlookcustComponent implements OnInit {
  @Input() companyId: string;
  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {

  }
  productList;         // 产品列表
  sreach() {
    this.getProductList();
  }
  params = {
    customerName: null,
    phoneNumber: null,
  }

  // 获取产品列表
  getProductList() {
    this.api.getCompanyCustomerList({ companyId: this.companyId }).subscribe(resp => {
      this.productList = resp['data'];
    })
  }

  cancel() {
    this.modal.destroy();
  }
  submit() {
    if (this.signTeplateData.length === 0) {
      this.message.warning('请选择客户！');
      return
    };
    this.modal.destroy(this.signTeplateData);
  }



  ngOnInit() {
    this.getProductList();
  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  signTeplateData;
  selectedCategory(e, id): void {
    // const sttings = cateIds.split(',');
    // this.levelOne = Number(sttings[0]);
    // this.getcategorySecond(sttings[0]);
    // this.levelTwo = Number(sttings[1]);
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.signTeplateData = this.productList.filter(item => item.id == id);
  }


}