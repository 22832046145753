import {Component, OnInit, Input} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {Api} from '@service/Api';
import {Utils} from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd';


@Component({
  selector: 'app-modal-add-achievement',
  templateUrl: './modal-add-achievement.component.html',
  styleUrls: ['./modal-add-achievement.component.less']
})
export class ModalAddAchievementComponent implements OnInit {
  @Input() type: string; // 新增类型 部门/公司
  @Input() id: string; // 新增类型id
  @Input() name: string; // 新增类型名称
  form: FormGroup;  // 新增客户表单
  canSubmit = true;

  dataList: any[] = []; //部门/当前可选择的员工
  typeName: string;

  constructor(private api: Api,
              fb: FormBuilder,
              private modal: NzModalRef,
              public message: NzMessageService,
              ) {
    this.form = fb.group({
      objId: [null,],
      type: [this.type,],
      year: [null,],
      january: [null,],
      february: [null],
      march: [null],
      april: [null,],
      may: [null],
      june: [null,],
      july: [null,],
      august: [null,],
      september: [null],
      october: [null],
      november: [null],
      december: [null],
      yearTarget: [null],
      firstQuarter: [null],
      secondQuarter: [null],
      thirdQuarter: [null],
      fourthQuarter: [null],
    });
  }

  //保存信息
  save(value) {
    this.canSubmit = false;
    value.type = this.type;
    value.year = Utils.dateFormat(value.year, 'yyyy');
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    console.log(value);

    if (value.objId == null) {
      this.canSubmit = true;
      this.message.warning('请选择部门！');
      return;
    }
    if (value.year == null || value.year == '1970') {
      this.canSubmit = true;
      this.message.warning('请选择年份！');
      return;
    }



    if (!this.form.valid) {
      this.canSubmit = true;
      return;
    } else {

      this.api.addAchievement(value).subscribe(res => {

        this.canSubmit = true;
        this.cancel();
      },err => {
        this.canSubmit = true;
      })

    }


  }

  getUserByOffice() {
    let param = {
      objId: this.id,
    }
    this.api.getUserByOffice(param).subscribe(res => {
      this.dataList = res['data'].map(function (i) {
        return {'id': i.userId, 'name': i.name}
      });
    });
  }

  cancel() {
    this.modal.destroy();
  }

  ngOnInit() {
    if ('2' == this.type) {
      this.typeName = '部门';
      this.dataList.push({'id': this.id, 'name': this.name});
    } else {
      //员工
      this.typeName = '员工';

      //查询员工信息
      this.getUserByOffice();
    }
  }

}
