import { Component, OnInit,Input } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-task-info',
  templateUrl: './drawer-task-info.component.html',
  styleUrls: ['./drawer-task-info.component.less']
})
export class DrawerTaskInfoComponent implements OnInit {
  @Input() value = '';

  constructor(
    public api: Api,
  ) { }

  taskInfo :any = {};
  taskInfoLogList :any = [];
  taskInfoItemList :any = [];
  
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  initLoading = true; // bug
  loadingMore = false;
  sortName = null;
  // 接收人名称
  params = {
    receiveUserName:null,
    orderBy:null,
    sortName:null,
    taskInfoId:null,
  }

  ngOnInit() {
    this.api.getTaskInfoDetail(this.value).subscribe(res => {
      this.taskInfo = res['data']
      this.taskLogListInit();
      this.getTaskInfoJoinLists();
    });
    this.params.taskInfoId = this.value
  }

  //任务动态
  taskLogListInit(): void {
    this.loadingMore = true;
    let params = {
      current:this.pageCtrl.nzPageIndex,
      object:{
        taskInfoId:this.value
      },
      size:this.pageCtrl.nzPageSize,
    }
    this.api.getTaskInfoLogList(params).subscribe(res=>{
      let _obj = res['data'].records;
      this.pageCtrl.total = res['data'].total

      if(_obj.length == 0){
        this.loadingMore = true;
        this.initLoading = false;
        return false;
      }else{
        this.loadingMore = false
      }
      this.taskInfoLogList = this.taskInfoLogList.concat(_obj || []);
      this.initLoading = false;

      if (this.taskInfoLogList.length >= res['data'].total) {
        this.loadingMore = true;
      } else {
        this.loadingMore = false;
      }
    })
  }
  //加载任务动态
  onLoadMore():void{
    this.pageCtrl.nzPageIndex++;
    this.initLoading = true;
    this.taskLogListInit();
  }

  pageCtrl2 = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  //参与人列表
  getTaskInfoJoinLists(){
    let params = {
      current:this.pageCtrl2.nzPageIndex,
      object:this.params,
      size:this.pageCtrl2.nzPageSize,
    }
    this.api.getTaskInfoJoinList(params).subscribe(res=>{
      this.taskInfoItemList = res['data'].records;
      this.pageCtrl2.total = res['data'].total;
    })
  }

  // 翻页
  toPage(page) {
    this.pageCtrl2.nzPageIndex = page;
    this.getTaskInfoJoinLists();
  }
  
  query(){
    switch(this.sortName){
      case 'name_desc':
        this.params.sortName = 'receive_user_name';
        this.params.orderBy = 'desc';
        break;
      case 'name_asc':
        this.params.sortName = 'receive_user_name';
        this.params.orderBy = 'asc';
        break;
      case 'date_desc':
        this.params.sortName = 'finish_date';
        this.params.orderBy = 'desc';
        break;
      case 'date_asc':
        this.params.sortName = 'finish_date';
        this.params.orderBy = 'asc';
        break;
      case 'progress_desc':
        this.params.sortName = 'finish_progress';
        this.params.orderBy = 'desc';
        break;
      case 'progress_asc':
        this.params.sortName = 'finish_progress';
        this.params.orderBy = 'asc';
        break;
    }
    this.pageCtrl2.nzPageIndex = 1;
    this.pageCtrl2.total = 0;
    this.getTaskInfoJoinLists();
  }

  reset(){
    this.params.orderBy = null;
    this.params.receiveUserName = null;
    this.params.sortName = null;
    this.sortName = null;
    this.pageCtrl2.nzPageIndex = 1;
    this.pageCtrl2.total = 0;
    this.getTaskInfoJoinLists();
  }
} 
