import { Component, OnInit } from '@angular/core';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Utils } from '@service/Utils';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-customer-loss-check',
  templateUrl: './modal-customer-loss-check.component.html',
  styleUrls: ['./modal-customer-loss-check.component.less']
})
export class ModalCustomerLossCheckComponent implements OnInit {
  contractUrl: string;
  params: any;
  loadDeptData: any;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }


  submitParams = {
    checkDesc: null,
    flowBusinessStepUserAttachmentList: []
  };
  refundIsAll: null;//是否寄回账本

  // 业务主管审核 start
  ywzgParams = {
    returnVisitDate: null,//回访时间
    returnVisitContent: null,//回访记录
    distributeUserId: null,//分发人员
    distributeUserName: null,//分发人员名称
  };
  departmentList = [];
  deptId;
  // 获取公司部门列表
  getDepartment() {
    this.api.findCompanyDeptTree().subscribe((res) => {
      this.departmentList = res['data'];
    })
  }
  userList = [];
  // 获取部门下用户Id列表
  getUserIdList(datas) {
    var data = '';
    if (datas.length >= 1) {
      data = datas[datas.length - 1]
    }
    const params = {
      current: 1,
      size: 100,
      object: {
        objId: data,
        type: 1,
      },
    }
    this.api.getUserOfficeList(params).subscribe(res => {
      this.userList = res['data'].records;
    })
  }
  changeUser(e) {
    if (this.userList && e) {
      const user = this.userList.filter(item => item.userId == e);
      this.ywzgParams.distributeUserName = user[0]['name']
    }
  }
  // 业务主管审核 end

  // 分发 start
  ffParams = {
    // deliverUserName: null,
    deliverUserId: null, //交付人id
  };
  // 获取用户列表
  deptUserList = [];
  deliverUserName;
  getUserList() {
    const params = {
      current: 1,
      size: 1000,
      object: {
        // objId: JSON.parse(localStorage.getItem('userInfo')).dept.id,
        // type: 1,
      },
    }
    this.api.queryUserListByCompany(params).subscribe(res => {
      this.deptUserList = res['data'].records;
    })
    // this.api.getUserListByDept().subscribe((res) => {
    //   this.deptUserList = res['data'];
    //   this.deptUserList.forEach(data => {
    //     this.api.getUserListByDeptId({ deptId: data.id }).subscribe(item => {
    //       item['data'].forEach(msg => {
    //         msg['isLeaf'] = true;
    //       });
    //       data['children'] = item['data'];
    //     })
    //   });
    // });
  }

  changeDeliverUser(e) {
    // console.log(e)
    // let curId = null;
    // if (e.length >= 1) {
    //   curId = e[e.length - 1]
    // }
    let user = [];
    user = this.deptUserList.filter(item => item.userId == e);
    this.deliverUserName = user[0]['name'];
    console.log(this.deliverUserName)
  }
  // 分发 end
  // 交付人员审核 start
  jfryParams = {
    deliverActualEndDate: null, //实际终止时间
    deliverBackBookFlag: null,//账本寄回标记0不寄回1寄回
    deliverUnDesc: null,//账本不寄回原因
    accountBookAttachmentList: [],//账本寄回凭证
    deliverBackDate: null,//寄回时间
    financeHandoverAttachmentList: [],//财务数据
    deliverFinanceHandoverDate: null,//财务数据交接时间
    accountDataAttachmentList: [],//账号资料
    deliverAccountDataDate: null,//账号资料交接时间
    otherDataAttachmentList: [],//其他资料
    deliverOtherDataDate: null,//其他资料交接时间
  }
  // // 上传附件
  // uploadChange(info, type, userType) {
  //   var attachmentUrls = [];
  //   if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
  //     (info.fileList || []).map((item, index) => {
  //       let p = {
  //         attachmentUrl: item.response.data.fileUrl,
  //         customerAwayId: this.curList.id,
  //         userType: userType
  //       }
  //       attachmentUrls.push(p)
  //     })
  //     switch (type) {
  //       case 'accountBookAttachmentList':
  //         this.jfryParams.accountBookAttachmentList = attachmentUrls; //账本寄回凭证
  //         break;
  //       case 'financeHandoverAttachmentList':
  //         this.jfryParams.financeHandoverAttachmentList = attachmentUrls; //财务数据
  //         break;
  //       case 'accountDataAttachmentList':
  //         this.jfryParams.accountDataAttachmentList = attachmentUrls; //账号资料
  //         break;
  //       case 'otherDataAttachmentList':
  //         this.jfryParams.otherDataAttachmentList = attachmentUrls; //其他资料
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }
  // 交付人员审核 end
  // 交付主管审核 start
  jfzgParams = {
    deliverManageActualAwayAmount: null //实际损失金额
  };
  // 交付主管审核 end
  // 通过
  // submitForm() {
  //   // switch (this.params.type) {
  //   //   case 'ywzg':
  //   //     // 业务主管审核
  //   //     const ywzgParams = JSON.parse(JSON.stringify(this.ywzgParams))
  //   //     if (!ywzgParams.returnVisitDate) {
  //   //       this.message.warning('请选择回访时间！');
  //   //       return
  //   //     }
  //   //     if (!ywzgParams.returnVisitContent) {
  //   //       this.message.warning('请填写回访记录！');
  //   //       return
  //   //     }
  //   //     if (status == 1) {
  //   //       if (!ywzgParams.distributeUserId) {
  //   //         this.message.warning('请选择分发人员！');
  //   //         return
  //   //       }
  //   //     }
  //   //     ywzgParams.returnVisitDate = Utils.dateFormat(ywzgParams.returnVisitDate, 'yyyy-MM-dd');
  //   //     ywzgParams.returnVisitStatus = status;
  //   //     ywzgParams.id = this.curList.id;
  //   //     this.api.saveMangeCheck(ywzgParams).subscribe(res => {
  //   //       this.message.success('审核成功！');
  //   //       this.modal.destroy();
  //   //     }, err => {
  //   //     })
  //   //     break;
  //   //   case 'ff':
  //   //     // 分发
  //   //     const ffParams = JSON.parse(JSON.stringify(this.ffParams))
  //   //     if (ffParams.deliverUserId.length >= 1) {
  //   //       ffParams.deliverUserId = ffParams.deliverUserId[ffParams.deliverUserId.length - 1]
  //   //     }
  //   //     if (ffParams.deliverUserId.length == 0) {
  //   //       this.message.warning('请选择交付人员！');
  //   //       return
  //   //     }
  //   //     ffParams.deliverUserName = this.deliverUserName;
  //   //     ffParams.id = this.curList.id;
  //   //     this.api.saveDistributeCheck(ffParams).subscribe(res => {
  //   //       this.message.success('分发成功！');
  //   //       this.modal.destroy();
  //   //     }, err => {
  //   //     })
  //   //     break;
  //   //   case 'jfry':
  //   //     // 交付人员审核
  //   //     const jfryParams = JSON.parse(JSON.stringify(this.jfryParams))
  //   //     if (status == 1) {
  //   //       if (!jfryParams.deliverActualEndDate) {
  //   //         this.message.warning('请选择实际终止时间！');
  //   //         return
  //   //       }
  //   //       if (jfryParams.deliverBackBookFlag === null) {
  //   //         this.message.warning('请选择账本是否寄回！');
  //   //         return
  //   //       }
  //   //       if (jfryParams.deliverBackBookFlag === '0') {
  //   //         if (!jfryParams.deliverUnDesc) {
  //   //           this.message.warning('请输入账本不寄回原因！');
  //   //           return
  //   //         }
  //   //       }
  //   //       if (jfryParams.deliverBackBookFlag === '1') {
  //   //         if (jfryParams.accountBookAttachmentList.length == 0) {
  //   //           this.message.warning('请上传账本寄回凭证！');
  //   //           return
  //   //         }
  //   //         if (!jfryParams.deliverBackDate) {
  //   //           this.message.warning('请选择寄回时间！');
  //   //           return
  //   //         }
  //   //       }
  //   //       if (jfryParams.financeHandoverAttachmentList.length == 0) {
  //   //         this.message.warning('请上传财务数据！');
  //   //         return
  //   //       }
  //   //       if (!jfryParams.deliverFinanceHandoverDate) {
  //   //         this.message.warning('请选择财务数据交接时间！');
  //   //         return
  //   //       }
  //   //       if (jfryParams.accountDataAttachmentList.length == 0) {
  //   //         this.message.warning('请上传账号资料！');
  //   //         return
  //   //       }
  //   //       if (!jfryParams.deliverAccountDataDate) {
  //   //         this.message.warning('请选择账号资料交接时间！');
  //   //         return
  //   //       }
  //   //       // if (jfryParams.otherDataAttachmentList.length == 0) {
  //   //       //   this.message.warning('请上传其他资料！');
  //   //       //   return
  //   //       // }
  //   //       // if (!jfryParams.deliverOtherDataDate) {
  //   //       //   this.message.warning('请选择其他资料交接时间！');
  //   //       //   return
  //   //       // }
  //   //     }
  //   //     if (jfryParams.deliverActualEndDate) {
  //   //       jfryParams.deliverActualEndDate = Utils.dateFormat(jfryParams.deliverActualEndDate, 'yyyy-MM-dd');
  //   //     }
  //   //     if (jfryParams.deliverBackDate) {
  //   //       jfryParams.deliverBackDate = Utils.dateFormat(jfryParams.deliverBackDate, 'yyyy-MM-dd');
  //   //     }
  //   //     if (jfryParams.deliverFinanceHandoverDate) {
  //   //       jfryParams.deliverFinanceHandoverDate = Utils.dateFormat(jfryParams.deliverFinanceHandoverDate, 'yyyy-MM-dd');
  //   //     }
  //   //     if (jfryParams.deliverAccountDataDate) {
  //   //       jfryParams.deliverAccountDataDate = Utils.dateFormat(jfryParams.deliverAccountDataDate, 'yyyy-MM-dd');
  //   //     }
  //   //     if (jfryParams.deliverOtherDataDate) {
  //   //       jfryParams.deliverOtherDataDate = Utils.dateFormat(jfryParams.deliverOtherDataDate, 'yyyy-MM-dd');
  //   //     }
  //   //     jfryParams.id = this.curList.id;
  //   //     jfryParams.deliverStatus = status;

  //   //     if (status == 1) {
  //   //       this.modalService.create({
  //   //         nzTitle: '提示',
  //   //         nzContent: '点击确定后确认书会通过短信形式发送给客户，请及时联系客户进行确认!',
  //   //         nzStyle: { top: '30px' },
  //   //         nzWidth: 500,
  //   //         nzMaskClosable: false,
  //   //         //nzFooter:null,
  //   //         //nzOnCancel: () => this.form.reset(),
  //   //         nzOnOk: () => {
  //   //           this.api.saveDeliverCheck(jfryParams).subscribe(res => {
  //   //             this.message.success('审核成功！');
  //   //             this.modal.destroy();
  //   //           }, err => {
  //   //           })
  //   //         }
  //   //       })
  //   //     } else {
  //   //       // 不通过
  //   //       this.api.saveDeliverCheck(jfryParams).subscribe(res => {
  //   //         this.message.success('审核成功！');
  //   //         this.modal.destroy();
  //   //       }, err => {
  //   //       })
  //   //     }
  //   //     // this.api.saveDeliverCheck(jfryParams).subscribe(res => {
  //   //     //   // “确认书已通过短信形式发送给客户，请及时联系客户进行确认”
  //   //     //   if (status == 1) {
  //   //     //     this.modalService.create({
  //   //     //       nzTitle: '审核成功！',
  //   //     //       nzContent: '确认书已通过短信形式发送给客户，请及时联系客户进行确认!',
  //   //     //       nzStyle: { top: '30px' },
  //   //     //       nzWidth: 500,
  //   //     //       nzMaskClosable: false,
  //   //     //       //nzFooter:null,
  //   //     //       //nzOnCancel: () => this.form.reset(),
  //   //     //       nzOnOk: () => this.modal.destroy()
  //   //     //     });

  //   //     //   } else {
  //   //     //     this.message.success('审核成功！');
  //   //     //     this.modal.destroy();
  //   //     //   }
  //   //     // }, err => {
  //   //     // })
  //   //     break;
  //   //   case 'jfzg':
  //   //     // 交付主管审核
  //   //     const jfzgParams = JSON.parse(JSON.stringify(this.jfzgParams))
  //   //     if (status == 1) {
  //   //       if (!jfzgParams.deliverManageActualAwayAmount) {
  //   //         this.message.warning('请填写实际损失金额！');
  //   //         return
  //   //       }
  //   //     }
  //   //     jfzgParams.id = this.curList.id;
  //   //     jfzgParams.deliverManageStatus = status;
  //   //     this.api.saveDeliverMangeCheck(jfzgParams).subscribe(res => {
  //   //       this.message.success('审核成功！');
  //   //       this.modal.destroy();
  //   //     }, err => {
  //   //     })
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }

  //   this.api.customerLossCheckNew(params).subscribe(res => {
  //     this.modal.destroy(res);
  //   });

  // };
  endDate;
  submitForm() {
    this.submitParams['id'] = this.params.id;
    this.submitParams['checkStatus'] = this.params.agree;
    // if (this.submitParams.flowBusinessStepUserAttachmentList.length == 0) {
    //   this.message.warning('请上传客户沟通记录！');
    //     return
    // }
    if (this.params.type == 'account_manage' && !this.endDate) {
      this.message.warning('请选择最终确认流失时间！');
      return
    }

    this.api.customerLossCheckNew(this.submitParams).subscribe(res => {
      this.message.success('操作成功');
      if (this.params.type == 'account_manage') {
        this.api.updateCustomerAway({
          id: this.params.id,
          endDate: Utils.dateFormat(this.endDate, 'yyyy-MM-dd')
        }).subscribe(res => { });
      }
      this.modal.destroy(res);
    });
  }
  curList; //当前选中列表
  ngOnInit() {
    // if (this.params.type == 'ywzg') {
    //   this.getDepartment();
    // } else if (this.params.type == 'ff') {
    //   this.getUserList();
    // }
    this.curList = this.params.listData;
  }
  cancel() {
    this.modal.destroy();
  }

  // 上传附件统一
  // 上传附件
  uploadChangeNew(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
    }
    this.submitParams['flowBusinessStepUserAttachmentList'] = attachmentUrls;
  }
}
