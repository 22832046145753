import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';


@Component({
  selector: 'app-modal-add-share',
  templateUrl: './modal-add-share.component.html',
  styleUrls: ['./modal-add-share.component.less']
})
export class ModalAddShareComponent implements OnInit {
  @Input() shareListItem = []; // 分享列表
  form: FormGroup;

  userList = []; // 用户列表
  deptUserId: string;
  constructor(
    fb: FormBuilder,
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {
    this.form = fb.group({
      shareDays: [null, Validators.required],
      archivesCustomerShareUserList: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getUserList();
  }

  loading = false;
  // 分享查看天数
  shareDate = [
    { type: "7", name: '7天' },
    { type: "15", name: '15天' },
    { type: "30", name: '30天' },
  ];
  cancel() {
    this.modal.destroy()
  };

  // 获取用户列表
  getUserList() {
    this.api.getUserListByCompanyId().subscribe((res) => {
      this.userList = res['data'];
    })
  }

  submit() {
    this.loading = true;
    //上传附件
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let value = this.form.value;

    // 下载转化成 0 、 1
    var fileArr = [];
    (this.shareListItem || []).map(item => {
      let obj = {
        archivesCustomerAttachmentId: item.archivesCustomerAttachmentId,
        archivesUrl: item.archivesUrl,
        archivesName: item.archivesName,
        companyName: item.companyName,
        customerName: item.customerName,
        customerMobile: item.customerMobile,
        archivesType: item.archivesType,
        companyId: item.companyId,
        customerId: item.customerId,
        downloadFlag: item.downloadFlag ? 1 : 0,
      }
      fileArr.push(obj)
    })

    var attachmentUrls = [];
    (value.archivesCustomerShareUserList || []).map((item, index) => {
      let p = {
        userId: item
      }
      attachmentUrls.push(p)
    })

    var params = {
      archivesCustomerShareAttachmentList: fileArr,
      shareDays: value.shareDays,
      archivesCustomerShareUserList: attachmentUrls,
    }

    this.api.archivesCustomerShare(params).subscribe(res => {
      this.message.success('分享成功!')
      this.modal.destroy()
    }, err => {
      this.loading = false;
    })

  }
}
