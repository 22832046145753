import { Component, OnInit, Input } from '@angular/core';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-source-config-list',
  templateUrl: './modal-source-config-list.component.html',
  styleUrls: ['./modal-source-config-list.component.less']
})
export class ModalSourceConfigListComponent implements OnInit {
  @Input() data: any; // 新增类型id

  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  listOfData=[];//列表数据集合
  constructor(private api: Api,) { }


  // 翻页
  toPage(page) {
    console.log(page)
    this.pageCtrl.nzPageIndex = page;
    this.getSourceConfigList();
  }
  
  getSourceConfigList(){
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        //parentId:this.id
      }
    }
    this.api.getSourceConfigPageList(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  ngOnInit() {
    //this.getSourceConfigList();
  }

}
