import { Component, OnInit, Input } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';


@Component({
  selector: 'app-drawer-reimburs-writeoff-apply',
  templateUrl: './drawer-reimburs-writeoff-apply.component.html',
  styleUrls: ['./drawer-reimburs-writeoff-apply.component.less']
})
export class DrawerReimbursWriteoffApplyComponent implements OnInit {
  @Input() value = '';
  constructor(

    public location: Location,
    public api: Api,
    public message: NzMessageService,
  ) {

  }

  serverDetail;
  ngOnInit() {

    this.api.getUserVerificationApplyDetail(this.value).subscribe(res => {
      this.serverDetail = res['data'];
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.serverDetail.flowBusinessStepDTOList && this.serverDetail.flowBusinessStepDTOList.length > 0) {
        this.serverDetail.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    })
  }



}
