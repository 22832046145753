import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalSelectproductComponent } from '../modal-selectproduct/modal-selectproduct.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { Router } from "@angular/router"
import { ModalCompanyComponent } from '../modal-company/modal-company.component';

@Component({
  selector: 'app-modal-edit-product',
  templateUrl: './modal-edit-product.component.html',
  styleUrls: ['./modal-edit-product.component.less']
})
export class ModalEditProductComponent implements OnInit {
  @Input() orderId = '';
  @Input() isShare;
  @Input() orderInfo;
  productList = []; // 已选择产品
  constructor(
    public api: Api,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private router: Router,
    public message: NzMessageService,
  ) {

  }
  loading = false;
  totalPayment = 0; // 总金额
  productTotalPayment = 0 // 产品金额
  discountsAmount = 0 //优惠金额
  // 回签，提前，当月,
  renewType = [
    {
      code: 0,
      name: '当月'
    },
    // {
    //   code: 1,
    //   name: '回签'
    // },
    {
      code: 2,
      name: '提前'
    }
  ]
  productType = [
    {
      code: 0,
      name: '新签'
    },
    {
      code: 1,
      name: '续费'
    },
    {
      code: 2,
      name: '回签'
    }
  ]
  // 打开产品列表
  openProduct(): void {
    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        var resProduct = [];
        res.map((item, index) => {
          return resProduct.push({
            cateNames: item.t,
            cateId: item.cateId,
            name: item.name,
            productName: item.name,
            cost: item.cost,
            productPrice: item.price,
            price: item.price,
            realPrice: item.realPrice,
            areaProductId: item.id,
            productCommId: item.productCommId,
            orderId: this.orderId,
            settlePrice: item.settlePrice,
            productNature: item.productNature,
            productPeriod: item.serverPeriod,
            hasCompanyId: '1',
            companyId: null,
            periodAmount: item['realPrice'] / (item['serverPeriod'] || 1),
            renewFlag: '0',
            amount: 1,
            tipsPeriodAmount: item.tipsPeriodAmount,
          })
        })
        // res.map(item => {
        //   item['cateNames'] = item.t;
        //   item['productName'] = item.name;
        //   item['productPrice'] = item.price;
        //   item['areaProductId'] = item.id;
        //   item['companyId'] = null;
        //   item['productPeriod'] = item['serverPeriod'];
        //   item['hasCompanyId'] = '1';
        //   item['renewFlag'] = '0';
        //   item['amount'] = 1;
        //   // 单价
        //   item['periodAmount'] = item['realPrice'] / (item['productPeriod'] || 1);
        // })
        this.productList = [...new Set([...this.productList, ...resProduct])];
        this.getPriceTotal();
      }
    });
  };
  itemAlert = false;
  productNmae: string;
  getItemRealPrice(data) {
    if (data.productNature != 0) {
      // 长期
      if (data.periodAmount < data.tipsPeriodAmount) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.productName + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    } else {
      // 一次性
      if (data.realPrice < data.productPrice) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.productName + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    }
    this.getPriceTotal();
  }
  afterClose(): void {
    console.log('close');
    this.itemAlert = false;
  }
  // 计算实际销售价
  getPrice(data) {
    this.productList.map(item => {
      console.log(item['periodAmount'])
      console.log(item['productPeriod'] + (item['givePeriod'] || 0))
      if (item.productNature != 0) {
        item.realPrice = item['periodAmount'] * (item['productPeriod'] + (item['givePeriod'] || 0));
      }
    })
    this.getItemRealPrice(data);
  }
  // 计算单价
  getPeriodAmount(data) {
    this.productList.map(item => {
      if (item.productNature != 0) {
        item['periodAmount'] = (item.realPrice / (item['productPeriod'] + (item['givePeriod'] || 0))).toFixed(2);
      }
    })
    this.getItemRealPrice(data);
  }

  getPriceTotal() {
    this.totalPayment = 0;
    this.productList.map(item => {
      this.totalPayment += Number(item.realPrice) * Number(item.amount);
    })
  }

  // getPriceTotal(isTrue) {
  //   this.totalPayment = 0;
  //   this.productTotalPayment = 0;
  //   this.productList.map(item => {
  //     this.productTotalPayment += Number(item.realPrice);
  //     this.totalPayment = this.productTotalPayment - Number(this.discountsAmount);
  //   })
  //   if (isTrue) {
  //     // 删除产品更新 金额
  //     this.api.updateOrderPrice({ orderId: this.orderId, totalPayment: this.totalPayment }).subscribe(res => {
  //     })
  //   }
  // }
  delete(id, i) {
    let newList = JSON.parse(JSON.stringify(this.productList));
    try {
      newList.forEach((item, index) => {
        if (id == item.id && id != undefined) {
          // 已有产品，调接口删除
          this.api.delOrderProduct({ id: id }).subscribe(res => {
            this.message.success('删除成功');
            newList.splice(i, 1);
            this.productList = newList;
            this.getPriceTotal();
            throw 'jumpout';
          })
        } else if (id == undefined) {
          // 新增产品，直接删除
          newList.splice(i, 1)
          this.productList = newList;
          this.getPriceTotal();
          throw 'jumpout';
        } else {
          // 否则数据保留
          // newList.push(item);
        }
      });
    } catch (e) {
    }
  }
  cancel() {
    this.modal.destroy()
  }
  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m;
  }
  submit() {
    var params = {};
    params['orderId'] = this.orderId;
    params['productList'] = this.productList;
    params['discountsAmount'] = this.discountsAmount;
    params['totalPayment'] = this.totalPayment;
    params['isShare'] = this.isShare;
    let isProductNature = true;
    let hasPrice = false;
    let hasCompany = false;
    this.productList.map(item => {
      //判断产品价格 当前“实际销售价（单价）” 已低于 “市场指导价（单价）”，请先与上级领导核实
      // 长期
      if (item.productNature != 0) {
        if (item.periodAmount < item.tipsPeriodAmount) {
          params['priceStatus'] = 4;
        }
      }
      // 一次性
      if (item.productNature == 0) {
        if (item.realPrice < item.productPrice) {
          params['priceStatus'] = 4;
        }
      }
      if (!item.realPrice) {
        hasPrice = true;
      }
      if (item.hasCompanyId == '1' && !item.companyId) {
        hasCompany = true;
      }
      if (item.hasCompanyId == '0') {
        item.companyId = null;
      }
      // if (this.productList[0].productNature != item.productNature) {
      //   this.message.error('一次性产品和长期产品不能同时下单，请重新选择', {
      //     nzDuration: 5000
      //   })
      //   isProductNature = false;
      //   this.loading = false;
      //   return
      // }
      // if (item.renewFlag == 1 && item.renewType == null) {
      //   isProductNature = false;
      //   this.loading = false;
      //   this.message.error('请选择续费类型')
      //   return
      // }
      if (item.productNature != 0) {
        item['serverPeriod'] = (item['productPeriod'] || 0) + (item['givePeriod'] || 0);
        item.startDate = this.dataToStr(item.startDate)
        if (!item.startDate) {
          isProductNature = false;
          this.loading = false;
          this.message.error('请选择服务开启时间！');
          return
        }
      }
    });
    if (hasPrice) {
      this.message.error('实际销售价不能为0！');
      this.loading = false;
      return;
    }
    if (hasCompany) {
      this.message.error('请选择客户公司！');
      this.loading = false;
      return;
    }
    if (!isProductNature) {
      return
    }
    this.api.updateOrder(params).subscribe(res => {
      this.modal.destroy(params);
      this.message.success('修改成功');
    })
  }
  getProduct() {
    this.api.getOrderProduct({ orderId: this.orderId }).subscribe(res => {
      this.productList = res['data'];
      this.productList.map((item, index) => {
        item.realPrice = item.salePrice;
        item.hasCompanyId = item.companyId ? '1' : '0';
        item.renewFlag = item.renewFlag || '0';
        item['amount'] = 1;
      });
      this.getPriceTotal();
      // this.getOrder();
      // this.productInfo.map(i => {
      //   if (i.taskId != null) {
      //     this.getTaskDetail(i.taskId);
      //   }
      // });
    })
  }
  getOrder() {
    this.api.getOrderOrder({ id: this.orderId }).subscribe(res => {
      let data = res['data'];
      this.discountsAmount = res['data'].discountsAmount;
      this.getPriceTotal();
    })
  }
  ngOnInit() {
    this.getProduct();
    this.getCompanyList();
  }

  companyList: [];
  // 获取公司列表
  getCompanyList() {
    this.api.getCompanyList({ id: this.orderInfo.customerId }).subscribe(res => {
      this.companyList = res['data'];
    })
  }
  // 弹出窗逻辑 报销名目 start
  isVisible = false
  desInfo = null;
  showModal(index): void {
    switch (index) {
      case 1:
        this.desInfo = '此产品所关联的客户公司,请务必填写。如客户公司未录入到系统，请及时录入再选择!'
        break;
      default:
        break;
    }
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  // 弹出窗逻辑 报销名目 en
  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.orderInfo.customerId,
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => {
      this.getCompanyList();
    });
  }
  // 获取续费标记
  getFindRenewFlag(data, i) {
    if (data.companyId) {
      this.api.findRenewFlag({ companyId: data.companyId, cateId: data.cateId }).subscribe(res => {
        this.productList.map((item, index) => {
          if (index == i) {
            item.renewFlag = res['data'];
          }
        })
      })
    }
  }
  getHasCompanyId(data, i) {
    if (data.hasCompanyId == 1) {
      this.getFindRenewFlag(data, i)
    } else {
      data.renewFlag = '0';
    }
  }
}
