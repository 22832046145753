import { Component, Input, OnInit } from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import {ModalWangeditorComponent} from '../modal-wangeditor/modal-wangeditor.component';

@Component({
  selector: 'app-modal-distributors-customer',
  templateUrl: './modal-distributors-customer.component.html',
  styleUrls: ['./modal-distributors-customer.component.less']
})
export class ModalDistributorsCustomerComponent implements OnInit {

  constructor(public api: Api,
    private modalService: NzModalService, ) { }
  
  @Input() value = '';
  // #region tab1 获取客户详情
  parameters = []; //客户阶段数据集合
  customerRecordList = []; // 客户跟进记录列表
  customerPayRecordList = [] // 客户交付记录列表
  customerInfo;        // 客户信息


  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMore = true;
  getTaskRecordList() {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        customerId: this.value
      }
    }
    this.api.getTaskRecord(params).subscribe(res => {
      console.log(res)
      console.log('11111111111')
      this.customerRecordList = [...this.customerRecordList, ...res['data'].records];

      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    })
  }

  // 获取客户交付记录
  getTaskPayRecordList() {
    const customerId = this.value
    this.api.getCustomerPayRecordList(customerId).subscribe(res => {
      this.customerPayRecordList =  res['data'];
    })
  }

  bigImg : any = false;
  imgList: any;
  toBigImg(content,img){
    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: content,
      nzStyle: {top: '40px'},
      nzMaskClosable: false,
      nzFooter: null
    });
    this.bigImg = true;
    this.imgList = img;
  }

  hiddenImg(){
    this.bigImg = false;
  }
  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getTaskRecordList();
  }

  getCustomerDetail() {
    this.api.getCustomerDetail({ id: this.value }).subscribe(res => {
      this.customerInfo = res['data'];
      this.initCustomerParameters();
    })
  }
  //客户阶段初始化查询
  initCustomerParameters() {
    const params = {
      parentCode: 'CUSTOMER_STAGE',
    }
    let classname = true
    this.api.getSysParameters(params).subscribe(res => {
      this.parameters = res['data'].map(res => {
        if (this.customerInfo.customerCateCode == res.code) {
          res['class'] = true;
          classname = false;
        } else { 
          res['class'] = classname;
        }
        return res;
      });
    });
  }
  // #endregion
  
  // #region tab2 获取订单信息
  orderList = [];      // 关联订单列表
  distributorsProperties=[{
    cateName:'',
    list:[{
      propertyName:'',
      price:'',
    }],
  }];//附加提成
  getOrderListById() {
    this.api.getDisOrderInfo({customerId: this.value}).subscribe(res => {
      this.orderList = res['data'];
      this.orderList.forEach(e=>{
      });
    })
  }
  getDistributorsProperties(){
    console.log();
    this.api.getDistributorsPropertiesByCustomerId({customerId:this.value }).subscribe(res=>{
        this.distributorsProperties=res['data'];
    })
  }

  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'customer'
      },
      nzStyle: {top: '40px'},
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        data['customerId'] = this.value;
        this.api.addCustomerRecord(data).subscribe(res => {
          this.customerRecordList = [];
          this.getTaskRecordList();
        })
      }
    });
  }
  
  // #endregion

  //

  ngOnInit() {
    this.getCustomerDetail();
    this.getOrderListById();
    this.getDistributorsProperties();
    this.getTaskRecordList();
    this.getTaskPayRecordList();
  }

}
