import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Observer } from 'rxjs';
import { Api } from '@service/Api';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { Utils } from '@service/Utils';
import { param } from 'src/assets/ueditor/third-party/jquery-1.10.2';
import { ModalSelectproductComponent } from '../modal-selectproduct/modal-selectproduct.component';
import { ModalSelectOrderProductComponent } from '../modal-select-order-product/modal-select-order-product.component';
@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.less'],
})
export class ModalPaymentComponent implements OnInit {
  @Input() id: string;
  @Input() orderAccount: string;
  @Input() customerId: string;

  form: FormGroup;
  invoiceForm: FormGroup;
  qrcodeObj = {};
  payListOrigin = []; // 支付方式列表
  payList = []; // 支付方式列表
  receivingCompanyList = []; //收款公司
  loading = false;
  linkVisible = false; // 添加导航弹出
  showQrcode = false; // 显示二维码弹窗
  qrcodeUrl: any; // 二维码地址
  type = 1; // 支付类型：0线下、1线上
  shouAccount = false; // 对公账号
  uploadUrl: string;
  fileList = [];
  uploadParams = {
    contractId: ''
  };
  dedicated = false; // 专票
  expressage = true; // 寄送
  orderInvoiceDTO: any;
  contractUrl: string;
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => ~['image'].indexOf(w.type.slice(0, 5)));
        if (filterFiles.length !== fileList.length) {
          this.message.error(`包含文件格式不正确，只支持上传图片`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: UploadFile[]) => {
        return new Observable((observer: Observer<UploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];

  advanceOrderNo = null;//预付款订单ID
  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private ref: ChangeDetectorRef,
  ) {
    this.form = fb.group({
      paymentsId: [null, Validators.required],
      // orderAmountTotal: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      orderAmountTotal: [null, Validators.required],
      startPayTime: [new Date()],
      payAccount: [null],
      imageIdList: [null],
      productAmountTotal: new FormControl({ value: null, disabled: true }, Validators.required),
      key: new FormControl({ value: null, disabled: true }, Validators.required), // 已付金额
      invoice: [1],
      remark: [null],
      type: 1,
      orderInvoiceDTO: [null],
      signedEnterpriseId: [null],
      signedEnterpriseName: [null],
      paymentCompany: [null],
      sysOrderInvoiceTrustAttachmentList: [null],
      advanceOrderId: [null],
      openInvoiceType: [0, Validators.required],
    });

    // 发票信息
    this.invoiceForm = fb.group({
      invoiceContent: [null, Validators.required],
      invoiceTaxNo: [null, Validators.required],
      invoiceAmount: [null, Validators.required],
      // invoiceAmount: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      invoiceType: [1, Validators.required],
      vatTelphone: [null],
      consigneeAddress: [null],
      mailing: [1, Validators.required],
      consigneeRealname: [null],
      consigneeTelphone: [null, Validators.pattern(/^1[3456789]\d{9}$/)],
      vatCompanyAddress: [null],
      vatBankName: [null],
      vatBankAccount: [null],
      remark: [null],
      hasAuthorization: [0, Validators.required],
    })
    this.uploadUrl = this.api.uplaodAttachment();
    this.contractUrl = this.api.storagesUploadWithController();
  }

  

  getPayAmount() {
    this.api.getPayAmount({ orderId: this.id }).subscribe(res => {
      this.form.get('key').setValue(res['data']);
    })
  }

  handleCancel() {
    this.showQrcode = false;
    this.form.reset();
    this.cancel();
  }
  // 支付方式
  getPayMode() {
    this.api.getPayMode().subscribe(res => {
      this.payList = res['data'];
    })
  }

  nzDisableds = false;
  // 收款公司
  getReceivingCompanys() {
    this.api.getReceivingCompany({ orderId: this.id }).subscribe(res => {
      this.receivingCompanyList = res['data'];
      //订单分多笔支付时，发起一笔支付后，后续支付无需选择收款公司（禁用下拉框，默认为首次支付选择的公司。所有支付被驳回后可重新选择）
      if (this.receivingCompanyList.length == 1) {
        this.form.get('signedEnterpriseId').setValue(this.receivingCompanyList[0].id)
        this.nzDisableds = true
      }
    })
  }
  // 获取收款公司
  getCompany() {
    this.api.getCompany(this.id).subscribe(res => {
      // console.log(res);
      this.form.get('signedEnterpriseId').setValue(res['data'].bussCompanyId)
      this.form.get('signedEnterpriseName').setValue(res['data'].bussCompanyName)
      this.form.get('productAmountTotal').setValue(res['data'].totalPayment);
      this.getCustomerById(res['data'].customerId);
    })
  }

  invoiceHeader="";
  getCustomerById(customerId) {
    this.api.getCustomerDetail({
      id:customerId,
      type:0
    }).subscribe(res => {
      this.invoiceHeader=res["data"].name;
    })
  }
  // startPayTime
  showButton: any = false;
  showPaymentCompany: any = false;

  payId;
  // 过滤支付方式(线上 /线下)
  // type == 1 线上
  // type == 0 线下  showButton = true

  filterPayList() {
    this.shouAccount = false;
    if (this.form.get('type').value === 1) {
      this.payList = this.payListOrigin.filter(item => item.isOnline);
      this.showButton = false;
    } else {
      this.payList = this.payListOrigin.filter(item => !item.isOnline);
      this.showButton = true;
    }
    this.payId = null;
  }

  changePayList(e) {

    let str = this.payList.filter(item => item.id == e);
    if (str.length == 0) return
    this.form.get('type').setValue(!str[0].isOnline ? 0 : 1)
    this.showButton = !str[0].isOnline;
    this.invoice=true;
    this.isCompanyInvoice=false;
    this.invoiceForm.get("invoiceContent").setValue(this.invoiceHeader);
    //对公转账 展示付款公司
    if (str[0].id == 6) {
      this.showPaymentCompany = true
      this.isPrepayment = false
      this.isOrder = false;
      this.isCompanyInvoice=true;
      console.log(this.form.get("paymentCompany").value);
      this.invoiceForm.get("invoiceContent").setValue(this.form.get("paymentCompany").value);
    } else if (str[0].id == 20) {
      this.isPrepayment = true
      this.showPaymentCompany = false
      this.isOrder = false;
    } else if (str[0].payCode == '023') {
      this.isPrepayment = false
      this.showPaymentCompany = false
      this.isOrder = true;
      this.invoice=false;
    } else {
      this.showPaymentCompany = false
      this.isPrepayment = false
      this.isOrder = false;
    }
    this.toggleValidators();
    // let arr = this.payList.map(item => {
    //   if(e == item.id){
    //     if(!item.isOnline){
    //       this.form.get('type').setValue(0)
    //       this.showButton = true;
    //     }else{
    //       this.form.get('type').setValue(1)
    //       this.showButton = false;
    //     }
    //     return
    //   }
    // })

    console.log(str)
    console.log(e)
  }


  // // 展示对公账号输入框
  // changeAccountStatus() {
  //   let a = this.form.get('paymentsId').value;
  //   let name = this.payListOrigin.find(item => item.id == a).payName;

  //   // 线上支付及线下现金支付不需对公账户
  //   if (name == '现金' || this.form.get('type').value === 1) {
  //     this.shouAccount = false;
  //     this.form.get('payAccount')!.clearValidators();
  //     this.form.get('payAccount')!.markAsPristine();
  //   } else {
  //     this.shouAccount = true;
  //     this.form.get('payAccount')!.setValidators(Validators.required);
  //     this.form.get('payAccount')!.markAsDirty();
  //   }
  //   this.form.get('payAccount')!.updateValueAndValidity();
  // }

  // 是否开票切换状态
  invoice = true;
  isCompanyInvoice=false;

  toggleValidators() {
    if (this.isCompanyInvoice) {
      this.invoiceForm.get('invoiceTaxNo').setValidators(Validators.required);
    } else {
      this.invoiceForm.get('invoiceTaxNo').clearValidators();
    }
    this.invoiceForm.get('invoiceTaxNo').updateValueAndValidity();
  }

  changeInvoice(e: boolean) {
    this.invoice = e;
  }

  changeOrderAmountTotal(e) {
    console.log(e);
    this.invoiceForm.get("invoiceAmount").setValue(e);
  }

  changePaymentCompany(e) {
    this.invoiceForm.get("invoiceContent").setValue(e);
  }

  cancel() {
    this.modal.destroy()
  }

  isHasAuthorization = null;
  findhasAuthorizationId(e) {
    this.isHasAuthorization = e
  }

  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;

      return
    }

    if (this.form.get("signedEnterpriseId").value == null) {
      this.message.error("请选择收款公司！");
      this.loading = false
      return
    }

    if (this.showPaymentCompany) {
      if (this.form.get("paymentCompany").value == null) {
        this.message.error("请输入付款公司！");
        this.loading = false
        return
      }
      if (this.form.get("startPayTime").value == null) {
        this.message.error("请选择实际支付日期！");
        this.loading = false
        return
      }
    } else {
      this.form.get('paymentCompany').setValue(null);
    }
    var bsPayRecordOrderProductList = [];
    if (this.isOrder) {
      var hasOrder = true;
      if (this.productList.length == 0) {
        hasOrder = false;
        this.message.error("请添加抵扣订单产品！");
        this.loading = false
        return false;
      }
      this.productList.map((item, index) => {
        if (item.deductionAmount2 > 0) {
          bsPayRecordOrderProductList.push({
            deductionAmount: item.deductionAmount2,
            orderProductId: item.id,
            orderNo: item.orderNo,
            customerName: item.customerName,
            orderStatus: item.status,
          })
        } else if ((item.paymentAmount - (item.deductionAmount || 0)) < item.deductionAmount2) {
          this.loading = false;
          hasOrder = false;
          return false;
        } else {
          hasOrder = false;
          this.loading = false;
          return false;
        }

      })
      if (!hasOrder) {
        this.message.error("抵扣订单产品金额不能为0，且不能大于可抵扣金额！");
        this.loading = false
        return false;
      }

    }

    // 申请开票
    if (this.invoice) {
      Object.keys(this.invoiceForm.controls).map((key) => {
        this.invoiceForm.controls[key].markAsDirty();
        this.invoiceForm.controls[key].updateValueAndValidity();
      });
      if (!this.invoiceForm.valid) {
        this.loading = false;
        return
      }
      let invoiceParam = this.invoiceForm.value;
      invoiceParam['orderId'] = this.id;
      this.orderInvoiceDTO = invoiceParam;
    }

    let params = this.form.getRawValue();
    params.startPayTime = Utils.dateFormat(params.startPayTime, 'yyyy-MM-dd');
    params['orderId'] = this.id;
    if (Number(params.orderAmountTotal) > 50000 && this.form.get('paymentsId').value == 5) {
      this.message.error("聚合支付：收款金额不可超过5万，请分两次支付！");
      this.loading = false
      return
    }
    if (this.isOrder) {
      params['bsPayRecordOrderProductList'] = bsPayRecordOrderProductList;
    }
    if (this.invoice) {
      //如果开票
      this.orderInvoiceDTO['hasAuthorization'] = this.orderInvoiceDTO['hasAuthorization'] == 1 ? true : false
      params['orderInvoiceDTO'] = this.orderInvoiceDTO;

      if (Number(params.orderInvoiceDTO['invoiceAmount']) > Number(params.orderAmountTotal)) {
        this.message.error("开票金额不能大于本次支付金额！");
        this.loading = false
        return
      }
      //授权委托书
      if (this.fileOBJ.length > 0 && this.isHasAuthorization == 1) {
        params.sysOrderInvoiceTrustAttachmentList = this.fileOBJ
      } else {
        params.sysOrderInvoiceTrustAttachmentList = null
      }
    }

    if (this.isPrepayment) {
      //预付款订单
      if (this.form.get("advanceOrderId").value == null) {
        this.message.error("请选择预收款订单！");
        this.loading = false
        return
      } else {
        params.advanceOrderId = this.selectOrderData[0].id
      }
      this.api.orderPrepaymentListAdvanceOrderPay(params).subscribe(res => {
        this.message.success('订单支付成功！');
        this.modal.destroy();
      }, () => this.loading = false)
    } else {
      params.advanceOrderId = null;
      if (this.form.controls.type.value === 1) {
        this.api.getWechatPayQrcode(params).subscribe(res => {
          this.showQrcode = true;
          this.qrcodeObj = res['data'];
          this.qrcodeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res['data'].fileUrl);
        }, err => {
          this.loading = false;
        })
      } else {
        if (this.fileList.length > 0) {
          params['imageIdList'] = this.fileList.map(f => f.response.data.id);
        }
        this.api.offlinePayment(params).subscribe(res => {
          this.message.success('订单支付成功！');
          this.modal.destroy();
        }, () => this.loading = false)
      }
    }

  }

  fileOBJ = [];
  // 上传授权委托书
  uploadChanges(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl
        }
        attachmentUrls.push(p)
      })
      this.fileOBJ = attachmentUrls
    }
  }

  invoiceType(event): void {
    if (event == 0) {
      this.dedicated = true;
    } else {
      this.dedicated = false;
    }
  }

  expressageType(event): void {
    if (event == 1) {
      this.expressage = true;
    } else {
      this.expressage = false;
    }
  }

  // 上传发票
  uploadChange(info) {
    // if (info.file.status === 'done' && info.file.response.code === 200) {
    //   this.fileList.push(info.file.response.data.id);
    //   console.log(this.fileList)
    //   // this.uploadParams['contractId'] = info.file.response.data.id;

    // }
  }

  //选择预收款
  selectOrderData;
  isPrepayment = false;
  listOfData = [];
  addOrderList(orderListTemplate) {
    if (this.form.get("signedEnterpriseId").value == null) {
      this.message.error("请选择收款公司！");
      return
    }
    this.modalService.create({
      nzTitle: '添加预收款订单',
      nzContent: orderListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 1300,
      nzMaskClosable: false,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzOnOk: () => this.addParentOrder()
    });
    this.getOrderlistPrepaymentList();
  }

  getOrderlistPrepaymentList() {
    const params = {
      customerId: this.customerId,
      signedEnterpriseId: this.form.get("signedEnterpriseId").value,
    }
    this.api.orderPrepaymentListAdvanceOrderList(params).subscribe(res => {
      this.listOfData = res['data']
    });
  }

  addParentOrder() {
    this.advanceOrderNo = this.selectOrderData[0].advanceOrderNo
  }

  orderMapOfCheckedId: { [key: string]: boolean } = {};
  orderRefreshStatus(e, id): void {
    for (let i in this.orderMapOfCheckedId) {
      this.orderMapOfCheckedId[i] = false;
    }
    this.orderMapOfCheckedId[id] = true;
    this.selectOrderData = this.listOfData.filter(item => item.id == id);
  }

  // 订单列表
  isOrder = false;
  productList = [];
  totalPayment = 0; // 总金额
  // 打开产品列表
  openProduct(): void {

    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectOrderProductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        console.log(res);
        this.productList = [...new Set([...this.productList, ...res])];
        this.getPriceTotal();
      }
    });
  }
  getPriceTotal() {
    this.totalPayment = 0;
    this.productList.map(item => {
      this.totalPayment += Number(item.deductionAmount2);
    })
    this.form.get('orderAmountTotal').setValue(this.totalPayment);
  }
  delete(id) {
    let newList = [];
    this.productList.forEach((item, index) => {
      if (item.id !== id) {
        newList.push(item);
      }
    });
    this.productList = newList;
    this.getPriceTotal();
  }
  getItemRealPrice(data) {
    console.log(data)
    if ((data.paymentAmount - (data.deductionAmount || 0)) < data.deductionAmount2) {
      this.message.error("抵扣不可大于可抵扣金额！");
      data.deductionAmount2 = 0;
      this.ref.markForCheck();
      this.ref.detectChanges();
      return false;
    }
    this.getPriceTotal();
  }
  ngOnInit() {
    this.getPayMode();
    this.getPayAmount();
    // this.getReceivingCompanys();
    this.getCompany();
    // this.form.get('productAmountTotal').setValue(this.orderAccount);
    this.toggleValidators();
  }

}
