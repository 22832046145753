import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import * as startOfMonth from 'date-fns/start_of_month';
import * as endOfMonth from 'date-fns/end_of_month';

import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { NzCascaderOption } from 'ng-zorro-antd';
let fk;
let selectCityData = []; // 选择的城市

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  linkForm: FormGroup; // 导航列表表单
  scheduleForm: FormGroup; // 创建日程表单

  resourceOption = {
    color: ['#FF4A66', '#DDCE32', '#6A48E1', '#31A982', '#3E7CE1'],

    tooltip: {
      trigger: 'item',
      formatter: function (obj) {
        return obj.data.name + ':' + obj.data.reveal + '个'
      },

    },

    legend: {
      data: []
    },
    series: [
      {
        name: '数量',
        type: 'funnel',
        left: '6%',
        width: '60%',
        height: '62%',
        min: 3,
        max: 11,
        minSize: '24%',
        maxSize: '90%',
        sort: 'descending',
        gap: 0,
        label: {
          // show: false,
          //   position: 'inside'
          normal: {
            position: 'right',
            textStyle: {
              fontSize: 12
            },
            formatter: function (obj) {
              return obj.data.name + obj.data.reveal + '个'
            },
          },
          emphasis: {
            position: 'inside',
            formatter: '{b}',
            textStyle: {
              fontSize: 12
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            opacity: 2
          }
        },
        data: [

        ]
      },

    ]
  };
  //-------------------------柱状图开始
  roi = {
    label: {
      show: true,
    },
    legend: {
      data: []
    },
    title: {
      text: '',
      bottom: 10,
      left: 'center',
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        show: false,
        color: '#a9a9a9'
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#a9a9a9'
        },
        interval: 0,
      },
      axisLine: {
        lineStyle: {
          color: '#a9a9a9'
        }
      },
      data: ['投放金额', '总购买金额', '首次购买', '再购买', '转介绍'],
    }],
    yAxis: {
      type: 'value',
      name: '元',
      nameTextStyle: {
        fontSize: 12,
        color: '#a9a9a9'
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#a9a9a9'
        },
        interval: 0,
      },
      splitLine: {
        lineStyle: {
          color: '#e8e8e8',
          width: 1,
          type: 'solid'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#a9a9a9'
        }
      }
    },
    series: [{
      name: '',
      type: 'bar',
      barWidth: '40%',
      data: [
      ]
    }]
  };
  //-------------------------柱状图结束


  //==============================目标完成仪表盘
  targetCompletion = {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%"
    },
    series: [{
      name: '目标完成率',
      type: 'gauge',
      detail: {
        formatter: '{value}%'
      },
      axisLine: {
        show: true,
        lineStyle: {
          width: 30,
          shadowBlur: 0,
          color: [
            [0.3, '#FF4A66'],
            [0.7, '#DDCE32'],
            [1, '#31A982']
          ]
        }
      },
      data: [{
        value: 50,
        name: '完成率',
      }]

    }]
  };

  //================================排行榜-记账报税
  league = {
    title: {
      text: '',
      bottom: 10,
      left: 'center',
      textStyle: {
        fontSize: 16
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        show: false,
        color: '#a9a9a9'
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#a9a9a9'
        },
        interval: 0,
      },

      axisLine: {
        lineStyle: {
          color: '#a9a9a9'
        }
      },
      data: [],
    }],
    yAxis: {
      type: 'value',
      name: '元',
      nameTextStyle: {
        fontSize: 12,
        color: '#a9a9a9'
      },
      axisLabel: {
        textStyle: {
          fontSize: 12,
          color: '#a9a9a9'
        },
        interval: 0//0：全部显示，1：间隔为1显示对应类目，2：依次类推，
      },
      splitLine: {
        lineStyle: {
          color: '#e8e8e8',
          width: 1,
          type: 'solid'
        }
      },
      axisLine: {
        lineStyle: {
          color: '#a9a9a9'
        }
      },
    },
    series: [{
      name: '',
      type: 'bar',
      barWidth: '40%',
      data: []
    }]
  };

  monthFormat: string = 'yyyy-MM';
  monthList = []; // 月份列表
  deptList = [];//月份
  provinceList = [];
  cityValues: any[] | null = null; // 数据面板城市
  ROIData; //ROI
  PanelDataVO; // 数据面板
  taskAccount; // 我的任务
  links = [];       // 导航栏列表
  linkVisible = false; // 添加导航弹出
  isOkLoading = false;
  editType = 'create';
  linkId = '';
  sortValue; // 排序值
  echartOpt; //echart对象
  echartROIOpt; //echart对象
  echartLeagueOpt; //排行榜echart对象
  echartTargetOpt;//目标完成率echart对象
  deptId: Number;// 数据面板部门
  MothItem: String;// 数据面板月份
  mothItem_tmp: String;//月份
  areaCode: String;
  areaId: any; // 选择城市的ID
  type: string = '2';
  targetCompletionData = { target: "", total: "", completion: 0 };

  constructor(
    fb: FormBuilder,
    private modalService: NzModalService,
    private message: NzMessageService,
    private nzContextMenuService: NzContextMenuService,
    public api: Api,
  ) {
    fk = this.api;
    this.linkForm = fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      sort: [null, Validators.required],
    });

    this.scheduleForm = fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  cityValuesT; // 客户资源城市
  deptIdT; // 客户资源部门
  MothItemT; // 客户资源日期

  cityValuesRoi; // 资源转换城市
  deptIdRoi; // 资源转换部门
  MothItemRoi; // 资源转换日期

  cityValuestargetCompletion; // 目标完成率城市
  deptIdtargetCompletion; // 目标完成率部门
  MothItemtargetCompletion; // 目标完成率日期

  cityValuesleague; // 排行榜城市
  deptIdleague; // 排行榜部门
  MothItemleague; // 排行榜日期
  otherType;
  // 数据搜索
  onDataAreaChanges(values: NzCascaderOption[], type): void {
    if (values.length > 0) {
      this.areaCode = values[values.length - 1]['code'];
      this.areaId = values[values.length - 1]['id'];
    }
    else {
      this.areaCode = undefined;
      this.areaId = '';
    }
    this.getdeptList();
    //     selectCityData.forEach(data=>{
    //   if(data.code == this.areaCode){
    //     this.areaId = data.id;
    //     this.getdeptList();
    //   }
    // })
    this.getHomeDataPanelSearchS(type);
  }
  deptIds;
  onDataDeptChanges(values: any, type): void {
    this.deptId = values.slice(-1);
    this.getHomeDataPanelSearchS(type);
  }
  onDataMothChanges(values: any, type): void {
    if (values) {
      if (type === 3) {
        this.mothItem_tmp = this.dateToStr(values);
        this.MothItemtargetCompletion = this.mothItem_tmp;
      } else {
        let t = values.split('/');
        this.mothItem_tmp = t[0] + '-' + t[1];
      }
    }
    else {
      this.mothItem_tmp = null;
      this.MothItemtargetCompletion = this.mothItem_tmp;
    }
    this.getHomeDataPanelSearchS(type);
  }
  onChanges(values: any, type) {
    if (!this.mothItem_tmp) {
      this.mothItem_tmp = this.dateToStr(new Date());
    }
    this.getHomeDataPanelSearchS(type);
  }
  // 切换仪表盘时清空筛选条件
  clear() {
    this.mothItem_tmp = null;
    this.deptId = null;
    this.areaCode = null;
  }
  getHomeDataPanelSearchS(n) {
    // if(this.otherType && this.otherType != n){
    //   this.clear();
    //   this.otherType = n;
    // }else {
    //   this.otherType = n;
    // }
    const params = {

    }
    if (this.deptId != null && this.deptId != undefined) {
      params['deptId'] = this.deptId;
    }
    if (this.areaCode != null && this.areaCode != undefined) {
      params['areaCode'] = this.areaCode;
    }
    if (this.mothItem_tmp != null && this.mothItem_tmp != undefined) {
      if (n === 3 && this.type === '0') {
        if (typeof (this.MothItemtargetCompletion) !== 'string') {
          params['date'] = this.dateToStr(this.MothItemtargetCompletion).split('-')[0];
        } else {
          params['date'] = this.MothItemtargetCompletion.split('-')[0];
        }
      } else {
        params['date'] = this.mothItem_tmp;
      }
    }


    if (n == 1) { // 数据面板
      let paramsVo = {};
      if (this.deptIds && this.deptIds.length > 0 ? this.deptIds.slice(-1) : null) {
        paramsVo['deptId'] = this.deptIds.slice(-1);
      }
      if (this.cityValues.length > 1 ? this.cityValues[1] : this.cityValues[0]) {
        paramsVo['areaCode'] = this.cityValues.length > 1 ? this.cityValues[1] : this.cityValues[0];
      }
      if (this.MothItem ? this.MothItem.replace('/', '-') : null) {
        paramsVo['date'] = this.MothItem ? this.MothItem.replace('/', '-') : null;
      }
      this.api.getPanelDataVO(paramsVo).subscribe(res => {
        this.PanelDataVO = res['data'];
      })
    } else if (n == 2) { // 客户资源池
      let paramsSe = {};
      if (this.deptIdT && this.deptIdT.length > 0 ? this.deptIdT.slice(-1) : null) {
        paramsSe['deptId'] = this.deptIdT.slice(-1);
      }
      if (this.cityValuesT.length > 1 ? this.cityValuesT[1] : this.cityValuesT[0]) {
        paramsSe['areaCode'] = this.cityValuesT.length > 1 ? this.cityValuesT[1] : this.cityValuesT[0];
      }
      if (this.MothItemT ? this.MothItemT.replace('/', '-') : null) {
        paramsSe['date'] = this.MothItemT ? this.MothItemT.replace('/', '-') : null;
      }
      this.api.getSellingVulnerabilities(paramsSe).subscribe(res => {
        const data = res['data'];
        const list = [14, 7, 9, 5, 2];
        this.resourceOption.series[0].data = data.map(function (item, i) {
          return { name: item.name, reveal: item.amount, value: list[i] };
        }
        );
        //this.resourceOption.series[1].max=data.map(item=>item.amount).reduce((prev, curr)=> prev+curr);   
        this.resourceOption.legend.data = data.map(item => item.name);
        this.anyFunction();
      })
    } else if (n == 3) { // 目标完成率
      let paramsT = {};
      if (this.deptIdtargetCompletion && this.deptIdtargetCompletion.length > 0 ? this.deptIdtargetCompletion.slice(-1) : null) {
        paramsT['deptId'] = this.deptIdtargetCompletion.slice(-1);
      }
      if (this.cityValuestargetCompletion.length > 1 ? this.cityValuestargetCompletion[1] : this.cityValuestargetCompletion[0]) {
        paramsT['areaCode'] = this.cityValuestargetCompletion.length > 1 ? this.cityValuestargetCompletion[1] : this.cityValuestargetCompletion[0];
      }
      this.MothItemtargetCompletion = this.MothItemtargetCompletion ? this.MothItemtargetCompletion.replace('/', '-') : null
      if (this.MothItemtargetCompletion != null && this.MothItemtargetCompletion != undefined) {
        if (this.type === '0') {
          if (typeof (this.MothItemtargetCompletion) !== 'string') {
            paramsT['date'] = this.dateToStr(this.MothItemtargetCompletion).split('-')[0];
          } else {
            paramsT['date'] = this.MothItemtargetCompletion.split('-')[0];
          }
        } else {
          paramsT['date'] = this.MothItemtargetCompletion;
        }
      }
      paramsT['quarterType'] = this.type;
      this.api.getTargetCompletion(paramsT).subscribe(res => {
        this.targetCompletionData = res['data'];
        this.targetCompletion.series[0].data[0].value = this.targetCompletionData.completion;
        this.anyTargetFunction();
      });
    } else if (n == 4) { // 资源转换比
      let paramsR = {};
      if (this.deptIdRoi && this.deptIdRoi.length > 0 ? this.deptIdRoi.slice(-1) : null) {
        paramsR['deptId'] = this.deptIdRoi.slice(-1);
      }
      if (this.cityValuesRoi.length > 1 ? this.cityValuesRoi[1] : this.cityValuesRoi[0]) {
        paramsR['areaCode'] = this.cityValuesRoi.length > 1 ? this.cityValuesRoi[1] : this.cityValuesRoi[0];
      }
      if (this.MothItemRoi ? this.MothItemRoi.replace('/', '-') : null) {
        paramsR['date'] = this.MothItemRoi ? this.MothItemRoi.replace('/', '-') : null;
      }
      this.roiParams = paramsR;
      this.getRoi();
    } else if (n == 5) { // 代理记账排行榜
      let paramsA = {};
      if (this.deptIdleague && this.deptIdleague.length > 0 ? this.deptIdleague.slice(-1) : null) {
        paramsA['deptId'] = this.deptIdleague.slice(-1);
      }
      if (this.cityValuesleague.length > 1 ? this.cityValuesleague[1] : this.cityValuesleague[0]) {
        paramsA['areaCode'] = this.cityValuesleague.length > 1 ? this.cityValuesleague[1] : this.cityValuesleague[0];
      }
      if (this.MothItemleague ? this.MothItemleague.replace('/', '-') : null) {
        paramsA['date'] = this.MothItemleague ? this.MothItemleague.replace('/', '-') : null;
      }
      this.agencyLeagueParams = paramsA;
      this.getAgencyLeague();
    }
  }

  // 获取本年度月份列表
  getMonthList() {
    let date = new Date();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    while (month > 0) {

      let i = month < 10 ? '0' + month : month;

      this.monthList.push({
        value: `${year}/${i}`,
        name: (month--) + '月'
      });
    }
    // console.log( this.monthList);
  }

  // 转换时间格式
  dateToStr(date) {
    if (date) {
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      month = month < 10 ? '0' + month : month;
      return date = year + '-' + month;
    }
    else {
      return date = null;
    }
  }

  // 获取数据面板信息
  getPanelDataVO() {
    let params = {
      // todo 
    };
    this.api.getPanelDataVO(params).subscribe(res => {
      this.PanelDataVO = res['data'];
    })
  }

  // 获取部门
  getdeptList() {
    this.api.getPerTreeNullList({ areaId: this.areaId }).subscribe(res => {
      if (res['data']) {
        this.deptList = this.setTreeList(res['data'][0]['sectionList']);
      } else {
        this.deptList = undefined;
        this.deptIds = '';
      }
      if (this.deptList && this.deptList.length < 1) {
        this.deptList = undefined;
        this.deptIds = '';
      }
    })
  }
  setTreeList(list) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let isLeaf = list[i].sectionList ? false : true;
      let obj = {
        name: list[i].name,
        id: list[i].id,
        code: list[i].code,
        type: list[i].type,
        isLeaf: isLeaf,
        remark: list[i].remark,
        address: list[i].address,
        areaCode: list[i].areaCode,
        category: list[i].category,
      }
      result.push(obj);
      if (list[i].sectionList) {
        obj['children'] = this.setTreeList(list[i].sectionList);
      }
    }
    return result;
  }
  validate(_index: number): boolean {
    return true;
  }

  getSellingVulnerabilities() {
    let params = {
      // todo 
    };
    this.api.getSellingVulnerabilities(params).subscribe(res => {
      const data = res['data'];
      const list = [14, 11, 9, 7, 5, 5];
      this.resourceOption.series[0].data = data.map(function (item, i) {
        return { name: item.name, reveal: item.amount, value: list[i] };
      }

      );

      //this.resourceOption.series[1].max=data.map(item=>item.amount).reduce((prev, curr)=> prev+curr);
      this.resourceOption.legend.data = data.map(item => item.name);

      this.anyFunction();
      //console.log(JSON.stringify(this.resourceOption));
    })
  }

  onChartInit(chartOpt) {
    this.echartOpt = chartOpt;  //echartOpt是定义的用于接受echart的变量
  }
  //修改echart对象,触发事件即可
  anyFunction() {
    this.echartOpt.setOption(this.resourceOption, true); //resourceOption是echart的option配置对象
  }

  /********************** 导航栏 start **********************/
  // 获取导航栏列表
  getLinks() {
    this.api.getLinks({}).subscribe(res => {
      this.links = res['data'];
    })
  }
  addLink() {
    this.linkVisible = true;
  }
  updateValue(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.sortValue = value;
    }
  }
  // 右键菜单
  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    this.nzContextMenuService.create($event, menu);
  }
  // 修改链接
  toChange(link) {
    this.linkVisible = true;
    this.editType = 'update';
    this.linkId = link.id;
    Object.keys(link).map((key) => {
      if (this.linkForm.get(key)) {
        this.linkForm.get(key).setValue(link[key]);
      }
    })
  }
  // 刪除链接
  deleteLink(id) {
    this.api.deleteLink({ id: id }).subscribe(res => {
      this.getLinks();
    })
  }
  handleCancel() {
    this.linkVisible = false;
    this.linkForm.reset();
  }
  submitLink(value) {
    // 更新表单状态
    Object.keys(this.linkForm.controls).map((key) => {
      this.linkForm.controls[key].markAsDirty();
      this.linkForm.controls[key].updateValueAndValidity();
    });

    if (!this.linkForm.valid) {
      return
    }
    this.isOkLoading = true;
    value['color'] = '#1890ff';

    if (this.editType === 'create') {
      this.api.addLink(value).subscribe(res => {
        this.refresh();
      }, err => {
        this.isOkLoading = false;
      })
    } else {
      value['id'] = this.linkId;
      this.api.updateLink(value).subscribe(res => {
        this.refresh();
      }, err => {
        this.isOkLoading = false;
      })
    }
  }
  refresh() {
    this.linkVisible = false;
    this.isOkLoading = false;
    this.linkForm.reset();
    this.message.create('success', `导航标签添加成功!`);
    this.getLinks();
  }
  /********************** 导航栏 end **********************/

  // 我的任务统计
  getTaskAccount() {
    this.api.getTaskAccount().subscribe(res => {
      this.taskAccount = res['data'];
    })
  }

  //-----------------ROI柱状图方法
  onROIChartInit(chartOpt) {
    this.echartROIOpt = chartOpt;
  }
  //修改echart对象,触发事件即可
  anyROIFunction() {
    this.echartROIOpt.setOption(this.roi, true); //resourceOption是echart的option配置对象
  }
  roiParams: any = {};
  getRoi() {
    this.api.getRoi(this.roiParams).subscribe(res => {
      this.ROIData = res['data'];
      const data = res['data'];
      const showData = [{
        name: "投放金额",
        value: data.putAmount,
        itemStyle: {
          color: '#753FDC'
        }
      }, {
        name: "总购买金额",
        value: data.allAmount,
        itemStyle: {
          color: '#32B16C'
        }
      }, {
        name: "首次购买",
        value: data.firstBuy,
        itemStyle: {
          color: '#FF9F00'
        }
      }, {
        name: "再购买",
        value: data.repurchase,
        itemStyle: {
          color: '#427CFF'
        }
      }, {
        name: "转介绍",
        value: data.referral,
        itemStyle: {
          color: '#1F78B4'
        }
      },]
      this.roi.series[0].data = showData;
      this.anyROIFunction();

    })

  }

  //=====================目标为完成率方法
  onTargetChartInit(chartOpt) {
    this.echartTargetOpt = chartOpt;
  }
  //修改echart对象,触发事件即可
  anyTargetFunction() {
    this.echartTargetOpt.setOption(this.targetCompletion, true); //resourceOption是echart的option配置对象
  }
  getTargetCompletion() {
    let params = {
      quarterType: 2,
      data: this.dateToStr(new Date())
    };
    this.api.getTargetCompletion(params).subscribe(res => {
      this.targetCompletionData = res['data'];
      this.targetCompletion.series[0].data[0].value = this.targetCompletionData.completion;
      this.anyTargetFunction();
    });
  }


  //========================排行榜
  onLeagueChartInit(chartOpt) {
    this.echartLeagueOpt = chartOpt;
  }
  //修改echart对象,触发事件即可
  anyLeagueFunction() {
    this.echartLeagueOpt.setOption(this.league, true); //resourceOption是echart的option配置对象
  }
  agencyLeagueParams: any = {};
  getAgencyLeague() {
    this.api.getAgencyLeague(this.agencyLeagueParams).subscribe(res => {
      const data = res['data'];
      console.log(data)
      const showData = data.map(item => {
        const showData = {
          name: item.name,
          value: item.amount,
          itemStyle: {
            color: '#753FDC'
          },
        }
        return showData;
      });
      this.league.xAxis[0].data = data.map(item => { return item.name; });
      this.league.series[0].data = showData;
      
      this.anyLeagueFunction();
    });
  }

  // #region 日程
  scheduleList = []; // 日程列表
  ScheduleDateList = []; // 日程列表所包含的日期
  colors = ['#11c06c', '#0bb2d4', '#eb6709', '#ff4c52'];
  theme = '#11c06c';
  today = new Date();
  query = {
    stratTime: '',
    endTime: ''
  };
  detail = {
    title: '',
    content: ''
  }; // 当前日程
  // 修改日程主题色
  changeTheme(theme) {
    this.theme = theme;
  }
  // 日期选择限制
  resetEndTime() {
    this.scheduleForm.controls['endTime'].reset();
  }
  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) < 0;
  };
  disabledDateEnd = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.scheduleForm.get('startTime').value) < 0;
  };

  // 设置查询参数
  setQueryParams(date: Date) {
    this.query.stratTime = Utils.dateFormat(startOfMonth(date), 'yyyy-MM-dd hh:mm:ss');
    this.query.endTime = Utils.dateFormat(endOfMonth(date), 'yyyy-MM-dd hh:mm:ss');
    this.getScheduleList();
  }
  // 设置日程表状态
  setScheduleStatus(date: Date): boolean {
    return this.scheduleList.some(item => {
      let start = differenceInCalendarDays(date, item.startTime);
      let end = differenceInCalendarDays(date, item.endTime);
      return (start >= 0 && end <= 0);
    })
  }
  panelChange(e) {
    console.log(e)
  }
  selectChange(date) {
    this.setQueryParams(date);
    let i = this.scheduleList.find(item => {
      let start = differenceInCalendarDays(date, item.startTime);
      let end = differenceInCalendarDays(date, item.endTime);
      return (start >= 0 && end <= 0);
    })
    if (i) {
      this.detail.title = i.title;
      this.detail.content = i.content;
    } else {
      this.detail.title = this.detail.content = '';
    }
  }
  // 获取日程列表
  getScheduleList() {
    let params = {
      startTime: this.query.stratTime,
      endTime: this.query.endTime,
    }
    this.api.getScheduleList(params).subscribe(res => {
      this.scheduleList = res['data'];
    })
  }
  // 添加日程
  addSchedule(content) {
    this.modalService.create({
      nzTitle: '新建日程',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskClosable: false,
      nzOnCancel: () => this.scheduleForm.reset(),
      nzOnOk: () => new Promise((resolve, reject) => {
        // 更新表单状态
        Object.keys(this.scheduleForm.controls).map((key) => {
          this.scheduleForm.controls[key].markAsDirty();
          this.scheduleForm.controls[key].updateValueAndValidity();
        });
        if (!this.scheduleForm.valid) {
          resolve(false)
          return
        }
        let params = this.scheduleForm.value;
        params.startTime = Utils.dateFormat(params.startTime, 'yyyy-MM-dd hh:mm:ss');
        params.endTime = Utils.dateFormat(params.endTime, 'yyyy-MM-dd hh:mm:ss');
        params.color = this.theme;
        this.api.addSchedule(this.scheduleForm.value).subscribe(res => {
          this.scheduleForm.reset();
          this.message.success('日程添加成功');
          resolve(true)
        }, err => {
          resolve(false)
        });
      }).catch(() => { })
    });
  }
  // #endregion
  city_Data: any;
  ngOnInit() {
    this.getPanelDataVO();
    this.getMonthList();
    // this.getdeptList();
    this.getLinks();
    this.getTaskAccount();
    this.getSellingVulnerabilities();
    this.getRoi();
    this.getTargetCompletion();
    this.getAgencyLeague();
    this.setQueryParams(this.today);
    setTimeout(() => this.selectChange(this.today), 1000);

    this.MothItemtargetCompletion = this.dateToStr(new Date());

    this.api.getDimensionAreaData().subscribe(res => {
      this.city_Data = res['data'];
    });
  }


}
