import { Component, Input, OnInit } from '@angular/core';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-customer-group',
  templateUrl: './modal-customer-group.component.html',
  styleUrls: ['./modal-customer-group.component.less']
})
export class ModalCustomerGroupComponent implements OnInit {
  @Input() customerInfo: object; // 客户信息
  constructor(
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) { }

  ngOnInit() {
    this.getGroupchatList();
    if (this.customerInfo['chatId']) {
      this.api.groupchatGet(this.customerInfo['chatId']).subscribe(res => {
        this.groupInfo = res['data'];
        this.groupInfo.memberList = JSON.parse(this.groupInfo.memberList);
        this.groupInfo.adminList = JSON.parse(this.groupInfo.adminList);
      });
    }

  }
  groupInfo;
  orderTypeList = []; // 客户群列表
  search() {
    this.getGroupchatList();
  }
  reset() {
    this.params = {
      name: '',
      chatStatus: '0'
    };
    this.pageCtrl = {
      total: 0,
      nzPageIndex: 1,
      nzPageSize: 10
    };
    this.getGroupchatList();
  }
  //分页
  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = {
    name: '',
    chatStatus: '0',//群状态
  }

  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getGroupchatList();
  };
  // 获取客户群列表
  getGroupchatList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    }
    this.api.groupchatList(params).subscribe((res) => {
      this.orderTypeList = res['data'].records;
      this.orderTypeList.map((item, index) => {
        item.memberList = JSON.parse(item.memberList);
        item.adminList = JSON.parse(item.adminList);
      })
    })
  }
  // signTeplateData;
  // //选择客户群
  // mapOfCheckedIdTwo: { [key: string]: boolean } = {};
  // refreshStatus(e, id): void {
  //   for (let i in this.mapOfCheckedIdTwo) {
  //     this.mapOfCheckedIdTwo[i] = false;
  //   }
  //   this.mapOfCheckedIdTwo[id] = true;
  //   this.signTeplateData = this.orderTypeList.filter(item => item.id == id);
  // }

  cancel() {
    this.modal.destroy();
  }

  loading = false;
  // 保存申请
  // submit() {
  //   this.loading = true;
  //   if (this.signTeplateData.length === 0) {
  //     this.message.warning('请选择群！');
  //     this.loading = false;
  //     return
  //   };
  //   this.api.updateChat({
  //     chatId: this.signTeplateData[0]['chat_id'],
  //     chatName: this.signTeplateData[0]['name'],
  //     id: this.customerInfo['id']
  //   }).subscribe(res => {
  //     this.message.success('绑定成功！');
  //     this.modal.destroy();
  //     this.loading = false;
  //   }, err => {
  //     this.loading = false;
  //   })
  // }
  // 保存申请
  submit(data) {
    this.loading = true;
    this.api.updateChat({
      chatId: data['id'],
      chatName: data['name'],
      id: this.customerInfo['id']
    }).subscribe(res => {
      this.message.success('绑定成功！');
      this.modal.destroy();
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }
  unLock() {
    this.api.unBandChat(this.customerInfo['id']).subscribe(res => {
      this.message.success('解绑成功！');
      this.customerInfo['chatId'] = null;
    }, err => {
    })
  }

}
