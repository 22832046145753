import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { DataDimensionService } from '@service/DataDimensionService';
import { ModalSelectproductComponent } from '../../../component/modal-selectproduct/modal-selectproduct.component';

let localApi;

@Component({
  selector: 'app-modal-add-coupon',
  templateUrl: './modal-add-coupon.component.html',
  styleUrls: ['./modal-add-coupon.component.less']
})
export class ModalAddCouponComponent implements OnInit {
  @Input() type: string;
  @Input() data: object;

  form: FormGroup;


  formatterPercent = (value: number) => `${value}`;
  parserPercent = (value: string) => value.replace('', '');
  // formatterPercent = (value: number) => `${value} %`;
  // parserPercent = (value: string) => value.replace(' %', '');


  constructor(
    public fb: FormBuilder,
    private modalService: NzModalService,
    public message: NzMessageService,
    public api: Api,
    public requstService: DataDimensionService,
  ) {
    localApi = this.api;
    this.form = fb.group({
      couponTitle: ['', Validators.required],
      couponType: [1, Validators.required],
      productUse: [null, Validators.required],
      couponVal: [null, Validators.required],
      couponCount: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      remark: [''],

    });
  }

  setData(data) {
    this.form.get('productName').setValue(data['productName']);
    this.form.get('unifyAmount').setValue(data['unifyAmount']);
    this.form.get('productType').setValue(data['productType']);
    this.form.get('scale').setValue(data['scale']);
    this.form.get('isHighTechnology').setValue(data['isHighTechnology']);
    this.form.get('productNature').setValue(data['productNature']);
    this.form.get('remark').setValue(data['remark']);
    this.form.get('cateIds').setValue([data['oneCategoryId'], data['twoCategoryId']]);
  }

  ngOnInit() {
    this.requstService.getDataDimension();
    this.getAllParameters();
    if (this.type == 'edit') {
      this.setData(this.data['id'])
    }
  }

  bsCouponProductList = []; // 已选择产品
  // 打开产品列表
  openProduct(): void {
    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.bsCouponProductList = [...new Set([...this.bsCouponProductList, ...res])];
        let map = new Map();
        for (let item of this.bsCouponProductList) {
          map.set(item.id, item);
        }
        this.bsCouponProductList = [...map.values()];
      }
    });
  }
  // 删除产品
  delete(i) {
    let newList = [];
    this.bsCouponProductList.forEach((item, index) => {
      // if (item.id !== id) {
      //   newList.push(item);
      // }
      if (i !== index) {
        newList.push(item);
      }
    });
    this.bsCouponProductList = newList;
  }

  // 指定类型
  productUse;
  productUseChange(e) {
    this.productUse = e;
  }
  couponType = 1;
  // 折扣类型
  couponTypeChange(e) {
    this.couponType = e;
  }

  index = 1;
  bsCouponCateList: any = [
    {
      cateIds: [],
    }
  ];
  addCate() {
    this.bsCouponCateList = [
      ...this.bsCouponCateList,
      {
        cateIds: [],
      }
    ];
  }
  // 删除产品
  deleteCate(i) {
    let newList = JSON.parse(JSON.stringify(this.bsCouponCateList));
    newList.splice(i, 1)
    this.bsCouponCateList = newList;
  }

  queryProdList = [];   // 产品列表: 查询参数
  // 获取[选择分类]
  getAllParameters() {
    this.api.getAllParameters({ parentCode: "CPFL" }).subscribe(res => {
      this.queryProdList = this.newsetTreeList(res['data'], false);
    });
  }
  // 递归改变树结构
  newsetTreeList(list, isLeaf) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let obj = {
        name: list[i].name,
        id: list[i].id,
        code: list[i].code,
        isLeaf: isLeaf
      }
      result.push(obj);
      if (list[i].parametersVO) {
        obj['children'] = this.newsetTreeList(list[i].parametersVO, true);
      }
    }
    return result;
  }
}
