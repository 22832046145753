import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drawer-card-set-month',
  templateUrl: './drawer-card-set-month.component.html',
  styleUrls: ['./drawer-card-set-month.component.less']
})
export class DrawerCardSetMonthComponent implements OnInit {
  @Input() value = '';
  public baseUrl; // api基本路径
  constructor(
    public api: Api,
    public global: Global,
    public message: NzMessageService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.getServiceStoragesList();
  };

  listOfData = [];
  monthNo = null;
  countZnYw = null;
  extraAmount = null;
  countYw = null;
  getServiceStoragesList() {
    this.api.cardDetail(this.value).subscribe((res) => {
      this.listOfData = res['data'].sysOfficeRelationList;
      this.countZnYw = res['data'].cardNum;
      this.extraAmount = res['data'].extraAmount;
      this.monthNo = res['data'].monthNo;
      this.countAll();
    })
  }

  // 计算总卡位 
  countAll() {
    this.countYw = 0;
    // 计算总数
    (this.listOfData || []).map(item => {
      if (item.businessFlag == 1) {
        this.countYw += Number(item.cardNum || 0);
      }
    });
  };

}
