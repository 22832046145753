import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-order-examine',
  templateUrl: './modal-order-examine.component.html',
  styleUrls: ['./modal-order-examine.component.less']
})
export class ModalOrderExamineComponent implements OnInit {
  params: any;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) { }

  ngOnInit() {
    // 审核模块 start
    this.getUserList();
    if (this.params.type == 'jg') {
      this.getServiceFlowList('order_price');

    } else if (this.params.type == 'bj') {
      this.getServiceFlowList('order_over');
    } else if (this.params.type == 'cpbj') {
      this.getServiceFlowList('order_product_over');
    }
    // 审核模块 start
  }
  cancel() {
    this.modal.destroy();
  }
  canSubmit = false;
  submitForm() {
    this.canSubmit = true
    // 审核模块 start
    var flowBusinessStepDTOList = [];
    var stepBool = true;
    (this.flowListStep || []).map((item, index) => {
      flowBusinessStepDTOList[index] = {
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        serviceNo: item.serviceNo,
        stepId: item.stepId,
        stepName: item.stepName,
        stepNo: item.stepNo,
      }
      // 循环用户列表，获取用户id 与名称
      var selected_user = [];
      var selectedFin = [];
      // 区分用户为多人或者单人
      if (item.userId instanceof Array) {
        if (item.userId.length == 0) {
          stepBool = false;
          return false;
        }
        item.userId.map((child, index) => {
          selected_user = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == child
          });
          selectedFin.push(Object.assign({}, ...selected_user))
        })
      } else {
        if (!item.userId) {
          stepBool = false;
          return false;
        }
        selectedFin = this.usersList.filter((obj) => {
          obj.userName = obj.name;
          return obj.userId == item.userId
        });
      }
      flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
    })
    if (!stepBool) {
      this.message.error('审核人员不能为空！');
      this.canSubmit = false;
      return;
    }
    var params = {
      flowBusinessStepDTOList: flowBusinessStepDTOList,
      id: this.params.id
    }
    if (this.params.type == 'jg') {
      this.api.setOrderPriceExamineCheck(params).subscribe(res => {
        this.message.success('已发起订单价格审核，需主管审核通过才能发起支付', {
          nzDuration: 5000
        });
      })
    } else if (this.params.type == 'bj') {
      this.api.userOrderCommitFinish(params).subscribe(res => {
        this.message.success('已发起订单办结审核，需主管审核通过才能进行提成', {
          nzDuration: 5000
        });
      })
    } else if (this.params.type == 'cpbj') {
      this.api.userProductCommitFinish(params).subscribe(res => {
        this.message.success('已发起产品办结审核，需主管审核通过才能进行提成', {
          nzDuration: 5000
        });
      })
    }
    this.modal.destroy();
    // 审核模块 end

  }

  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end

}
