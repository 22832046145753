import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-work-deliver',
  templateUrl: './modal-work-deliver.component.html',
  styleUrls: ['./modal-work-deliver.component.less']
})
export class ModalWorkDeliverComponent implements OnInit {

  @Input() params: any;

  settlementPrice: number; // 结算价格
  evaluate: string; //工单评价
  isCheckNumber: number;//是否已经全部选择 1全选，2未选完
  checkOptions = [];
  contractUrl: string;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel() {
    this.modal.destroy();
  }
  workOrderEvaluationList = [
    { value: '4', name: '5分（非常满意）' },
    { value: '3', name: '4分（满意）' },
    { value: '2', name: '3分（一般）' },
    { value: '1', name: '2分（不满意）' },
    { value: '0', name: '1分（非常不满意）' },
  ];
  updateSingleChecked() {

  }

  submitForm() {
    switch (this.params.type) {
      case 'jf'://工单交付
        this.orderDeliveryCheck();
        break;
      case 'ys'://工单验收
        this.orderAcceptanceCheck();
        break;
    }
  }

  orderDeliveryCheck() {
    if (!this.settlementPrice) {
      this.message.error('请输入结算价格');
      return
    }
    if (!this.orderTaskAttachments.length) {
      this.message.error('请上传工单交付附件');
      return
    }
    if (this.checkOptions.every(item => item.checked)) {
      this.isCheckNumber = 1
    } else {
      this.isCheckNumber = 2
      this.message.error('请勾选全部');
      return
    }
    let params = {
      taskId: this.params.id,
      clearingPrice: this.settlementPrice,
      orderTaskAttachments: this.orderTaskAttachments,
    }
    // 审核模块 start
    var flowBusinessStepDTOList = [];
    var stepBool = true;
    (this.flowListStep || []).map((item, index) => {
      flowBusinessStepDTOList[index] = {
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        serviceNo: item.serviceNo,
        stepId: item.stepId,
        stepName: item.stepName,
        stepNo: item.stepNo,
      }
      // 循环用户列表，获取用户id 与名称
      var selected_user = [];
      var selectedFin = [];
      // 区分用户为多人或者单人
      if (item.userId instanceof Array) {
        if (item.userId.length == 0) {
          stepBool = false;
          return false;
        }
        item.userId.map((child, index) => {
          selected_user = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == child
          });
          selectedFin.push(Object.assign({}, ...selected_user))
        })
      } else {
        if (!item.userId) {
          stepBool = false;
          return false;
        }
        selectedFin = this.usersList.filter((obj) => {
          obj.userName = obj.name;
          return obj.userId == item.userId
        });
      }
      flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
    })
    if (!stepBool) {
      this.message.error('审核人员不能为空！');
      return;
    }
    params['flowBusinessStepDTOList'] = flowBusinessStepDTOList;
    // 审核模块 end
    this.api.finishTask(params).subscribe((res) => {
      this.message.success('已发起工单交付审核，需主管审核通过才能进行验收', {
        nzDuration: 5000
      });
      this.modal.destroy();
    })
  }
  // 工单附件
  orderTaskAttachments = [];
  // 交付上传附件
  finishChangeFile(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
      this.orderTaskAttachments = attachmentUrls;
    }
  }

  orderAcceptanceCheck() {
    if (!this.evaluate) {
      this.message.error('请选择工单评价');
      return
    }
    if (this.checkOptions.every(item => item.checked)) {
      this.isCheckNumber = 1
    } else {
      this.isCheckNumber = 2
      this.message.error('请勾选全部');
      return
    }
    let params = {
      id: this.params.id,
      status: this.params.status,
      evaluate: this.evaluate == '0' ? '1分（非常不满意）' : this.evaluate == '1' ? '2分（不满意）' : this.evaluate == '2' ? '3分（一般）' : this.evaluate == '3' ? '4分（满意）' : this.evaluate == '4' ? '5分（非常满意）' : '',
    }
    this.api.acceptance(params).subscribe((res) => {
      this.modal.destroy();
    })
  }
  orderDeliveryList() {
    this.checkOptions = [];
    this.api.getSysParametersByParentCode({ parentCode: 'TASK_DELIVERY' }).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        var obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].name;
        obj['checked'] = false;
        this.checkOptions.push(obj)
      }
    });
  }
  orderAcceptanceList() {
    this.checkOptions = [];
    this.api.getSysParametersByParentCode({ parentCode: 'WORK_ORDER_ACCEPTANCE' }).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        var obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].name;
        obj['checked'] = false;
        this.checkOptions.push(obj)
      }
    });
  }
  ngOnInit() {
    if (this.params.type == 'jf') {
      this.orderDeliveryList();
      // 审核模块 start
      this.getUserList();
      this.getServiceFlowList('task_deliver');
      // 审核模块 end
    } else if (this.params.type == 'ys') {
      this.orderAcceptanceList();
    }
  }
  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end

}
