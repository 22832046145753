import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drawer-people-set-month',
  templateUrl: './drawer-people-set-month.component.html',
  styleUrls: ['./drawer-people-set-month.component.less']
})
export class DrawerPeopleSetMonthComponent implements OnInit {
  @Input() value = '';
  public baseUrl; // api基本路径
  constructor(
    public api: Api,
    public global: Global,
    public message: NzMessageService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.getServiceStoragesList();
  };

  listOfData;
  getServiceStoragesList() {
    this.api.groupMonthDetail(this.value).subscribe((res) => {
      this.listOfData = res['data'];
    })
  }

}
