import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Api } from '@service/Api';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-create-cooperation-customer',
  templateUrl: './modal-create-cooperation-customer.component.html',
  styleUrls: ['./modal-create-cooperation-customer.component.less']
})
export class ModalCreateCooperationCustomerComponent implements OnInit {
  @Input() customerId: string;

  officeForm: FormGroup;
  provinceList: []; // 省份列表
  cityList: [];     // 城市列表
  companyList = []; // 公司列表，查询框
  loading=false;
  currentCompany:any={
    category:1,
  };
  constructor(
    private api: Api, fb: FormBuilder,
    private modal: NzModalRef,
  ) {
    this.officeForm = fb.group({
      province: ['', Validators.required],
      cityId: ['', Validators.required],
      companyId: ['',],
    });
  }

  // #region 获取省市列表
  getProvince(level, code?) {
    const params = {
      level: level,
    }
    code ? params['parentCode'] = code : null;
    this.api.getCityList(params).subscribe((res) => {
      if (level == 1) {
        this.provinceList = res['data'];
      } else if (level == 2) {
        this.cityList = res['data'];
      }
    })
  }
  // 查询城市列表
  getCityList(code) {
    this.getProvince(2, code);
  }
  findCompanyByAreaId(areaId) {
    if (null != areaId) {
      this.api.findCompanyNoPerByAreaId({areaId:areaId}).subscribe((res) => {
        if("1"!=this.currentCompany.category){
          this.companyList = res['data'];
        }
      })
    }
  }
  cancel() {
    this.modal.destroy();
  }
  // #endregion

  // #region tab2 
  save() {
    this.loading=true;
    // 更新表单状态
    Object.keys(this.officeForm.controls).map((key) => {
      this.officeForm.controls[key].markAsDirty();
      this.officeForm.controls[key].updateValueAndValidity();
    });

    if (!this.officeForm.valid) {
      this.loading = false;
      return
    }

    let params = this.officeForm.getRawValue();
    params['customerId'] = this.customerId;
    this.api.saveCreateCooperationCustomer(params).subscribe(res=>{
      this.loading = false;
      this.cancel();
    }, err=>{
      this.loading = false;
    });
  }

  // #endregion 

  ngOnInit() {
    this.api.getCurrentOffice().subscribe(res=>{
      this.currentCompany=res['data'];
      console.log(this.currentCompany);
    });
    this.getProvince(1);
  }

}
