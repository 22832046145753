import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';

@Component({
  selector: 'app-drawer-entprise-server',
  templateUrl: './drawer-entprise-server.component.html',
  styleUrls: ['./drawer-entprise-server.component.less']
})
export class DrawerEntpriseServerComponent implements OnInit {
  @Input() value = '';

  @Input() companyServerNo = '';
  @Input() manageName = '';
  @Input() chargeName = '';
  @Input() companyServerPeriod = '';
  @Input() companyServerStatus = '';
  @Input() typeFlag = '';
  @Input() serverType;
  @Input() detail;

  contractUrl: string;
  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
    this.form = fb.group({
      followContent: [null, Validators.required],
    });
    this.formFail = fb.group({
      remark: [null, Validators.required],
    });
    this.formSchedule = fb.group({
      stageName: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      deliverContent: [null, Validators.required],
      deliverObjContent: [null, Validators.required],
      firstPartyContent: [null, Validators.required],
      firstPartyDockPost: [null, Validators.required],
      secondPartyPerson: [null, Validators.required],
      firstPartyCheckPerson: [null, Validators.required],
    });
    this.formSummary = fb.group({
      sumDate: [null, Validators.required],
      questionOrItem: [null, Validators.required],
      deliveryMethod: [null, Validators.required],
      deliveryContent: [null, Validators.required],
    });
  }

  form: FormGroup;
  formFail: FormGroup;
  formSchedule: FormGroup;
  formSummary: FormGroup;
  contractNum: any = 0;
  activeList: any = [0];  // 已激活菜单
  sysTaskRecordList: any = [] // 系统操作日志
  listOfData: any = [];
  loadMoreOrderRecordList: any;
  // 附件
  bsCompanyServerFollowAttachmentList = []
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  detailCompanyServer: any = {};
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getServerInfoList();
  }

  getServerInfoList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        companyServerInfoId: this.value
      }
    }
    this.api.getCompanyServerPeriod(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.listOfData.map((period, index) => {
        period['accountStatus'] = period['accountStatus'] == 1 ? true : false;
        period['taxStatus'] = period['taxStatus'] == 1 ? true : false;
        period['voucherStatus'] = period['voucherStatus'] == 1 ? true : false;
      })
      this.pageCtrl.total = res['data'].total;
    });
    // this.typeFlag true 长期，false 一次性
    // if (this.typeFlag) {
    //   this.api.getCompanyServerPeriod(params).subscribe(res => {
    //     this.listOfData = res['data'].records;
    //     this.pageCtrl.total = res['data'].total;
    //   });
    // } else {
    //   this.api.getCompanyServerFollowList(params).subscribe(res => {
    //     this.listOfData = res['data'].records;
    //     this.pageCtrl.total = res['data'].total;
    //   });
    // }
  }

  getServerDetail() {
    this.api.getServerDetail(this.value).subscribe(res => {
      this.detailCompanyServer = res['data'];
      this.companyServerStatus = this.detailCompanyServer.companyServerStatus;
    });
  }

  ngOnInit() {
    // console.log(this.value)
    // console.log(this.typeFlag) // true 长期，false 一次性
    this.getServerDetail();
    this.getServerInfoList();
  }
  // 切换菜单
  changeTabs(e) {
    if (this.activeList.includes(e)) {
      return
    }

    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getServerInfo();//基本信息
        break;
      case 2:
        this.serverProductList();//基本信息
        break;
      case 3:
        this.orderRecordList = [];
        this.getOrderRecordList();//跟进记录
      case 4:
        this.getTaskContinueListBySys();//操作记录
        break;
      // case 2:
      //   this.serverProductList();//产品列表
      //   break;
      // case 3:
      //   this.scheduleList();//项目排期列表
      //   break;
      // case 4:
      //   this.summaryList();//项目交付汇总表
      //   break;
      default:
        break;
    }
  }
  serverInfo = [];
  getServerInfo() {
    this.api.bsCompanyServerInfoGet(this.value).subscribe(res => {
      this.serverInfo = res['data'];
    })
  }
  // 是否显示加载更多
  onSysLoadMore() {
    this.pageCtrl2.pageIndex++;
    this.getTaskContinueListBySys();
  }
  pageCtrl2 = {
    pageIndex: 1,
    pageSize: 5
  }
  sysLoadingMore = true;
  getTaskContinueListBySys() {
    const params = {
      current: this.pageCtrl2.pageIndex,
      size: this.pageCtrl2.pageSize,
      object: {
        companyServerId: this.value
      }
    }
    this.api.getCompanyServerLog(params).subscribe(res => {
      this.sysTaskRecordList = [...this.sysTaskRecordList, ...res['data'].records];

      if (this.pageCtrl2.pageIndex == res['data'].pages) {
        this.sysLoadingMore = false;
      }
    })
  }
  productList = [];
  serverProductList() {
    this.api.serverProductList(this.value).subscribe(res => {
      this.productList = res['data'];
    })
  }
  //交付凭证
  openEditor(content, data) {
    this.modalService.create({
      nzTitle: '新增凭证',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.setServerDate(data)
    });
  }


  setServerDate(data) {
    this.form.controls.followContent.markAsDirty();
    this.form.controls.followContent.updateValueAndValidity();
    if (!this.form.valid) {
      return false;
    }
    let params = this.form.getRawValue();
    var _obj;
    _obj = {
      companyServerPeriodId: data.id,
      followContent: params.followContent,
      bsCompanyServerFollowAttachmentList: this.bsCompanyServerFollowAttachmentList
    };
    this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
      this.message.success('添加成功！');
      this.form.reset()
      this.getServerInfoList();
    })
    // this.typeFlag true 长期，false 一次性
    // if (this.typeFlag) {
    //   _obj = {
    //     companyServerPeriodId: data.id,
    //     followContent: params.followContent,
    //     bsCompanyServerFollowAttachmentList: this.bsCompanyServerFollowAttachmentList
    //   };
    //   this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
    //     this.message.success('添加成功！');
    //     this.form.reset()
    //     this.getServerInfoList();
    //   })
    // } else {
    //   _obj = {
    //     companyServerId: this.value,
    //     followContent: params.followContent,
    //     bsCompanyServerFollowAttachmentList: this.bsCompanyServerFollowAttachmentList
    //   };
    //   this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
    //     this.message.success('添加成功！');
    //     this.form.reset()
    //     this.getServerDetail();
    //     this.getServerInfoList();
    //   })
    // }


  }

  //异常
  openFail(content, data) {
    this.modalService.create({
      nzTitle: '异常',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.setFail(data)
    });
  }

  setFail(data) {
    this.formFail.controls.remark.markAsDirty();
    this.formFail.controls.remark.updateValueAndValidity();
    if (!this.formFail.valid) {
      return false;
    }
    let params = this.formFail.getRawValue();
    var _obj;
    _obj = {
      id: data.id,
      periodStatus: 2,
      remark: params.remark,
    };
    this.api.companyServerUnusual(_obj).subscribe((res) => {
      this.message.success('操作成功！');
      this.formFail.reset()
      this.getServerInfoList();
    })
    // this.typeFlag true 长期，false 一次性
    // if (this.typeFlag) {
    //   _obj = {
    //     id: data.id,
    //     periodStatus: 2,
    //     remark: params.remark,
    //   };
    //   this.api.companyServerUnusual(_obj).subscribe((res) => {
    //     this.message.success('操作成功！');
    //     this.formFail.reset()
    //     this.getServerInfoList();
    //   })
    // } else {
    //   _obj = {
    //     id: this.value,
    //     companyServerStatus: 4,
    //     remark: params.remark,
    //   };
    //   this.api.companyServerOnceUnusual(_obj).subscribe((res) => {
    //     this.message.success('操作成功！');
    //     this.formFail.reset()
    //     this.getServerDetail();
    //     this.getServerInfoList();
    //   })
    // }

  }

  // 解除异常
  checkFail(id) {
    let params = {
      // periodStatus: 1,
      id: id,
    }
    this.api.companyServerUnUnusual(params).subscribe((res) => {
      this.message.success('操作成功！');
      this.getServerInfoList();
    })
    // if (this.typeFlag) {
    //   let params = {
    //     // periodStatus: 1,
    //     id: id,
    //   }
    //   this.api.companyServerUnUnusual(params).subscribe((res) => {
    //     this.message.success('操作成功！');
    //     this.getServerInfoList();
    //   })
    // } else {
    //   let params = {
    //     // companyServerStatus: 4,
    //     id: this.value,
    //   }
    //   this.api.companyServerOnceUnUnusual(params).subscribe((res) => {
    //     this.message.success('操作成功！');
    //     this.getServerDetail();
    //     this.getServerInfoList();
    //   })
    // }
  }

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
      this.bsCompanyServerFollowAttachmentList = attachmentUrls;
    }
  }

  // 交付
  checkServe(id) {
    let params = {
      periodStatus: 1,
      id: id,
    }
    this.api.completeCompanyServer(params).subscribe((res) => {
      this.message.success('交付成功！');
      this.getServerInfoList();
    })
    // if (this.typeFlag) {
    //   let params = {
    //     periodStatus: 1,
    //     id: id,
    //   }
    //   this.api.completeCompanyServer(params).subscribe((res) => {
    //     this.message.success('交付成功！');
    //     this.getServerInfoList();
    //   })
    // } else {
    //   let params = {
    //     companyServerStatus: 2,
    //     id: this.value,
    //   }
    //   this.api.serverUpdate(params).subscribe((res) => {
    //     this.message.success('交付成功！');
    //     // if (!this.typeFlag) {
    //     //   this.drawerRef.close();
    //     // }
    //     this.getServerDetail();
    //     this.getServerInfoList();
    //   })
    // }
  }

  // 打开订单信息详情
  openOrder(id) {
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerOrderComponent, { value: string, type: any }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        type: 0,
      }
    });
  }

  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }
  openSchedule(content) {
    this.modalService.create({
      nzTitle: '项目交付排期',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.formSchedule.reset(),
      nzOnOk: () => {
        Object.keys(this.formSchedule.controls).map((key) => {
          this.formSchedule.controls[key].markAsDirty();
          this.formSchedule.controls[key].updateValueAndValidity();
        });
        if (!this.formSchedule.valid) {
          return false;
        }
        let params = this.formSchedule.getRawValue();
        params.startDate = this.dataToStr(params.startDate);
        params.endDate = this.dataToStr(params.endDate);
        var _obj;
        _obj = {
          companyId: this.detail.companyId,
          stageName: params.stageName,
          bsCompanyAccountStageItemList: [params]
        };
        this.api.saveBsCompanyAccountStage(_obj).subscribe((res) => {
          this.message.success('保存成功！');
          this.formSchedule.reset();
          this.scheduleList();
        })

      }
    });
  }

  schedulePageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  scheduleListData: [];
  // 翻页
  toPageSchedule(page) {
    this.schedulePageCtrl.nzPageIndex = page;
    this.scheduleList();
  }
  // 获取项目排期列表
  scheduleList() {
    const params = {
      current: this.schedulePageCtrl.nzPageIndex,
      size: this.schedulePageCtrl.nzPageSize,
      object: {
        companyId: this.detail.companyId
      }
    }
    this.api.bsCompanyAccountStageItemList(params).subscribe(res => {
      this.scheduleListData = res['data'].records;
      this.schedulePageCtrl.total = res['data'].total;
    });
  }

  // 项目交付汇总表
  openSummary(content) {
    this.modalService.create({
      nzTitle: '项目交付汇总表',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.formSummary.reset(),
      nzOnOk: () => {
        Object.keys(this.formSummary.controls).map((key) => {
          this.formSummary.controls[key].markAsDirty();
          this.formSummary.controls[key].updateValueAndValidity();
        });
        if (!this.formSummary.valid) {
          return false;
        }
        let params = this.formSummary.getRawValue();
        params.sumDate = this.dataToStr(params.sumDate);
        params.companyId = this.detail.companyId;
        params.bsCompanyAccountSumAttachmentList = this.bsCompanyAccountSumAttachmentList;

        this.api.saveBsCompanyAccountSum(params).subscribe((res) => {
          this.message.success('保存成功！');
          this.formSummary.reset();
          this.summaryList();
        })

      }
    });
  }
  summaryPageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  summaryListData: [];
  // 翻页
  toPageSummary(page) {
    this.summaryPageCtrl.nzPageIndex = page;
    this.summaryList();
  }
  // 获取项目交付汇总表
  summaryList() {
    const params = {
      current: this.summaryPageCtrl.nzPageIndex,
      size: this.summaryPageCtrl.nzPageSize,
      object: {
        companyId: this.detail.companyId
      }
    }
    this.api.bsCompanyAccountSumList(params).subscribe(res => {
      this.summaryListData = res['data'].records;
      this.summaryPageCtrl.total = res['data'].total;
    });
  }
  bsCompanyAccountSumAttachmentList = [];
  // 上传相关资料
  uploadChangeSummary(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
      this.bsCompanyAccountSumAttachmentList = attachmentUrls;
    }
  }
  orderRecordList = [];//跟进日志
  pageCtrlRecord = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMoreLog = true;
  getOrderRecordList() {
    const params = {
      current: this.pageCtrlRecord.pageIndex,
      size: this.pageCtrlRecord.pageSize,
      object: {
        bindId: this.value,
        logType: 6,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.orderRecordList = [...this.orderRecordList, ...res['data'].records];
      if (this.pageCtrlRecord.pageIndex == res['data'].pages) {
        this.loadingMoreLog = false;
      }
    })
  }
  // 添加跟进记录
  openEditorRecord() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'renew',
        bindId: this.value,
        // bindName: this.orderInfo.orderNo,
        // customerId: this.orderInfo.customerId,
        // customerName: this.orderInfo.customerName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.orderRecordList = [];
          this.getOrderRecordList();
        })
      }
    });
  }
}
