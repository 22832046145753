import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { Global } from '@service/global.ts';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormatEmitEvent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-add-manage-target',
  templateUrl: './modal-add-manage-target.component.html',
  styleUrls: ['./modal-add-manage-target.component.less']
})

export class ModalAddManageTargetComponent implements OnInit {
  treeList: NzTreeNodeOptions[]; // 树形产品类别
  activedNode: NzTreeNode;
  officeForm: FormGroup;

  constructor(fb: FormBuilder,
    private modal: NzModalRef,
    public router: Router,
    public api: Api,
    // private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
    public global: Global) {
  }
  isAllChecked = false; // 是否全选
  isOperating = false;  //
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfData = []; //列表数据
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params: any = { // 页面查询参数
    userName: null,
    mobile: null,
    organization: null,
    userStatus: null,
    workNumber: null,
    dateList: [],
    dateOfEntryStartDate: null,
    dateOfEntryEndDate: null,
    zzDateList: [],
    positiveDateStartDate: null,
    positiveDateEndDate: null,
    maxSchool: null,
    companyId: null,
    deptId: null,
  };
  permissions = []; // 按钮权限数组

  ngOnInit() {
    this.getPubCustomerList();
    this.getTreeCategory();
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
    });

    // this.getUserOfficeList();
  }

  refreshStatus(): void {
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.id]).length;
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  // 重置
  reset() {
    Object.keys(this.params).forEach(e => {
      this.params[e] = null;
    });
    this.params.dateList = [];
    this.params.zzDateList = [];
    this.getPubCustomerList();
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    // 转正日期
    if (this.params.zzDateList.length != 0) {
      this.params.positiveDateStartDate = Utils.dateFormat(this.params.zzDateList[0], 'yyyy-MM-dd');
      this.params.positiveDateEndDate = Utils.dateFormat(this.params.zzDateList[1], 'yyyy-MM-dd');
    } else {
      this.params.positiveDateStartDate = null;
      this.params.positiveDateEndDate = null;
    }
    // 入职时间
    if (this.params.dateList.length != 0) {
      this.params.dateOfEntryStartDate = Utils.dateFormat(this.params.dateList[0], 'yyyy-MM-dd');;
      this.params.dateOfEntryEndDate = Utils.dateFormat(this.params.dateList[1], 'yyyy-MM-dd');;
    } else {
      this.params.dateOfEntryStartDate = null;
      this.params.dateOfEntryEndDate = null;
    }
    this.getPubCustomerList();
  }


  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getPubCustomerList();
  }

  // 获取客户列表
  getPubCustomerList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    };
    this.api.employeesList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  // 组织架构 start
  // 递归改变树结构
  setTreeList(list) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let isLeaf = list[i].sysUserInductionDeptList ? false : true;
      let obj = {
        title: list[i].name,
        key: list[i].id,
        isLeaf: isLeaf,
        inductionCompanyId: list[i].inductionCompanyId,
        seq: list[i].seq,
        type: list[i].type,
        manageUserId: list[i].manageUserId,
      }
      result.push(obj);
      if (list[i].sysUserInductionDeptList) {
        obj['children'] = this.setTreeList(list[i].sysUserInductionDeptList);
      }
    }
    return result;
  }

  // 获取树形公司类别
  getTreeCategory() {
    this.api.sysUserInductionCompany().subscribe(res => {
      this.treeList = this.setTreeList(res['data']);
      //this.params.objId = this.treeList[0].key;
      //this.params.type = this.treeList[0].type;
      // this.getPubCustomerList();
    })
  }
  // #region 菜单树操作集合
  // 展开子菜单
  openFolder(data: NzTreeNode | Required<NzFormatEmitEvent>): void {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
    //console.log(data);
    //console.log(this.activeNode);
  }

  // 点击查看具体员工
  activeNode(data: NzFormatEmitEvent): void {
    this.activedNode = data.node!;
    const originData = data.node.origin;

    // data.nodes.origin.type = 0 公司 ， 1 部门
    this.params.companyId = originData.type == 0 ? originData.key : null;
    this.params.deptId = originData.type == 1 ? originData.key : null;
    this.pageCtrl.nzPageIndex = 1; // 重置页码
    this.getPubCustomerList();

  }

  // 组织架构 end
  OfficeList = [];
  //查询用户信息
  getUserOfficeList() {
    this.api.getUserList().subscribe(res => {
      this.OfficeList = res['data']
    })
  }
  loading = false;
  submit() {
    // let checkedList = []; // 已选产品
    // console.log(this.mapOfCheckedId)
    // this.productList
    //   .filter(item => this.mapOfCheckedId[item.id])
    //   .map(v => {
    //     checkedList.push(v)
    //   });

    // if (this.productList.length === 0) {
    //   this.message.warning('请选择产品！');
    //   return
    // }
    // ;
    this.loading = true;
    let list = [];
    this.listOfData
      .filter(item => this.mapOfCheckedId[item.id])
      .map(v => list.push(v));
    this.modal.destroy(list);
  }

  cancel() {
    this.modal.destroy();
  }

}
