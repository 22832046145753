import { param } from 'src/assets/ueditor/third-party/jquery-1.10.2';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import {NzMessageService} from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-source-config-add',
  templateUrl: './modal-source-config-add.component.html',
  styleUrls: ['./modal-source-config-add.component.less']
})
export class ModalSourceConfigAddComponent implements OnInit {
  @Input() obj: any; // 新增类型id
  @Input() type: string; //类型 0 新增父 1 新增子
  @Input() sourceFlag:string;

  @Input() sort: number;
  
  @Input()  clearingForm:string;//结算方式
  @Input()  clearingType:string;//结算类型
  @Input()  maxCluePrice:string;//线索价格上限（元）
  @Input()  clueCost:string;//线索成本（元）
  @Input()  cluePrice:string;//线索价格（元）



  form: FormGroup;  // 新增客户表单
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  
  params = { // 页面查询参数
    name: null,
    sourceCode:null,
  }

  isPrecise = false;//结算方式
  isClearingFormDAY = false;//按日结算
  isClearingFormITEM = false;//按条结算
  isClearingFormPRICE = false;//每条固定结算
  loading = false;
  sourceTypeList=[{//线索类型
    type:"SEM",
    name:'推广竞价'
  },{
    type:"INFORMATION_FLOW",
    name:'信息流'
  },{
    type:"NUCLEAR_NAME",
    name:'核名'
  },{
    type:"ON_LINE",
    name:'线上'
  },{
    type:"OFFLINE",
    name:'线下'
  }]
  sourcePoolTypeList=[{//来源标记
    type:"PRECISE",
    name:'精准线索'
  },{
    type:"NUCLEAR_NAME",
    name:'核名线索'
  },{
    type:"INDEPENDENT_ADD",
    name:'自主添加'
  },{
    type:"BUYING_CLUE",
    name:'自购资源'
  }];

  clearingFormTypeList=[{//结算方式
    type:"BY_THE_DAY",
    name:'日均消费价'
  },{
    type:"BY_THE_ITEM",
    name:'单条定价'
  },{
    type:"ITEM_FIXED_PRICE",
    name:'批量定价'
  }];
  clearingTypeList=[{//结算类型
    type:"SEARCH_TYPE_CLUE",
    name:'搜索类线索'
  },{
    type:"INFO_FLOW_TYPE_CLUE",
    name:'信息流类线索'
  }];
  clearingFormTypeTempList =[];
  constructor(private api: Api,
    private fb: FormBuilder,
    public message: NzMessageService,
    private modal: NzModalRef,
    private utils: Utils, ) { }

  cancel() {
    this.modal.destroy();
  }

  submit(){
    this.loading = true;

    if (this.form.value.name == null ) {
      this.message.info('请填写名称！');
      this.loading = false
      return
    }
    // if (this.form.value.sourceType == null) {
    //   this.message.info('请选择线索类型！');
    //   this.loading = false
    //   return
    // }
    if (this.form.value.sourceFlag == null ) {
      this.message.info('请选择线索入口！');
      this.loading = false
      return
    }
    if (this.form.value.sort == null ) {
      this.message.info('请填写序号！');
      this.loading = false
      return
    }

    //定价方式
    if(this.isPrecise){
      if(this.form.value.clearingForm == null){
        this.message.info('请选择定价方式！');
        this.loading = false
        return
      }
    }

    if(this.isClearingFormDAY){
      if(this.form.value.clearingType == null){
        this.message.info('请选择定价分类！');
        this.loading = false
        return
      }
    }

    if(this.isClearingFormITEM){
      if(this.form.value.maxCluePrice == null){
        this.message.info('请输入线索价格上限！');
        this.loading = false
        return
      }
    }

    if(this.isClearingFormPRICE){
      if(this.form.value.clueCost == null){
        this.message.info('请输入线索成本！');
        this.loading = false
        return
      }
      if(this.form.value.cluePrice == null){
        this.message.info('请输入线索价格！');
        this.loading = false
        return
      }
    }

    let param = {
      id : this.form.value.id,
      name : this.form.value.name,
      sourceType: this.form.value.sourceType,
      sourceFlag:this.form.value.sourceFlag,
      sort:this.form.value.sort,
      clearingForm:this.form.value.clearingForm,
      clearingType:this.form.value.clearingType,
      maxCluePrice:this.form.value.maxCluePrice,
      clueCost:this.form.value.clueCost,
      cluePrice:this.form.value.cluePrice,
    }
    // console.log(param)
    // this.loading = false
    // return
    this.api.addSourceConfig(param).subscribe(res=>{
        this.cancel();
        this.message.success('新增成功！');
    }, () => this.loading = false);

  }
  changeSourceFlag(e){
    //结算方式
    if(e == 'PRECISE'){
      this.isPrecise = true;
      this.clearingFormTypeTempList = this.clearingFormTypeList;
    } else if(e == 'BUYING_CLUE'){
      this.isPrecise = true;
      this.clearingFormTypeTempList = []
      this.clearingFormTypeTempList=[{
        type:"BY_THE_ITEM",
        name:'单条定价'
      }];
    
      this.isClearingFormITEM = false;
      this.isClearingFormPRICE = false;
    }else{
      this.isPrecise = false;
      this.isClearingFormDAY = false;
      this.isClearingFormITEM = false;
      this.isClearingFormPRICE = false;
    }
  }

  changeClearingForm(e){
    //按日
    if(e == 'BY_THE_DAY'){
      this.isClearingFormDAY = true;
    }else{
      this.isClearingFormDAY = false;
    }

    //按条
    if(e == 'BY_THE_ITEM'){
      this.isClearingFormITEM = true;
    }else{
      this.isClearingFormITEM = false;
    }
    
    //每条固定
    if(e == 'ITEM_FIXED_PRICE'){
      this.isClearingFormPRICE = true;
    }else{
      this.isClearingFormPRICE = false;
    }
  
  }

  ngOnInit() {
    console.log(this.obj)
    if(this.obj.sourceFlag == "PRECISE"){
      this.isPrecise = true;
    }

    if(this.obj.clearingForm == "BY_THE_DAY"){
      this.isClearingFormDAY = true;
    }

    if(this.obj.clearingForm == "BY_THE_ITEM"){
      this.isClearingFormITEM = true;
    }

    if(this.obj.clearingForm == "ITEM_FIXED_PRICE"){
      this.isClearingFormPRICE = true;
    }

    // this.getSourceConfigList()
    this.form = this.fb.group({
      id: [this.obj.id,],
      parentId: [this.obj.parentId,],
      name: [this.obj.name, Validators.required],
      amount: [this.obj.amount,],
      saleAmount: [this.obj.saleAmount,],
      month: [null],
      sort: [this.obj.sort,Validators.required],
      sourceType:[this.obj.sourceType,],
      sourceFlag:[this.obj.sourceFlag, Validators.required],

      clearingForm:[this.obj.clearingForm, Validators.required],
      clearingType:[this.obj.clearingType, Validators.required],
      maxCluePrice:[this.obj.maxCluePrice, Validators.required],
      clueCost:[this.obj.clueCost, Validators.required],
      cluePrice:[this.obj.cluePrice, Validators.required],
    });
  }

}
