import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

export interface TreeNodeInterface {
  id?: number;
  businessName?: string;  //业务类型

  januaryMonth?: number;	//number 1月份
  januaryMonthEstimate?: number;	//number 1月份预估

  februaryMonth?: number;	//number 2月份
  februaryMonthEstimate?: number;	// 2月份预估

  marchMonth?: number;	//number 3月份
  marchMonthEstimate?: number;	//number 3月份预估

  aprilMonth?: number;	//number 四月份
  aprilMonthEstimate?: number;	//number 四月份预估

  mayMonth?: number;	//number 5月份
  mayMonthEstimate?: number;	//number 5月份预估

  juneMonth?: number;	//number 6月份
  juneMonthEstimate?: number;	//number 6月份预估

  julyMonth?: number;	//number 7月份
  julyMonthEstimate?: number;	//number 7月份预估

  augustMonth?: number;	//number 8月份
  augustMonthEstimate?: number;	//number 8月份预估

  septemberMonth?: number;	//number 9月份
  septemberMonthEstimate?: number;	//number 9月份预估

  octoberMonth?: number;	//number 10月份
  octoberMonthEstimate?: number;	//number 10月份预估

  novemberMonth?: number;	//number 11月份
  novemberMonthEstimate?: number;	//number 11月份预估

  decemberMonth?: number;	//number 12月份
  decemberMonthEstimate?: number;	//number 12月份预估

  level?: number;
  expand?: boolean;
  parent?: TreeNodeInterface;
  childList?: TreeNodeInterface[];
}

@Component({
  selector: 'app-modal-add-finance',
  templateUrl: './modal-add-finance.component.html',
  styleUrls: ['./modal-add-finance.component.less']
})
export class ModalAddFinanceComponent implements OnInit {
  @Input() listOfMapData = [];
  constructor(
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) { }
  loading = false;
  // 表格收缩 start
  mapOfExpandedData = [];
  newsArr = []
  convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: false });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.childList) {
        for (let i = node.childList.length - 1; i >= 0; i--) {
          stack.push({ ...node.childList[i], level: node.level! + 1, expand: false, parent: node });
        }
      }
    }
    return array;
  }

  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if (!$event) {
      if (data.childList) {
        data.childList.forEach((d) => {
          const target = array.find(a => a.id === d.id)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: boolean }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.id]) {
      hashMap[node.id] = true;
      array.push(node);
    }
  }
  // 表格收缩 end

  ngOnInit() {
    this.listOfMapData.forEach((item, index) => {
      this.mapOfExpandedData[item.id] = this.convertTreeToList(item);
    });
    this.newsArr = JSON.parse(JSON.stringify(this.listOfMapData));
  }

  cancel() {
    this.modal.destroy()
  }

  submit() {
    this.loading = true;
    //多个数组对象 合并
    var activityList = [];
    const activityData = this.mapOfExpandedData;
    for (const key in activityData) {
      if (activityData[key]) {
        activityList = activityList.concat(activityData[key]);
      }
    }
    this.api.CompantOfficeYearUpdate(activityList).subscribe(res => {
      this.message.success('保存成功!')
      this.modal.destroy()
    }, err => {
      this.loading = false;
    })
  }

}
