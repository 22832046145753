import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';

@Component({
  selector: 'app-modal-addservice-new',
  templateUrl: './modal-addservice-new.component.html',
  styleUrls: ['./modal-addservice-new.component.less']
})
export class ModalAddserviceNewComponent implements OnInit {
  @Input() detailInfo: any; // 产品id
  form: FormGroup;
  loading = false;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      accountStatus: ['1', Validators.required],
      taxStatus: ['1', Validators.required],
      actualStartDate: [null, Validators.required],
      actualEndDate: [null, Validators.required],
      companyServerPeriod: [null, Validators.required],
    });
  }
  ngOnInit() {
    this.form.get('companyServerPeriod').setValue(this.detailInfo.companyServerPeriod);
    this.form.get('actualStartDate').setValue(this.detailInfo.actualStartDate);
    this.getServerEndDates();
  }
  // 提交任务
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    if (!this.loading) {
      return
    }
    let modelVlue = {
      id: this.detailInfo.id,
      taxStatus: params['taxStatus'],
      accountStatus: params['accountStatus'],
      companyServerPeriod: params['companyServerPeriod'],
      actualStartDate: Utils.dateFormat(params['actualStartDate'], 'yyyy-MM-dd'),
      actualEndDate: Utils.dateFormat(params['actualEndDate'], 'yyyy-MM-dd')
    }
    this.api.serverOpen(modelVlue).subscribe((res) => {
      this.message.success('创建成功！');
      this.modal.destroy(res);
    }, err => {
      this.loading = false
    })
  }

  needOptions = [
    { label: '需要', value: '1' },
    { label: '不需要', value: '0' },
  ];
  cancel() {
    this.modal.destroy();
  }

  getServerEndDates() {
    if (this.form.value.actualStartDate && this.form.value.companyServerPeriod) {
      this.api.getServerEndDate({
        actualStartDate: this.form.value.actualStartDate,
        companyServerPeriod: this.form.value.companyServerPeriod
      }).subscribe(res => {
        this.form.get('actualEndDate').setValue(res['data'].actualEndDate);
      })
    }
  }

}
