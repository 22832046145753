import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-transfer-recommed',
  templateUrl: './drawer-transfer-recommed.component.html',
  styleUrls: ['./drawer-transfer-recommed.component.less']
})
export class DrawerTransferRecommedComponent implements OnInit {
  @Input() value = '';


  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  // 0查看 1编辑
  type = 0;
  contractUrl: string;
  uploadParams: any = {};
  fileList: any;
  transferDetail: any = {};
  getServerDetail() {
    this.api.transferRecommendDetail(this.value).subscribe(res => {
      this.transferDetail = res['data'];
      // if (this.transferDetail.receiveUserId) {
      //   this.api.getSysUserInfos(this.transferDetail.receiveUserId).subscribe(res => {
      //     this.transferDetail['receiveUserCompanyName'] = res['data'].companyName;
      //     this.transferDetail['receiveUserDeptName'] = res['data'].deptName;
      //   })
      // }
      // if (this.transferDetail.recommendUserId) {
      //   this.api.getSysUserInfos(this.transferDetail.recommendUserId).subscribe(res => {
      //     this.transferDetail['recommendUserCompanyName'] = res['data'].companyName;
      //     this.transferDetail['recommendUserDeptName'] = res['data'].deptName;
      //   })
      // }

      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.transferDetail.flowBusinessStepDTOList && this.transferDetail.flowBusinessStepDTOList.length > 0) {
        this.transferDetail.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    });
  }
  ngOnInit() {
    this.getServerDetail();
  }
  bsCustomerRecommendAttachmentList = [];
  // 上传附件
  uploadChange(info) {
    var bsCustomerRecommendAttachmentList = [];
    if (info.file.status == 'done' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        bsCustomerRecommendAttachmentList.push(
          {
            attachmentUrl: item.response.data.fileUrl
          }
        )
      })
      this.bsCustomerRecommendAttachmentList = bsCustomerRecommendAttachmentList;
    }
  }
  edit() {
    this.type = 1;
    this.bsCustomerRecommendAttachmentList = [];
    this.fileList = [];
  }
  cancal() {
    this.type = 0;
    this.getServerDetail();
  }
  //处理编辑的附件 
  attrAttachments() {
    let attrArray = [];
    (this.transferDetail.bsCustomerRecommendAttachmentList || []).map((item, index) => {
      attrArray.push(
        {
          attachmentUrl: item.attachmentUrl
        }
      )
    })
    return attrArray
  }

  submit() {
    var bsCustomerRecommendAttachmentList = [...this.bsCustomerRecommendAttachmentList, ...this.attrAttachments()]
    var params = {
      bsCustomerRecommendAttachmentList: bsCustomerRecommendAttachmentList,
      id: this.transferDetail.id,
    };
    this.api.uprecommendDateAttachment(params).subscribe(res => {
      this.cancal();
    })
  }

  attachmentDel(index: number): void {
    let newList = JSON.parse(JSON.stringify(this.transferDetail.bsCustomerRecommendAttachmentList));
    newList.splice(index, 1)
    this.transferDetail.bsCustomerRecommendAttachmentList = newList;
    console.log(this.transferDetail.bsCustomerRecommendAttachmentList)
  }
}
