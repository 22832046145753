import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { DrawerUserComponent } from '../drawer-user/drawer-user.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-transfer-customer',
  templateUrl: './modal-transfer-customer.component.html',
  styleUrls: ['./modal-transfer-customer.component.less']
})
export class ModalTransferCustomerComponent implements OnInit {
  value: any;
  constructor(
    public api: Api,
    private modal: NzModalRef,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
  ) { }


  transferId = null;//交接员工ID
  isAllChecked = false; // 是否全选
  isOperating = false;  //
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = { // 页面查询参数
    name: null,
    phoneNumber: null,
    weChat: null,
    companyName: null,
    userId: null,
  };
  listOfData = [];
  ngOnInit() {
    this.params.userId = this.value.id;
    this.getPubCustomerList();
    this.getUserList();
  }
  refreshStatus(): void {
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.jobId]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.jobId]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.jobId]).length;
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => (this.mapOfCheckedId[item.jobId] = value));
    this.refreshStatus();
  }

  // 翻页
  toPage(page) {
    console.log(page);
    this.pageCtrl.nzPageIndex = page;
    this.getPubCustomerList();
  }

  // 获取客户列表
  getPubCustomerList() {
    var objParams = JSON.parse(JSON.stringify(this.params))
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: objParams
    };
    this.api.getHandCustomerList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;

    });
  }
  userList = []; // 用户列表
  getUserList(): void {
    // 不选择部门，直接选择人
    // const params = {
    //   current: 1,
    //   size: 100,
    //   object: {
    //     objId: JSON.parse(localStorage.getItem('userInfo')).dept.id,
    //     type: 1,
    //   },
    // }
    // this.api.getUserOfficeList(params).subscribe(res => {
    //   this.userList = res['data'].records;
    //   // item['data'].records;
    // })
    this.api.getUserList().subscribe(res => {
      this.userList = res['data'];
    })
  }
  // 打开客户信息详情
  open(id, index, jobId) {
    const customerDrawer = this.drawerService.create<DrawerUserComponent, { value: string, index: number, jobId: any, plateValue: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerUserComponent,
      nzContentParams: {
        value: id,
        index,
        jobId,
        plateValue: 'my-customer'
      }
    });
    customerDrawer.afterClose.subscribe((data) => {
      this.getPubCustomerList();
    });
  }

  reset() {
    this.params.name = null;
    this.params.phoneNumber = null;
    this.params.weChat = null;
    this.params.companyName = null;
    this.getPubCustomerList();
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getPubCustomerList();
  }

  cancel() {
    this.modal.destroy();
  }
  transferAll() {
    if (!this.transferId) {
      this.message.error('请选择对接人');
      return false;
    }
    var params = {
      "nowUserId": this.transferId,
      "oriUserId": this.value.id
    }
    this.api.savePersonHandover(params).subscribe(res => {
      this.message.success('提交成功！');
      this.modal.destroy();
    })
  }
  submitForm() {
    if (!this.transferId) {
      this.message.error('请选择对接人');
      return false;
    }
    // let list = [];
    // this.listOfData
    //   .filter(item => this.mapOfCheckedId[item.jobId])
    //   .map(v => list.push(v.jobId));

    let entries = Object.entries(this.mapOfCheckedId);
    let trueItems = entries.filter(([key, value]) => value === true);
    let list = trueItems.map(([key, value]) => parseInt(key));

    if (list.length === 0) {
      this.message.warning('请选择客户！');
      return
    };
    var params = {
      "customerJobList": list,
      "nowUserId": this.transferId,
      "oriUserId": this.value.id
    }
    this.api.saveHandover(params).subscribe(res => {
      this.message.success('提交成功！');
      this.modal.destroy();
      // item['data'].records;
    })
  }
}
