import { Component, Input, OnInit } from '@angular/core';
import { Api } from '@service/Api';
import { environment } from '../../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerRequesExamineComponent } from '../../component/drawer-reques-examine/drawer-reques-examine.component';
import { DrawerRequesExamineBackComponent } from '../../component/drawer-reques-examine-back/drawer-reques-examine-back.component';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { DrawerReimbursementsApplyComponent } from '../../component/drawer-reimbursements-apply/drawer-reimbursements-apply.component';
import { DrawerReimbursWriteoffApplyComponent } from '../../component/drawer-reimburs-writeoff-apply/drawer-reimburs-writeoff-apply.component';

@Component({
  selector: 'app-drawer-finance',
  templateUrl: './drawer-finance.component.html',
  styleUrls: ['./drawer-finance.component.less']
})
export class DrawerFinanceComponent implements OnInit {
  public baseUrl; // api基本路径
  @Input() params: any;
  @Input() financeItem: any;
  constructor(
    public api: Api,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
  ) {
    this.baseUrl = environment.baseUrl;
  }


  businessCostDetail; //营业成本明细
  businessCostBackDetail; //营业成本明细 -退回
  collectionDetail; //营业回款明细
  recommendInDetail; //推荐收入
  recommendOutDetail; //推荐支出
  refundDetail; //退款明细
  deliveryRevenueDetail; //交付收入
  deliveryCostDetail; //交付成本
  unBusinessCostDetail; //营销费用
  salaryBonusCostDetail; //人员薪酬奖金成本
  salaryCostDetail; //人员薪酬成本
  otherCostDetail; //其他费用明细
  withholdingDeliveryCostDetail; //预扣交付成本
  withholdingDeliveryFhCostDetail; //反还交付成本
  ngOnInit() {
    console.log(this.financeItem)
    // 初始化变量以存储最终使用的值
    let codeToUse;
    // 检查 this.financeItem['topBusinessCode'] 是否有值或者等于 0
    if (this.financeItem['topBusinessCode'] || parseInt(this.financeItem['topBusinessCode']) === 0) {
      codeToUse = this.financeItem['topBusinessCode'];
      // 退款特殊处理
      if (this.financeItem['topBusinessCode'] == '31' || this.financeItem['topBusinessCode'] == '32') {
        codeToUse = '3';
      }
      // 营业回款特殊处理
      if (this.financeItem['topBusinessCode'] == '10' || this.financeItem['topBusinessCode'] == '11') {
        codeToUse = '0';
      }
    } else {
      codeToUse = this.financeItem['businessCode'];
    }
    switch (codeToUse) {
      case '0':
        // 营业回款
        var params = this.params;
        if (this.financeItem.businessCode == '10' || this.financeItem.topBusinessCode == '10' || this.financeItem.parentBusinessCode == '10') {
          // 营业回款：转单
          params.inLine = 0;
        } else if (this.financeItem.businessCode == '11' || this.financeItem.topBusinessCode == '11' || this.financeItem.parentBusinessCode == '11') {
          // 营业回款 实际
          params.inLine = 1;
        }
        if (params.yearType == 0) {
          // 收付实现制 0
          this.api.collectionDetail(params).subscribe(res => {
            this.collectionDetail = res['data'];
          })
        }
        if (this.params.yearType == 1) {
          // 权责发生制 1
          this.api.collectionDetailFull(params).subscribe(res => {
            this.collectionDetail = res['data'];
          })
        }
        this.api.recommendInDetail(params).subscribe(res => {
          this.recommendInDetail = res['data'];
        })
        break;
      case '2':
        // 营业成本
        this.api.businessCostDetail(this.params).subscribe(res => {
          this.businessCostDetail = res['data'];
        })
        this.api.businessCostBackDetail(this.params).subscribe(res => {
          this.businessCostBackDetail = res['data'];
        })
        this.api.recommendOutDetail(this.params).subscribe(res => {
          this.recommendOutDetail = res['data'];
        })
        break;
      case '3':
        // 退款
        var params = this.params;
        if (this.financeItem.businessCode == '31' || this.financeItem.topBusinessCode == '31' || this.financeItem.parentBusinessCode == '31') {
          // 退款：转单
          params.inLine = 0;
        } else if (this.financeItem.businessCode == '32' || this.financeItem.topBusinessCode == '32' || this.financeItem.parentBusinessCode == '32') {
          // 实际退款
          params.inLine = 1;
        }
        this.api.refundDetail(params).subscribe(res => {
          this.refundDetail = res['data'];
        })
        break;
      case '4':
        // 交付收入
        this.api.deliveryRevenueDetail(this.params).subscribe(res => {
          this.deliveryRevenueDetail = res['data'];
        })
        break;
      case '5':
        // 交付成本
        this.api.deliveryCostDetail(this.params).subscribe(res => {
          this.deliveryCostDetail = res['data'];
        })
        break;
      case '6':
        // 营业回款
        if (this.params.yearType == 0) {
          // 收付实现制 0
          this.api.collectionDetail(this.params).subscribe(res => {
            this.collectionDetail = res['data'];
          })
        }
        if (this.params.yearType == 1) {
          // 权责发生制 1
          this.api.collectionDetailFull(this.params).subscribe(res => {
            this.collectionDetail = res['data'];
          })
        }
        // 请款列表
        this.api.businessCostDetail(this.params).subscribe(res => {
          this.businessCostDetail = res['data'];
        })
        // 请款退回列表
        this.api.businessCostBackDetail(this.params).subscribe(res => {
          this.businessCostBackDetail = res['data'];
        })
        // 退款
        var params = this.params;
        if (this.financeItem.businessCode == '31' || this.financeItem.topBusinessCode == '31' || this.financeItem.parentBusinessCode == '31') {
          params.inLine = 0;
        } else if (this.financeItem.businessCode == '32' || this.financeItem.topBusinessCode == '32' || this.financeItem.parentBusinessCode == '32') {
          params.inLine = 1;
        }
        this.api.refundDetail(params).subscribe(res => {
          this.refundDetail = res['data'];
        })
        break;
      case '7':
        // 预扣交付成本
        this.api.withholdingDeliveryCostDetail(this.params).subscribe(res => {
          this.withholdingDeliveryCostDetail = res['data'];
        })
        this.api.withholdingDeliveryFhCostDetail(this.params).subscribe(res => {
          this.withholdingDeliveryFhCostDetail = res['data'];
        })
        break;
      case '8':
        // 营销费用
        this.api.unBusinessCostDetail(this.params).subscribe(res => {
          this.unBusinessCostDetail = res['data'];
        })
        break;
      case '9':
        // 人员薪酬
        if (this.params.monthNo == '12') {
          this.financeSalaryIndex = 1;
          this.financeSalaryList = [
            {
              salaryIndex: 0,
              monthNo: '01',
              yearNo: this.params.yearNo,
              title: this.params.yearNo + '-01'
            },
            {
              salaryIndex: 0,
              monthNo: '12',
              yearNo: this.params.yearNo,
              title: this.params.yearNo + '-12'
            },
            {
              salaryIndex: 0,
              monthNo: '01',
              yearNo: (Number(this.params.yearNo) + 1).toString(),
              title: (Number(this.params.yearNo) + 1) + '-01'
            }
          ]
        }
        else {
          this.financeSalaryList = [{
            salaryIndex: 0,
            monthNo: this.params.monthNo,
            yearNo: this.params.yearNo,
            title: this.params.yearNo + (this.params.monthNo ? '-' + this.params.monthNo : '')
          }]
        }
        this.getFinanceSalaryList(this.params);
        break;
      case '10000':
        // 其他费用
        this.api.otherCostDetail(this.params).subscribe(res => {
          this.otherCostDetail = res['data'];
        })
        break;
      default:
        break;
    }
  }
  // 成本包含请款、请款退回
  orderHtmlIndex = 0;
  salaryIndex = 0;
  withHoldIndex = 0;
  receiptsIndex = 0;
  selectTabset(e, type) {
    if (type == 'salary') {
      this.salaryIndex = e.index;
    }
    if (type == 'business') {
      this.orderHtmlIndex = e.index;
    }
    if (type == 'receipts') {
      this.receiptsIndex = e.index;
    }
    if (type == 'withHold') {
      this.withHoldIndex = e.index;
    }
  }
  // 人员薪酬
  financeSalaryIndex = 0;
  financeSalaryList = [];
  selectFinanceSalary(e) {
    this.financeSalaryIndex = e.index;
    var param = this.params;
    param.yearNo = this.financeSalaryList[this.financeSalaryIndex]['yearNo'];
    param.monthNo = this.financeSalaryList[this.financeSalaryIndex]['monthNo'];
    this.getFinanceSalaryList(param);
  }
  getFinanceSalaryList(params) {
    this.api.salaryCostDetail(params).subscribe(res => {
      this.salaryCostDetail = res['data'];
    })
    this.api.salaryBonusCostDetail(params).subscribe(res => {
      this.salaryBonusCostDetail = res['data'];
    })
  }
  /**
   * 导出
   * @param data
   *
   */
  loading = false;
  downLoad() {
    this.loading = true;
    let date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var time = Y + M + D;
    var title = time + '报表明细'
    let urlApi = '';
    var params = this.params;
    // 初始化变量以存储最终使用的值
    let codeToUse;
    // 检查 this.financeItem['topBusinessCode'] 是否有值或者等于 0
    if (this.financeItem['topBusinessCode'] || parseInt(this.financeItem['topBusinessCode']) === 0) {
      codeToUse = this.financeItem['topBusinessCode'];
      // 退款特殊处理
      if (this.financeItem['topBusinessCode'] == '31' || this.financeItem['topBusinessCode'] == '32') {
        codeToUse = '3';
      }
      // 营业回款特殊处理
      if (this.financeItem['topBusinessCode'] == '10' || this.financeItem['topBusinessCode'] == '11') {
        codeToUse = '0';
      }
    } else {
      codeToUse = this.financeItem['businessCode'];
    }
    switch (codeToUse) {
      case '0':
        if (this.financeItem.businessCode == '10') {
          params.inLine = 0;
        } else if (this.financeItem.businessCode == '11') {
          params.inLine = 1;
        }
        if (this.params.yearType == 0) {
          urlApi = '/sysOfficeYear/importCollectionDetail';
        } else if (this.params.yearType == 1) {
          urlApi = '/sysOfficeYear/importCollectionDetailFull';
        }
        break;
      case '2':
        urlApi = '/sysOfficeYear/importBusinessCostDetail';
        break;
      case '3':
        if (this.financeItem.businessCode == '31') {
          params.inLine = 0;
        } else if (this.financeItem.businessCode == '32') {
          params.inLine = 1;
        }
        urlApi = '/sysOfficeYear/importRefundDetail';
        break;
      case '4':
        urlApi = '/sysOfficeYear/importDeliveryRevenueDetail';
        break;
      case '5':
        urlApi = '/sysOfficeYear/importDeliveryCostDetail';
        break;
      case '7':
        if (this.withHoldIndex == 0) {
          urlApi = '/sysOfficeYear/importWithholdingDeliveryCostDetail';
        }
        if (this.withHoldIndex == 1) {
          urlApi = '/sysOfficeYear/importWithholdingDeliveryFhCostDetail';
        }
        break;
      case '8':
        urlApi = '/sysOfficeYear/importUnBusinessCostDetail';
        break;
      case '9':
        params.yearNo = this.financeSalaryList[this.financeSalaryIndex]['yearNo'];
        params.monthNo = this.financeSalaryList[this.financeSalaryIndex]['monthNo'];
        if (this.salaryIndex == 0) {
          urlApi = '/sysOfficeYear/importSalaryCostDetail';
        }
        if (this.salaryIndex == 1) {
          urlApi = '/sysOfficeYear/importSalaryBonusCostDetail';
        }
        break;
      case '10000':
        urlApi = '/sysOfficeYear/importOtherCostDetail';
        break;
      default:
        break;
    }
    let url = this.baseUrl + urlApi;
    var httpRequest = new XMLHttpRequest();
    httpRequest.responseType = "arraybuffer";
    httpRequest.open("POST", url, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Admin-Token", localStorage.getItem("token"));
    httpRequest.setRequestHeader("responseType", "blob");

    httpRequest.send(JSON.stringify(params));
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState == 4 && httpRequest.status == 200) {
        var data = httpRequest.response;
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', title + '.xls');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    }
  }

  // 打开请款详情
  openRequesExamine(id, editType, serviceProviderId, requestNo) {
    const modal = this.drawerService.create<DrawerRequesExamineComponent, { value: string, editType: string, serviceProviderId: string, requestNo: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerRequesExamineComponent,
      nzContentParams: {
        value: id,
        editType: editType,
        serviceProviderId: serviceProviderId,
        requestNo: requestNo
      }
    });
    modal.afterClose.subscribe(() => {
    })
  }
  // 打开请款退回详情
  openRefundNo(id, editType, serviceProviderId, requestNo) {
    const modal = this.drawerService.create<DrawerRequesExamineBackComponent, { value: string, editType: string, serviceProviderId: string, requestNo: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerRequesExamineBackComponent,
      nzContentParams: {
        value: id,
        editType: editType,
        serviceProviderId: serviceProviderId,
        requestNo: requestNo
      }
    });
    modal.afterClose.subscribe(() => {
    })
  }

  // 打开订单详情
  openOrder(id, type) {
    this.drawerService.create<DrawerOrderComponent, { value: string, type: number }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerOrderComponent,
      nzContentParams: {
        value: id,
        type: type,
      }
    });
  }


  // 打开报销详情
  openApply(id, type) {
    if (type == 0) {
      // 报销
      const modal = this.drawerService.create<DrawerReimbursementsApplyComponent, { value: string, type: string }, string>({
        nzWidth: 1000,
        nzWrapClassName: 'drawer-user',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: DrawerReimbursementsApplyComponent,
        nzContentParams: {
          value: id,
          type: 'my'
        }
      });
      modal.afterClose.subscribe(() => {
        // this.getServiceStoragesList();
      })
    } else if (type == 1) {
      // 核销
      const modal = this.drawerService.create<DrawerReimbursWriteoffApplyComponent, { value: string }, string>({
        nzWidth: 1000,
        nzWrapClassName: 'drawer-user',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: DrawerReimbursWriteoffApplyComponent,
        nzContentParams: {
          value: id,
        }
      });
      modal.afterClose.subscribe(() => {
        // this.getServiceStoragesList();
      })
    }

  }
}
