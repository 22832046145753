import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-product-examine',
  templateUrl: './drawer-product-examine.component.html',
  styleUrls: ['./drawer-product-examine.component.less']
})
export class DrawerProductExamineComponent implements OnInit {
  @Input() value = '';

  constructor(
    public message: NzMessageService,
    public api: Api,
    private drawerService: NzDrawerService,
  ) { }
  contractNum = 0;
  ngOnInit() {
    this.getOrderProduct();
    this.getBsOrderTaskList();
  }

  productInfo = {};
  // 获取产品信息
  getOrderProduct() {
    this.api.orderProductInfo(this.value).subscribe(res => {
      this.productInfo = res['data'];
    })
  }

  bsOrderTaskList = [];
  // 获取关联工单
  getBsOrderTaskList() {
    this.api.orderTaskList(this.value).subscribe(res => {
      this.bsOrderTaskList = res['data'];
    })
  }
  // 打开任务详情
  open(id, index) {
    const modal = this.drawerService.create<DrawerTaskComponent, { value: string, index: string, noButton: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id,
        index: index,
        noButton: '0'
      }
    });
  }
}
