
import { NzModalRef } from 'ng-zorro-antd/modal';

import {Component, ElementRef, OnInit, ViewChild,Input} from '@angular/core';
import {Location} from '@angular/common';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Api} from '@service/Api';
import {AuthService} from '../../../auth/auth.service';
import {NzMessageService} from 'ng-zorro-antd/message';

import {UEditorComponent} from 'ngx-ueditor';

@Component({
  selector: 'app-modal-edit-jwcx-product',
  templateUrl: './modal-edit-jwcx-product.component.html',
  styleUrls: ['./modal-edit-jwcx-product.component.less']
})
export class ModalEditJwcxProductComponent implements OnInit {
  
  @Input() productInfo: any; // 
  isSpinning = false;
  html:'';
  @ViewChild('templateInput' ,{static:true}) templateInput: UEditorComponent;
  constructor(
    private api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) {
    
  }
  loading = false;

  applyCondition:'';
  
  

  submit() {
    this.loading = true;
    console.log(this.productInfo)

    let content = this.templateInput.Instance.getContent()
    console.log(content)
    //getJwcxProductUpdate

    // this.api.getJwcxProductUpdate(params).subscribe(res => {
    //   this.message.success('编辑成功！');
    //   this.cancel();
    // });

  }

  cancel() {
    this.modal.destroy();
  }
  ngOnInit() {
    console.log(this.productInfo)
    

  }
  
  

}
