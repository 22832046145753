import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';

@Component({
  selector: 'app-drawer-order-renew',
  templateUrl: './drawer-order-renew.component.html',
  styleUrls: ['./drawer-order-renew.component.less']
})
export class DrawerOrderRenewComponent implements OnInit {

  @Input() value = '';
  @Input() productNo = '';
  @Input() id = '';
  @Input() type: any;
  @Input() menuType: any; // my 我的订单  team 团队订单
  @Input() single: any; // my 我的订单  team 团队订单
  @Input() isRelevance: boolean;// 是否联合签单人  true 不能操作

  @Input() isMyPay: boolean;// 甩单 支付状态 
  @Input() payStatus: string;// 支付状态 

  jobId: any;
  addTaskForm: FormGroup;
  contractNum:any;
  orderInfo;    // 客户信息
  renewInfo;    // 客户信息
  taskInfo = []; // 任务信息

  customerTaskRecord = [] // 交付记录
  labelModal: NzModalRef;

  productInfo = [];  // 产品信息
  paymentList = [];  // 支付记录列表
  refundRecord = []; // 退款信息
  activeList = [0];  // 已激活菜单
  taskList = [];     // 任务列表

  orderRecordList = [];//订单跟进日志

  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public api: Api,
  ) {
    
  }

  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getOrderProduct();
        break;
      case 2:
        this.getRenewDetail();
        break;
      case 3:
        this.getOrderRecordList();
        break;
      default:
        break;
    }
  }

  // #region tab1: 产品信息
  // 获取产品信息
  getOrderProduct() {
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.productInfo = res['data'];
    })
  }

  // 续费信息
  getRenewDetail() {
    this.api.renewOrderDetails(this.id).subscribe(res => {
      this.renewInfo = res['data'];
    })
  }

  // 获取订单详情
  getOrderDetail() {
    this.api.getOrderDetail({ orderId: this.value }).subscribe(res => {
      this.orderInfo = res['data'];
    })
  }

  // 添加跟进记录
  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.id,
        type: ''
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.renewOrderaddLog({
          content: data['content'],
          orderProductRecordId: this.id
        }).subscribe(res => {
          this.orderRecordList = [];
          this.getOrderRecordList();
        })
      }
    });
  }
  getOrderRecordList() {
    this.api.renewOrderLogList(this.id).subscribe(res => {
      this.orderRecordList = res['data'];
    })
  }

  ngOnInit() {
    this.getOrderDetail();
  }
}
