import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';


@Component({
  selector: 'app-drawer-manage-target-report',
  templateUrl: './drawer-manage-target-report.component.html',
  styleUrls: ['./drawer-manage-target-report.component.less']
})
export class DrawerManageTargetReportComponent implements OnInit {
  @Input() value = '';
  @Input() statisticsMonth = '';
  @Input() userInductionCompanyId = '';
  @Input() userInductionCompanyName = '';
  activeList: any = [0];    // 已激活菜单
  targetReport: any = []; //列表数据
  constructor(
    public message: NzMessageService,
    public router: Router,
    public api: Api,
  ) {
  }
  curTab = 0;
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    // if (this.activeList.includes(e)) {
    //   return
    // }
    // this.activeList.push(e);
    this.curTab = e;
    if (e == 0) {
      this.teamStatisticsReport(null);
    } else {
      this.targetReportDetailAll();
      this.teamStatisticsReport(e);
    }
    // if (e != 4) {
    //   this.targetReportDetailAll();
    //   this.teamStatisticsReport(e);
    // } else if (e == 4) {
    //   this.teamStatisticsReport(null);
    // }
  }

  // 获取团队个别
  targetReportDetailAll() {
    var param = {
      id: this.value,
      statisticsMonth: this.statisticsMonth,
      userInductionCompanyId: this.userInductionCompanyId,
    }
    if (this.curTab != 0) {
      param['teamFlag'] = this.curTab - 1;
    }
    this.api.targetReportDetailAll(param).subscribe(res => {
      let data = res['data'];
      this.targetReport = data;
    })
  }
  // 团队人效汇总数据
  teamStatistics;
  // 各个部门的汇总
  teamStatisticsObj = {
    shouldPrice: null,
    realPrice: null
  }
  // 获取团队汇总
  teamStatisticsReport(e) {
    var param = {
      id: this.value,
      statisticsMonth: this.statisticsMonth,
      userInductionCompanyId: this.userInductionCompanyId,
    }
    if (e != null) {
      param['teamFlag'] = e - 1;
    }
    this.api.teamStatisticsReport(param).subscribe(res => {
      let data = res['data'];
      if (e != null) {
        if (data.length == 0) {
          this.teamStatisticsObj = {
            shouldPrice: null,
            realPrice: null
          }
        } else {
          this.teamStatisticsObj = data[0];
        }
      } else {
        this.teamStatistics = data;
      }
    })
  }

  ngOnInit() {
    // this.targetReportDetailAll();
    // this.teamStatisticsReport(this.curTab);
    this.changeTabs(this.curTab)
  }
  desInfo = null;
  // 弹出窗逻辑 报销名目 start
  isVisible = false
  showModal(index): void {
    switch (index) {
      case 1:
        this.desInfo = '当月服务客户（办结完）的毛利 = 实际办结完服务曾经订单回款 - 为了办结这个订单支付的第三方成本 - 当月银行实际退款。'
        break;
      case 2:
        this.desInfo = '当月自开发签订合同的毛利= 客户合同实际回款 - 为了办结这个订单支付的第三方成本 - 当月实际支付退款'
        break;
      case 3:
        this.desInfo = '当月总户数带来的订单毛利（全产品）= 客户合同实际回款 - 为了办结这个订单支付的第三方成本 - 当月实际支付退款'
        break;
      default:
        break;
    }
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  // 弹出窗逻辑 报销名目 en
}
