import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';




let api_tmp;
@Component({
  selector: 'app-modal-userDataPer',
  templateUrl: './modal-userDataPer.component.html',
  styleUrls: ['./modal-userDataPer.component.less']
})
export class ModalAddUserPerComponent implements OnInit {
  @Input() id: String;
  @Input() userId: String;

  form: FormGroup;  // 新增用户表单
  referralUserType = '';
  datas = [];
  canSubmit = true;
  isEdit = false;
  showArea = false;
  city_Data = [];


  constructor(
    fb: FormBuilder,
    public api: Api,
    private modal: NzModalRef,
  ) {
    this.form = fb.group({
      dataType: [null, Validators.required],
      dataId: [null, Validators.required],
      remark: [null,],
      deptId: [null,],
    });
    api_tmp = this.api;
  }




  save(value) {
    this.canSubmit = false;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    value['id'] = this.id;
    value['userId'] = this.userId;
    if (value.dataType == 4) {
      value.dataId = value.remark[value.remark.length - 1];
      value.remark = JSON.stringify(value.remark);
    } else {
      value.remark = '';
    }
    if (value.dataType == 2) {
      value.dataId = value.deptId;
    }
    if (!this.form.valid) {
      this.canSubmit = true;
      return
    }
    delete value.deptId;
    if (!this.isEdit) {
      this.api.addUserPer(value).subscribe(res => {
        this.modal.destroy();
      }, err => {
        this.canSubmit = true;
      })
    } else {
      value['id'] = this.id;
      this.api.updateUser(value).subscribe(res => {
        this.canSubmit = true;
        this.modal.destroy();
      }, err => {
        this.canSubmit = true;
      })

    }
  }

  cancal() {
    this.modal.destroy();
  }

  // 获取用户详细信息
  getUserDetail() {
    this.api.getUserDetail({ id: this.id }).subscribe(res => {
      const userInfo = res['data'];
      Object.keys(userInfo).map((key) => {
        if (this.form.get(key)) {
          this.form.get(key).setValue(userInfo[key]);
        }
      })
    })
  }

  //获取数据信息
  getData() {
    let type = this.form.controls.dataType.value;
    this.form.get('dataId').setValue(undefined);
    this.form.get('deptId').setValue(undefined);
    if (type == 4) {
      this.showArea = true;
    } else {
      this.showArea = false;
    }
    let param = {
      type: type == 2 ? 3 : type,
    }
    this.api.getDataPer(param).subscribe(res => {
      let data = res['data'];
      this.datas = data;
    });
  }

  deptList: any;
  // 根据公司获取部门
  getDept() {
    this.form.get('deptId').setValue(undefined);
    if (this.form.controls.dataType.value == 2) {
      let companyid = this.form.controls.dataId.value;
      if (companyid) {
        this.api.findDeptByCompanyId({ companyId: companyid }).subscribe((res) => {
          this.deptList = res['data'];
        })
      }
    }

  }

  nzOptions: any;
  //获取部门信息
  getDeptData() {
    let data = {
      type: 1,
    }
    if (this.isEdit) { //如果是编辑
      this.api.getDept(data).subscribe(res => {
        this.nzOptions = res['data'];
      });
    }
  }




  loadCityData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      const params = {};
      if (index < 0) {
        params['level'] = 1;
      } else if (index === 0) {
        params['level'] = 2;
        params['parentCode'] = node.code;
      }
      api_tmp.getCityList(params).subscribe(res => {
        node.children = res['data'];
        if (index === 0) {
          node.children.map((v) => {
            v['isLeaf'] = true;
          });
        }
        resolve();
      });
    });
  }

  ngOnInit() {

  }
}
