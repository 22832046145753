import { Component, OnInit } from '@angular/core';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-select-order-product',
  templateUrl: './modal-select-order-product.component.html',
  styleUrls: ['./modal-select-order-product.component.less']
})
export class ModalSelectOrderProductComponent implements OnInit {

  productList = [];         // 产品列表
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfExpandData: { [key: string]: boolean } = {};

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {
  }

  params = {
    orderNo: null,
    porductName: null,
  }
  orderInfo;
  query() {
    this.api.getOrderId(this.params.orderNo).subscribe(res => {
      if (res['data']) {
        this.getOrderProduct(res['data'].id)
        this.orderInfo = res['data'];
      } else {
        this.message.error('没有找到订单号!');
      }
    })
  }
  // 获取产品信息
  getOrderProduct(orderId) {
    this.api.getOrderProduct({ orderId: orderId }).subscribe(res => {
      this.productList = res['data'];
      this.productList.map((item, index) => {
        item['orderNo'] = this.orderInfo.orderNo
        item['status'] = this.orderInfo.status
        item['deductionAmount2'] = null;
      });
    })
  }


  cancel() {
    this.modal.destroy();
  }

  submit() {
    let checkedList = []; // 已选产品
    console.log(this.mapOfCheckedId)
    this.productList
      .filter(item => this.mapOfCheckedId[item.id])
      .map(v => {
        checkedList.push(v)
      });

    if (this.productList.length === 0) {
      this.message.warning('请选择产品！');
      return
    }
    ;

    this.modal.destroy(checkedList);
  }

  ngOnInit() {


  }
}
