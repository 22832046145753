import { Component, OnInit, Input } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-drawer-sign-enterprise',
  templateUrl: './drawer-sign-enterprise.component.html',
  styleUrls: ['./drawer-sign-enterprise.component.less']
})
export class DrawerSignEnterpriseComponent implements OnInit {
  @Input() value = '';
  public baseUrl; // api基本路径
  info;    // 客户信息
  constructor(
    public api: Api,
  ) {
    this.baseUrl = environment.baseUrl;
  }
  params = {
    dateStart: null,
    dateEnd: null,
    id: null,
  };

  ngOnInit() {
    this.params.id = this.value;
    this.api.getSignedEnterpriseDetail(this.value).subscribe(res => {
      this.info = res['data']
    });
  }
  listOfData = [];//列表数据集合
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  activeList = [0];  // 已激活菜单
  contractNum = 0;
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getPayList();
  };
  /**
   * 获取收支明细
   */
  isSpinning = false;
  dateList = [new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30), new Date()];
  getPayList() {
    if (this.dateList.length != 0) {
      this.params.dateStart = Utils.dateFormat(this.dateList[0], 'yyyy-MM-dd');
      this.params.dateEnd = Utils.dateFormat(this.dateList[1], 'yyyy-MM-dd');
    } else {
      this.params.dateStart = null;
      this.params.dateEnd = null;
    }
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.isSpinning = true;
    this.api.queryInExpDetail(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
      this.isSpinning = false;
    }, () => this.isSpinning = false);
  }
  // 导出
  loading = false;
  /**
 *工资条导出
*/
  downLoad() {
    this.loading = true;
    var params = this.params;
    let date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var time = Y + M + D;
    var title = '收支明细'
    let url = this.baseUrl + '/sys/signedEnterprise/doWnLoadInExpDetailPage'
    var httpRequest = new XMLHttpRequest();
    httpRequest.responseType = "arraybuffer";
    httpRequest.open("POST", url, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Admin-Token", localStorage.getItem("token"));
    httpRequest.setRequestHeader("responseType", "blob");
    httpRequest.send(JSON.stringify(params));
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState == 4 && httpRequest.status == 200) {
        var data = httpRequest.response;
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', title + '.xls');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    }
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getPayList();
  }
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }

    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getPayList();
        break;
    }
  }

}
