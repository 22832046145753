import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Api } from '@service/Api';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-cause',
  templateUrl: './modal-cause.component.html',
  styleUrls: ['./modal-cause.component.less']
})
export class ModalCauseComponent implements OnInit {

  @Input() params: any;
  form: FormGroup;
  constructor(
    private api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
    private fb: FormBuilder) {
    this.form = fb.group({
      delReason: ['', Validators.required],
      checkDesc: [null]
    });
  }

  cancel() {
    this.modal.destroy();
  }

  submitForm() {
    if (this.params.applyType) {
      // 工资审核
      if (!this.form.getRawValue().checkDesc) {
        this.message.error(this.params.checkStatus == 3 ? '请输入驳回理由！' : '请输入拒绝理由！')
        return
      }
      const params = {
        userSalaryApplyId: this.params.userSalaryApplyId,
        // checkType: this.params.checkType,
        checkStatus: this.params.checkStatus,
        checkDesc: this.form.getRawValue().checkDesc,
      };
      // 驳回
      if (this.params.checkStatus == 3) {
        this.api.userSysUserSalaryApplyReject(params).subscribe((res) => {
          this.message.success('审核成功')
          this.modal.destroy();
          this.form.reset();
        })
      } else {
        this.api.userSysUserSalaryApplyCheck(params).subscribe((res) => {
          this.message.success('审核成功')
          this.modal.destroy();
          this.form.reset();
        })
      }

    } else {
      Object.keys(this.form.controls).map((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      });
      if (!this.form.valid) {
        return
      } else {
        this.params.delReason = this.form.controls.delReason.value;
        this.api.deleteNotThread(this.params).subscribe(res => {
          console.log(res)
          if (res['code'] == 200) {
            this.message.create('success', `发起删除的申请提交成功`);
          }
        })
        this.modal.destroy();
        this.form.reset();
      }
    }
  }
  ngOnInit() {
    console.log(this.params)
  }

}
