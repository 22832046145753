import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';


@Component({
  selector: 'app-drawer-customer-maintain',
  templateUrl: './drawer-customer-maintain.component.html',
  styleUrls: ['./drawer-customer-maintain.component.less']
})
export class DrawerCustomerMaintainComponent implements OnInit {
  @Input() value = '';
  @Input() statisticsMonth = '';
  @Input() userInductionCompanyId = '';
  @Input() userInductionCompanyName = '';
  activeList: any = [0];    // 已激活菜单
  targetReport: any = []; //财税顾问
  targetReportHigh: any = []; //高级客服
  constructor(
    public message: NzMessageService,
    public router: Router,
    public api: Api,
  ) {
  }
  curTab = 0;
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    // if (this.activeList.includes(e)) {
    //   return
    // }
    // this.activeList.push(e);
    this.curTab = e;
    // if (e != 4) {
    //   this.targetReportDetailAll();
    //   this.teamStatisticsReport(e);
    // } else if (e == 4) {
    //   this.teamStatisticsReport(null);
    // }
  }

  // 获取团队个别
  targetReportDetailAll(teamFlag) {
    var param = {
      id: this.value,
      statisticsMonth: this.statisticsMonth,
      userInductionCompanyId: this.userInductionCompanyId,
      teamFlag: teamFlag
    }
    this.api.targetReportDetailAll(param).subscribe(res => {
      let data = res['data'];
      switch (teamFlag) {
        case 3:
          this.targetReport = data;
          break;
        case 5:
          this.targetReportHigh = data;
          break;
        default:
          break;
      }
    })
  }
  // 团队人效汇总数据
  teamStatistics;
  // 各个部门的汇总
  teamStatisticsObj = {
    shouldPrice: null,
    realPrice: null
  }
  // 获取团队汇总
  teamStatisticsReport(e) {
    var param = {
      id: this.value,
      statisticsMonth: this.statisticsMonth,
      userInductionCompanyId: this.userInductionCompanyId,
    }
    if (e != null) {
      param['teamFlag'] = e;
    }
    this.api.teamStatisticsReport(param).subscribe(res => {
      let data = res['data'];
      if (e != null) {
        if (data.length == 0) {
          this.teamStatisticsObj = {
            shouldPrice: null,
            realPrice: null
          }
        } else {
          this.teamStatisticsObj = data[0];
        }
      } else {
        this.teamStatistics = data;
      }
    })
  }

  ngOnInit() {
    this.targetReportDetailAll(3);
    this.targetReportDetailAll(5);
    // this.teamStatisticsReport(this.curTab);
  }
  desInfo = null;
  // 弹出窗逻辑 报销名目 start
  isVisible = false
  showModal(index): void {
    switch (index) {
      case 1:
        this.desInfo = '续费客户实际续费的毛利+续费客户新增订单毛利+续费客户转介绍客户带来的新增订单毛利(即含当月收当月退，历史收当月退。'
        break;
      case 2:
        this.desInfo = '当月自开发签订合同的毛利= 客户合同实际回款 - 为了办结这个订单支付的第三方成本 - 当月实际支付退款'
        break;
      case 3:
        this.desInfo = '当月总户数带来的订单毛利（全产品）= 客户合同实际回款 - 为了办结这个订单支付的第三方成本 - 当月实际支付退款'
        break;
      default:
        break;
    }
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  // 弹出窗逻辑 报销名目 en
}
