import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DomSanitizer } from '@angular/platform-browser'
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-prepayment',
  templateUrl: './modal-prepayment.component.html',
  styleUrls: ['./modal-prepayment.component.less']
})
export class ModalPrepaymentComponent implements OnInit {
  @Input() customerInfo: string; // 客户信息
  form: FormGroup;
  receivingCompanyList = []//收款公司

  showQrcode = false; // 显示二维码弹窗
  loading = false;
  qrcodeUrl: any; // 二维码地址
  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.form = fb.group({
      name: new FormControl({ value: null, disabled: true }, Validators.required),
      phoneNumber: new FormControl({ value: null, disabled: true }, Validators.required),
      remark: [null],
      customerId: [null],
      signedEnterpriseId: [null, Validators.required],
      advanceOrderAmount: [null, Validators.required],
    });
  }

  ngOnInit() {
    //收款公司
    this.api.getPaymentCompanyList({ type: 0 }).subscribe(res => {
      this.receivingCompanyList = res['data'];
    })


    this.form.get('customerId').setValue(this.customerInfo['id']);
    this.form.get('name').setValue(this.customerInfo['name']);
    this.form.get('phoneNumber').setValue(this.customerInfo['phoneNumber']);
  }

  cancel() {
    this.modal.destroy()
  }
  handleCancel() {
    this.showQrcode = false;
    this.form.reset();
    this.cancel();
  }
  qrcodeObj = {};
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let value = this.form.value;
    (this.receivingCompanyList || []).map(item => {
      if (item.signedEnterpriseId == value.signedEnterpriseId) {
        value.signedEnterpriseName = item.fullNameOffice
        return
      }
    })
    if (Number(value.advanceOrderAmount) > 50000) {
      this.message.error("收款金额不可超过5万，请分两次支付！");
      this.loading = false
      return
    }
    this.api.addCustomerAdvanceOrders(value).subscribe(res => {
      this.qrcodeObj = res['data'];
      this.showQrcode = true;
      this.qrcodeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res['data'].fileUrl);

    }, err => {
      this.loading = false;
    })
  }

}
