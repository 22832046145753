import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { DomSanitizer } from '@angular/platform-browser'
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-add-account',
  templateUrl: './modal-add-account.component.html',
  styleUrls: ['./modal-add-account.component.less']
})
export class ModalAddAccountComponent implements OnInit {
  showQrcode=false;//充值页面

  qrcodeUrl:any="";

  account = 0;
  actualPayAmount = 0;
  objId = '';
  actuaPrice = 0;
  canSubmit=true;

  companyList = [];

  constructor(private api: Api,
    private modal: NzModalRef,
    public sanitizer:DomSanitizer,
    public message: NzMessageService,) {

    }
  cancel() {
    this.modal.destroy();
  }

  add(){
    if(this.objId == ''){
      //充值金额不能值于1000
      this.message.error('选择充值的公司');
      return
    }
    if(this.account < 1000){
      //充值金额不能值于1000
      this.message.error('充值金额不能低于1000');
      return
    }
    if(this.account == 0){
      //请输入充值金额，最低1000
      this.message.error('请输入充值金额，最低1000');
      return
    }
    
    this.actuaPrice = this.account * 1.08;
    this.actualPayAmount = Number(this.actuaPrice.toFixed(2));
    this.canSubmit = false;
    const param = {
      account:this.account,
      actualPayAmount:this.actuaPrice.toFixed(2),
      objId:this.objId,
      objType:1,
    }
  
    this.canSubmit=true;
    this.api.officeBbcBankQrCode(param).subscribe(res=>{
        this.canSubmit=true;
        console.log(res);
        this.qrcodeUrl=this.sanitizer.bypassSecurityTrustResourceUrl(res['data'].url);
        this.showQrcode=true;
    });
  }
  handleCancel(){
    this.showQrcode = false;
  }
 // 获取用户权限，公司或部门的权限
 getDataDimension() {

  this.api.getOfficeDataDimension({type: 0}).subscribe((res) => {
    this.companyList = res['data'];
  })
  
  }

  itemClass: any;
  ngOnInit() {
    this.getDataDimension();
    let params = { parentCode: 'OFFICE_PAY' }
    this.api.getSysParameters(params).subscribe(res => {
      this.itemClass = res['data'];
      console.log(this.itemClass);
    });
  }

}
