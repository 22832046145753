import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
let cnm;
let apiTmp;

@Component({
  selector: 'app-modal-stakeholder-config',
  templateUrl: './modal-stakeholder-config.component.html',
  styleUrls: ['./modal-stakeholder-config.component.less']
})
export class ModalStakeholderConfigComponent implements OnInit {
  @Input() id: string;
  constructor(
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) { }
  ngOnInit() {
    this.getUserListByCompanyId();
    this.getServerRole();
    this.getServerUserDetail();
  }
  listOfData: any = [
    // {
    //   userId: null,
    //   userName: null,
    //   serverRoleId: null,
    //   receiveFlag: 0,
    // }
  ];


  toAddUserConfig(): void {
    this.listOfData = [
      ...this.listOfData,
      {
        userId: null,
        userName: null,
        serverRoleId: null,
        serverRoleNo: null,
        serverRoleName: null,
        receiveFlag: 0,
      }
    ]
  };
  // 删除配置
  delConfig(index: number): void {
    let newList = JSON.parse(JSON.stringify(this.listOfData));
    newList.splice(index, 1)
    this.listOfData = newList;
  };

  // 获取角色权限
  serverRole = [];
  getServerRole() {
    this.api.getServerRole().subscribe(res => {
      this.serverRole = res['data'];
    });
  }
  // 查看详情
  getServerUserDetail() {
    this.api.serverUserDetail(this.id).subscribe(res => {
      // this.listOfData = res['data'];
      var paramsData = [];
      res['data'].map((item, index) => {
        paramsData[index] = {
          companyServerId: item.id,
          userId: item.userId,
          userName: item.userName,
          serverRoleId: item.serverRoleId,
          serverRoleName: item.serverRoleName,
          serverRoleNo: item.serverRoleNo,
          receiveFlag: item.receiveFlag,
          id: item.id
        }
      })
      this.listOfData = paramsData;

    });
  }

  UserSelectedChange(e, index) {
    this.staffList.map((item, i) => {
      if (item.userId == e) {
        this.listOfData[index].userName = item.name;
        return
      }
    })
  }
  serverChange(e, index) {
    this.serverRole.map((item, i) => {
      if (item.id == e) {
        this.listOfData[index].serverRoleName = item.roleName;
        this.listOfData[index].serverRoleNo = item.roleNo;
        return
      }
    })
  }



  staffList = []
  getUserListByCompanyId() {
    this.api.getUserListByCompanyId().subscribe(res => {
      this.staffList = res['data'];
    })
  }
  cancal() {
    this.modal.destroy();
  }
  canSubmit = false;
  submit() {
    this.canSubmit = true;
    var paramsData = [];
    this.listOfData.map((item, index) => {
      paramsData[index] = {
        companyServerId: this.id,
        userId: item.userId,
        userName: item.userName,
        serverRoleId: item.serverRoleId,
        serverRoleName: item.serverRoleName,
        serverRoleNo: item.serverRoleNo,
        receiveFlag: item.receiveFlag,
        id: item.id || null
      }
    })
    this.api.saveOrUpdateBatch(paramsData, this.id).subscribe(res => {
      this.message.success('保存成功！');
      this.canSubmit = true
      this.cancal();
    }, err => {
      this.canSubmit = false
    })
  }

}
