import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-refund-examine',
  templateUrl: './modal-refund-examine.component.html',
  styleUrls: ['./modal-refund-examine.component.less']
})
export class ModalRefundExamineComponent implements OnInit {
  @Input() params: any;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel() {
    this.modal.destroy();
  }
  ngOnInit() {
    console.log(this.params)
  }
  contractUrl: string;
  actualRefundTime = null;
  fileUrls = []; // 上传回单

  submitForm() {

    if (this.actualRefundTime === null) {
      this.message.error('请选择实际支付日期');
      return
    }
    if (this.fileUrls.length <= 0) {
      this.message.error('请上传回单');
      return
    }
    this.params.actualRefundTime = this.dataToStr(this.actualRefundTime);// 实际支付日期
    this.params.fileUrls = this.fileUrls;// 上传回单

    this.api.orderRefundAudit(this.params).subscribe((res) => {
      this.message.success('审核完成');
      this.modal.destroy(res);
    })

  }

  // 上传附件
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        attachmentUrls.push(item.response.data.fileUrl)
      })
      this.fileUrls = attachmentUrls;
    }
  }

  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }

}
