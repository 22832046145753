import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalCompanyComponent } from '../modal-company/modal-company.component';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { ModalRecordeditorComponent } from '../modal-recordeditor/modal-recordeditor.component';
import { Api } from '@service/Api';
import { DomSanitizer } from '@angular/platform-browser';

import { ModalAddFileComponent } from '../../component/modal-add-file/modal-add-file.component';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { ModalAddInvoiceComponent } from '../../component/modal-addInvoice/modal-addInvoice.component';
import { ModalOrderExamineComponent } from '../../component/modal-order-examine/modal-order-examine.component';
import { DrawerCompanyComponent } from '../../component/drawer-company/drawer-company.component';
import { DrawerCustomerGroupComponent } from '../../component/drawer-customer-group/drawer-customer-group.component';
// 续费
import { ModalAddRenewComponent } from '../../component/modal-add-renew/modal-add-renew.component';
import { DrawerEntpriseServerRenewComponent } from '../../component/drawer-entprise-server-renew/drawer-entprise-server-renew.component';

let cnm;
let apiTmp;

@Component({
  selector: 'drawer-user',
  templateUrl: './drawer-user.component.html',
  styleUrls: ['./drawer-user.component.less']
})
export class DrawerUserComponent implements OnInit {
  @Input() plateValue = '';
  @Input() value = '';
  @Input() jobId: any;
  @Input() index = 0;
  @Input() type: any;
  @Input() priceStatus: any;

  addressForm: FormGroup;  // 添加收货地址表单
  form: FormGroup;
  formRew: FormGroup;
  customerInfo;        // 客户信息
  companyDetail;       // 公司详细信息
  companyList = [];    // 公司列表
  activeList = [0];    // 已激活菜单
  provinceList: [];    // 省份列表
  cityList: [];        // 城市列表
  showCompany = false; // 展示公司详情
  labelList = [];      // 标签列表
  userLabelList = [];  // 客户标签列表
  userParentLabelList = []; //选中多选的id
  loading = false;     // loading状态
  addressList = [];    // 客户收货地址
  orderList = [];      // 关联订单列表
  customerRecordList = []; // 跟进记录列表
  customerPayRecordList = []; // 客户交付记录列表
  parameters = []; //客户阶段数据集合
  qrcodeUrl = '';      // 绑定微信二维码
  fileList = []; // 文件列表
  uploadParams = {};
  uploadUrl: string;
  companyData = []; //公司列表
  relevanceRoleid: number;
  nzOptions = [];
  staffList: [];//关联员工列表

  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public router: Router,
    public api: Api,
    private sanitizer: DomSanitizer,
    private drawerService: NzDrawerService,
  ) {
    apiTmp = this.api;
    this.uploadUrl = this.api.bsCompanyDiscernLicense();

    this.addressForm = fb.group({
      province: [null, Validators.required],
      areaId: [null, Validators.required],
      customerAddress: [null, Validators.required],
      userName: [null, Validators.required],
      userPhone: [null, Validators.required],
    });

    this.form = fb.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      companyId: [null, Validators.required],
      remark: [null,],
      UserSelectedValue: [null]
    });
    this.formRew = fb.group({
      sendId: [null, Validators.required],
    });
  }

  //选择跟进人
  relevancyStaffShow(relevancyStaffTemplate) {
    this.form.reset()
    this.getCompanyData();
    const modalService = this.modalService.create({
      nzTitle: '选择负责人',
      nzContent: relevancyStaffTemplate,
      nzStyle: { top: '80px' },
      nzWidth: 900,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.updateJobUserId()
    });
    modalService.afterClose.subscribe(res => {
      this.getCustomerDetail();
    })
  }

  UserSelectedChange(e) {
    //console.log(e)
  }

  updateJobUserId() {
    let data = {
      customerId: this.value,
      userId: this.form.value.UserSelectedValue,
    }
    this.api.updateCustomerUserid(data).subscribe(res => {
      this.message.success('更换负责人成功~');
      this.drawerRef.close();

    });
  }

  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    console.log(e)
    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getLabelList();
        break;
      case 2:
        this.getOrderListById();
        break;
      case 3:
        this.getCustomerRecord();
        break;
      case 4:
        this.getCompanyList();
        break;
      case 5:
        // 续费管理 只有我的客户能看见
        this.customerServerList();
        // this.getCustomerPayRecordByCusId();
        break;
      case 6:
        this.ArchivesFileList();
        // this.getCustomerPayRecordByCusId();
        break;
      case 7:
        this.getProvince(1);
        this.getCustomerAddress();
        break;
      default:
        break;
    }
  }

  bindWechat() {
    if (this.qrcodeUrl.length > 0) {
      return
    }
    this.api.getqrCodeUrl({ id: this.value, type: 0 }).subscribe(res => {
      this.qrcodeUrl = res['data'].fileUrl;
    })
  }


  // #region tab0: 客户基本信息
  // 获取客户详情
  getCustomerDetail() {
    if (this.type == 'customer') {
      this.type = 0
    }
    this.api.getCustomerDetail({ id: this.value, type: 0 }).subscribe(res => {
      this.customerInfo = res['data'];
      this.initCustomerParameters();
    })
  }

  // 编辑客户信息
  toCustomer() {
    this.drawerRef.close();
    this.router.navigate(['/forms/add-customer'], { queryParams: { id: this.value } });
  }

  // #endregion

  // #region tab1: 标签
  // 查询标签列表
  getLabelList() {
    // 客户标签 tagType = 0
    var params = {
      tagType: 0
    }
    this.api.getLabelList(params).subscribe(res => {
      this.labelList = res['data'];
      this.getUserLabel();
    })
  }

  // 获取客户标签
  getUserLabel() {
    this.api.getUserLabel({ customerId: this.value }).subscribe(res => {
      this.userLabelList = res['data'];
      this.setTag()
    })
  }

  setTag() {
    const len = this.labelList.length;
    for (let i = 0; i < len; i++) {

      const tagList = this.labelList[i]['sysCustomerTagConfigVOS'];

      if (!tagList) {
        continue
      }


      for (let j = 0; j < tagList.length; j++) {

        // 如果标签存在于客户标签数组，添加标记
        // userLabelList 选中的id
        console.log(this.userLabelList)
        if (this.userLabelList.includes(tagList[j].id)) {
          // 多选
          if (!this.labelList[i].radio) {
            tagList[j].checked = true;
            // 多选
            this.userParentLabelList.push(tagList[j].id);
          } else {
            // 单选
            this.labelList[i].checkId = tagList[j].id;
          }
        } else {
          tagList[j].checked = false;
        }
      }
    }

  }

  SendTeplateData;
  mapOfCheckedId: { [key: string]: boolean } = {};

  radioValue = null;
  // 标记客户标签 多选
  checkChange(checked, id) {
    if (checked) {
      this.userLabelList.push(id);
      this.userParentLabelList.push(id);
    } else {
      this.userLabelList = this.userLabelList.filter(t => t !== id);
      this.userParentLabelList = this.userParentLabelList.filter(t => t !== id);
    }
    console.log(this.userParentLabelList)
  }
  // checkChangeRadio 单选
  checkChangeRadio(checked) {
    console.log(checked)
  }
  saveLabel() {
    // 先保存多选id,再筛选出单选选中id
    const userLabelList = JSON.parse(JSON.stringify(this.userParentLabelList));
    for (let j = 0; j < this.labelList.length; j++) {
      if (this.labelList[j].radio && this.labelList[j].checkId) {
        userLabelList.push(this.labelList[j].checkId);
      }
    }
    const parmas = {
      customerId: this.value,
      labelId: userLabelList

    }
    this.api.addUserLabel(parmas).subscribe(res => {
      this.message.success('添加成功！');
    })
  }

  // #endregion

  // #region tab2: 关联订单列表
  getOrderListById() {
    this.api.getOrderListById({ customerId: this.value }).subscribe(res => {
      this.orderList = res['data'];
    })
  }

  // #endregion

  // #region tab3: 跟进记录列表

  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMore = true;

  delImg(array) {
    array
    console.log(array);
  }

  getCustomerRecord() {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        bindId: this.value,
        logType: 0,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      // var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
      // res['data'].records.map(item => {
      //   item['arrayImage'] = item.content.match(reg);
      // });
      this.customerRecordList = [...this.customerRecordList, ...res['data'].records];
      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    });
  }
  preview(data) {
    window.open(data);
  }
  bigImg: any = false;
  imgList: any;
  toBigImg(content, img) {
    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzMaskClosable: false,
      nzFooter: null
    });
    this.bigImg = true;
    this.imgList = img;
  }

  hiddenImg() {
    this.bigImg = false;
  }
  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getCustomerRecord();
  }

  openEditor() {

    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'customer',
        bindId: this.customerInfo.id,
        bindName: this.customerInfo.name,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.customerRecordList = [];
          this.getCustomerRecord();
        })
      }
    });
  }

  // openRecord() {
  //   const modal = this.modalService.create({
  //     nzTitle: '添加交付记录',
  //     nzContent: ModalRecordeditorComponent,
  //     nzComponentParams: {
  //       customerId: this.value,
  //     },
  //     nzStyle: {top: '40px'},
  //     nzWidth: 800,
  //     nzMaskClosable: false,
  //     nzFooter: null
  //   });
  //   modal.afterClose.subscribe((data) => {
  //     this.customerPayRecordList = [];
  //     this.getCustomerPayRecordByCusId();
  //   });
  // }

  // #endregion

  // #region tab4: 公司
  // 获取公司列表
  getCompanyList() {
    this.api.getCompanyList({ id: this.value }).subscribe(res => {
      this.companyList = res['data'];
    })
  }

  // 获取自己的填写了交付记录
  getCustomerPayRecordByCusId() {
    this.api.getMyPayRecord({ id: this.value }).subscribe(res => {
      this.customerPayRecordList = res['data'];
    })
  }

  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.value,
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => this.getCompanyList());
  }
  // 打开公司信息详情
  openCompany(id, index, jobId) {
    const customerDrawer = this.drawerService.create<DrawerCompanyComponent, { value: string, index: number, jobId: any, plateValue: string }, string>({
      nzWidth: 1100,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerCompanyComponent,
      nzContentParams: {
        value: id,
        index,
        jobId,
        plateValue: 'my-customer'
      }
    });
    customerDrawer.afterClose.subscribe((data) => {
      this.getCompanyList();
    });
  };


  // 上传营业执照
  UploadLicense() {
    this.getCompanyList();
  }

  // 删除公司
  deleteCompany(id) {
    console.log(id)
  }

  // #endregion

  // #region tab5: 收货地址

  // 获取省市列表
  getProvince(level, code?) {
    const params = {
      level: level,
    }
    code ? params['parentCode'] = code : null;
    this.api.getCityList(params).subscribe((res) => {
      if (level == 1) {
        this.provinceList = res['data'];
      } else {
        this.cityList = res['data'];
      }
    })
  }

  // 查询城市列表
  getCityList() {
    const code = this.addressForm.controls.province.value;
    this.addressForm.get('areaId').setValue(null);
    this.getProvince(2, code);
  }

  // 保存收货地址
  saveAddress(value) {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.addressForm.controls).map((key) => {
      this.addressForm.controls[key].markAsDirty();
      this.addressForm.controls[key].updateValueAndValidity();
    });

    if (!this.addressForm.valid) {
      this.loading = false;
      return false;
    }
    value['customerId'] = this.value;
    this.api.addCustomerAddress(value).subscribe(res => {
      this.loading = false;
      this.getCustomerAddress();
      this.addressForm.reset();
      this.message.success('收货地址添加成功！');
    }, err => {
      this.loading = false;
    })
  }

  delete(id) {
    this.api.deleteCustomerAddress({ id: id }).subscribe(res => {
      this.getCustomerAddress();
    })
  }

  // 获取客户收货地址
  getCustomerAddress() {
    this.api.getCustomerAddress({ customerId: this.value }).subscribe(res => {
      this.addressList = res['data'];
      const i = this.addressList.findIndex(item => {
        return item.default == true;
      });
      let defaultAddress = this.addressList.splice(i, 1);
      this.addressList = [...defaultAddress, ...this.addressList];
    })
  }

  // 设置默认地址
  setDefaultAddress(id) {
    this.api.setDefaultAddress({ id: id }).subscribe(res => {
      this.getCustomerAddress();
      this.message.success('默认地址设置成功！');
    })
  }

  // #endregion

  //客户阶段初始化查询
  initCustomerParameters() {
    const params = {
      parentCode: 'CUSTOMER_STAGE',
    }
    let classname = true
    this.api.getSysParameters(params).subscribe(res => {
      this.parameters = res['data'].map(res => {
        if (this.customerInfo.customerCateCode == res.code) {
          res['class'] = true;
          classname = false;
        } else {
          res['class'] = classname;
        }
        return res;
      });
    });
  }

  changeParameter(index) {
    this.parameters.map((item, i) => item.class = i <= index);
    this.api.updateCustomer({
      'customerCateCode': this.parameters[index].code,
      'id': this.customerInfo.id,
    }).subscribe(res => {
      this.getCustomerDetail();
    });
  }

  /**
   * 删除公司客户关联信息
   * @param customerId
   * @param companyId
   */
  deleteCompanyCustomer(customerId, companyId) {
    const data = {
      customerId: this.value,
      companyId: companyId,
    }
    this.api.deleteCompanyCustomer(data).subscribe(res => {
      this.getCompanyList();
    }, res => {
      this.message.error('删除失败');
    })
  }
  investList = [];
  //关联公司
  relevancyComponentModal(id, content) {
    this.getCompanyInvest(id);
    const modalService = this.modalService.create({
      nzTitle: '关联公司',
      nzContent: content,
      nzStyle: { top: '80px' },
      nzWidth: 900,
      nzMaskClosable: false,
      nzFooter: null,
    });
  }
  //获取股权穿透信息
  getCompanyInvest(id) {
    this.api.getCompanyInvestLists(id).subscribe(res => {
      this.investList = res['data']
    });
  }

  //选择跟进人
  addJobJoint(content) {
    this.form.reset()
    this.getCompanyData();
    const modalService = this.modalService.create({
      nzTitle: '选择跟进人',
      nzContent: content,
      nzStyle: { top: '80px' },
      nzWidth: 900,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addJobJoints()
    });
    // modalService.afterClose.subscribe(res => {
    //   this.getCustomerDetail();
    // })
  }

  //获取公司信息
  getCompanyData() {
    let data = {
      type: 0,
    };
    this.api.getCompanyOrDept(data).subscribe(res => {
      this.companyData = res['data'];
    });
  }

  addJobJoints() {
    let data = {
      areaCode: this.areaCode,
      customerId: this.value,
      userId: this.form.value.UserSelectedValue,
    }
    this.api.addJobJoint(data).subscribe(res => {
      this.message.success('添加成功');
      this.drawerRef.close();

    });
  }

  loadDeptData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      let data = {
        type: 1,
      }
      if (index < 0) {
        if (cnm == null) {
          return;
        }
        data['companyId'] = cnm;
      } else {
        data['companyId'] = node.id;
      }
      apiTmp.getDept(data).subscribe(res => {
        node.children = res['data'];
        resolve();
      });
    });
  }

  //获取部门信息
  getDeptData() {
    cnm = this.form.controls.companyId.value;
    let data = {
      type: 1,
    }
    this.form.get('remark').setValue(null);
    this.api.getAuthorityDept(data).subscribe(res => {
      this.nzOptions = res['data'];
      this.areaCode = this.nzOptions[0].areaCode;
    });
  }
  validate(_index: number): boolean {
    return true;
  }


  depid;
  areaCode;
  onDeptChanges(values): void {
    if (values == null) {
      return;
    }
    if (values.length > 1) {
      this.depid = values.slice(-1);
      let data = {
        deptId: this.depid
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    } else {
      let data = {
        deptId: values
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    }
    console.log(this.depid);
  }

  // 附件信息
  filePageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  }
  // 翻页
  toPage(page) {
    this.filePageCtrl.nzPageIndex = page;
    this.ArchivesFileList();
  }
  archivesType = null;
  companyId = null;
  ArchivesFileList() {
    const pamares = {
      current: this.filePageCtrl.nzPageIndex,
      object: {
        customerId: this.value,
        archivesType: this.archivesType,
        companyId: this.companyId,
      },
      size: this.filePageCtrl.nzPageSize
    }
    this.api.ArchivesFileLists(pamares).subscribe(res => {
      const arr = res['data'].records;
      (arr || []).map(item => {
        let last = item.archivesUrl.lastIndexOf('.');
        let ext = item.archivesUrl.substr(last + 1);
        item.isLast = ext == 'png' || ext == 'jpg' || ext == 'jpeg' ? false : true;
      })
      this.fileList = arr;
      this.filePageCtrl.total = res['data'].total;
    })
  }

  //上传附件
  fileShow(data?) {
    console.log(data)
    const modal = this.modalService.create({
      nzTitle: '上传附件',
      nzContent: ModalAddFileComponent,
      nzComponentParams: {
        customerId: data,
      },
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterOpen.subscribe((data) => {
      console.log('[afterOpen] emitted!')
    });
    modal.afterClose.subscribe(() => {
      this.ArchivesFileList();
    })
  }

  ngOnInit() {
    this.getCustomerDetail();
    this.uploadParams['customerId'] = this.value;
    this.changeTabs(this.index);
    this.getDeptData();
  }

  //富文本图片 点击查看
  showImages(event) {
    if (event.target.nodeName == 'IMG') {
      window.open(event.target.currentSrc)
    }
  }

  // 打开客户信息详情
  open(id, type, isRelevance, menu, isMyPay, single, payStatus, priceStatus) {
    console.log(id, type, isRelevance, menu, isMyPay, single, payStatus, priceStatus)
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerUserComponent, { value: string, menuType: string, isRelevance: boolean, isMyPay: boolean, single: string, payStatus: string, priceStatus: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        menuType: menu,
        isRelevance: isRelevance,
        isMyPay: isMyPay,
        single: single,
        payStatus: payStatus,
        priceStatus: priceStatus,
      }
    });
  }

  // 申请开票
  apllyInvoice(id, phoneNumber, customerName, orderAccount, customerId?) {
    const modal = this.modalService.create({
      nzTitle: '申请开票',
      nzContent: ModalAddInvoiceComponent,
      nzComponentParams: {
        orderId: id,
        customerPhone: phoneNumber,
        orderAccount: orderAccount,
        customerId: customerId,
        customerName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => {
      this.getOrderListById();
    });
  }

  //发起订单办结审核
  orderFinish(id) {
    const params = {
      id: id,
      type: 'bj'
    };
    const modal = this.modalService.create({
      nzTitle: '订单办结审核流程',
      nzContent: ModalOrderExamineComponent,
      nzComponentParams: {
        params: params,
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskClosable: false,
      nzFooter: null,
    });
    modal.afterClose.subscribe(() => {
      this.getOrderListById();
    })
  }
  // 打开客户信息详情
  openGroup(data) {
    const customerDrawer = this.drawerService.create<DrawerCustomerGroupComponent, { dataInfo: object }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerCustomerGroupComponent,
      nzContentParams: {
        dataInfo: data
      }
    });
  }
  // 续费管理
  orderRewList: [];
  customerServerList() {
    this.api.customerServerList(this.value).subscribe(res => {
      this.orderRewList = res['data'];
    })
  }

  // 新建c续费
  toRenewOrder(data): void {
    const modal = this.modalService.create({
      nzTitle: '续费',
      nzContent: ModalAddRenewComponent,
      nzStyle: { top: '40px' },
      nzWidth: 1200,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzComponentParams: {
        params: data,
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe(() => {
      this.customerServerList();
    });
  }

  //续费失败
  toConfirm(id) {
    // this.api.renewOrderFailStatus(id).subscribe(res => {
    //   this.getMyOrderList();
    // })
    this.router.navigate(["/forms/add-customer-loss"], { queryParams: { id: id } });
    this.drawerRef.close();
  }

  // 修改财税顾问
  changeCsgw(content, data) {
    this.getUserList();
    this.modalService.create({
      nzTitle: '修改财税顾问',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 500,
      nzMaskClosable: false,
      nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.updateUserCsgw(data['id'])
    });
  }
  // 修改财税顾问
  updateUserCsgw(id) {
    this.formRew.controls.sendId.markAsDirty();
    this.formRew.controls.sendId.updateValueAndValidity();
    if (!this.formRew.valid) {
      return false;
    }
    // let params = this.form.value;
    let params = { sendId: this.formRew.get('sendId').value, id: id };
    // params.ids = list;
    this.api.updateUserCsgw(params).subscribe((res) => {
      this.message.success('财税顾问修改成功！');
      this.customerServerList();
    })
  }

  // 打开客户详情
  opend(data, type) {
    const modal = this.drawerService.create<DrawerEntpriseServerRenewComponent, {
      value: string,
      companyServerNo: string,
      sendName: string,
      chargeName: string,
      companyServerPeriod: string,
      companyServerStatus: string,
      typeFlag: boolean,
      type: any,
    }, string>({
      nzWidth: 890,
      nzWrapClassName: '服务详情',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerEntpriseServerRenewComponent,
      nzContentParams: {
        value: data.id,
        companyServerNo: data.companyServerNo,
        sendName: data.sendName,
        chargeName: data.chargeName,
        companyServerPeriod: data.companyServerPeriod,
        companyServerStatus: data.companyServerStatus,
        typeFlag: true,
        type: type,
      }
    });
    modal.afterClose.subscribe(() => {
      this.customerServerList();
    })
  }
  userList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.userList = res['data'];
    })
  }
}
