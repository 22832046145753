import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-prepayment',
  templateUrl: './drawer-prepayment.component.html',
  styleUrls: ['./drawer-prepayment.component.less']
})
export class DrawerPrepaymentComponent implements OnInit {

  @Input() value = '';
  @Input() index = '';
  prepaymentInfo: any; // 客户信息
  prepaymentLogs: any = [];
  // index: any;
  constructor(
    public api: Api,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) { }

  // 切换菜单
  changeTabs(e) {
    switch (e) {
      // case 0:
      //   // this.advancePaymentList();
      //   this.getPrepaymentDetail();
      //   break;
      case 1:
        // this.advancePaymentList();
        this.getOrderPrepaymentListLogs();
        break;
      default:
        break;
    }

  }

  ngOnInit() {
    this.getPrepaymentDetail();
    if (this.index) {
      this.changeTabs(this.index)
    }
  }
  orderList = [];
  advancePaymentList() {
    this.api.advancePaymentList(this.value).subscribe(res => {
      this.orderList = res['data'];
    })
  }
  getPrepaymentDetail() {
    this.api.orderlistPrepaymentDetail(this.value).subscribe(res => {
      this.prepaymentInfo = res['data'];
    })
  }

  getOrderPrepaymentListLogs() {
    this.api.orderPrepaymentListLogs({
      advanceOrderId: this.value
    }).subscribe(res => {
      this.prepaymentLogs = res['data'];
    })
  }

  ewmImg = null;
  imgShow(src, ewmShowContent) {
    this.ewmImg = src;
    const modal = this.modalService.create({
      nzWidth: 'max-content',
      nzStyle: { top: '40px' },
      nzContent: ewmShowContent,
      nzComponentParams: {
        content: src,
      },
      nzFooter: null,
      nzTitle: null,
    });
  }
  // 打开订单信息详情
  openOrder(id) {
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerOrderComponent, { value: string, type: any }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        type: 0,
      }
    });
  }


}
