import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-verify-different-worderoder',
  templateUrl: './modal-verify-different-worderoder.component.html',
  styleUrls: ['./modal-verify-different-worderoder.component.less']
})
export class ModalVerifyDifferentWorderoderComponent implements OnInit {
  params: any;
  verifyMark = null;//审核备注
  actualPaymentDate = null;
  contractUrl:string;
  uploadReceiptUrls = []; // 上传回单
  startDate = null;

  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) { 
    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel(){
    this.modal.destroy();
  }

  submitForm(dy){
    if(!this.params.agree){
      if(!this.verifyMark){
        this.message.error('请输入审核备注');
        return
      }
    } else {
      if(this.params.code == 'CASHIER_PAYMENT'){
        if(this.startDate === null){
          this.message.error('请选择日期');
          return
        }
        if(this.uploadReceiptUrls.length <= 0 && this.params.type == 'sdjs'  ){
          this.message.error('请上传回单');
          return
        }
        this.actualPaymentDate = this.dataToStr(this.startDate);// 实际支付日期
        this.uploadReceiptUrls = this.uploadReceiptUrls;// 上传回单
      }
    }
    let params = {
      checkStatus:this.params.agree,
      checkDesc:this.verifyMark,
      orderTaskSettId:this.params.userApplyId,
      checkType:this.params.code,
      actualPaymentDate:this.actualPaymentDate,
      receiptAttachmentList:this.uploadReceiptUrls,
    }
    this.api.bsOrderTaskSettWorkOrderCheck(params).subscribe(res => {
      this.modal.destroy();
    });
    if(dy){
      // 高级会计审核 增加打印
      if(this.params.code == 'SENIOR_ACCOUNTING_AUDIT' && this.params.agree == 1 && this.params.type == "sdjs"){
        window.open("/forms/add-print-fling/?id=" + this.params.userApplyId );
      }
    }
  }

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if(info.file.status  == 'done' || info.file.status  == 'removed' && info.file.response.code == 200){
      (info.fileList || []).map((item, index) => {
        var params = {"attachmentUrl":item.response.data.fileUrl};
        params.attachmentUrl = item.response.data.fileUrl
        attachmentUrls.push(params)
      })
      this.uploadReceiptUrls = attachmentUrls;
    }
  }

  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }

  ngOnInit() {
    
  }

}
