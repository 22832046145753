import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { UploadFile, UploadFilter } from 'ng-zorro-antd/upload';
@Component({
  selector: 'app-modal-addInvoice',
  templateUrl: './modal-addInvoice.component.html',
  styleUrls: ['./modal-addInvoice.component.less']
})
export class ModalAddInvoiceComponent implements OnInit {
  @Input() orderId: string;
  @Input() customerPhone: string;
  @Input() customerName: string;
  @Input() orderAccount: string;
  @Input() customerId: any;

  form: FormGroup;
  loading = false;
  listOfData = []; // 支付记录列表
  expressage = false; // 寄送
  dedicated = false; // 专票

  contractUrl: string;

  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef
  ) {

    this.form = fb.group({
      invoiceType: [null, Validators.required],
      invoiceTaxNo: [null, Validators.required],
      invoiceContent: [null, Validators.required],
      invoiceAmount: [null, Validators.required],
      vatTelphone: [null],
      consigneeAddress: [null],
      mailing: [null, Validators.required],
      hasAuthorization: [null, Validators.required],
      consigneeRealname: [null],
      consigneeTelphone: [null],
      vatCompanyAddress: [null],
      vatBankName: [null],
      vatBankAccount: [null],
      remark: [null],
      payRecordList: [null],
      //orderId: [this.orderId, Validators.required],
      customerPhone: [null],
      customerName: [null],
      sysOrderInvoiceTrustAttachmentList: [null],
      customerInvoiceId: [null],
    });

    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel() {
    this.modal.destroy();
  }

  // 获取支付记录列表数据
  getPayRecordlistByOrderId() {
    this.api.getPayRecordlistByOrderId({ orderId: this.orderId, requestWay: 1 }).subscribe(res => {
      this.listOfData = res['data'];
    });
  }
  selectData = [];//选择列表
  selectUserIdList = [];
  selectPayRecordList = [];
  NoteListTemplateShow(NoteListTemplate) {
    this.modalService.create({
      nzTitle: '选择支付记录',
      nzContent: NoteListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      //nzFooter:null,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addParentNoteTask()
    });
  }
  addParentNoteTask() {
    this.selectUserIdList = [];
    this.selectPayRecordList = [];
    for (let value of this.selectData) {
      this.selectUserIdList.push(value.payAmount);
      this.selectPayRecordList.push(value.id);
    }
    if (this.selectUserIdList.length == 0) {
      this.message.error('请选择选择支付记录')
      return
    }
    this.invoiceAmount(this.selectUserIdList)
  }

  //选择
  mapOfCheckedIdTwo: { [key: string]: boolean } = {};
  refreshStatus(e, id): void {
    if (e) {
      let value = this.listOfData.find(i => i.id == id)
      this.selectData.push(value);
    } else {
      let i = this.selectData.findIndex(item => item.id == id);
      this.selectData.splice(i, 1)
    }
  }

  expressageType(event): void {
    if (event == 1) {
      this.expressage = true;
    } else {
      this.expressage = false;
    }
  }
  invoiceType(event): void {
    if (event == 0) {
      this.dedicated = true;
    } else {
      this.dedicated = false;
    }
  }
  disableds = true;
  invoiceTotal: number;
  //总额
  invoiceAmount(arr) {
    let total = 0;
    for (var i = arr.length - 1; i >= 0; i--) {
      total += arr[i];
    }
    //return total;
    this.form.get('invoiceAmount').setValue(total);
    this.invoiceTotal = total;
    this.disableds = false
  }

  // 保存申请
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }

    let params = this.form.getRawValue();
    params.hasAuthorization = params.hasAuthorization == 1 ? true : false
    params.orderId = this.orderId;
    params.payRecordList = this.selectPayRecordList;

    // if(params.invoiceAmount > this.invoiceTotal){
    //   this.message.error('开票金额不可大于实际支付金额')
    //   this.loading = false;
    //   return
    // }
    // if (params.invoiceAmount > this.orderAccount) {
    //   this.message.error('开票金额不可大于订单金额！')
    //   this.loading = false;
    //   return
    // }
    //授权委托书
    if (this.fileOBJ.length > 0) {
      params.sysOrderInvoiceTrustAttachmentList = this.fileOBJ
    } else {
      params.sysOrderInvoiceTrustAttachmentList = null
    }

    this.api.addOrderInvoice(params).subscribe(res => {
      this.message.success('申请开票成功！');
      this.modal.destroy();
    }, err => {
      this.loading = false;
    })
  }
  isHasAuthorization = null;
  findhasAuthorizationId(e) {
    console.log(e)
    this.isHasAuthorization = e
  }
  ngOnInit() {
    this.getPayRecordlistByOrderId();
    this.form.get('customerPhone').setValue(this.customerPhone);
    this.form.get('customerName').setValue(this.customerName);
    this.api.getDefaultByCustomerId(this.customerId).subscribe(res => {
      var data = res['data'];
      if (!data) {
        return false;
      }
      this.form.get('consigneeRealname').setValue(data.consigneeRealname);
      this.form.get('consigneeTelphone').setValue(data.consigneeTelphone);
      this.form.get('consigneeAddress').setValue(data.consigneeAddress);
      this.form.get('invoiceContent').setValue(data.invoiceContent);
      this.form.get('invoiceTaxNo').setValue(data.invoiceTaxNo);
      this.form.get('vatCompanyAddress').setValue(data.vatCompanyAddress);
      this.form.get('vatBankAccount').setValue(data.vatBankAccount);
      this.form.get('customerInvoiceId').setValue(data.id);
    })
  }
  fileOBJ = [];
  // 上传授权委托书
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl
        }
        attachmentUrls.push(p)
      })
      this.fileOBJ = attachmentUrls
    }
  }

  // 发票信息选择 start
  isVisibleSk = false
  invoiceCompanyList = [];
  orderMapOfCheckedId: { [key: string]: boolean } = {};
  selectOrderData = [];
  orderRefreshStatus(e, id): void {
    for (let i in this.orderMapOfCheckedId) {
      this.orderMapOfCheckedId[i] = false;
    }
    this.orderMapOfCheckedId[id] = true;
    this.selectOrderData = this.invoiceCompanyList.filter(item => item.id == id);
  }
  addParentOrder() {
    if (this.selectOrderData.length > 0) {
      this.form.get('consigneeRealname').setValue(this.selectOrderData[0].consigneeRealname);
      this.form.get('consigneeTelphone').setValue(this.selectOrderData[0].consigneeTelphone);
      this.form.get('consigneeAddress').setValue(this.selectOrderData[0].consigneeAddress);
      this.form.get('invoiceContent').setValue(this.selectOrderData[0].invoiceContent);
      this.form.get('invoiceTaxNo').setValue(this.selectOrderData[0].invoiceTaxNo);
      this.form.get('vatCompanyAddress').setValue(this.selectOrderData[0].vatCompanyAddress);
      this.form.get('vatBankAccount').setValue(this.selectOrderData[0].vatBankAccount);
      this.form.get('customerInvoiceId').setValue(this.selectOrderData[0].id);
      this.handleCancelSk();
    } else {
      this.message.error('请选择数据！');
    }

  }
  showModalSk(): void {
    this.api.getByCustomerId(this.customerId).subscribe(res => {
      this.invoiceCompanyList = res['data'];
      this.isVisibleSk = true;
    })
  }
  handleCancelSk(): void {
    this.isVisibleSk = false;
  }
  delSk() {
    this.form.get('consigneeRealname').setValue(null);
    this.form.get('consigneeTelphone').setValue(null);
    this.form.get('consigneeAddress').setValue(null);
    this.form.get('invoiceContent').setValue(null);
    this.form.get('invoiceTaxNo').setValue(null);
    this.form.get('vatCompanyAddress').setValue(null);
    this.form.get('vatBankAccount').setValue(null);
    this.form.get('customerInvoiceId').setValue(null);
  }
  setDefault(id) {
    this.api.setDefault(id, this.customerId).subscribe(res => {
      this.message.error('设置成功！');
      this.showModalSk();
    })
  }
  delete(id) {
    this.api.bsCustomerInvoiceDel(id).subscribe(res => {
      this.message.error('操作成功！');
      this.showModalSk();
    })
  }
  isVisible = false
  // 发票信息选择 start
  showModal(type): void {
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
}
