import { Injectable } from '@angular/core';

declare let hex_md5;

/**
 * @description Utils类存放和业务无关的公共方法
 */
@Injectable()
export class Utils {

  /**
   * @description 将日期转换为指定格式的日期字符串
   * @param {date} date 需要转换的日期, 如: new Date()
   * @param {string} fmt 需要转换的格式，如：yyyy/MM/dd
   * @return {string} 返回转换格式后的日期字符串
   */
  static dateFormat(date, fmt: string): string {
    date = new Date(date);
    const obj = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(),      // 日
      'h+': date.getHours(),     // 小时
      'm+': date.getMinutes(),   // 分
      's+': date.getSeconds(),   // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      // RegExp.$1指的是与正则表达式匹配的第一个(以括号为标志，如:/(y+)/)子匹配字符串
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    for (const k in obj) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
          ? (obj[k])
          : (('00' + obj[k]).substr(('' + obj[k]).length)));
      }
    }
    return fmt;
  }

    /**
   * @description 将日期转换为指定格式的日期字符串
   * @param {date} date 需要转换的日期, 如: new Date()
   * @param {string} fmt 需要转换的格式，如：yyyy/MM/dd
   * @return {string} 返回转换格式后的日期字符串
   */
   dateFormats(date, fmt: string): string {
    date = new Date(date);
    const obj = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(),      // 日
      'h+': date.getHours(),     // 小时
      'm+': date.getMinutes(),   // 分
      's+': date.getSeconds(),   // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      // RegExp.$1指的是与正则表达式匹配的第一个(以括号为标志，如:/(y+)/)子匹配字符串
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    for (const k in obj) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
          ? (obj[k])
          : (('00' + obj[k]).substr(('' + obj[k]).length)));
      }
    }
    return fmt;
  }

}
