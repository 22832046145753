import { Component, OnInit } from '@angular/core';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-selectproduct-single',
  templateUrl: './modal-selectproduct-single.component.html',
  styleUrls: ['./modal-selectproduct-single.component.less']
})
export class ModalSelectproductSingleComponent implements OnInit {

  productList;         // 产品列表
  categoryFirst = [];  // 一级类别
  categorySecond = []; // 二级类别
  levelOne: number;
  levelTwo: number;


  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {
  }


  getProductLevel(level, parentId) {
    const params = {
      object: {
        parentId: parentId
      },
      size: 100,
      current: 0
    }
    //getListFirstFloorList  兼容简道云，初始化调用一次
    this.api.getListFirstFloorList(params).subscribe((res) => {
      if (level == 0) {
        this.categoryFirst = res['data']['records'];
      } else {
        this.categorySecond = res['data']['records'];
      }
    })
  }

  getcategorySecond(id) {
    this.levelTwo = -1;
    this.getProductLevelTwo(1, id);
  }
  //选择一级，查二级
  getProductLevelTwo(level, parentId) {
    const params = {
      object: {
        parentId: parentId,
        parentCode: 'CPFL'
      },
      size: 100,
      current: 0
    }
    this.api.getDictionaryList(params).subscribe((res) => {
      if (level == 0) {
        this.categoryFirst = res['data']['records'];
      } else {
        this.categorySecond = res['data']['records'];
      }
    })
  }
  //


  sreach(value) {
    this.sreachName = value;
    this.getProductList(this.pageCtrl.cateId);
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getProductList(this.pageCtrl.cateId);
  }

  pageCtrl = { // 分页控制
    current: 1,
    size: 5,
    cateId: 0,
    cateName: ''
  };
  total;
  sreachName: any = '';
  couldInput: any = true;

  // 获取产品列表
  getProductList(id) {
    const params = {
      current: this.pageCtrl.current,
      size: 5,
      cateId: id,
      cateName: this.sreachName
    }
    this.pageCtrl = { ...params };
    this.api.getFollowUpByCategory(params).subscribe(res => {
      this.total = res['data'].total;
      if (this.total !== 0) {
        this.couldInput = false;
      } else {
        this.couldInput = true;
      }
      this.productList = res['data'].records;

      // 获取产品属性
      this.productList.map((item, index) => {

        const productId = item.id; // 产品id
        const ids = []; // 产品所选属性组合
      })
    })
  }

  changePrice(event, id, attr) {
    // 当前属性对应的产品
    const i = this.productList.findIndex(item => {
      return item.id == id;
    });

    let pd = this.productList[i];

    let Ids = []; // 已选属性id列表
    pd.propertyList = []; // 已选属性列表

    pd.expand.map((item, index) => {
      if (item['sysBaseAreaPropertyList'].length > 0) {
        Ids.push(item.checked);
        pd.propertyList.push(item['sysBaseAreaPropertyList'].find(v => v.id == item.checked));
        console.log(index);
        if (pd.propertyList[index]) {
          //保留父层的计算方式
          pd.propertyList['arithmetic'] = item['arithmetic'];
        }
      }
    })
    //计算所有
    let count = pd.price;
    //计算加法集合
    pd.propertyList.filter(v => {
      if (v)
        return v.arithmetic == 0
    }).forEach(e => {
      count = count + e.price;
    });
    //计算乘法集合
    pd.propertyList.filter(v => {
      if (v)
        return v.arithmetic == 1
    }).forEach(e => {
      count = count * e.price;
    });
    pd['realPrice'] = count;
  }


  cancel() {
    this.modal.destroy();
  }

  submit() {
    // let checkedList = []; // 已选产品
    // this.productList
    //   .filter(item => this.mapOfCheckedId[item.id])
    //   .map(v => {
    //     v['t'] = this.categoryFirst.find(i => i.id == this.levelOne).name +
    //       '/' +
    //       this.categorySecond.find(i => i.id == this.levelTwo).name;
    //     if (!v['realPrice']) {
    //       v['realPrice'] = v['price'];
    //     }
    //     v['amount'] = 1;
    //     checkedList.push(v)
    //   });

    // if (this.signTeplateData.length === 0) {
    //   this.message.warning('请选择产品！');
    //   return
    // }
    // ;

    this.modal.destroy(this.signTeplateData);
  }



  ngOnInit() {
    const params = {
      object: {
        code: 'CPFL'
      },
      size: 100,
      current: 0
    }
    this.api.getDictionaryList(params).subscribe((res) => {
      this.getProductLevel(0, res['data']['records'][0].id);
    })

  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  signTeplateData;
  selectedCategory(e, id): void {
    // const sttings = cateIds.split(',');
    // this.levelOne = Number(sttings[0]);
    // this.getcategorySecond(sttings[0]);
    // this.levelTwo = Number(sttings[1]);
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.signTeplateData = this.productList.filter(item => item.id == id);
    // console.log(this.signTeplateData)
  }


  /*  multipleChoice(id, cateIds) {
  
      let index = this.mapOfCheckedId.indexOf(id);
      if (index > -1) {
        this.mapOfCheckedId.splice(index, 1);
      } else {
        this.mapOfCheckedId[id] = id;
      }
  
      this.selectedCategory(cateIds);
    }*/
}
