import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NzModalRef} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';

import {Api} from '@service/Api';

@Component({
  selector: 'app-modal-recordeditor',
  templateUrl: './modal-recordeditor.component.html',
  styleUrls: ['./modal-recordeditor.component.less']
})
export class ModalRecordeditorComponent implements OnInit {
  @Input() customerId: any;

  form: FormGroup;
  loading = false;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.form = fb.group({
      productName: [null, Validators.required],
      returnedMoney: [null, [Validators.required, Validators.pattern(/^[0-9]+([.]{1}[0-9]+){0,1}$/)]], // 整数或小数的正则
      grossPrdfit: [null, [Validators.required, Validators.pattern(/^[0-9]+([.]{1}[0-9]+){0,1}$/)]],
      commission: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      commissionPrice: [null, [Validators.required, Validators.pattern(/^[0-9]+([.]{1}[0-9]+){0,1}$/)]],
      remark: [null],
    });
  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }

    let params = this.form.getRawValue();
    params.customerId = this.customerId;
   
    this.api.addCustomerPayRecord(params).subscribe(res => {
      this.message.success('客户交付记录添加成功！');
      this.modal.destroy();
    }, err => {
      this.loading = false;
    })

  }

  ngOnInit() {
   
  }

}
