import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { DashBoardRoutingModule } from './dashboard-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';

import { DashboardComponent } from './dashboard.component';

import { NewAddCustomer } from './panelDataList/newAddCustomer.component';
import { OrderCountList } from './orderCountList/orderCountList.component';
import { CalendarComponent } from './calendar/calendar.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';


@NgModule({
  // imports: [DashboardRoutingModule],
  imports: [
    SharedModule,
    NgxEchartsModule,
    DashBoardRoutingModule
  ],
  declarations: [DashboardComponent, NewAddCustomer, OrderCountList, CalendarComponent, DashboardNewComponent],
  exports: [DashboardComponent]
})
export class DashboardModule { }
