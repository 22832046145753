import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DrawerUserComponent } from '../../component/drawer-user/drawer-user.component';
import { DrawerBusinessComponent } from '../../component/drawer-business/drawer-business.component';


@Component({
  selector: 'app-drawer-business-dashboard',
  templateUrl: './drawer-business-dashboard.component.html',
  styleUrls: ['./drawer-business-dashboard.component.less']
})
export class DrawerBusinessDashboardComponent implements OnInit {

  @Input() value = '';
  @Input() type = '';
  @Input() params: any;
  constructor(
    private drawerService: NzDrawerService,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) { }
  ngOnInit() {
    if (this.type == '0') {
      // 遗留
      this.getBusinessStatisticsYlList();
    } else if (this.type == '1') {
      // 来源
      this.getBusinessStatisticsSourceList();
    }
  }

  businessList = [];
  //遗留商机明细列表
  getBusinessStatisticsYlList() {
    var param = {
      userId: this.params.userId,

    }
    if (this.params.dateType == 'month') {
      param['currentMonth'] = this.params.statisticsMonth;
    } else if (this.params.dateType == 'year') {
      param['currentYear'] = this.params.statisticsMonth.split('-')[0];;
    }
    if (this.params.payFlag == '0' || this.params.payFlag == '1') {
      param['payFlag'] = this.params.payFlag;
    }
    this.api.businessStatisticsYlList(param).subscribe(res => {
      this.businessList = res['data'];
    })
  }

  //来源商机明细列表
  getBusinessStatisticsSourceList() {
    var param = {
      businessSource: this.params.businessSource == '' ? undefined : this.params.businessSource,
      userId: this.params.userId,

    }
    if (this.params.payFlag == '0' || this.params.payFlag == '1') {
      param['payFlag'] = this.params.payFlag;
    }
    if (this.params.dateType == 'month') {
      param['currentMonth'] = this.params.statisticsMonth;
    } else if (this.params.dateType == 'year') {
      param['currentYear'] = this.params.statisticsMonth.split('-')[0];;
    }
    this.api.businessStatisticsSourceList(param).subscribe(res => {
      this.businessList = res['data'];
    })
  }


  // 打开客户信息详情
  openCustomer(id, index, jobId) {
    const customerDrawer = this.drawerService.create<DrawerUserComponent, { value: string, index: number, jobId: any, plateValue: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerUserComponent,
      nzContentParams: {
        value: id,
        index,
        jobId,
        plateValue: 'my-customer'
      }
    });
  }
  // 打开详情
  openDetail(data, type) {
    const modal = this.drawerService.create<DrawerBusinessComponent, {
      value: string,
      type: string,
    }, string>({
      nzWidth: 890,
      nzWrapClassName: '商机',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerBusinessComponent,
      nzContentParams: {
        value: data.id,
        type: type,
      }
    });
  }
}
