import { Component, Input, OnInit } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-archives',
  templateUrl: './drawer-archives.component.html',
  styleUrls: ['./drawer-archives.component.less']
})
export class DrawerArchivesComponent implements OnInit {
  @Input() value = '';
  constructor(
    public api: Api,
  ) { }

  ngOnInit() {
    this.shareDetail();
  }
  index:any;
  archivesDetail;
  archivesCustomerShareAttachmentList;
  archivesCustomerShareUserList;
  shareDetail() {
    this.api.shareDetail(this.value).subscribe(res => {
      this.archivesDetail = res['data'];
      const arr = this.archivesDetail.archivesCustomerShareAttachmentList;
      (arr || []).map(item => {
        let last = item.archivesUrl.lastIndexOf('.');
        let ext = item.archivesUrl.substr(last + 1);
        item.isLast = ext == 'png' || ext == 'jpg' || ext == 'jpeg' ? false : true;
      })
      this.archivesCustomerShareAttachmentList = arr;
      this.archivesCustomerShareUserList = this.archivesDetail.archivesCustomerShareUserList;
    })
  }

  // 查看的用户 start
  filePageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  }
  // 翻页
  toPage(page) {
    this.filePageCtrl.nzPageIndex = page;
    this.seeUserList();
  }

  userList;
  seeUserList() {
    const pamares = {
      current: this.filePageCtrl.nzPageIndex,
      object: {
        archivesCustomerShareId: this.value
      },
      size: this.filePageCtrl.nzPageSize
    }
    this.api.archivesCustomerShareUserRecordList(pamares).subscribe(res => {
      const arr = res['data'].records;
      this.userList = arr;
      this.filePageCtrl.total = res['data'].total;
    })
  }
  // 查看的用户 end

  // 下载的用户 start
  fileDownPageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  }
  // 翻页
  toDownPage(page) {
    this.fileDownPageCtrl.nzPageIndex = page;
    this.seeDownUserList();
  }

  userDownList;
  seeDownUserList() {
    const pamares = {
      current: this.fileDownPageCtrl.nzPageIndex,
      object: {
        archivesCustomerShareId: this.value
      },
      size: this.fileDownPageCtrl.nzPageSize
    }
    this.api.archivesCustomerAttachmentDownloadList(pamares).subscribe(res => {
      const arr = res['data'].records;
      (arr || []).map(item => {
        let last = item.archivesUrl.lastIndexOf('.');
        let ext = item.archivesUrl.substr(last + 1);
        item.isLast = ext == 'png' || ext == 'jpg' || ext == 'jpeg' ? false : true;
      })
      this.userDownList = arr;
      this.fileDownPageCtrl.total = res['data'].total;
    })
  }
  // 下载的用户 end

  activeList = [0, 1];    // 已激活菜单
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 0:
        this.shareDetail();
        break;
      case 1:
        this.shareDetail();
        break;
      case 2:
        this.seeUserList();
        break;
      case 3:
        this.seeDownUserList();
        break;
      default:
        break;
    }
  }
}
