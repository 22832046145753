import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-product-coupon',
  templateUrl: './drawer-product-coupon.component.html',
  styleUrls: ['./drawer-product-coupon.component.less']
})
export class DrawerProductCouponComponent implements OnInit {
  @Input() value = '';

  constructor(
    public api: Api,
  ) { }

  couponInfo: any = {};
  ngOnInit() {
    this.api.getCoupon(this.value).subscribe(res => {
      this.couponInfo = res['data'];
      console.log(this.couponInfo)
    });
  }
} 
