import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NewAddCustomer } from './panelDataList/newAddCustomer.component';
import { OrderCountList } from './orderCountList/orderCountList.component';
import { CalendarComponent } from './calendar/calendar.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: DashboardComponent },
  { path: 'newAddCustomer', component: NewAddCustomer },
  { path: 'orderCountList', component: OrderCountList },
  { path: 'calendar', component: CalendarComponent },
  { path: 'dashboardNew', component: DashboardNewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashBoardRoutingModule { }
