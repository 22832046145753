import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from '../../auth/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-enterprise-band',
  templateUrl: './enterprise-band.component.html',
  styleUrls: ['./enterprise-band.component.less']
})
export class EnterpriseBandComponent implements OnInit {

  constructor(
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    public message: NzMessageService,
  ) { }

  ngOnInit() {

    let data = {
      code: this.route.snapshot.queryParams['auth_code'],
      userId: this.route.snapshot.queryParams['userId'],
    };
    console.log(data)
    this.authService.enterpriseLoginBand(data).subscribe(() => {
      if (this.authService.isLoggedIn) {
        this.message.success('企业微信绑定成功！');
        let redirect = '/dashboard';
        setTimeout(() => {
          this.router.navigateByUrl(redirect);
        }, 2000);
      }
    }, err => {
      console.log('绑定失败')

    })

  }

}
