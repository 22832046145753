import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';


@Component({
  selector: 'app-drawer-customer-group',
  templateUrl: './drawer-customer-group.component.html',
  styleUrls: ['./drawer-customer-group.component.less']
})
export class DrawerCustomerGroupComponent implements OnInit {
  @Input() dataInfo = {};
  constructor(
    public api: Api,
  ) { }
  groupInfo;
  ngOnInit() {
    console.log(this.dataInfo['chatId'])
    this.api.groupchatGet(this.dataInfo['chatId']).subscribe(res => {
      this.groupInfo = res['data'];
      this.groupInfo.memberList = JSON.parse(this.groupInfo.memberList);
      this.groupInfo.adminList = JSON.parse(this.groupInfo.adminList);
    });
  }

}