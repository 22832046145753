import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';
import { ActivatedRoute } from '@angular/router';
import { Global } from '@service/global.ts';

@Component({
  selector: 'app-modal-transfer-recommend',
  templateUrl: './modal-transfer-recommend.component.html',
  styleUrls: ['./modal-transfer-recommend.component.less']
})
export class ModalTransferRecommendComponent implements OnInit {
  @Input() customerInfo: string; // 客户信息

  constructor(
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private route: ActivatedRoute,
    public global: Global,
    private modal: NzModalRef,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  contractUrl: string;
  fileList: any;
  uploadParams = {};
  ngOnInit() {
    console.log(this.customerInfo);
    // 审核模块 start
    this.getUserList();
    this.getServiceFlowList('recommend_transfer');
    this.getPaymentCompanieList(this.customerInfo['receiveOfficeId'], 'pay');
    this.getPaymentCompanieList(this.customerInfo['recommendOfficeId'], 'receive');
    // 审核模块 end
  }
  canSubmit = false;
  params = {
    payCompanyId: null,
    payCompanyName: null,
    receiveCompanyId: null,
    receiveCompanyName: null,
    transferFlag: null,
    transferAmount: null,
    transferDesc: null,
    bsCustomerRecommendAttachmentList: [],//发票Urls
  }
  paymentCompanyList = [];//付款公司列表
  recommendOfficeId = [];//收款公司列表
  //  获取公司列表
  getPaymentCompanieList(officeId, type) {
    this.api.getPaymentCompanieList(officeId).subscribe(res => {
      switch (type) {
        case 'receive':
          this.recommendOfficeId = res['data'];
          break;
        case 'pay':
          this.paymentCompanyList = res['data'];
          break;
        default:
          break;
      }
    })
  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    this.canSubmit = true
    if (this.params.payCompanyId == null) {
      this.message.error('请选择付款公司！');
      this.canSubmit = false
      return
    }
    if (this.params.receiveCompanyId == null) {
      this.message.error('请选择服收款公司！');
      this.canSubmit = false
      return
    }
    if (this.params.transferFlag == null) {
      this.message.error('请选择转款标记！');
      this.canSubmit = false
      return
    }
    if (!this.params.transferAmount) {
      this.message.error('请输入转款金额!');
      this.canSubmit = false
      return
    }
    // if (this.params.bsCustomerRecommendAttachmentList.length == 0) {
    //   this.message.error('请上传发票!');
    //   this.canSubmit = false
    //   return
    // }
    var params = JSON.parse(JSON.stringify(this.params));
    this.paymentCompanyList.map(item => {
      if (item.signedEnterpriseId == params.payCompanyId) {
        params.payCompanyName = item.fullNameOffice;
      }
    })
    this.recommendOfficeId.map(item => {
      if (item.signedEnterpriseId == params.receiveCompanyId) {
        params.receiveCompanyName = item.fullNameOffice;
      }
    })
    params.id = this.customerInfo['id'];
    // 审核模块 start
    var flowBusinessStepDTOList = [];
    var stepBool = true;
    (this.flowListStep || []).map((item, index) => {
      flowBusinessStepDTOList[index] = {
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        serviceNo: item.serviceNo,
        stepId: item.stepId,
        stepName: item.stepName,
        stepNo: item.stepNo,
      }
      // 循环用户列表，获取用户id 与名称
      var selected_user = [];
      var selectedFin = [];
      // 区分用户为多人或者单人
      if (item.userId instanceof Array) {
        if (item.userId.length == 0) {
          stepBool = false;
          return false;
        }
        item.userId.map((child, index) => {
          selected_user = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == child
          });
          selectedFin.push(Object.assign({}, ...selected_user))
        })
      } else {
        if (!item.userId) {
          stepBool = false;
          return false;
        }
        selectedFin = this.usersList.filter((obj) => {
          obj.userName = obj.name;
          return obj.userId == item.userId
        });
      }
      flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
    })
    if (!stepBool) {
      this.message.error('审核人员不能为空！');
      this.canSubmit = false;
      return;
    }
    params['flowBusinessStepDTOList'] = flowBusinessStepDTOList;
    // 审核模块 end
    this.api.addTransfer(params).subscribe(res => {
      this.message.success('操作成功！')
      this.cancel();
    }, err => {
      this.canSubmit = false
    })

  }

  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end
  // 上传附件
  uploadChange(info, type) {
    var bsCustomerRecommendAttachmentList = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        bsCustomerRecommendAttachmentList.push(
          {
            attachmentUrl: item.response.data.fileUrl
          }
        )
      })
      this.params.bsCustomerRecommendAttachmentList = bsCustomerRecommendAttachmentList;
    }
  }
}
