import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-add-office-config',
  templateUrl: './modal-add-office-config.component.html',
  styleUrls: ['./modal-add-office-config.component.less']
})
export class ModalAddOfficeConfigComponent implements OnInit {
  @Input() id: string; // 新增类型id
  @Input() threadCeiling: Number; // 日线索上限
  @Input() receiveAccount: Number; // 精准线索价
  @Input() approvalNameAccount: Number //核名线索价
  form: FormGroup;

  Index: any;
  configs: any;
  canSubmit = true;
  principalData = [];

  categoryList = [
    { id: 0, name: "直营" },
    { id: 1, name: "加盟商" },
  ];

  threadTypeList = [

  ];
  threadType: string;

  userInfo: any;
  officeName: string;
  areaName: string;
  areaId: bigint;
  category: bigint;
  address: string;//地址
  firstPerson: string;
  tel: bigint;
  constructor(
    private fb: FormBuilder,
    private api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      officeName: [null],
      areaName: [null],
      category: [null],
      threadType: [null, Validators.required],
      address: [null],
      officeDesc: [null],
      firstPerson: [null, Validators.required],
      firstPersonName: [null],
      tel: [null],
      //receiveAccount:[null,[Validators.required,Validators.pattern(/^[0-9]*$/)]],
      //:[null,[Validators.required,Validators.pattern(/^[0-9]*$/)]],
      receiveAccount: [null, Validators.required],
      threadCeiling: [null, Validators.required],
      //approvalNameAccount:[null,[Validators.required,Validators.pattern(/^[0-9]*$/)]],
    });
  }
  changeThreadType(e) {
    this.Index = this.threadTypeList.findIndex(item => item.id === e);

    let account = this.threadTypeList[this.Index].receiveAccount;
    let ceiling = this.threadTypeList[this.Index].threadCeiling;
    this.form.get('receiveAccount').setValue(account);
    this.form.get('threadCeiling').setValue(ceiling);

    this.configs = this.threadTypeList[this.Index];

  }
  submit() {
    this.canSubmit = false;
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    let params = this.form.getRawValue();
    params["areaCode"] = this['userInfo']["areaCode"]
    params["areaId"] = this['userInfo']["areaId"]
    params["officeId"] = this['userInfo']["officeId"]
    params["firstPersonName"] = this['userInfo']["firstPersonName"]

    let data = {}
    data["address"] = params["address"]
    data["officeDesc"] = params["officeDesc"]
    data["areaCode"] = params["areaCode"]
    data["areaId"] = params["areaId"]
    data["areaName"] = params["areaName"]
    data["category"] = params["category"]

    data["configs"] = []

    data["configs"][0] = this.configs

    data["firstPerson"] = params["firstPerson"]
    data["firstPersonName"] = params["firstPersonName"]
    data["officeId"] = params["officeId"]
    data["officeName"] = params["officeName"]
    data["tel"] = params["tel"]

    this.canSubmit = true;

    if (this.id == null) {
      if (!this.form.valid) {
        this.canSubmit = true;
        return
      }
      console.log(params)
      this.api.saveOfficeConfig(params).subscribe(res => {
        this.cancel();
      });
    } else {
      if (!this.form.valid) {
        this.canSubmit = true;
        return
      }
      params['officeId'] = this.id;

      data["configs"][0].receiveAccount = Number(this.receiveAccount)
      data["configs"][0].threadCeiling = Number(this.threadCeiling)
      console.log(data)

      this.api.saveOfficeCompanyConfig(data).subscribe(res => {
        this.message.success('编辑成功！');
        this.cancel();
      });
    }
  }

  cancel() {
    this.modal.destroy();
  }
  principalDataList() {
    this.api.getUserOfficePrincipal({ companyId: this.id }).subscribe(res => {
      console.log(res['data'])
      this.principalData = res['data'];
    })
  }
  ngOnInit() {
    if (this.id != null) {
      //获取公司配置信息
      this.principalDataList();
      this.api.getOfficeSettingInfo({ officeId: this.id }).subscribe(res => {
        let data = res['data'];
        this.userInfo = res['data']
        this.threadTypeList = this.userInfo.configs

        Object.keys(data).map((key) => {
          if (this.form.get(key) && key) {
            this.form.get(key).setValue(data[key]);
          }
        })
      });

    } else {
      console.log('add')
    }
  }

}

