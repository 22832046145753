import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';


@Component({
  selector: 'app-drawer-business',
  templateUrl: './drawer-business.component.html',
  styleUrls: ['./drawer-business.component.less']
})
export class DrawerBusinessComponent implements OnInit {

  @Input() value = '';
  @Input() type: any;
  constructor(
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) { }
  extraTemplate: any;
  taskInfo: any = {};
  curIndex = 0;
  ngOnInit() {
    if (this.type) {
      this.curIndex = this.type
    }
    this.changeTabs(this.curIndex)
  }
  // getCustomerAwayDetail() {
  //   this.api.customerAwayDetail(this.value).subscribe(res => {
  //     this.taskInfo = res['data']
  //     //提成结算审核人ID 查询 审核人公司，部门，人
  //     if (this.taskInfo.flowBusinessStepDTOList && this.taskInfo.flowBusinessStepDTOList.length > 0) {
  //       this.taskInfo.flowBusinessStepDTOList.map((item, index) => {
  //         (item.flowBusinessStepUserList || []).map((child, index) => {
  //           this.api.getSysUserInfos(child.userId).subscribe(res => {
  //             child['checkUserCompanyName'] = res['data'].companyName;
  //             child['checkUserDeptName'] = res['data'].deptName;
  //           })
  //         })

  //       })
  //     }
  //   });
  // }
  activeList = [];  // 已激活菜单
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 0:
        // this.getCustomerAwayDetail();
        this.getBusinessDetail();
        break;
      case 1:
        this.getOrderRecordList();
        break;
      default:
        break;
    }
  }
  orderRecordList = [];//跟进日志
  pageCtrl2 = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMoreLog = true;
  getOrderRecordList() {
    const params = {
      current: this.pageCtrl2.pageIndex,
      size: this.pageCtrl2.pageSize,
      object: {
        bindId: this.value,
        logType: 5,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.orderRecordList = [...this.orderRecordList, ...res['data'].records];
      if (this.pageCtrl2.pageIndex == res['data'].pages) {
        this.loadingMoreLog = false;
      }
    })
  }
  // 添加跟进记录
  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'business',
        bindId: this.value,
        // bindName: this.orderInfo.orderNo,
        // customerId: this.orderInfo.customerId,
        // customerName: this.orderInfo.customerName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.orderRecordList = [];
          this.getOrderRecordList();
        })
      }
    });
  }

  businessInfo;
  getBusinessDetail(): void {
    this.api.businessDetail(this.value).subscribe(res => {
      this.businessInfo = res['data'];
    })
  }

  // 预览凭证
  preview(data) {
    window.open(data);
  }
}
