import {Pipe, PipeTransform} from '@angular/core';

/**
 * @description 自定义管道操作符，用于显示后端状态对应的文本
 */
@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: any, type?: string): any {

    const statusList = {

      mailing: { // 收货类型
        0: '上门自取',
        1: '寄送'
      },
      invoiceType: { // 发票类型
        0: '专票',
        1: '普票'
      },
      invoiceStatus: { // 发票状态
        0: '未票',
        1: '已开票'
      },
      verify: { // 审核状态
        0: '未审核',
        1: '开票审核通过',
        2: '开票审核未通过',
      },
      payStatus: { // 支付状态
        0: '未支付',
        1: '部分支付',
        2: '全款支付'
      },
      eventType: { // 事件类型
        0: '订单',
        1: '任务',
      },
      settlementStatus: { // 结算状态
        0: '进行中',
        1: '结算中',
        2: '已发放'
      },
      // 经纬创新 订单状态
      // 海尔云数贷，富登富盈贷、亿联亿微贷订单状态： P6500\P6700\P6900
      statusObj1:{
        '00': '申请未提交',
        '02': '返回用户修改(已提交审批)',
        33: '下户尽调',
        35: '授信中',
        36: '终审(内部/外部)审批通过',
        39: '贷款已拒绝',
        40: '已签约合同，待放款',
        45: '授信生效',
        49: '放款终止',
        50: '已放款，履行中',
        60: '履行完毕',
        70: '授信到期',
        75: '授信暂停/额度暂停',
        90: '签约超时',
        91: '下户预约超时关闭',
        99: '申请超时关闭',
      } ,
      //  平安宅抵贷，新微贷，智贷星订单状态： zdx\xwdsjd\pazdd
      statusObj2:{
        0: '申请中',
        1: '客户经理处理中',
        2: '已作废',
        3: '已否决',
        4: '已失效',
        5: '审批中',
        6: '审批通过',
        7: '审批否决',
        8: '放款成功',
        9: '放款失败',
        11: '已核额',
      },
       //  宜信税贷订单状态： yxstd
      statusObj3:{
        20: '初筛通过已提交',
        30: '提交成功',
        40: '审批通过',
        41: '审批拒绝',
        50: '放款成功',
        60: '还款完成',
        70: '授信过期',
        80: '客户放弃',
        90: '等待补充资料',
        100: '审核中',
        110: '回退中',
        120: '实地中',
      },
    }
    return statusList[type][value];
  }
}
