import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ModalSelectproductComponent } from '../modal-selectproduct/modal-selectproduct.component';
import { ModalCustomerComponent } from '../modal-customer/modal-customer.component';
import { Api } from '@service/Api';
import { Router } from "@angular/router"
import { ModalSelectmycompanyComponent } from '../modal-selectmycompany/modal-selectmycompany.component';
import { ModalCompanyComponent } from '../modal-company/modal-company.component';


@Component({
  selector: 'app-modal-neworder',
  templateUrl: './modal-neworder.component.html',
  styleUrls: ['./modal-neworder.component.less']
})
export class ModalNeworderComponent implements OnInit {
  @Input() type: string;
  form: FormGroup;
  form2: FormGroup;
  productList = []; // 已选择产品
  payList = [];
  bussCompanyList = [];
  companyLists = [];
  loading = false;
  totalPayment = 0; // 总金额
  // 回签，提前，当月,
  renewType = [
    {
      code: 0,
      name: '当月'
    },
    // {
    //   code: 1,
    //   name: '回签'
    // },
    {
      code: 2,
      name: '提前'
    }
  ];
  productType = [
    {
      code: 0,
      name: '新签'
    },
    {
      code: 1,
      name: '续费'
    },
    {
      code: 2,
      name: '回签'
    }
  ]

  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private router: Router
  ) {
    this.form = fb.group({
      //name: new FormControl({value: null, disabled: true}),
      //phone: new FormControl({value: null, disabled: false}),
      // customerName: [null, Validators.required],
      phone: [null, Validators.required],
      customerName: new FormControl({ value: null, disabled: true }),
      remark: [null], // 总金额
      totalPayment: [null], // 总应支付金额
      couponAmount: [null], // 已优惠金额
      orderAllAmount: [null], // 订单总金额：
      couponInfoId: [null], // 优惠券：
      companyId: [null], // 客户公司
      bussCompanyId: [null, Validators.required], //入账公司 
      achievementArr: [null, Validators.required], // 业务归属
      achievementCompanyId: [null], // 业务归属公司id
      achievementDeptId: [null], // 业务归属部门id
      introduceCompanyId: [null], // 业务归属部门id
      introduceCompanyName: [null], // 业务归属部门Name
    });

    this.form2 = fb.group({
      acceptProvinceId: [null, Validators.required], //城市区域 省
      acceptCityId: [null], //城市区域 市
      acceptOfficeId: [null, Validators.required], //接单公司id
      type: [null, Validators.required], //分成方式 
      pricevalue: [null],
      proportionValue: [null],
      acceptUserId: [null], //接单用户id
    });

  }

  divideTyleList = [{
    type: 'FIXED_PRICE',
    name: '一口价'
  }, {
    type: 'PROFIT_SHARE',
    name: '利润分成'
  }]

  proportionList = [
    { id: 0, name: '0' },
    { id: 1, name: '10' },
    { id: 2, name: '20' },
    { id: 3, name: '30' },
    { id: 4, name: '40' },
    { id: 5, name: '50' },
    { id: 6, name: '60' },
    { id: 7, name: '70' },
    { id: 8, name: '80' },
    { id: 9, name: '90' },
    { id: 10, name: '100' },
  ]
  loadDeptData: any;
  city_Data_2 = [];//公司，部门 分摊中心
  getOfficeDepartTreeLists() {
    this.api.getOfficeDepartTrees().subscribe(res => {
      //this.shareOfficeList = res['data']
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.city_Data_2 = strs;
    })
  }
  onChanges(values) {
    console.log(values)
  }

  // 获取支付方式
  getPayMode() {
    this.api.getPayMode().subscribe(res => {
      this.payList = res['data'];
    })
  }

  // 打开产品列表
  openProduct(): void {
    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        res.map(item => {
          item['companyId'] = null;
          item['productPeriod'] = item['serverPeriod'];
          item['realSalePrice'] = item['realPrice'];
          item['couponAmount'] = 0;
          item['hasCompanyId'] = '1';
          item['renewFlag'] = '0';
          // 单价
          item['periodAmount'] = item['realSalePrice'] / (item['productPeriod'] || 1);
          item['cost'] = item['cost'] == 0 ? null : item['cost'];
          item['productType'] = item['productType'];
          item['renewType'] = null;
        })
        this.productList = [...new Set([...this.productList, ...res])];
        this.getCoupon();
        this.getPriceTotal();
      }
    });
  }

  delete(i) {
    let newList = [];
    this.productList.forEach((item, index) => {
      // if (item.id !== id) {
      //   newList.push(item);
      // }
      if (i !== index) {
        newList.push(item);
      }
    });
    this.productList = newList;
    this.getCoupon();
    this.getPriceTotal();
  }

  itemAlert = false;
  productNmae: string;
  getItemRealPrice(data) {
    if (data.productNature != 0) {
      // 长期
      if (data.periodAmount < data.tipsPeriodAmount) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.name + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    } else {
      // 一次性
      if (data.realSalePrice < data.price) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.name + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    }
    this.getPriceTotal();
  }

  afterClose(): void {
    console.log('close');
    this.itemAlert = false;
  }

  // 计算实际销售价
  getPrice(data) {
    this.productList.map(item => {
      if (item.productNature != 0) {
        item.realSalePrice = item['periodAmount'] * (item['productPeriod'] + (item['givePeriod'] || 0));
      }
    })
    this.getItemRealPrice(data);
  }
  // 计算单价
  getPeriodAmount(data) {
    this.productList.map(item => {
      if (item.productNature != 0) {
        item['periodAmount'] = (item.realSalePrice / (item['productPeriod'] + (item['givePeriod'] || 0))).toFixed(2);
      }
    })
    this.getItemRealPrice(data);
  }

  getPriceTotal() {
    // this.orderAllAmount = 0;
    // this.productList.map(item => {
    //   this.orderAllAmount += Number(item.realSalePrice) * Number(item.amount);
    // })
    // // 计算优惠券
    this.changeCoupon(this.couponInfoId);
  }


  cancel() {
    this.modal.destroy();
  }

  isVisible = false;
  editName = false;
  customerId = null;
  // disabled = true;

  // 根据手机号查客户
  curCostomer;
  searchPhone() {
    let _phone = Number(this.form.value['phone']);
    this.api.getCustomerPhoneInfo({ phone: _phone }).subscribe(res => {
      let _data = res['data'];
      this.curCostomer = _data;
      switch (_data.type) {
        case 'MY':
          //自己的客户
          this.form.get('customerName').setValue(_data.name);
          this.customerId = _data.customerInfoId;
          //可以修改
          this.editName = true;
          this.getCompanyLists(_data.customerInfoId);
          this.introduceFlag(_data.customerInfoId);
          this.getCoupon();
          break;
        case 'NO_EXIST':
          //不存在的客户
          //弹窗提示用户是否新建客户
          this.isVisible = true;
          // this.disabled = true;
          this.editName = false;
          break;
        case 'OTHER':
          //他人客户
          this.message.error('该号码并非您的客户，请重新输入!');
          this.form.get('phone').setValue(null);
          this.form.get('customerName').setValue(null);
          // this.disabled = true;
          this.editName = false;
          break;
        default:
          this.message.error('错误了，请重试');
          break;
      }
    });
  }
  // 获取公司列表
  getCompanyLists(customerInfoId) {
    this.productList.map(item => {
      item['hasCompanyId'] = '1';
      item['companyId'] = null;
    })
    this.api.getCompanyList({ id: customerInfoId }).subscribe(res => {
      this.companyLists = res['data'];
    })
  }

  editNameShow() {
    // this.disabled = false;
    // this.editName = false;
    // 编辑客户
    const modal = this.modalService.create({
      nzTitle: '编辑客户',
      nzContent: ModalCustomerComponent,
      nzComponentParams: {
        id: this.customerId
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        console.log(res);
        // this.productList = [...new Set([...this.productList, ...res])];
        // this.getPriceTotal();
        this.form.get('customerName').setValue(res.name);
      }
    });
  }

  // 添加公司
  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.customerId,
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => {
      if (!this.customerId) {
        return false;
      }
      this.getCompanyLists(this.customerId);
    });
  }

  // 获取续费标记
  getFindRenewFlag(data, i) {
    if (data.companyId) {
      this.api.findRenewFlag({ companyId: data.companyId, cateId: data.cateId }).subscribe(res => {
        const parts = res['data'].split("_");
        this.productList.map((item, index) => {
          if (index == i) {
            item.renewFlag = parts[0];
            item.renewType = null;
            if (parts[0] == 1) {
              item.renewType = parts[1];
            }
          }
        })
      })
    }
  }
  getHasCompanyId(data, i) {
    if (data.hasCompanyId == 1) {
      this.getFindRenewFlag(data, i)
    } else {
      data.renewFlag = '0';
    }
  }


  handleCancel() {
    this.isVisible = false;
    this.form.get('phone').setValue(null);
    this.form.get('customerName').setValue(null);
    // this.disabled = true;
    this.editName = false;
  }
  divideTyleTyle: string;
  selectDivideTyle(e) {
    this.divideTyleTyle = e
  }
  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m;
  }
  amounts = 0;
  submit() {
    this.amounts = 0;
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    //新增甩单
    if (this.type == 'fling') {
      Object.keys(this.form2.controls).map((key) => {
        this.form2.controls[key].markAsDirty();
        this.form2.controls[key].updateValueAndValidity();
      });
      if (!this.form2.valid) {
        this.loading = false;
        return
      }

      let params = {
        acceptProvinceId: null,
        acceptCityId: null,
        value: null,
        remark: null,
        type: null,
        acceptOfficeId: null,
        acceptUserId: null,
        acceptDepartId: null,
        bussCompanyId: null,
        bussCompanyName: null,
        customerId: this.customerId,//客户ID
      }

      let value = this.form2.value;

      params.acceptProvinceId = value.acceptProvinceId[0];
      params.acceptCityId = value.acceptProvinceId[1];
      params.acceptOfficeId = value.acceptOfficeId;
      params.acceptUserId = value.acceptUserId;
      params.remark = this.form.value.remark;
      params.bussCompanyId = this.form.value.bussCompanyId;

      (this.bussCompanyList || []).map(item => {
        if (this.form.value.bussCompanyId == item.signedEnterpriseId) {
          params.bussCompanyName = item.fullNameOffice
          return
        }
      })

      if (this.divideTyleTyle == 'PROFIT_SHARE') {//利润分成
        if (value['proportionValue'] == null) {
          this.message.error('发起人分成比例不能为空');
          this.loading = false;
          return;
        } else {
          if (value['proportionValue'] > 0) {
            params.value = Number(value['proportionValue'] + '0')
          } else {
            params.value = value['proportionValue']
          }
        }
      } else {//一口价
        if (value['pricevalue'] == null) {
          this.message.error('发起人分成金额不能为空');
          this.loading = false;
          return;
        } else {
          params.value = value['pricevalue']
        }
      }

      (this.receiveList || []).map(item => {
        if (params.acceptUserId == item.userId) {
          params.acceptDepartId = item.departId
          return
        }
      })

      params.type = this.divideTyleTyle;

      this.api.addSingleOrder(params).subscribe(res => {
        this.modal.destroy();
        this.message.success('订单创建成功！');
      }, () => this.loading = false);
    } else {
      //普通新增订单
      let isProductNature = true;
      let value = this.form.value;
      value.cost = 0;
      value.customerId = this.customerId;//客户ID
      value.orderAllAmount = this.orderAllAmount;
      value.couponAmount = this.couponAmount;
      value.totalPayment = this.totalPayment;
      value.couponInfoId = this.couponInfoId;
      this.productList.map(item => {
        item.couponInfoId = this.couponInfoId;
      });
      value.productList = this.productList;

      value.achievementCompanyId = value.achievementArr['0'];
      value.achievementDeptId = value.achievementArr['1'];
      (this.city_Data_2 || []).map(item => {
        if (item.id == value.achievementCompanyId) {
          value.achievementCompanyName = item.name;
          (item.children || []).map(child => {
            if (child.id == value.achievementDeptId) {
              value.achievementDeptName = child.name;
            }
          })
        }
      });
      if (value.productList.length <= 0) {
        this.message.error('请先添加产品！');
        this.loading = false;
        return
      };

      if (!value.introduceCompanyName && this.hasSelectCompany) {
        this.message.error('请添加转介绍公司！');
        this.loading = false;
        return
      };

      let hasPrice = false;
      let hasCompany = false;
      let hasRenewType = false;
      value.productList.map(item => {
        if (item.amount == "") {
          this.amounts = 1;
        }
        if (!item.realSalePrice) {
          hasPrice = true;
        }
        if (item.hasCompanyId == '1' && !item.companyId) {
          hasCompany = true;
        }
        if (item.hasCompanyId == '0') {
          item.companyId = null;
        }
        if (!item.renewType) {
          hasRenewType = true;
        }
        //判断产品价格 当前“实际销售价（单价）” 已低于 “市场指导价（单价）”，请先与上级领导核实
        // 长期
        if (item.productNature != 0) {
          if (item.periodAmount < item.tipsPeriodAmount) {
            value.priceStatus = 4;
          }
        }
        // 一次性
        if (item.productNature == 0) {
          if (item.realSalePrice < item.price) {
            value.priceStatus = 4;
          }
        }
        // if (value.productList[0].productNature != item.productNature) {
        //   this.message.error('一次性产品和长期产品不能同时下单，请重新选择', {
        //     nzDuration: 5000
        //   })
        //   isProductNature = false;
        //   this.loading = false;
        //   return
        // }
        // if (item.renewFlag == 1 && item.renewType == null) {
        //   isProductNature = false;
        //   this.loading = false;
        //   this.message.error('请选择续费类型')
        //   return
        // }
        if (item.productNature != 0) {
          item['serverPeriod'] = (item['productPeriod'] || 0) + (item['givePeriod'] || 0);
          item.startDate = this.dataToStr(item.startDate)
          if (!item.startDate) {
            isProductNature = false;
            this.loading = false;
            this.message.error('请选择服务开启时间！');
            return
          }
        }
        if (item.cost == null || item.cost === '') {
          isProductNature = false;
          this.loading = false;
          this.message.error('请填写产品成本！');
          return
        }
        item.realMonthPrice = Number((item.realSalePrice || 0) / (item.productPeriod || 1))
        value.cost += item.cost || 0;
      });
      if (!isProductNature) {
        return
      }
      value.orderNature = value.productList[0].productNature;
      if (this.amounts == 1) {
        this.message.error('请填写产品数量！');
        this.loading = false;
        return;
      }
      if (hasPrice) {
        this.message.error('实际销售价不能为0！');
        this.loading = false;
        return;
      }
      if (hasCompany) {
        this.message.error('请选择客户公司！');
        this.loading = false;
        return;
      }
      if (hasRenewType) {
        this.message.error('请选择类型！');
        this.loading = false;
        return;
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.form.value['phone'])) {
        this.message.error('手机号码不符合规范!');
        this.loading = false;
        return
      }

      (this.bussCompanyList || []).map(item => {
        if (value.bussCompanyId == item.signedEnterpriseId) {
          value.bussCompanyName = item.fullNameOffice
          return
        }
      })

      // if(this.itemAlert){
      //   this.message.warning('请仔细阅读提示信息，关闭后在进行提交订单',{
      //     nzDuration: 5000
      //   });
      //   this.loading = false;
      //   return
      // }
      this.api.queryCustomerInfo(value.customerId).subscribe(res => {
        this.api.addOrder(value).subscribe(res => {
          this.modal.destroy();
          this.message.success('订单创建成功！');
        }, () => this.loading = false);
      }, err => {
        this.editNameShow();
        this.loading = false
      })
    }
  }

  ngOnInit() {
    this.getPayMode();
    this.getOfficeDepartTreeLists();
    this.getCompanyData();
    let userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.form.get('achievementArr').setValue([userObj.dept.companyId, userObj.dept.id]);
    if (this.type == 'fling') {
      this.getDataDimension();
    }
  }

  //获取公司信息
  getCompanyData() {
    this.api.getUserReimbursementsApplyCompanies().subscribe(res => {
      this.bussCompanyList = res['data'];
    })
  }

  companyList = []; // 用户权限，公司列表
  city_Data = []; // 区域对象a
  receiveList = []; // 接单人

  // 获取用户权限，公司或部门的权限
  getDataDimension() {
    this.api.getProvinciDataByNoRole().subscribe(res => {
      this.city_Data = res['data'];
    });
  }

  //区域
  findCompanyByAreaId(areaId) {
    if (null != areaId) {
      this.api.findCompanyNoPerByAreaId({ areaId: areaId[1], requestSource: 1 }).subscribe((res) => {
        this.companyList = res['data'];
      })
    }
  }
  //公司
  findDeptById(companyId) {
    if (null != companyId) {
      this.api.getUserInfoByOfficeIdList({ officeId: companyId }).subscribe(res => {
        this.receiveList = res['data'];
      })
    }
  }

  customerLink() {
    this.modal.destroy();
    let _phone = Number(this.form.value['phone']);
    //window.document.location.href = '/forms/add-customer?phone=' + _phone;
    this.router.navigate(["/forms/add-customer"], { queryParams: { phone: _phone } })
  }

  hasSelectCompany = false;
  // 根据客户ID判断是否可以下推荐订单
  introduceFlag(customerId) {
    this.api.introduceFlag(customerId).subscribe(res => {
      this.hasSelectCompany = res['data'] == 1 ? true : false;
    })
  }
  introduceCompanyName: null;
  addCompany() {
    // this.disabled = false;
    // this.editName = false;
    // 编辑客户
    const modal = this.modalService.create({
      nzTitle: '添加转介绍公司',
      nzContent: ModalSelectmycompanyComponent,
      nzComponentParams: {
        // id: this.customerInfo['id']
      },
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        console.log(res);
        // this.productList = [...new Set([...this.productList, ...res])];
        // this.getPriceTotal();
        this.form.get('introduceCompanyId').setValue(res[0].id);
        this.form.get('introduceCompanyName').setValue(res[0].name);
      }
    });
  }
  // 弹出窗逻辑 公司 start
  isVisibleGs = false
  desInfo = null;
  showModal(index): void {
    switch (index) {
      case 1:
        this.desInfo = '此产品所关联的客户公司,请务必填写。如客户公司未录入到系统，请及时录入再选择!'
        break;
      default:
        break;
    }
    this.isVisibleGs = true;
  }
  handleCancelGs(): void {
    this.isVisibleGs = false;
  }
  // 弹出窗逻辑 公司 en

  // 去重数组
  unique(arr) {
    return Array.from(new Set(arr))
  }
  // 获取用户可用优惠券
  couponInfoId; //优惠券id
  couponAmount = 0;//已优惠金额
  orderAllAmount = 0;//订单总金额：
  couponList: [];
  getCoupon() {
    if (!this.customerId) {
      return false;
    }
    this.couponInfoId = null;
    this.couponList = [];
    var params = {
      areaProductIds: [],
      cateIds: [],
      customerId: this.customerId,
    }
    if (this.productList.length == 0) {
      this.couponList = [];
      return;
    }
    this.productList.map((item, index) => {
      params.areaProductIds.push(item.id);
      params.cateIds.push(item.cateId);
      item.couponAmount = 0;
      item.realPrice = item.realSalePrice - Number(item.couponAmount);
    })
    params.areaProductIds = this.unique(params.areaProductIds);
    params.cateIds = this.unique(params.cateIds);
    this.api.customerAvailableCouponList(params).subscribe(res => {
      this.couponList = res['data'];
    })
  }
  // 选择优惠券
  changeCoupon(e) {
    let arr = e ? this.couponList.filter(item => item['id'] == e)[0] : {};
    // 现金券只计算一次，折扣券 相关的产品都可以计算
    this.orderAllAmount = 0;
    this.couponAmount = 0;
    this.totalPayment = 0;
    this.productList.map((item, index) => {
      this.orderAllAmount += Number(item.realSalePrice) * Number(item.amount);
      item.couponAmount = 0;
      item.realPrice = item.realSalePrice - Number(item.couponAmount);
      switch (arr['productUse']) {
        case 0:
          // 通用
          if (index == 0) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        case 1:
          // 指定到分类
          if (item.cateId == arr['cateId']) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        case 2:
          // 指定到产品
          if (item.id == arr['areaProductId']) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        default:
          break;
      }
      this.couponAmount += Number(item.couponAmount);
      this.totalPayment += Number(item.realPrice);
    })
  }
  // 续费
  renewTypeList = [
    { id: '1', name: '正常续费' },
    { id: '11', name: '提前续费' },
    { id: '2', name: '回签' },
  ]
  //新增
  renewTypeAddList = [
    { id: '3', name: '代账客户复购' },
    { id: '4', name: '代账客户转介绍' },
    { id: '5', name: '非代账客户复购' },
    { id: '6', name: '非代账客户转介绍' },
    { id: '7', name: '美团' },
    { id: '8', name: '高德' },
    { id: '9', name: '公司资源' },
    { id: '94', name: '内部介绍' },
    { id: '97', name: '自主开发' },
    { id: '96', name: '渠道开发' },
    { id: 98, name: '小红书' },
  ]
}
