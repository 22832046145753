import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-drawer-work-report-details',
  templateUrl: './drawer-work-report-details.component.html',
  styleUrls: ['./drawer-work-report-details.component.less']
})
export class DrawerWorkReportDetailsComponent implements OnInit {
  @Input() value = {};
  @Input() id = '';
  @Input() type;
  constructor(
    private drawerService: NzDrawerService,
    public api: Api,
    public global: Global,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
    this.getReportUser();
  }
  listOfData = [];
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getReportUser();
  }
  getReportUser() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        reportUserId: this.id
      },
    };
    switch (this.type) {
      case 'read':
        this.api.reportUserReadList(params).subscribe((res) => {
          this.listOfData = res['data'].records;
          this.pageCtrl.total = res['data'].total;
        })
        break;
      case 'score':
        this.api.reportUserScoreList(params).subscribe((res) => {
          this.listOfData = res['data'].records;
          this.pageCtrl.total = res['data'].total;
        })
        break;
      default:
        break;
    }

  }
}
