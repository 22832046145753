import { Component, Input, OnInit } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-work-report',
  templateUrl: './drawer-work-report.component.html',
  styleUrls: ['./drawer-work-report.component.less']
})
export class DrawerWorkReportComponent implements OnInit {
  @Input() value = {};
  @Input() id = '';
  constructor(
    public api: Api,
  ) { }

  ngOnInit() {
    console.log(this.value)
    this.ruleUserDetail();
  }
  reportInfo;
  // 获取任务详情
  ruleUserDetail() {
    this.api.ruleUserDetailRead(this.id).subscribe(res => {
      this.reportInfo = res['data'];
    })
  }
}
