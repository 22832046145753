import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-select-different-workorder',
  templateUrl: './modal-select-different-workorder.component.html',
  styleUrls: ['./modal-select-different-workorder.component.less']
})
export class ModalSelectDifferentWorkorderComponent implements OnInit {

  orderList;
 
  @Input() orderChoiceList = [];
  @Input() initiatorOfficeId = '';//发起方公司id
  @Input() acceptOfficeId = '';//接单公司id
  @Input() workType = '';

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) {
  }
  params = {
    workOrderNo: null,
    customerName: null,
    phoneNumber: null,
    initiatorOfficeId: null,
    acceptOfficeId: null,
  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];
  dateList: any = [];

  ngOnInit() {
    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.id] = true;
      });
      this.mapOfCheckedId = newList;
    }
    if(this.workType == 'wfqd'){
      this.params.initiatorOfficeId = this.initiatorOfficeId;
      this.params.acceptOfficeId = this.acceptOfficeId;
    }else{
      this.params.initiatorOfficeId = this.acceptOfficeId; 
      this.params.acceptOfficeId = this.initiatorOfficeId;
    }

    this.mapOfCheckedItem = this.orderChoiceList;

    this.getOrderList();
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getOrderList();
  }
  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    object: {}
  };

  total;

  selectedCategory(id) {
    var choiceList = this.orderList.filter(item => {
      this.mapOfCheckedId[item.id];
      if (this.mapOfCheckedId[item.id]) {
        return item
      }
    })
    this.mapOfCheckedItem = choiceList;
  }
  // 翻页
  toPage(page) {
    this.pageCtrl.current = page;
    this.getOrderList();
  }
  // 获取产品列表 
  getOrderList() {
    let params = {
      current: this.pageCtrl.current,
      size: this.pageCtrl.size,
      object: this.params
    }
    if(this.workType == 'wfqd'){
      // bsOrderTaskSettWorkOrdeInitiatorList  我发起的
      this.api.bsOrderTaskSettWorkOrdeInitiatorList(params).subscribe(res => {
        this.orderList = res['data'].records;
        this.total = res['data'].total;
      })
    }else{
      // bsOrderTaskSettWorkOrdeAcceptList 我接收的
      this.api.bsOrderTaskSettWorkOrdeAcceptList(params).subscribe(res => {
        this.orderList = res['data'].records;
        this.total = res['data'].total;
      })
    }
  }
  cancel() {
    this.modal.destroy();
  }

  submit() {
    if (this.mapOfCheckedItem.length === 0) {
      this.message.warning('请选择订单！');
      return
    }
    console.log(this.mapOfCheckedItem)
    this.modal.destroy(this.mapOfCheckedItem);
  }

  query() {
   

    this.getOrderList();
  }
  reset() {
    this.params.workOrderNo = null;
    this.params.customerName = null;
    this.params.phoneNumber = null;
    this.getOrderList();
  }

}
