import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
@Component({
  selector: 'app-drawer-entprise-server-renew',
  templateUrl: './drawer-entprise-server-renew.component.html',
  styleUrls: ['./drawer-entprise-server-renew.component.less']
})
export class DrawerEntpriseServerRenewComponent implements OnInit {
  @Input() value = '';

  @Input() companyServerNo = '';
  @Input() manageName = '';
  @Input() chargeName = '';
  @Input() companyServerPeriod = '';
  @Input() companyServerStatus = '';
  @Input() typeFlag = '';
  @Input() serverType;
  @Input() type: any;

  contractUrl: string;
  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
    this.form = fb.group({
      followContent: [null, Validators.required],
    });
    this.formFail = fb.group({
      remark: [null, Validators.required],
    });
  }

  form: FormGroup;
  formFail: FormGroup;
  contractNum: any = 0;
  activeList: any = [];  // 已激活菜单
  sysTaskRecordList: any = [] // 系统操作日志
  listOfData: any = [];
  loadMoreOrderRecordList: any;
  // 附件
  bsCompanyServerFollowAttachmentList = []
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  detailCompanyServer: any = {};
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getServerInfoList(this.curServer);
  }

  getServerInfoList(id) {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        companyServerId: id
      }
    }
    // this.typeFlag true 长期，false 一次性
    if (this.typeFlag) {
      this.api.getCompanyServerPeriod(params).subscribe(res => {
        this.listOfData = res['data'].records;
        this.pageCtrl.total = res['data'].total;
      });
    } else {
      this.api.getCompanyServerFollowList(params).subscribe(res => {
        this.listOfData = res['data'].records;
        this.pageCtrl.total = res['data'].total;
      });
    }
  }

  // serverList = [];
  // getServerDetailNew() {
  //   this.api.getServerDetailNew(this.value).subscribe(res => {
  //     this.serverList = res['data'];
  //     if (this.serverList.length > 0) {
  //       this.curServer = this.serverList[0].id;
  //       // this.getServerInfoList(this.curServer);
  //       this.changeTabs(0)
  //     }
  //   });
  // }
  // getServerDetail(id) {
  //   this.api.getServerDetail(id).subscribe(res => {
  //     this.detailCompanyServer = res['data'];
  //     this.companyServerStatus = this.detailCompanyServer.companyServerStatus;
  //   });
  // }

  ngOnInit() {
    if (this.type) {
      this.contractNum = this.type
    }
    this.changeTabs(this.contractNum)
    // this.serverInfo();//基本信息
    // this.getServerDetailNew();
    // this.getServerInfoList(this.curServer);
  }
  curServer = null;
  // 切换菜单
  changeTabs(e) {
    // if (this.activeList.includes(e)) {
    //   return
    // }

    // this.activeList.push(e);
    this.contractNum = e;
    // if (e == this.serverList.length) {
    //   this.serverProductList();//购买列表
    // } else {
    //   this.curServer = this.serverList[e].id;
    //   this.getServerInfoList(this.curServer);
    // }
    switch (e) {
      case 0:
        this.serverInfo();//基本信息
        break;
      case 1:
        this.serverProductList();//购买时尚界
        break;
      case 2:
        this.orderRecordList = [];
        this.getOrderRecordList();//跟进记录
        break;
      default:
        break;
    }
  }
  orderRecordList = [];//跟进日志
  pageCtrl2 = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMoreLog = true;
  getOrderRecordList() {
    const params = {
      current: this.pageCtrl2.pageIndex,
      size: this.pageCtrl2.pageSize,
      object: {
        bindId: this.value,
        logType: 6,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.orderRecordList = [...this.orderRecordList, ...res['data'].records];
      if (this.pageCtrl2.pageIndex == res['data'].pages) {
        this.loadingMoreLog = false;
      }
    })
  }
  // 添加跟进记录
  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'renew',
        bindId: this.value,
        // bindName: this.orderInfo.orderNo,
        // customerId: this.orderInfo.customerId,
        // customerName: this.orderInfo.customerName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.orderRecordList = [];
          this.getOrderRecordList();
        })
      }
    });
  }
  // 预览凭证
  preview(data) {
    window.open(data);
  }
  // // 是否显示加载更多
  // onSysLoadMore() {
  //   this.pageCtrl2.pageIndex++;
  //   this.getTaskContinueListBySys();
  // }
  // pageCtrl2 = {
  //   pageIndex: 1,
  //   pageSize: 5
  // }
  // sysLoadingMore = true;
  // getTaskContinueListBySys() {
  //   const params = {
  //     current: this.pageCtrl2.pageIndex,
  //     size: this.pageCtrl2.pageSize,
  //     object: {
  //       companyServerId: this.value
  //     }
  //   }
  //   this.api.getCompanyServerLog(params).subscribe(res => {
  //     this.sysTaskRecordList = [...this.sysTaskRecordList, ...res['data'].records];

  //     if (this.pageCtrl2.pageIndex == res['data'].pages) {
  //       this.sysLoadingMore = false;
  //     }
  //   })
  // }

  // //交付凭证
  // openEditor(content, data) {
  //   this.modalService.create({
  //     nzTitle: '新增凭证',
  //     nzContent: content,
  //     nzStyle: { top: '40px' },
  //     nzWidth: 800,
  //     nzMaskClosable: false,
  //     nzOnCancel: () => this.form.reset(),
  //     nzOnOk: () => this.setServerDate(data)
  //   });
  // }

  // setServerDate(data) {
  //   this.form.controls.followContent.markAsDirty();
  //   this.form.controls.followContent.updateValueAndValidity();
  //   if (!this.form.valid) {
  //     return false;
  //   }
  //   let params = this.form.getRawValue();
  //   var _obj;
  //   // this.typeFlag true 长期，false 一次性
  //   if (this.typeFlag) {
  //     _obj = {
  //       companyServerPeriodId: data.id,
  //       followContent: params.followContent,
  //       bsCompanyServerFollowAttachmentList: this.bsCompanyServerFollowAttachmentList
  //     };
  //     this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
  //       this.message.success('添加成功！');
  //       this.form.reset()
  //       this.getServerInfoList(this.curServer);
  //     })
  //   } else {
  //     _obj = {
  //       companyServerId: this.value,
  //       followContent: params.followContent,
  //       bsCompanyServerFollowAttachmentList: this.bsCompanyServerFollowAttachmentList
  //     };
  //     this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
  //       this.message.success('添加成功！');
  //       this.form.reset()
  //       this.getServerDetailNew();
  //       this.getServerInfoList(this.curServer);
  //     })
  //   }


  // }

  // //异常
  // openFail(content, data) {
  //   this.modalService.create({
  //     nzTitle: '异常',
  //     nzContent: content,
  //     nzStyle: { top: '40px' },
  //     nzWidth: 800,
  //     nzMaskClosable: false,
  //     nzOnCancel: () => this.form.reset(),
  //     nzOnOk: () => this.setFail(data)
  //   });
  // }

  // setFail(data) {
  //   this.formFail.controls.remark.markAsDirty();
  //   this.formFail.controls.remark.updateValueAndValidity();
  //   if (!this.formFail.valid) {
  //     return false;
  //   }
  //   let params = this.formFail.getRawValue();
  //   var _obj;
  //   // this.typeFlag true 长期，false 一次性
  //   if (this.typeFlag) {
  //     _obj = {
  //       id: data.id,
  //       periodStatus: 2,
  //       remark: params.remark,
  //     };
  //     this.api.companyServerUnusual(_obj).subscribe((res) => {
  //       this.message.success('操作成功！');
  //       this.formFail.reset()
  //       this.getServerInfoList(this.curServer);
  //     })
  //   } else {
  //     _obj = {
  //       id: this.value,
  //       companyServerStatus: 4,
  //       remark: params.remark,
  //     };
  //     this.api.companyServerOnceUnusual(_obj).subscribe((res) => {
  //       this.message.success('操作成功！');
  //       this.formFail.reset()
  //       this.getServerDetailNew();
  //       this.getServerInfoList(this.curServer);
  //     })
  //   }

  // }

  // // 解除异常
  // checkFail(id) {
  //   if (this.typeFlag) {
  //     let params = {
  //       // periodStatus: 1,
  //       id: id,
  //     }
  //     this.api.companyServerUnUnusual(params).subscribe((res) => {
  //       this.message.success('操作成功！');
  //       this.getServerInfoList(this.curServer);
  //     })
  //   } else {
  //     let params = {
  //       // companyServerStatus: 4,
  //       id: this.value,
  //     }
  //     this.api.companyServerOnceUnUnusual(params).subscribe((res) => {
  //       this.message.success('操作成功！');
  //       this.getServerDetailNew();
  //       this.getServerInfoList(this.curServer);
  //     })
  //   }
  // }

  // // 上传相关资料
  // uploadChange(info) {
  //   var attachmentUrls = [];
  //   if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
  //     (info.fileList || []).map((item, index) => {
  //       let p = {
  //         attachmentUrl: item.response.data.fileUrl,
  //       }
  //       attachmentUrls.push(p)
  //     })
  //     this.bsCompanyServerFollowAttachmentList = attachmentUrls;
  //   }
  // }

  // // 交付
  // checkServe(id) {
  //   if (this.typeFlag) {
  //     let params = {
  //       periodStatus: 1,
  //       id: id,
  //     }
  //     this.api.completeCompanyServer(params).subscribe((res) => {
  //       this.message.success('交付成功！');
  //       this.getServerInfoList(this.curServer);
  //     })
  //   } else {
  //     let params = {
  //       companyServerStatus: 2,
  //       id: this.value,
  //     }
  //     this.api.serverUpdate(params).subscribe((res) => {
  //       this.message.success('交付成功！');
  //       // if (!this.typeFlag) {
  //       //   this.drawerRef.close();
  //       // }
  //       this.getServerDetailNew();
  //       this.getServerInfoList(this.curServer);
  //     })
  //   }
  // }
  serverInfo() {
    this.api.bsCompanyServerInfoGet(this.value).subscribe(res => {
      this.serverInfo = res['data'];
    })
  }
  productList = [];
  serverProductList() {
    this.api.serverProductList(this.value).subscribe(res => {
      this.productList = res['data'];
    })
  }
  // 打开订单信息详情
  openOrder(id) {
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerOrderComponent, { value: string, type: any }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        type: 0,
      }
    });
  }

}
