import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  formPassword: FormGroup;
  error = '';

  uuId: string;
  imgCodeShow: string;
  uuId3: string;
  imgCodeShow3: string;

  constructor(
    fb: FormBuilder,
    private router: Router,
    public authService: AuthService,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      userName: [null, Validators.required],
      password: [null, Validators.required],
      captcha: [null, Validators.required],
    });
    // 找回密码表单
    this.formPassword = fb.group({
      phoneNumber: ['', Validators.required],
      code: ['', Validators.required],
      newPwd: ['', Validators.required],
      confirmNewPwd: ['', Validators.required],
      captcha: ['', Validators.required],
    });
  }

  get userName() {
    return this.form.controls.userName;
  }
  get password() {
    return this.form.controls.password;
  }
  get captcha() {
    return this.form.controls.captcha;
  }

  ngOnInit() {
    this.getImgCode();
  }

  getImgCode() {
    this.uuId = Math.random().toString(36).substr(2);
    this.imgCodeShow = this.authService.getCode(this.uuId);
  }
  getImgCode3() {
    this.uuId3 = Math.random().toString(36).substr(2);
    this.imgCodeShow3 = this.authService.getCode(this.uuId3);
  }
  loading = false;
  submit() {
    this.error = '';
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this[key].markAsDirty();
      this[key].updateValueAndValidity();
    });
    if (this.userName.invalid || this.password.invalid || this.captcha.invalid) {
      return;
    }

    let data = {
      username: this.userName.value,
      password: this.password.value,
      code: this.captcha.value,
      uuid: this.uuId
    };
    this.loading = true;
    this.authService.login(data).subscribe(() => {

      if (this.authService.isLoggedIn) {
        // let redirect = this.authService.redirectUrl
        //   ? this.router.parseUrl(this.authService.redirectUrl)
        //   : '/dashboard';
        let redirect = '/dashboard';
        this.router.navigateByUrl(redirect);
      }
    }, err => {
      this.loading = false;
      this.form.get('captcha').setValue('');
      this.getImgCode();
    })
  }

  workLoginPath() {
    this.api.getWorkLoginPathData().subscribe((res) => {
      window.location.href = res['data'];
      // window.open(res['data']);
    })
  }

  findPwd() {
    this.isVisible = true;
    this.getImgCode3();
  }
  isVisible = false
  handleCancel(): void {
    this.isVisible = false;
  }

  savePassword(value) {
    Object.keys(this.formPassword.controls).map((key) => {
      this.formPassword.controls[key].markAsDirty();
      this.formPassword.controls[key].updateValueAndValidity();
    });
    if (!this.formPassword.valid) {
      return
    }
    let params = JSON.parse(JSON.stringify(value));
    if (params.newPwd != params.confirmNewPwd) {
      this.message.warning("两次密码不一致，请重新输入");
      return;
    }
    delete params.captcha;
    this.api.pwdBack(params).subscribe(res => {
      this.message.success('修改成功！')
      this.handleCancel();
      this.formPassword.reset();
    })
  }
  canSubmit = false;
  // 获取短信验证码
  getphoneCode() {
    this.canSubmit = true;
    // String phone, String uuid, String imageCode
    if (!this.formPassword.get('phoneNumber').value) {
      this.message.warning("请输入正确手机号码！");
      this.canSubmit = false;
      return;
    }
    if (!this.formPassword.get('captcha').value) {
      this.message.warning("请输入图形验证码！")
      this.canSubmit = false;
      return;
    }
    var param = {
      uuid: this.uuId3,
      phone: this.formPassword.get('phoneNumber').value,
      imageCode: this.formPassword.get('captcha').value
    }
    this.api.getIphoneCode(param).subscribe(res => {
      this.message.success('获取成功！');
      this.sendCode();
      this.canSubmit = false;
    }, err => {
      this.canSubmit = false;
      this.getImgCode3();
    })

  }

  timeClock;
  timer_num;
  sendCode() {
    this.timer_num = 60;
    this.timeClock = setInterval(() => {
      this.timer_num = this.timer_num - 1;
      if (this.timer_num == 0) {
        clearInterval(this.timeClock);
      }
    }, 1000)
  }

}
