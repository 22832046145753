import { Component, OnInit,Input } from '@angular/core';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-usercomsum-item',
  templateUrl: './modal-usercomsum-item.component.html',
  styleUrls: ['./modal-usercomsum-item.component.less']
})
export class ModalUsercomsumItemComponent implements OnInit {
  @Input() id: any; // 新增类型id
  @Input() date: any;

  listOfData:any[]=[];//数据集合
  sourcePoolTypeList=[{//来源标记
    type:"PRECISE",
    name:'精准线索'
  },{
    type:"NUCLEAR_NAME",
    name:'核名线索'
  },{
    type:"INDEPENDENT_ADD",
    name:'自主添加'
  },{
    type:"BUYING_CLUE",
    name:'采买线索'
  }];

  pageCtrl = { // 分页控制
    nzTotal: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  constructor(public api: Api) { }
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getUserConsumptionList();
  }

  params = { // 页面查询参数
    userName:null,
    sourceFlag:null,
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getUserConsumptionList();
  }
       
  reset() {
    this.params.userName = null;
    this.params.sourceFlag = null;
    this.getUserConsumptionList();
  }


  getUserConsumptionList() {
    this.params['id'] = this.id;
    this.params['date']=this.date;
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.api.getUserConsumptionList(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.nzTotal = res['data'].total;
    });
  }

  ngOnInit() {
    this.getUserConsumptionList();
  }

}
