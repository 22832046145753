import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-drawer-personal-extra',
  templateUrl: './drawer-personal-extra.component.html',
  styleUrls: ['./drawer-personal-extra.component.less']
})
export class DrawerPersonalExtraComponent implements OnInit {
  @Input() value = '';
  grossConfigInfo: any; // 详情
  constructor(
    public api: Api,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
    this.grossConfigDetail();
  }
  sysUserGrossAddConfigList = [];
  sysUserGrossRenewConfigList = [];
  grossConfigDetail() {
    this.api.grossConfigDetail(this.value).subscribe(res => {
      this.grossConfigInfo = res['data'];
      this.sysUserGrossAddConfigList = this.grossConfigInfo.sysUserGrossAddConfigList;
      this.sysUserGrossRenewConfigList = this.grossConfigInfo.sysUserGrossRenewConfigList;
    })
  }

}
