import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Api} from '@service/Api';
import {NzModalRef} from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-check',
  templateUrl: './modal-check.component.html',
  styleUrls: ['./modal-check.component.less']
})
export class ModalCheckComponent implements OnInit {

  @Input() params: any;
  @Input() type: string;
  selectRefundIsAll : any = 0;
  form: FormGroup;
  constructor(private api : Api,
    private modal: NzModalRef,
    public message: NzMessageService,
    private fb: FormBuilder) {
    this.form = fb.group({
      remark: ['', Validators.required]
    });
   }

  ngOnInit() {
  
    if(this.type === '退款审核' && this.params.status === 1){
      this.form = this.fb.group({
        remark: ['', Validators.required],
        refundIsAll: [0, Validators.required]
      });
    }
  }

  cancel(){
    this.modal.destroy();
  }

  refundIsAllStatus(id){
    if(id === 1){
      this.form = this.fb.group({
        remark: [this.form.controls.remark.value, Validators.required],
        refundIsAll: [1, Validators.required],
        refundCount: ['', Validators.required]
      });
    }
    if(id === 0){
      this.form = this.fb.group({
        remark: [this.form.controls.remark.value, Validators.required],
        refundIsAll: [0, Validators.required]
      });
    }
  }
  submitForm() {
    
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      return
    } else {
      
      if(this.type === '支付审核'){
        this.params.comment = this.form.controls.remark.value;
        this.api.payVerify(this.params).subscribe(() => {
        })
      }
      
      this.params.remark = this.form.controls.remark.value;
      if(this.type === '合同审核'){
        this.api.contractReview(this.params).subscribe(res => {

        })
      }
      if(this.type === '退款审核'){
        if (this.params.status === 1) {
          this.params['refundIsAll'] = this.form.controls.refundIsAll.value;
          if(this.selectRefundIsAll == 1){
            this.params['refundCount'] = this.form.controls.refundCount.value;
          }
        }
        // this.api.orderRefundsVerify(this.params).subscribe(() => {
        //   this.message.success('操作成功！');
        // })
  
        this.api.orderRefundAudit(this.params).subscribe((res) => {
          this.message.success('操作成功！');
        })
      }
      if(this.type === '提成审核'){
        this.api.commissionVerify(this.params).subscribe(() => {

        })
      }
      //城市经理审核
      if(this.type === 'jl'){
        this.api.managePayVerifyofflinePayJL({ id:this.params.id,status:this.params.status,comment:this.form.controls.remark.value}).subscribe(res => {
        });
      }

      this.modal.destroy();
      this.form.reset();
    }
  }

}
