import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drawer-card-detail',
  templateUrl: './drawer-card-detail.component.html',
  styleUrls: ['./drawer-card-detail.component.less']
})
export class DrawerCardDetailComponent implements OnInit {

  @Input() shareMonth = '';
  @Input() type = '';
  public baseUrl; // api基本路径
  constructor(
    public api: Api,
    public global: Global,
    public message: NzMessageService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.getFunDetail();
  };
  index = 0;
  listOfData: [];
  wqDetail: [];
  getFunDetail() {
    if (this.type == 'bussDetail') {
      this.api.bussDetail(this.shareMonth).subscribe((res) => {
        this.listOfData = res['data'];
      })
    } else {
      this.api.bussFunDetail(this.shareMonth).subscribe((res) => {
        this.listOfData = res['data'];
      })
      this.api.wqSalary(this.shareMonth).subscribe((res) => {
        this.wqDetail = res['data'];
      })
    }

  }
  /**
     * 导出
     * @param data
     *
     */
  loading = false;
  downLoad() {
    this.loading = true;
    let date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var time = Y + M + D;
    var title;
    let urlApi;
    if (this.type == 'bussDetail') {
      title = time + '业务分摊总额';
      urlApi = `/sysUserApplyItem/bussDetailDownload/${this.shareMonth}`;
    } else {
      title = time + '业务+职能分摊明细';
      urlApi = `/sysUserApplyItem/bussFunDetailDownload/${this.shareMonth}`;
    }
    let url = this.baseUrl + urlApi;
    var httpRequest = new XMLHttpRequest();
    httpRequest.responseType = "arraybuffer";
    httpRequest.open("POST", url, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Admin-Token", localStorage.getItem("token"));
    httpRequest.setRequestHeader("responseType", "blob");

    httpRequest.send(JSON.stringify({}));
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState == 4 && httpRequest.status == 200) {
        var data = httpRequest.response;
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', title + '.xls');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    }
  }
}
