import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from "@service/Api";
import { Global } from '@service/global.ts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent implements OnInit {
  userInfo; // 用户信息
  menu = []; // 左侧导航菜单列表
  isCollapsed = false; // 菜单是否收缩
  selectData: any;
  openMap: { [name: string]: boolean } = {};

  constructor(
    public api: Api,
    public global: Global,
    public router: Router,
  ) { }

  // 获取用户信息
  getUserInfo() {
    this.api.getUserInfo().subscribe(res => {
      this.userInfo = res['data'];
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
      if (this.userInfo.updateFlag == 0) {
        this.router.navigate(['/forms/account']);
      }
    })
  }

  // 获取用户权限
  getMenu() {
    this.api.getAuth().subscribe(res => {
      this.menu = res['data'];
      let url = window.location.pathname;
      this.menu.some(item => {
        this.openMap[item.id] = false;
        if (item.sysMenuVOList) {
          const child = /detail/.test(url) || /write/.test(url);
          if (child) { // 如果包含详情、新增页面，将权限转至列表页
            let arr = url.split('/').splice(0, 3);
            url = arr.join('/') + '/list';
          }
          const active = [...item.sysMenuVOList].find(v => v.link == url);
          if (active) {
            this.openMap[item.id] = true;
            const msg = active.sysMenuVOList ? this.mapPermission(active.sysMenuVOList) : [];
            this.global.changemessage(msg);
            return true;
          }
        }
      });
      this.selectData = this.menu[0];
      //console.log(this.router.url)
      // console.log(this.selectData)

      if (this.selectData.sysMenuVOList != null) {
        this.router.navigate([this.selectData.sysMenuVOList[0].link]);
      } else if ('/dashboard' === url) {
        this.router.navigate(['/dashboard/home']);
      } else {
        this.router.navigate([url]);
      }

    })
  }

  openRouter(id) {
    this.menu.forEach(item => {
      if (item.id === id) {
        this.selectData = item;
      }
    })
    // console.log(this.selectData)
  }
  openHandler(value: string): void {
    // this.menu.forEach(item=>{
    //   if(item.id === value){
    //     this.selectData = item;
    //   }
    // })
    if (!this.isCollapsed) {
      for (const key in this.openMap) {
        if (key !== String(value)) {
          this.openMap[key] = false;
        }
      }
    }
  }

  activeName: any = '';
  toPage(data) {
    // console.log(data)
    this.activeName = data.name;
    const msg = data.sysMenuVOList ? this.mapPermission(data.sysMenuVOList) : [];

    // console.log(msg)
    // console.log(data.link)
    this.global.changemessage(msg);
    this.router.navigate([data.link]);
  }

  mapPermission(list: Array<any>): Array<any> {
    let res = [];
    list.forEach(item => {
      res.push(item.code);
    })
    return res;
  }

  // #region tab 全局显示
  showCheckLeadBalance = false;
  nzfooter = null;
  balance: Number;
  balance_text = false;
  two_tone_color = "#D9B300"
  bodyStyle = {
    padding: '5px',
    height: '120px',
  }
  checkLeadBalances() {
    //查询当前余额
    this.api.checkLeadBalance().subscribe(res => {
      if (res['data']['principal']) {
        this.balance = res['data']['balance'];
        if (this.balance > 500) {
          if (this.balance <= 2000 && this.balance > 1000) {
            this.balance = 2000
            this.showCheckLeadBalance = true;
          } else if (this.balance <= 1000) {
            this.balance = 1000
            this.showCheckLeadBalance = true;
          }
        } else {
          this.showCheckLeadBalance = true;
          this.balance = 500
          this.balance_text = true;
          this.two_tone_color = "#FF0000";
        }
      }

    });
  }
  handleCancel() {
    this.showCheckLeadBalance = false;
  }

  getQwConfig() {
    this.api.getofficeQwConfig().subscribe(res => {
      let userQwConfig = res['data'] || null;
      localStorage.setItem('userQwConfig', JSON.stringify(userQwConfig));
    })
  }
  // #endregion

  ngOnInit() {
    this.getUserInfo();
    this.getMenu();
    this.checkLeadBalances();
    this.getQwConfig();
  }

  isFull = false;
  fullCheck() {
    this.isFull = !this.isFull;
  }
}
