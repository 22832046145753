import { Component, OnInit, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';
import { ModalRequestAddOrderComponent } from '../../component/modal-request-add-order/modal-request-add-order.component';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerUserComponent } from '../../component/drawer-user/drawer-user.component';
import { Global } from '@service/global.ts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-drawer-reques-examine',
  templateUrl: './drawer-reques-examine.component.html',
  styleUrls: ['./drawer-reques-examine.component.less']
})
export class DrawerRequesExamineComponent implements OnInit {
  @Input() value = '';
  @Input() serviceProviderId = '';
  @Input() editType = '';
  @Input() requestNo = '';
  formRz: FormGroup;
  constructor(
    public location: Location,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    public global: Global,
    fb: FormBuilder,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
    this.formRz = fb.group({
      openInvoiceType: [null, Validators.required],
      manageFlag: [null, Validators.required],
    });
  }
  // 0查看 1编辑
  type = 0;

  uploadParams: any = {};
  fileFJList: any = [];
  fileList: any;
  contractUrl: string;
  canSubmit = false;
  // 服务类型 0非业务类类型1业务类型
  serverTypeList = [
    { type: "NO_BUSINESS_TYPE", name: '非业务类' },
    { type: "BUSINESS_TYPE", name: '业务类型' },
  ];
  billingTypeList = [
    { type: "INVOICE_BEFORE_PAYMENT", name: '先开票后付款' },
    { type: "PAYMENT_BEFORE_INVOICE", name: '先付款后开票' },
    // { type: "INVOICE_CANNOT_PROVIDED", name: '无法提供发票' },
  ];
  itemPaymentList = [
    { type: "MARKETING_EXPENSES", name: '营销费用' },
    { type: "THIRD_PARTY_SERVICE_FEE", name: '第三方服务费' },
  ];
  manageFlagList = [
    { type: "1", name: '城市经理' },
    { type: "0", name: '非城市经理' },
  ];
  orderChoiceList: any = [];

  params = {
    requestPaymentAmount: 0,//合计请款金额
    requestPaymentDesc: null,//请款特殊情况说明
    withholdAmount: '0',//预扣成本
    attachmentUrls: [],//发票Urls
    bsServiceProviderRequestPaymentInvoices: [],//发票Urls
    openInvoiceType: null,//开票类型1先开票后付款2先付款后开票3无法提供发票
    relationOrders: [
    ],//关联订单数据  
    serviceType: null,//服务商情况类型0非业务类类型1业务类型
    serviceProviderId: null,//服务商ID
    paymentItem: null, //支付名目
  }
  serverDetail: any = {
    id: '',
    relationOrders: [],
    bindingOrderType: '',
    serviceType: ''
  };
  permissions = []; // 按钮权限数组
  ngOnInit() {
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
      console.log(Message)
    });
    this.getPaymentDetail();
  }
  edit() {
    this.type = 1;
  }
  cancal() {
    this.type = 0;
    this.getPaymentDetail();
  }
  // // 打开关联订单
  addOrderList(): void {
    if (!this.serviceProviderId) {
      this.message.error('该订单暂无服务商数据！');
      return
    }
    const modal = this.modalService.create({
      nzTitle: '添加关联订单',
      nzContent: ModalRequestAddOrderComponent,
      nzStyle: { top: '40px' },
      nzWidth: '80%',
      nzComponentParams: {
        value: this.serviceProviderId,
        orderChoiceList: this.orderChoiceList
      },
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        // 去重
        var mapOfCheckedItemInit = res;
        var finallRes = [];
        finallRes = mapOfCheckedItemInit.filter(item => !this.orderChoiceList.some(ele => ele.id === item.id));
        var orderChoiceList = this.orderChoiceList.concat(finallRes);
        this.orderChoiceList = orderChoiceList;
      }
    });
  }
  // 删除关联订单
  deleteRowContacts(id) {
    let newList = [];
    (this.orderChoiceList || []).forEach((item, index) => {
      if (item.id !== id) {
        newList.push(item);
      }
    });
    this.orderChoiceList = newList;
  }
  // 服务商信息
  serverList = [];
  // 获取服务商全称
  getServeList(serviceType) {
    let params = {
      name: '',
      serviceType: serviceType
    };
    this.api.getServiceProviders(params).subscribe(res => {
      this.serverList = res['data'];
    })
  }
  // 请款类型 选择
  changeServerType(event): void {
    this.params.serviceProviderId = null;
    this.params.requestPaymentAmount = 0;
    this.params.withholdAmount = '0';
    this.getServeList(event);
  }

  // 上传附件
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        attachmentUrls.push(item.response.data.fileUrl)
      })
      this.params.attachmentUrls = attachmentUrls;
    }
  }

  receiptAttachmentList = [];
  // 回单
  uploadChangeHd(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        var params = { "attachmentUrl": '', 'attachmentKind': 2 };
        params.attachmentUrl = item.response.data.fileUrl;
        attachmentUrls.push(params)
      })
      this.receiptAttachmentList = attachmentUrls;
    }
  }
  uploadTem(temp) {
    this.modalService.create({
      nzTitle: '上传回单',
      nzContent: temp,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        if (this.receiptAttachmentList.length == 0) {
          this.message.error('请上传回单');
          return false;
        }
        this.api.requestAttachmentSave({
          serviceProviderRequestPaymentId: this.value,
          bsServiceProviderRequestPaymentAttachmentList: this.receiptAttachmentList
        }).subscribe(res => {
          this.ngOnInit();
          this.receiptAttachmentList = [];
        })
      }
    });
  }

  submit() {
    var params = JSON.parse(JSON.stringify(this.params));
    var newParams = {
      // attachmentUrls: params.attachmentUrls,
      bsServiceProviderRequestPaymentInvoices: this.serverDetail.bsServiceProviderRequestPaymentInvoices,
      bindingOrderType: this.serverDetail.bindingOrderType,
      relationOrders: [],
      id: this.serverDetail.id,
      serviceType: this.serverDetail.serviceType,
      invoiceAmount: this.serverDetail.invoiceAmount,
    };
    // if (params.attachmentUrls.length > 20) {
    //   this.message.error('发票上传不可超过20个!');
    //   return
    // }
    (this.orderChoiceList || []).map((item, index) => {
      if (!item.orderId) {
        var listItem = {
          orderId: item.id,
          orderNo: item.orderNo,
          requestPaymentAmount: item.requestPaymentAmount
        }
        newParams.relationOrders.push(listItem)
      }
    })
    // 新发票接口
    this.api.uploadWithControllerSave(newParams).subscribe(res => {
      this.message.success('修改成功!');
      this.cancal();
    })
    // this.api.getPaymentEdit(newParams).subscribe(res => {
    //   this.cancal();
    // })
  }

  // 获取服务商申请详情
  getPaymentDetail() {
    this.api.getPaymentDetail(this.value).subscribe(res => {
      this.serverDetail = res['data'];
      this.orderChoiceList = this.serverDetail.relationOrders;
      (this.serverDetail['requestPaymentChecks'] || []).map((item, index) => {
        let id = item.checkUserId;
        if (id) {
          this.api.getSysUserInfos(id).subscribe(res => {
            this.serverDetail['requestPaymentChecks'][index].approveCompanyName = res['data'].companyName
            this.serverDetail['requestPaymentChecks'][index].approveDeptName = res['data'].deptName
          })
        }
      })
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.serverDetail.flowBusinessStepDTOList && this.serverDetail.flowBusinessStepDTOList.length > 0) {
        this.serverDetail.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    })
  }
  // 删除发票
  attachmentDel(id) {
    this.api.attachmentDel(id).subscribe(res => {
      this.message.success('删除成功!');
      this.getPaymentDetail();
    })
  }
  // 删除回单
  requestAttachmentDel(id) {
    this.api.requestAttachmentDel(id).subscribe(res => {
      this.message.success('删除成功!');
      this.getPaymentDetail();
    })
  }
  // 服务商开票情况
  changeInvoice(e) {
    if (e == 'INVOICE_CANNOT_PROVIDED') {
      this.params.withholdAmount = (this.params.requestPaymentAmount * 0.1).toFixed(2)
    } else {
      this.params.withholdAmount = '0';
    }
  }
  // 合计请款金额 与预扣
  getPriceByAmount() {
    this.params.requestPaymentAmount = 0;
    (this.orderChoiceList || []).map(item => {
      this.params.requestPaymentAmount += Number(item.requestPaymentAmount || 0)
    })
    if (this.params.openInvoiceType == 'INVOICE_CANNOT_PROVIDED') {
      this.params.withholdAmount = (this.params.requestPaymentAmount * 0.1).toFixed(2)
    } else {
      this.params.withholdAmount = '0';
    }
  }

  // 打开订单信息详情
  openOrder(id, type) {
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerUserComponent, { value: string, type: any }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        type: type,
      }
    });
  }
  // 修改服务商开票情况
  editOpenInvoice(content) {
    this.formRz.get('openInvoiceType').setValue(this.serverDetail.openInvoiceType);
    this.formRz.get('manageFlag').setValue(this.serverDetail.manageFlag.toString());
    // this.fileList = [];
    // this.params.attachmentUrls = [];
    this.modalService.create({
      nzTitle: '修改服务商开票情况',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formRz.reset();
        this.getPaymentDetail();
      },
      nzOnOk: () => {
        Object.keys(this.formRz.controls).map((key) => {
          this.formRz.controls[key].markAsDirty();
          this.formRz.controls[key].updateValueAndValidity();
        });
        if (!this.formRz.valid) {
          return false;
        }
        let data = {
          id: this.value,
          openInvoiceType: this.formRz.get('openInvoiceType').value,
          manageFlag: this.formRz.get('manageFlag').value,
          // attachmentUrls: this.params.attachmentUrls,
          bsServiceProviderRequestPaymentInvoices: this.serverDetail.bsServiceProviderRequestPaymentInvoices,
          invoiceAmount: this.serverDetail.invoiceAmount,
        }
        this.api.getPaymentEditInfo(data).subscribe(res => {
          this.message.success('修改成功');
          this.formRz.reset();
          this.getPaymentDetail();
        })
      }
    });
  }

  loading = false;
  // 发票上传
  noType = (file: File): boolean => {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.api.uploadWithController(formData).subscribe(res => {
      this.loading = false;
      this.serverDetail.bsServiceProviderRequestPaymentInvoices = [...this.serverDetail.bsServiceProviderRequestPaymentInvoices, res.data];
      this.invoiceAmountTotal();
    },
      (error) => {
        // 处理请求错误，如果有需要的话
        this.loading = false;
      });
    return false;
  }
  // 删除人员
  deleteRow(index: number): void {
    // this.reimbursementList = this.reimbursementList.filter(d => d.id !== id);
    let newList = JSON.parse(JSON.stringify(this.serverDetail.bsServiceProviderRequestPaymentInvoices));
    newList.splice(index, 1)
    this.serverDetail.bsServiceProviderRequestPaymentInvoices = newList;
    this.invoiceAmountTotal();
  }

  invoiceAmountTotal() {
    var invoiceAmount = 0;
    this.serverDetail.bsServiceProviderRequestPaymentInvoices.map((item, index) => {
      invoiceAmount += this.parseCurrencyString(item.totalAmount)
    })
    this.serverDetail.invoiceAmount = invoiceAmount;
  }
  parseCurrencyString(currencyStr) {
    // 移除货币符号（如果有的话）
    var numberStr = currencyStr.replace(/[^\d.,]/g, '');

    // 将逗号替换为小数点（如果使用的是逗号作为千位分隔符）
    numberStr = numberStr.replace(/,/g, '.');

    // 解析字符串为数字
    var number = parseFloat(numberStr) || 0;

    // 返回数字
    return number;
  }
}
