import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-server-all-detail',
  templateUrl: './drawer-server-all-detail.component.html',
  styleUrls: ['./drawer-server-all-detail.component.less']
})
export class DrawerServerAllDetailComponent implements OnInit {
  @Input() deliverMonth = '';
  @Input() userId = '';
  @Input() id = '';

  contractUrl: string;
  constructor(
    public api: Api,
  ) {
  }

  listOfData: any = [];
  serverInfo :any = {};
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 500
  };

  detailCompanyServer: any = {};
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getServerInfoList();
  }
  getServerInfoList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        deliverMonth: this.deliverMonth,
        userId: this.userId
      }
    }
    this.api.deliverTeamDetail(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }


  ngOnInit() {
    this.getServerInfoList();
    this.api.deliverTotalDetail(this.id).subscribe(res => {
      this.serverInfo = res['data']
    });
  }


}
