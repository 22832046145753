
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-modal-add-sign-enterprise',
  templateUrl: './modal-add-sign-enterprise.component.html',
  styleUrls: ['./modal-add-sign-enterprise.component.less']
})
export class ModalAddSignEnterpriseComponent implements OnInit {
  @Input() obj: any; // 
  @Input() type: string; //

  form: FormGroup;
  form2: FormGroup;
  companyList = []; // 用户权限，公司列表
  unionPayList = []; // 用户权限，公司列表

  constructor(
    private fb: FormBuilder,
    private api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      officeIds: [null, Validators.required],
      socialCreditCode: [null, Validators.required],
      corpName: [null, Validators.required],
      corpPapersType: [null, Validators.required],
      corpPapersNum: [null, Validators.required],
      corpPhone: [null, Validators.required],
      cardNo: [null, Validators.required],
      bankName: [null, Validators.required],
      bankPhone: [null, Validators.required],
      bankBranchName: [null, Validators.required],
      id: [null],
      fullNameOffice: [null, Validators.required],
      rate: [null, Validators.required],
    });

    this.form2 = fb.group({
      id: [null],
      officeIds: [null, Validators.required],
    })
  }
  loading = false;
  loadings = false;
  allianceOfficeName = null;//公司名称
  unionPay = null;//支行

  isLoading = false;
  //证件类型
  certificatesTypeList =
    [
      { type: "ID_CARD", name: '身份证' },
      { type: "PASSPORT", name: '护照' },
      { type: "MILITARY_ID_CARD", name: '军官证' },
      { type: "REENTRY_PERMIT", name: '回乡证' },
      { type: "TAIWAN_ID_CARD", name: '台胞证' },
      { type: "POLICE_ID_CARD", name: '警官证' },
      { type: "SOLDIER_ID_CARD", name: '士兵证' },
      { type: "OTHER_PAPERS", name: '其他证件' },
    ];
  changecompanyList(e) {
    let arr = this.companyList.filter(item => item.id == e)
    this.allianceOfficeName = arr[0].name;
  }
  submit() {
    this.loading = true;
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    params.allianceOfficeName = this.allianceOfficeName;
    params.unionPay = this.unionPay;
    this.loading = false;
    if (this.type == 'edit') {
      this.api.editSignedEnterprise(params).subscribe(res => {
        this.message.success('编辑成功！');
        this.cancel();
      });
    } else {
      this.api.addSignedEnterprise(params).subscribe(res => {
        this.message.success('新增成功！');
        this.cancel();
      });
    }
  }

  cancel() {
    this.modal.destroy();
  }

  userTamplateShow(UserListTemplate) {
    this.getBankListSelect();
    this.modalService.create({
      nzTitle: '选择支行',
      nzContent: UserListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 700,
      nzMaskClosable: false,
      // nzFooter:null,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => {
        console.log('123')
      }
    });
  }

  bankNameList = [];//用户
  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  }
  name: string;//用户名
  getBankListSelect() {
    const pamares = {
      current: this.pageCtrl.nzPageIndex,
      object: {
        name: this.name,
      },
      size: this.pageCtrl.nzPageSize
    }
    this.api.getSignedEnterpriseUnionPay(pamares).subscribe(res => {
      this.bankNameList = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }

  selectID = null;
  selectBank(data) {
    this.unionPay = data.unionPay;
    this.form.get('bankBranchName').setValue(data.bankName)
    this.selectID = data.unionPay;
  }

  resetList() {
    this.name = '';
    this.getBankListSelect();
  }

  toPage(page, n) {
    this.pageCtrl.nzPageIndex = page;
    this.getBankListSelect();
  };

  // 获取用户权限，公司或部门的权限
  getDataDimension() {
    this.api.getOfficeDataDimension({ type: 0 }).subscribe((res) => {
      this.companyList = res['data'];
    })

    // this.api.getOfficeDataDimension({ type: 1 }).subscribe((res) => {
    //   this.deptList = res['data'];
    // })

    // this.api.getDimensionAreaData().subscribe((res) => {
    //   this.areaList = res['data'];
    // })
  }

  getOfficesCompanyList(){
    this.api.getOfficesBySignedEnterpriseIdList({}).subscribe(res=>{
      (res['data'] || []).map((item, index) => {
        var listItem = {
          label: item.name,
          value: item.id,
        }
        this.companyList.push(listItem)
      })
      console.log(this.companyList)
    })
  }
  ngOnInit() {
    //this.getDataDimension();
    this.getOfficesCompanyList();
    if (this.type == 'edit') {
      this.form.get('id').setValue(this.obj);
      this.api.getSignedEnterpriseDetail(this.obj).subscribe(res => {
        let data = res['data']
        this.form.get('officeIds').setValue(data['officeIds']);
        this.form.get('socialCreditCode').setValue(data['socialCreditCode']);
        this.form.get('corpName').setValue(data['corpName']);
        this.form.get('corpPapersType').setValue(data['corpPapersType']);
        this.form.get('corpPapersNum').setValue(data['corpPapersNum']);
        this.form.get('corpPhone').setValue(data['corpPhone']);
        this.form.get('cardNo').setValue(data['cardNo']);
        this.form.get('bankName').setValue(data['bankName']);
        this.form.get('bankPhone').setValue(data['bankPhone']);
        this.form.get('bankBranchName').setValue(data['bankBranchName']);
        this.form.get('fullNameOffice').setValue(data['fullNameOffice']);
        this.form.get('rate').setValue(data['rate']);
        this.unionPay = data['unionPay']
      });
      console.log(this.form)
    }   
    if(this.type == 'update'){
      this.form2.get('id').setValue(this.obj);
      this.api.getSignedEnterpriseDetail(this.obj).subscribe(res => {
        let data = res['data']
        this.form2.get('officeIds').setValue(data['officeIds']);
      });
    }
  }
  
  // 更新组织架构
  updateSubmit() {
    this.loadings = true;
    Object.keys(this.form2.controls).map((key) => {
      this.form2.controls[key].markAsDirty();
      this.form2.controls[key].updateValueAndValidity();
    });
    if (!this.form2.valid) {
      this.loadings = false;
      return
    }
    let params = this.form2.getRawValue();
    this.loadings = false;
    this.api.updateOfficesBySignedEnterpriseId(params).subscribe(res => {
      this.message.success('更新成功！');
      this.cancel();
    });
  }
 
}
