import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Api} from '@service/Api';
import {UploadFile, UploadFilter} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-modal-examine-invoicing',
  templateUrl: './modal-examine-invoicing.component.html',
  styleUrls: ['./modal-examine-invoicing.component.less']
})
export class ModalExamineInvoicingComponent implements OnInit {
  params: any;
  form: FormGroup;
  loading = false;
  contractUrl: string;
  fileOBJ = [];
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.form = fb.group({
      invoiceAmount: new FormControl({value:null,disableds:true}),
      invoiceType: [null, Validators.required],
      invoiceTaxNo: [null, Validators.required],
      invoiceTitle: [null, Validators.required],
      bsOrderTaskSettInvoiceAttachmentList: [null],
      remark: [null],
    });
    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel() {
    this.modal.destroy();
  }
 
  // 保存申请
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    if(this.fileOBJ.length == 0){
      this.message.error('请上传发票')
      this.loading = false;
      return
    }
    if(this.params.type == 'gdjs'){
      //工单结算
      params.orderTaskSettId = this.params.id;
      params.bsOrderTaskSettInvoiceAttachmentList = this.fileOBJ;

      this.api.workOrderTaskSettInvoice(params).subscribe(res => {
        this.message.success('申请开票成功！');
        this.modal.destroy();
      }, err => {
        this.loading = false;
      })
    }else{
      //甩单结算
    
      params.orderSingleClearingApplyId = this.params.id;
      params.bsOrderSingleInvoiceAttachmentList = this.fileOBJ;
      this.api.singleOrderTaskSettInvoice(params).subscribe(res => {
        this.message.success('申请开票成功！');
        this.modal.destroy();
      }, err => {
        this.loading = false;
      })
    }
  }

  ngOnInit() {
    let amout = Math.abs(this.params.initiatorSettleAmount);
    this.form.get('invoiceAmount').setValue(amout);
    console.log(this.params)
  }

  // 上传
  uploadChange(info) {
    var attachmentUrls = [];
    if(info.file.status  == 'done' || info.file.status  == 'removed' && info.file.response.code == 200){
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl:item.response.data.fileUrl
        } 
        attachmentUrls.push(p)
      })
      this.fileOBJ = attachmentUrls
    }
  }
  
}
