import { Component, OnInit,Input } from '@angular/core';
import { Api } from '@service/Api';
import {NzModalRef} from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-config-achievements',
  templateUrl: './modal-config-achievements.component.html',
  styleUrls: ['./modal-config-achievements.component.less']
})
export class ModalConfigAchievementsComponent implements OnInit {
  @Input() id: string; // id

  achievements = []
  achievementsNew = [
    {
      achievementsConfigBillie:null,
      achievementsConfigType:null,
    }
  ]
  canSubmit = false;
  proportionTotal = 0;
  achievementsConfigTypeList = [
    // 绩效类型 0战列任务1工作品质2学习任务3其他
    {id:0,achievementsConfigTypeName:'战略任务'},
    {id:1,achievementsConfigTypeName:'工作品质'},
    {id:2,achievementsConfigTypeName:'学习任务'},
  ]
  constructor(
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) { }


  adminI = 0;
  // // 新增
  addForm(): void {
    this.achievements = [
      ...this.achievements,
      {
        id: `${this.adminI}`,
        achievementsConfigBillie:null,
        achievementsConfigType:null,
      }
    ];
    this.adminI++;
  }

  // 删除
  deleteAdminCommissionRow(data) {
    let newList = [];
    (this.achievements || []).forEach((item, index) => {
      if (item.id !== data.id) {
        newList.push(item);
      }
    });
    if(data.creatorId){
      this.api.userAchievementsConfigItemDelete(data.id).subscribe(res=>{
        this.message.success('删除成功')
      })
    }
    this.achievements = newList;
    this.changeProportion();
  }

  ngOnInit() {
    this.api.getUserAchievementsConfigItem(this.id).subscribe(res=>{
      this.achievements = res['data']
      if(this.achievements.length > 0){
        this.changeProportion();
      }
    })
  }

  cancel(){
    this.modal.destroy();
  } 

  changeProportion(){
    this.proportionTotal = 0;
    (this.achievements || []).map(item => {
      this.proportionTotal += Number(item.achievementsConfigBillie || 0)
    })
    this.proportionTotal = Number((this.proportionTotal).toFixed(2))
  }

  submitForm(){
    this.canSubmit = true;
    if(this.achievements.length > 0){
      (this.achievements || []).map((item)=>{
        if(item.achievementsConfigType == null){
          this.message.error('请选择绩效类型');
          this.canSubmit = false;
          return
        }
        if(item.achievementsConfigBillie == null){
          this.message.error('请输入绩效占比');
          this.canSubmit = false;
          return
        }
      })
    }else{
      this.message.error('请添加绩效!');
      this.canSubmit = false;
      return
    }

    if(this.proportionTotal != 100){
      this.message.error('占比合计必须为 100%!');
      this.canSubmit = false;
      return
    }

    if(!this.canSubmit){
      return
    }

    let arraybuffer = [];
    let last = -1;
    for(let i = 0; i < this.achievements.length; i++){
      let group = this.achievements[i].achievementsConfigType;
      if(last === group){
        this.message.error('绩效类型 不能重复选择!');
        this.canSubmit = false;
        return
      }else{
        arraybuffer.push(group)
        last = group
      }
    }

    let params = {
      inductionDeptId:this.id,
      sysUserAchievementsConfigItemList:this.achievements
    }
    this.api.addUserAchievementsConfigItem(params).subscribe(res=>{
      this.cancel();
    })
  }
}
