import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-examine',
  templateUrl: './modal-examine.component.html',
  styleUrls: ['./modal-examine.component.less']
})

export class ModalExamineComponent implements OnInit {
  //请款审核
  //提成结算审核 
  //服务商入库审核

  @Input() params: any;
  checkDesc = null;//入库描述
  isCheckNumber: number;//是否已经全部选择 1全选，2未选完
  checkOptions = [];
  cashierPaymentCode = null;//出纳支付 CASHIER_PAYMENT
  contractUrl: string;
  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }

  cancel() {
    this.modal.destroy();
  }

  loading = false;
  allServerCheckedType = false;
  indeterminateServer = true;
  updateServerAllChecked(): void {
    this.indeterminateServer = false;
    if (this.allServerCheckedType) {
      this.checkOptions = this.checkOptions.map(item => ({
        ...item,
        checked: true
      }));
    } else {
      this.checkOptions = this.checkOptions.map(item => ({
        ...item,
        checked: false
      }));
    }
  }
  updateSingleChecked(event) {
    if (this.checkOptions.every(item => !item.checked)) {
      this.allServerCheckedType = false;
      this.indeterminateServer = false;
    } else if (this.checkOptions.every(item => item.checked)) {
      this.allServerCheckedType = true;
      this.indeterminateServer = false;

    } else {
      this.indeterminateServer = true;
    }
  }
  submitForm(type) {
    this.loading = true;
    if (!this.params.agree) {
      if (!this.checkDesc) {
        this.message.error('请输入驳回理由');
        this.loading = false;
        return
      }
    } else {
      if (this.checkOptions.every(item => item.checked)) {
        this.isCheckNumber = 1
      } else {
        this.isCheckNumber = 2
        this.message.error('请勾选全部');
        this.loading = false;
        return
      }
    }

    switch (this.params.type) {
      case 'rk'://入库审核
        this.userExamineCheck();
        break;
      case 'qk'://请款审核
        this.requesExamineCheck(type);
        break;
      case 'tcjs'://提成结算审核
        this.commissiSettlementExamineCheck();
        break;
      case 'customerAudit':
        this.customerApplyCheck();
        break;
      case 'rsbh':
        //提成结算审核 驳回
        this.rejectSettlementCheck();
        break;
      case 'gzsh':
        //工资审核审核 驳回
        this.salaryRevviewCheck();
        break;
    }
  }

  ngOnInit() {
    /**
     * 入库checklist
     */
    switch (this.params.type) {
      case 'rk':
        this.examineCheckList();
        break;
      case 'qk':
        this.requesExamineCheckList();
        break;
      case 'tcjs':
        if (this.params.approveStatus != 'HRBP') {
          this.examineCheckList();
        }
        break;
      default:
        console.log('error')
        break;
    }
    this.cashierPaymentCode = this.params.code;
  }

  /**
    params={
      providerId
      agree	boolean 同意 拒绝
      type：名称 rk 入库审核, qk 请款审核, tcjs，提成结算审核
      approveStatus 2 待初审 3 待复审
      code: 待初审 PROVIDER_FIRST   待复审 PROVIDER_FINAL
    }
  */
  //入库审核
  userExamineCheck() {
    let params = {
      agree: this.params.agree,
      // approveStatus: this.params.approveStatus,
      checkDesc: this.checkDesc,
      providerId: this.params.providerId,
    }
    this.api.ServiceauditProvider(params).subscribe(res => {
      this.modal.destroy(res);
    });
  }
  examineCheckList() {
    this.checkOptions = [];
    this.api.getSysParametersByParentCode({ parentCode: this.params.code }).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        var obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].name;
        obj['checked'] = false;
        this.checkOptions.push(obj)
      }
    });
  }
  //客户审核
  customerApplyCheck() {
    this.api.customerApplyChecks({
      customerApplyId: this.params.providerId,
      checkStatus: 0,
      checkDesc: this.checkDesc,
    }).subscribe(res => {
      this.message.success('审核成功!');
      this.modal.destroy();
    })
  }

  /**
    params={
      providerId
      agree: 同意 PASS   拒绝 NOT_PASS
      type：名称 rk 入库审核, qk 请款审核, tc，提成审核
      approveStatus: 先开票后付款 INVOICE_BEFORE_PAYMENT  先付款后开票 PAYMENT_BEFORE_INVOICE   无法提供发票 INVOICE_CANNOT_PROVIDED
      code: 部门主管审核 DEPARTMENT_HEAD_AUDIT  增值主管审核 VALUE_ADDED_SUPERVISOR_AUDIT  会计审核 ACCOUNTING_AUDIT   高级会计审核 SENIOR_ACCOUNTING_AUDIT  出纳支付 CASHIER_PAYMENT
    }
  */
  startDate = null;
  uploadReceiptUrls = []; // 上传回单
  //请款审核
  requesExamineCheck(type) {
    var params = {
      checkStatus: this.params.agree ? 'PASS' : 'NOT_PASS',
      // checkType: this.params.code,
      checkDesc: this.checkDesc,
      id: this.params.providerId,
      openInvoiceType: this.params.approveStatus,
      actualPaymentDate: null,
      uploadReceiptUrls: null,
    }

    if (this.params.code == 'cashier' && this.params.agree) {
      if (this.startDate === null) {
        this.message.error('请选择实际支付日期');
        this.loading = false;
        return
      }
      if (this.uploadReceiptUrls.length <= 0) {
        this.message.error('请上传回单');
        this.loading = false;
        return
      }
      params.actualPaymentDate = this.dataToStr(this.startDate);// 实际支付日期
      params.uploadReceiptUrls = this.uploadReceiptUrls;// 上传回单
    }

    this.api.serviceRequestPayments(params).subscribe(res => {
      this.modal.destroy(res);
    });

    //高级会计审核 增加打印
    if (type == 'dy') {
      //this.router.navigate(["/forms/add-printing"],{queryParams:{id:id}})
      window.open("/forms/add-printForm/?requestNo=" + this.params.data.requestNo + '&openInvoiceType=' + this.params.data.openInvoiceType + '&requestPaymentStatus=' + this.params.data.requestPaymentStatus);
    }
  }
  requesExamineCheckList() {
    let params = {
      checkType: this.params.code,
      openInvoiceType: this.params.approveStatus
    }
    this.checkOptions = [];
    this.api.getCheckOptionData(params).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        var obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].name;
        obj['checked'] = false;
        this.checkOptions.push(obj)
      }
    });
  }
  //提成结算审核
  commissiSettlementExamineCheck() {
    let params = {
      agree: this.params.agree,
      checkDesc: this.checkDesc,
      settlementId: this.params.providerId,
      // settlementStatus: this.params.approveStatus,
    }
    this.api.commissiSettlementExamine(params).subscribe(res => {
      this.modal.destroy(res);
    });
  }
  //提成结算审核
  rejectSettlementCheck() {
    let params = {
      agree: this.params.agree,
      checkDesc: this.checkDesc,
      settlementId: this.params.providerId,
    }
    this.api.rejectSettlement(params).subscribe(res => {
      this.modal.destroy();
    });
  }
  //工资审核
  salaryRevviewCheck() {
    let params = {
      agree: this.params.agree,
      checkDesc: this.checkDesc,
      settlementId: this.params.providerId,
    }
    this.api.rejectSettlement(params).subscribe(res => {
      this.modal.destroy();
    });
  }

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        attachmentUrls.push(item.response.data.fileUrl)
      })
      this.uploadReceiptUrls = attachmentUrls;
    }
  }
  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }
} 
