import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-modal-company',
  templateUrl: './modal-company.component.html',
  styleUrls: ['./modal-company.component.less']
})
export class ModalCompanyComponent implements OnInit {
  @Input() id: string; // 公司id
  @Input() customerId: string; // 客户id

  form: FormGroup;
  loading = false;
  companyTypeList = [
    { id: 0, name: '公司' },
    { id: 1, name: '社会组织' },
    { id: 3, name: '香港公司' },
    { id: 4, name: '政府机构' },
    { id: 5, name: '台湾公司' },
    { id: 6, name: '基金会' },
    { id: 7, name: '医院' },
    { id: 8, name: '海外公司' },
    { id: 9, name: '律师事务所' },
    { id: 10, name: '学校' },
    { id: -1, name: '其他' }
  ];
  loadDeptData: any;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
    this.form = fb.group({
      id: [null,],
      name: [null, Validators.required],
      operName: [null, Validators.required],
      regAddress: [null],
      provinceId: [null],
      province: [null],
      cityId: [null],
      city: [null],
      creditCode: [null],
      entType: [null],
      registCapi: [null,],
      scale: [null],
      scope: [null],
      startDate: [null],
      operIdCard: [null],
      operPhone: [null],
      yyly: [null],
      zykh: [null],
      yjhjj: [null],
      zyys: [null],
      openDate: [null],
      openAccount: [null],
      openBankBranch: [null],
      enterpriseType: [null],
      companyPersonCount: [null],
      financing: [null],
      businessLicenseUrl: [null],
      industryTypeIdArr: [null], //行业数组
      parentIndustryTypeId: [null], //一级行业id
      industryTypeId: [null], //所在行业id
      industryTypeName: '',
      status: [null],
      approvalDate: [null],
      companyPhone: [null],
      moreCompanyPhone: [null],
      email: [null],
      moreEmail: [null],
      taxNum: [null],
      regNo: [null],
      orgNo: [null],
      insuredPersons: [null],
      onceName: [null],
      englishName: [null],
      website: [null],
      newReportAddress: [null],
      highNewFlag: [null],
      specializedFlag: [null],
      technologySmallFlag: [null],
      listedFlag: [null],

    });
  }
  options = [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ];
  contractUrl: string;
  checkIndex: false;
  cancel() {
    this.modal.destroy();
  }
  settlementStatusList = [
    { 'value': '0', 'name': '有限责任公司' },
    { 'value': '1', 'name': '股份有限公司' },
    { 'value': '2', 'name': '股份合作公司' },
    { 'value': '3', 'name': '国有企业' },
    { 'value': '4', 'name': '集体所有制' },
    { 'value': '5', 'name': '个体工商户' },
    { 'value': '6', 'name': '独资企业' },
    { 'value': '7', 'name': '有限合伙' },
    { 'value': '8', 'name': '普通合伙' },
    { 'value': '9', 'name': '外商投资企业' },
    { 'value': '10', 'name': '港、澳、台商投资企业' },
    { 'value': '11', 'name': '联营企业' },
    { 'value': '12', 'name': '私营企业' },
  ];
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }

    let params = this.form.getRawValue();
    // 所属行业 start
    var industryTypeIdArr = this.form.controls['industryTypeIdArr'].value;
    if (industryTypeIdArr && industryTypeIdArr.length > 0) {
      params.parentIndustryTypeId = industryTypeIdArr[0];
      params.industryTypeId = industryTypeIdArr[1] || null;
    }
    // 所属行业 end
    params.customerId = this.customerId;
    // if (params.startDate != null) {
    //   params.startDate = Utils.dateFormat(params.startDate, 'yyyy-MM-dd hh:mm:ss');
    // }
    // if (params.openDate != null) {
    //   params.openDate = Utils.dateFormat(params.openDate, 'yyyy-MM-dd hh:mm:ss');
    // }
    // if (params.approvalDate != null) {
    //   params.approvalDate = Utils.dateFormat(params.approvalDate, 'yyyy-MM-dd hh:mm:ss');
    // }
    if (params.name.length < 6) {
      this.message.error('公司名称不能少于六个文字', {
        nzDuration: 5000
      });
      this.loading = false;
      return
    }

    if (this.hasId) {
      this.api.updateCompany(params).subscribe(res => {
        this.message.success('公司信息编辑成功！');
        this.modal.destroy();
      }, err => {
        this.loading = false;
      })
    } else {
      this.api.addCompanyInfo(params).subscribe(res => {
        this.message.success('公司信息编辑成功！');
        this.modal.destroy();
      }, err => {
        this.loading = false;
      })
    }
  }
  // 判断公司是否重复，有的话就添加信息
  changeName() {

    if (this.form.value['name'].length < 6) {
      this.message.error('公司名称不能少于六个文字', {
        nzDuration: 5000
      });
      return
    }

    if (this.form.value['name']) {
      this.api.findByCompanyName({ name: this.form.value['name'] }).subscribe(res => {
        const obj = res['data'];
        if (obj.id) {
          this.modalService.confirm({
            nzTitle: '提示',
            nzContent: '<b>该公司已存在与系统中,是否确定绑定?</b>',
            nzOnOk: () => this.changeForm(obj),
            nzOnCancel: () => {
              this.form.controls['name'].setValue(null);
              this.delForm();
            }
          });
        }
      });
    }
  }

  templateList: any;//列表
  companyName: string;
  searchLoading = false;
  //选择模板
  SendTeplateData = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  listOfControl = [];
  refreshStatus(e, id): void {
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.SendTeplateData = this.templateList.filter(item => item['id'] == id);
  }
  NoteListTemplateShow(addCompanyTemplate) {
    this.listOfControl = [];
    this.modalService.create({
      nzTitle: '快捷搜索公司',
      nzContent: addCompanyTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 1200,
      nzMaskClosable: false,
      nzOnOk: () => this.addParentNote()
    });
  }
  addParentNote() {
    let obj = this.SendTeplateData[0];
    // if(!obj){
    //   this.message.error('请选择公司！');
    //   return
    // }
    this.form.controls['name'].setValue(obj.companyName);
    this.form.controls['operName'].setValue(obj.faRen);
    this.form.controls['regAddress'].setValue(obj.address);
    this.form.controls['provinceId'].setValue(obj.provinceId);
    this.form.controls['province'].setValue(obj.provinceName);
    // this.form.controls['cityId'].setValue(obj.cityId);
    // this.form.controls['city'].setValue(obj.city);
    this.form.controls['creditCode'].setValue(obj.creditCode);
    this.form.controls['registCapi'].setValue(obj.regM);
    this.form.controls['scope'].setValue(obj.bussinessDes);
    this.form.controls['highNewFlag'].setValue(obj.highNewFlag);
    this.form.controls['specializedFlag'].setValue(obj.specializedFlag);
    this.form.controls['technologySmallFlag'].setValue(obj.technologySmallFlag);
    this.form.controls['listedFlag'].setValue(obj.listedFlag);
    this.form.controls['enterpriseType'].setValue(obj.regType);
    this.form.controls['startDate'].setValue(obj.issueTime);
  }

  //查询
  inquireList() {
    this.searchLoading = true;
    if (!this.companyName) {
      this.message.warning('请输入公司名称');
      this.searchLoading = false;
      return
    }
    this.api.orgCompanyFindByQzyCompanyName({ companyName: this.companyName }).subscribe(res => {
      this.templateList = res['data'];
      this.searchLoading = false;
    }, err => {
      this.searchLoading = false;
    })
  }

  // 清楚值 公司
  delForm() {
    // this.form.controls['id'].setValue(null);
    this.form.controls['operName'].setValue(null);
    this.form.controls['regAddress'].setValue(null);
    this.form.controls['provinceId'].setValue(null);
    this.form.controls['cityId'].setValue(null);
    this.form.controls['province'].setValue(null);
    this.form.controls['city'].setValue(null);
    this.form.controls['creditCode'].setValue(null);
    this.form.controls['entType'].setValue(null);
    this.form.controls['registCapi'].setValue(null);
    this.form.controls['scale'].setValue(null);
    this.form.controls['scope'].setValue(null);
    this.form.controls['startDate'].setValue(null);
    // 新增字段
    this.form.controls['operIdCard'].setValue(null);
    this.form.controls['operPhone'].setValue(null);
    this.form.controls['yyly'].setValue(null);
    this.form.controls['zykh'].setValue(null);
    this.form.controls['yjhjj'].setValue(null);
    this.form.controls['zyys'].setValue(null);
    this.form.controls['openDate'].setValue(null);
    this.form.controls['openAccount'].setValue(null);
    this.form.controls['openBankBranch'].setValue(null);
    this.form.controls['enterpriseType'].setValue(null);
    this.form.controls['companyPersonCount'].setValue(null);
    this.form.controls['financing'].setValue(null);
    this.form.controls['parentIndustryTypeId'].setValue(null);
    this.form.controls['industryTypeId'].setValue(null);
    this.form.controls['industryTypeIdArr'].setValue([]);
    this.form.controls['industryTypeName'].setValue(null);
    this.form.controls['businessLicenseUrl'].setValue(null);
    this.form.controls['status'].setValue(null);
    this.form.controls['approvalDate'].setValue(null);
    this.form.controls['companyPhone'].setValue(null);
    this.form.controls['moreCompanyPhone'].setValue(null);
    this.form.controls['email'].setValue(null);
    this.form.controls['moreEmail'].setValue(null);
    this.form.controls['taxNum'].setValue(null);
    this.form.controls['regNo'].setValue(null);
    this.form.controls['orgNo'].setValue(null);
    this.form.controls['insuredPersons'].setValue(null);
    this.form.controls['onceName'].setValue(null);
    this.form.controls['englishName'].setValue(null);
    this.form.controls['website'].setValue(null);
    this.form.controls['newReportAddress'].setValue(null);
    this.fileList = []

  }
  // 赋值 公司
  changeForm(obj) {
    this.form.controls['id'].setValue(obj.id);
    this.form.controls['name'].setValue(obj.name);
    this.form.controls['operName'].setValue(obj.operName);
    this.form.controls['regAddress'].setValue(obj.regAddress);
    if (obj.provinceId) {
      this.form.controls['provinceId'].setValue(String(obj.provinceId));
      this.form.controls['province'].setValue(obj.province);
      this.form.controls['cityId'].setValue(obj.cityId);
      this.form.controls['city'].setValue(obj.city);
    }

    this.form.controls['creditCode'].setValue(obj.creditCode);
    this.form.controls['entType'].setValue(obj.entType);
    this.form.controls['registCapi'].setValue(obj.registCapi);
    this.form.controls['scale'].setValue(obj.scale);
    this.form.controls['scope'].setValue(obj.scope);
    this.form.controls['startDate'].setValue(obj.startDate);
    this.form.controls['status'].setValue(obj.status);
    this.form.controls['approvalDate'].setValue(obj.approvalDate);
    this.form.controls['companyPhone'].setValue(obj.companyPhone);
    this.form.controls['moreCompanyPhone'].setValue(obj.moreCompanyPhone);
    this.form.controls['email'].setValue(obj.email);
    this.form.controls['moreEmail'].setValue(obj.moreEmail);
    this.form.controls['taxNum'].setValue(obj.taxNum);
    this.form.controls['regNo'].setValue(obj.regNo);
    this.form.controls['orgNo'].setValue(obj.orgNo);
    this.form.controls['insuredPersons'].setValue(obj.insuredPersons);
    this.form.controls['onceName'].setValue(obj.onceName);
    this.form.controls['englishName'].setValue(obj.englishName);
    this.form.controls['website'].setValue(obj.website);
    this.form.controls['newReportAddress'].setValue(obj.newReportAddress);
    // 新增字段
    this.form.controls['operIdCard'].setValue(obj.operIdCard);
    this.form.controls['operPhone'].setValue(obj.operPhone);
    this.form.controls['yyly'].setValue(obj.yyly);
    this.form.controls['zykh'].setValue(obj.zykh);
    this.form.controls['yjhjj'].setValue(obj.yjhjj);
    this.form.controls['zyys'].setValue(obj.zyys);
    this.form.controls['openDate'].setValue(obj.openDate);
    this.form.controls['openAccount'].setValue(obj.openAccount);
    this.form.controls['openBankBranch'].setValue(obj.openBankBranch);
    this.form.controls['enterpriseType'].setValue(obj.enterpriseType);
    this.form.controls['companyPersonCount'].setValue(obj.companyPersonCount);
    this.form.controls['financing'].setValue(obj.financing);
    // parentIndustryTypeId: [null], //一级行业id
    //   industryTypeId: [null], //所在行业id
    this.form.controls['parentIndustryTypeId'].setValue(obj.parentIndustryTypeId);
    this.form.controls['industryTypeId'].setValue(obj.industryTypeId);
    this.form.controls['industryTypeName'].setValue(obj.industryTypeName);
    if (obj.parentIndustryTypeId) {
      this.form.controls['industryTypeIdArr'].setValue([obj.parentIndustryTypeId]);
    }
    if (obj.industryTypeId) {
      this.form.controls['industryTypeIdArr'].setValue([obj.parentIndustryTypeId, obj.industryTypeId]);
    }
    this.form.controls['businessLicenseUrl'].setValue(obj.businessLicenseUrl);
    if (obj.businessLicenseUrl) {
      this.fileList = [{
        uid: 1,
        name: '营业执照附件',
        status: 'done',
        response: 'success',
        url: obj.businessLicenseUrl
      }]
    }
    // 其他信息
    this.form.controls['listedFlag'].setValue(obj.listedFlag);
    this.form.controls['technologySmallFlag'].setValue(obj.technologySmallFlag);
    this.form.controls['specializedFlag'].setValue(obj.specializedFlag);
    this.form.controls['highNewFlag'].setValue(obj.highNewFlag);


  }
  fileList = []; // 文件列表
  // 上传营业制作
  uploadChange(info) {
    if (info.file.status === 'done' && info.file.response.code === 200) {
      // this.fileList = [info.file.response.data.fileUrl];
      this.fileList = [{
        uid: info.file.response.data.id,
        name: '营业执照附件',
        status: 'done',
        response: 'success',
        url: info.file.response.data.fileUrl,
      }]
      this.form.controls['businessLicenseUrl'].setValue(info.file.response.data.fileUrl);
    }
  }
  // 获取选项的列表 start
  // --初始化学历 educational_background
  // --初始化性格 user_character
  // --客户营销方式 marketing_mode
  // --首单意向状态 first_order_intention_status
  // --上次电话状态 phone_status
  educational_background: [];
  user_character: [];
  listParame = ['educational_background', 'user_character'];
  getParameters(code, func) {
    this.api.getSysParametersByParentCode({ parentCode: code }).subscribe(res => {
      let resp = res['data'];
      func(resp);
    });
  };
  getParametList() {
    (this.listParame).map(item => {
      switch (item) {
        case 'educational_background':
          this.getParameters(item, (resp) => {
            this.educational_background = resp;
          })
          break;
        case 'user_character':
          this.getParameters(item, (resp) => {
            this.user_character = resp;
          })
          break;
        default:
          break;
      }
    })
  }
  industryType_Data = [];
  getSysParametersTreeByCode() {
    this.api.getSysParametersTreeByCode({ code: 'COMPANY_INDUSTRY_TYPE' }).subscribe(res => {
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.industryType_Data = strs;
    });
  };
  industryModel(industrySearch) {
    this.modalService.create({
      nzTitle: '选择行业类型',
      nzContent: industrySearch,
      nzStyle: { top: '30px' },
      nzWidth: 300,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        let industryTypeIdArr = this.form.controls['industryTypeIdArr'].value;
        (this.industryType_Data || []).map(item => {
          if (industryTypeIdArr && industryTypeIdArr.length > 0) {
            if (item.id == industryTypeIdArr[0]) {
              this.form.controls['industryTypeName'].setValue(item.name);
              (item.children || []).map(itemChild => {
                if (itemChild.id == industryTypeIdArr[1]) {
                  this.form.controls['industryTypeName'].setValue(itemChild.name);
                }
              })
            }
          }
        })
      }
    });
  };
  onChanges(e) {
    console.log(e)
  }
  hasId = null;

  // 地址 start
  provinceList: [];    // 省份列表
  cityList: [];        // 城市列表
  // 获取省市列表
  getProvince(level, id?) {
    const params = {
      level: level,
    }
    id ? params['parentId'] = id : null;
    this.api.getCityList(params).subscribe((res) => {
      if (level == 1) {
        this.provinceList = res['data'];
      } else {
        this.cityList = res['data'];
      }
    })
  }

  // 查询城市列表
  getCityList() {
    const id = this.form.controls.provinceId.value;
    // this.form.controls['province'].setValue(name);
    this.form.get('cityId').setValue(null);
    for (var i in this.provinceList) {
      if (this.provinceList[i]['id'] == id) {
        this.form.controls['province'].setValue(this.provinceList[i]['name']);
        break;
      }
    }
    this.getProvince(2, id);
  }
  // 城市改变
  getCityCur() {
    const id = this.form.controls.cityId.value;
    for (var i in this.cityList) {
      if (this.cityList[i]['id'] == id) {
        this.form.controls['city'].setValue(this.cityList[i]['name']);
        break;
      }
    }
  }
  // 地址 end
  ngOnInit() {
    this.hasId = this.id;
    if (this.id) {
      this.api.getCompanyDetails({ id: this.id }).subscribe(res => {
        const obj = res['data'];
        this.changeForm(obj)
      });
    }
    this.getParametList();
    this.getSysParametersTreeByCode();
    this.getProvince(1);
  }

}
