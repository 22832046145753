import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-fling-single',
  templateUrl: './drawer-fling-single.component.html',
  styleUrls: ['./drawer-fling-single.component.less']
})
export class DrawerFlingSingleComponent implements OnInit {
  @Input() value = '';
  @Input() serviceProviderId = '';
  @Input() editType = '';
  @Input() requestNo = '';
  constructor(
    private NzDrawerRef: NzDrawerRef<string>,
    public location: Location,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  // 0查看 1编辑
  type = 0;

  uploadParams :any= {};
  fileFJList :any= [];
  contractUrl: string;
  canSubmit = false;

  serverDetail :any = {
    id: '',
    relationOrders: [],
    bindingOrderType: '',
    serviceType: ''
  };
  invoicegDetail;
  ngOnInit() {
    this.getPaymentDetail();
  }
  // 获取甩单申请详情
  getPaymentDetail() {
    this.api.getClearingDetail(this.value).subscribe(res => {
      this.serverDetail = res['data'];
    })
    this.api.singleOrderTaskSettInvoicegApplyId(this.value).subscribe(res => {
      this.invoicegDetail = res['data'];
      console.log(this.invoicegDetail)
    })
  } 


}
