import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-salary-application',
  templateUrl: './drawer-salary-application.component.html',
  styleUrls: ['./drawer-salary-application.component.less']
})
export class DrawerSalaryApplicationComponent implements OnInit {
  @Input() value = '';
  constructor(
    public message: NzMessageService,
    public router: Router,
    public api: Api,
  ) { }

  userApplyInfo;
  userApplySysUserSalaryList = []
  index: any;
  ngOnInit() {
    this.getUserApplyInfo();
  }
  // 切换菜单
  activeList = [0];    // 已激活菜单

  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 1:
        break;
      default:
        break;
    }
  };

  getUserApplyInfo() {
    this.api.userSalaryApplyDetail(this.value).subscribe(res => {
      this.userApplyInfo = res['data']
      this.userApplySysUserSalaryList = res['data'].sysUserSalaryList;
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.userApplyInfo.flowBusinessStepDTOList && this.userApplyInfo.flowBusinessStepDTOList.length > 0) {
        this.userApplyInfo.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    })
  };
}
