import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
// import { DrawerEntpriseServerComponent } from '../drawer-entprise-server/drawer-entprise-server.component';
import { DrawerEntpriseServerComponent } from '../../component/drawer-entprise-server/drawer-entprise-server.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-transfer-server',
  templateUrl: './modal-transfer-server.component.html',
  styleUrls: ['./modal-transfer-server.component.less']
})
export class ModalTransferServerComponent implements OnInit {
  value: any;
  constructor(
    public api: Api,
    private modal: NzModalRef,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
  ) { }



  isAllChecked = false; // 是否全选
  isOperating = false;  //
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = { // 页面查询参数
    companyName: null,
    companyServerNo: null,
    accountId: null,
    sendId: null,
  };
  listOfData = [];
  ngOnInit() {
    // this.handoverType = '0';
    // this.params.sendId = this.value.id
    this.params.accountId = this.value.id;
    this.getPubCustomerList();
    this.getUserList();
  }
  refreshStatus(): void {
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.id]).length;
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  // 翻页
  toPage(page) {
    console.log(page);
    this.pageCtrl.nzPageIndex = page;
    this.getPubCustomerList();
  }

  // 获取客户列表
  getPubCustomerList() {
    // if (!this.handoverType) {
    //   return false;
    // }
    var objParams = JSON.parse(JSON.stringify(this.params))
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: objParams
    };
    this.api.handoverServerInfoList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;

    });
  }
  typeList = [
    {
      id: '0',
      name: '财税顾问'
    },
    // {
    //   id: '1',
    //   name: '主办会计'
    // }
  ]; // 用户列表
  // handoverType = null;//交接类型
  userList = []; // 用户列表
  transferId = null;//交接员工ID
  getUserList(): void {
    // 不选择部门，直接选择人
    // const params = {
    //   current: 1,
    //   size: 100,
    //   object: {
    //     objId: JSON.parse(localStorage.getItem('userInfo')).dept.id,
    //     type: 1,
    //   },
    // }
    // this.api.getUserOfficeList(params).subscribe(res => {
    //   this.userList = res['data'].records;
    //   // item['data'].records;
    // })
    this.api.getUserList().subscribe(res => {
      this.userList = res['data'];
    })
  }
  // 打开客户详情
  open(data) {
    const modal = this.drawerService.create<DrawerEntpriseServerComponent,
      {
        value: string,
        companyServerNo: string,
        manageName: string,
        chargeName: string,
        companyServerPeriod: string,
        companyServerStatus: string,
        serverType: number, //只有我负责的才能编辑
        typeFlag: boolean,
        detail: Object
      }, string>({
        nzWidth: 890,
        nzWrapClassName: '服务详情',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: DrawerEntpriseServerComponent,
        nzContentParams: {
          value: data.id,
          companyServerNo: data.companyServerNo,
          manageName: data.manageName,
          chargeName: data.chargeName,
          companyServerPeriod: data.companyServerPeriod,
          companyServerStatus: data.companyServerStatus,
          serverType: null,
          typeFlag: data.typeFlag,
          detail: data,
        }
      });

  }

  reset() {
    this.params.companyServerNo = null;
    this.params.companyName = null;
    // this.params.accountId = null;
    this.params.sendId = null;
    this.getPubCustomerList();
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getPubCustomerList();
  }

  cancel() {
    this.modal.destroy();
  }
  onTypeChange(e) {
    if (e == '0') {
      this.params.sendId = this.value.id
      this.params.accountId = null;
    } else if (e == '1') {
      this.params.sendId = null;
      this.params.accountId = this.value.id
    } else {
      return false;
    }
    this.query();
  };
  submitForm() {
    if (!this.transferId) {
      this.message.error('请选择对接员工');
      return false;
    }
    // if (!this.handoverType) {
    //   this.message.error('请选择对接类型');
    //   return false;
    // }
    let list = [];
    this.listOfData
      .filter(item => this.mapOfCheckedId[item.id])
      .map(v => list.push(v.id));

    if (list.length === 0) {
      this.message.warning('请选择企业服务！');
      return
    };
    var params = {
      "companyServerIdList": list,
      // 'handoverType': this.handoverType,
      "nowUserId": this.transferId,
      "oriUserId": this.value.id
    }
    this.api.serveSaveHandover(params).subscribe(res => {
      this.message.success('提交成功！');
      this.modal.destroy();
      // item['data'].records;
    })
  }

  transferAll() {
    if (!this.transferId) {
      this.message.error('请选择对接人');
      return false;
    }
    var params = {
      'allStatus': 1,
      "nowUserId": this.transferId,
      "oriUserId": this.value.id
    }
    this.api.serveSaveHandover(params).subscribe(res => {
      this.message.success('提交成功！');
      this.modal.destroy();
    })
  }
}
