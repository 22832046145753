import { Component, Input, OnInit } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-product-extra',
  templateUrl: './drawer-product-extra.component.html',
  styleUrls: ['./drawer-product-extra.component.less']
})
export class DrawerProductExtraComponent implements OnInit {
  @Input() value = '';

  constructor(
    public api: Api,
  ) { }
  extraInfo;    // 客户信息
  productList = [];
  // 获取订单详情
  getOrderDetail() {
    this.api.getProductConfigDetail(this.value).subscribe(res => {
      this.extraInfo = res['data'];
      this.productList = this.extraInfo.productName.split(',');
    })
  }
  ngOnInit() {
    this.getOrderDetail();
  }

}
