import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Global } from '@service/global.ts';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawerReimbursementsApplyComponent } from '../../component/drawer-reimbursements-apply/drawer-reimbursements-apply.component';

@Component({
  selector: 'app-drawer-buget-used-detail',
  templateUrl: './drawer-buget-used-detail.component.html',
  styleUrls: ['./drawer-buget-used-detail.component.less']
})
export class DrawerBugetUsedDetailComponent implements OnInit {
  @Input() value = '';
  @Input() budgetConfigId = '';
  @Input() nominalCate = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    public global: Global,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  tabNum = 0;
  listOfData = [];
  permissions = []; // 按钮权限数组
  ngOnInit() {
    this.getBudgetDetail();
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
    });
  }

  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getBudgetDetail();
  }
  getBudgetDetail() {
    var object = {
      budgetConfigId: this.budgetConfigId,
      nominalCate: this.nominalCate,
    }
    if (this.value) {
      object['nominalId'] = this.value;
    }
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: object,
    };
    this.api.budgetDetail(params).subscribe((res) => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }

  // 打开报销详情
  openApply(id) {
    // 报销
    const modal = this.drawerService.create<DrawerReimbursementsApplyComponent, { value: string, type: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerReimbursementsApplyComponent,
      nzContentParams: {
        value: id,
        type: 'my'
      }
    });
    modal.afterClose.subscribe(() => {
      // this.getServiceStoragesList();
    })

  }
}
