import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-server-config-add',
  templateUrl: './modal-server-config-add.component.html',
  styleUrls: ['./modal-server-config-add.component.less']
})
export class ModalServerConfigAddComponent implements OnInit {

  orderList;
  @Input() value = '';
  @Input() orderChoiceList = [];

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
    public api: Api,
  ) {
  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];
  dateList: any = [];

  ngOnInit() {
    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.typeNo] = true;
      });
      this.mapOfCheckedId = newList;
    }
    this.mapOfCheckedItem = this.orderChoiceList;
    this.getCompanyServerTypeLists();
  }

  selectedCategory(typeNo) {
    var choiceList = this.orderList.filter(item => this.mapOfCheckedId[item.typeNo])
    this.mapOfCheckedItem = choiceList;
  }

  getCompanyServerTypeLists() {
    this.api.getCompanyServerTypeList().subscribe(res => {
      this.orderList = res['data'];
    })
  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    if (this.mapOfCheckedItem.length === 0) {
      this.message.warning('请选择服务类别！');
      return
    }
    this.modal.destroy(this.mapOfCheckedItem);
  }

}
