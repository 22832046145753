import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { UploadFilter, UploadFile } from 'ng-zorro-antd/upload';



@Component({
  selector: 'app-modal-addcontract',
  templateUrl: './modal-addcontract.component.html',
  styleUrls: ['./modal-addcontract.component.less']
})
export class ModalAddcontractComponent implements OnInit {
  @Input() orderId: string;
  @Input() customerId: string;
  @Input() type: string;

  uploadUrl: string;
  contractUrl: string;
  form: FormGroup;
  loading = false;
  fileList = []; // 文件列表
  // uploadFile: any; // 上传文件
  uploadParams = {};
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => ~['application/pdf', 'image/png', 'image/jpeg'].indexOf(w.type));
        if (filterFiles.length !== fileList.length) {
          this.message.error(`包含文件格式不正确，只支持 png、jpeg、jpg、pdf 格式`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'async',
      fn: (fileList: UploadFile[]) => {
        return new Observable((observer: Observer<UploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      }
    }
  ];

  contractId;
  encryptionId;
  constructor(

    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {

    this.uploadUrl = this.api.uploadContractAttachment();

    this.contractUrl = this.api.storagesUploadWithController();

    this.uploadParams['contractId'] = '';

    this.form = fb.group({
      name: [null, Validators.required],
      // num: [null, Validators.required],
      money: [null, [Validators.required, Validators.pattern(/^[0-9|.]*$/)]],
      startTime: [null, Validators.required],
      endTime: [null],
      // annexId: [null, Validators.required],
    });
  }
  fileOBJ = {
    arr1: [],
  }

  // 上传合同
  /*
  uploadChange(info) {
    console.log(info)
    if (info.file.status === 'done' && info.file.response.code === 200) {
      this.fileList[0].id = info.file.response.data.id;
     
      console.log(this.fileList)
      // this.uploadParams['contractId'] = info.file.response.data.id;
      this.uploadParams['contractId'] = '';
      this.encryptionId = info.file.response.data;
      console.log(info.file.response.data);
      
    }
  }*/

  // 上传附件
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        if (item.response instanceof Object) {
          attachmentUrls.push(item.response.data.fileUrl)
        }
      })
      this.fileOBJ.arr1 = attachmentUrls;
    }
  }

  cancel() {
    this.modal.destroy();
  }

  // 保存合同信息
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();

    //params.annexId = this.fileList[0].id;
    params.orderId = this.orderId;
    params.customerId = this.customerId;
    params.types = 0;
    params.encryptionId = this.encryptionId;

    let http;
    let msg = '';
    try {
      params.startTime = this.dataToStr(params.startTime);
      params.endTime = this.dataToStr(params.endTime);
    } catch (e) {

    }

    if (this.type == 'add') {
      // 审核模块 start
      var flowBusinessStepDTOList = [];
      var stepBool = true;
      (this.flowListStep || []).map((item, index) => {
        flowBusinessStepDTOList[index] = {
          serviceId: item.serviceId,
          serviceName: item.serviceName,
          serviceNo: item.serviceNo,
          stepId: item.stepId,
          stepName: item.stepName,
          stepNo: item.stepNo,
        }
        // 循环用户列表，获取用户id 与名称
        var selected_user = [];
        var selectedFin = [];
        // 区分用户为多人或者单人
        if (item.userId instanceof Array) {
          if (item.userId.length == 0) {
            stepBool = false;
            return false;
          }
          item.userId.map((child, index) => {
            selected_user = this.usersList.filter((obj) => {
              obj.userName = obj.name;
              return obj.userId == child
            });
            selectedFin.push(Object.assign({}, ...selected_user))
          })
        } else {
          if (!item.userId) {
            stepBool = false;
            return false;
          }
          selectedFin = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == item.userId
          });
        }
        flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
      })
      if (!stepBool) {
        this.message.error('审核人员不能为空！');
        // this.canSubmit = false;
        return;
      }
      params['flowBusinessStepDTOList'] = flowBusinessStepDTOList;
      // 审核模块 end
      if (this.fileOBJ.arr1.length <= 0) {
        this.message.warning('请上传合同！');
        this.loading = false;
        return
      }
      params.urls = [
        ...this.fileOBJ.arr1,
      ]
      http = this.api.saveContract(params);
      msg = '添加';
    } else {
      msg = '修改';
      params['id'] = this.contractId;
      if (this.fileList.length <= 0) {
        this.message.warning('请上传合同！');
        this.loading = false;
        return
      }
      params.urls = [
        ...this.fileListFilter(this.fileList),
      ]
      //let str = this.fileListFilter(this.fileList)
      http = this.api.editContract(params);
    }
    http.subscribe(res => {
      this.message.success(`合同${msg}成功！`);
      this.modal.destroy();
    }, err => {
      this.loading = false;
    })

  }

  fileListFilter(arr) {
    var attr = [];
    (arr || []).map(item => {
      if (item.response instanceof Object) {
        attr.push(item.response.data.fileUrl)
      } else {
        attr.push(item.url)
      }
    })
    return attr
  }
  dataToStr(date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }

  ngOnInit() {
    // 审核模块 start
    this.getUserList();
    this.getServiceFlowList('contract');
    // 审核模块 end
    if (this.type == 'edit') {
      this.api.getContract({ orderId: this.orderId }).subscribe(res => {
        const data = res['data'];
        this.contractId = data.id;
        Object.keys(data).map((key) => {
          if (this.form.get(key)) {
            this.form.get(key).setValue(data[key]);
          }
        })
        var attachmentUrls = [];
        (data.urls || []).map((item, index) => {
          let obj = {
            uid: index + 1,
            name: item,
            status: 'done',
            response: 'Server Error 500', // custom error message to show
            url: item
          }
          attachmentUrls.push(obj)
        })
        this.fileList = attachmentUrls;
        // this.fileList = [{
        //   id: data.annexId,
        //   url: data.fileUrl,
        //   name: data.fileUrl
        // }]
      })
    }
  }

  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end
}
