import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { ModalSelectqualComponent } from '../modal-selectqual/modal-selectqual.component';
import { Utils } from '@service/Utils';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerEntpriseServerComponent } from '../../component/drawer-entprise-server/drawer-entprise-server.component';

import { ModalAddAffiliatesComponent } from '../modal-add-affiliates/modal-add-affiliates.component';
import { ModalAddContactComponent } from '../modal-add-contact/modal-add-contact.component';
import { ModalAddOutlookComponent } from '../modal-add-outlook/modal-add-outlook.component';
import { ModalSelectproductComponent } from '../modal-selectproduct/modal-selectproduct.component';
import { ModalCompanyComponent } from '../modal-company/modal-company.component';

@Component({
  selector: 'app-drawer-company',
  templateUrl: './drawer-company.component.html',
  styleUrls: ['./drawer-company.component.less']
})
export class DrawerCompanyComponent implements OnInit {
  @Input() value = '';
  @Input() index = 0;
  form: FormGroup;
  formLog: FormGroup;
  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public router: Router,
    public api: Api,
    private drawerService: NzDrawerService,
  ) {
    this.form = fb.group({
      logoutDate: [null, Validators.required],
    });
    this.formLog = fb.group({
      logContent: [null, Validators.required],
    });
  }

  ngOnInit() {
    // 获取tab数据
    this.getCompanyDetails();
    this.changeTabs(this.index);
  }

  companyInfo; //公司信息
  peopleList = []; //联系人信息
  qualList = []; //联系人信息
  investList = []; //穿透信息
  activeList = [];    // 已激活菜单
  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 0:
        // 代账收费情况
        this.getAccountInfo();
        break;
      case 1:
        // 基础信息
        this.getSysParametersTreeByCode();
        this.getProvince(1);
        this.getOperEnterprise();
        break;
      case 2:
        // 财税档案
        this.getCompanyTaxt();
        this.getCompanyHandoverList(0);
        this.getCompanyHandoverList(1);
        break;
      case 3:
        // 联系人
        this.getDecisionMakerList();
        break;
      case 4:
        // 行业经营特征
        this.getIndustryAspect();
        break;
      case 5:
        // 企服清单
        this.getCompanyListServer();
        this.getCompanyBuyProduct();
        break;
      case 6:
        // 下户信息
        this.getCompanyOutInfoList();
        break;
      case 7:
        // 满意度
        this.getSatisfaction();
        break;
      case 8:
        this.getLabelList();
        break;
      case 9:
        this.getQual();
        break;
      default:
        break;
    }
  };
  // 获取公司详情
  getCompanyDetails() {
    this.api.getCompanyDetails({ id: this.value }).subscribe(res => {
      this.companyInfo = res['data'];
      this.bsCompanyShareholderList = this.companyInfo.bsCompanyShareholderList || [];
      if (this.companyInfo.provinceId) {
        this.getCityList(true);
      }
      this.api.getCompanyCustomerList({ companyId: this.value }).subscribe(resp => {
        this.peopleList = resp['data'];
      })
    });
  }
  // 企业标签 start
  labelList = [];      // 标签列表
  userLabelList = [];  // 客户标签列表
  userParentLabelList = []; //选中多选的id
  customerRecordList = []; // 跟进记录列表
  saveLabel() {
    // 先保存多选id,再筛选出单选选中id
    const userLabelList = JSON.parse(JSON.stringify(this.userParentLabelList));
    for (let j = 0; j < this.labelList.length; j++) {
      if (this.labelList[j].radio && this.labelList[j].checkId) {
        userLabelList.push(this.labelList[j].checkId);
      }
    }
    const parmas = {
      companyId: this.value,
      labelId: userLabelList

    }
    this.api.saveSysCompanyTagRecord(parmas).subscribe(res => {
      this.message.success('添加成功！');
    })
  }
  // 查询标签列表
  getLabelList() {
    // 客户标签 tagType = 0
    // 企业标签 tagType = 1
    var params = {
      tagType: 1
    }
    this.api.getLabelList(params).subscribe(res => {
      this.labelList = res['data'];
      this.getUserLabel();
    })
  }

  // 获取客户标签
  getUserLabel() {
    this.api.getCompanyLabel({ companyId: this.value }).subscribe(res => {
      this.userLabelList = res['data'];
      this.setTag()
    })
  }
  setTag() {
    const len = this.labelList.length;
    for (let i = 0; i < len; i++) {

      const tagList = this.labelList[i]['sysCustomerTagConfigVOS'];

      if (!tagList) {
        continue
      }


      for (let j = 0; j < tagList.length; j++) {

        // 如果标签存在于客户标签数组，添加标记
        // userLabelList 选中的id
        if (this.userLabelList.includes(tagList[j].id)) {
          // 多选
          if (!this.labelList[i].radio) {
            tagList[j].checked = true;
            // 多选
            this.userParentLabelList.push(tagList[j].id);
          } else {
            // 单选
            this.labelList[i].checkId = tagList[j].id;
          }
        } else {
          tagList[j].checked = false;
        }
      }
    }
    console.log(this.labelList)

  }
  // 标记客户标签 多选
  checkChange(checked, id) {
    if (checked) {
      this.userLabelList.push(id);
      this.userParentLabelList.push(id);
    } else {
      this.userLabelList = this.userLabelList.filter(t => t !== id);
      this.userParentLabelList = this.userParentLabelList.filter(t => t !== id);
    }
  }
  // checkChangeRadio 单选
  checkChangeRadio(checked) {
    console.log(checked)
  }
  // 企业标签 end
  // 跟进记录 start
  bigImg: any = false;
  imgList: any;
  toBigImg(content, img) {
    const modal = this.modalService.create({
      nzTitle: '',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzMaskClosable: false,
      nzFooter: null
    });
    this.bigImg = true;
    this.imgList = img;
  }

  hiddenImg() {
    this.bigImg = false;
  }
  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getCustomerRecord();
  }

  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'company',
        bindId: this.companyInfo.id,
        bindName: this.companyInfo.name,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.customerRecordList = [];
          this.getCustomerRecord();
        })
      }
    });
  }
  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMore = true;
  getCustomerRecord() {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        bindId: this.value,
        logType: 1,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.customerRecordList = [...this.customerRecordList, ...res['data'].records];
      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    })
  }
  // 跟进记录 end
  // 预览凭证
  preview(data) {
    window.open(data);
  }
  //  添加资质 start
  // 获取资质
  getQual() {
    var params = {
      companyId: this.value
    }
    this.api.bsCompanyQualification(params).subscribe(res => {
      this.qualList = res['data'];
    })
  };
  openQual() {
    const modal = this.modalService.create({
      nzTitle: '添加资质',
      nzContent: ModalSelectqualComponent,
      nzComponentParams: {
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        var bsCompanyQualificationList = [];
        data.map(item => {
          var obj = {
            "certificateCategoryId": item.parentId,
            "certificateCategoryName": item.parentName,
            "issuingAuthorityName": item.issuingAuthorityName,
            "certificateName": item.name,
            "certificateNameId": item.id,
            "certificateDate": null,
            "certificateDateEnd": null
          }
          if (item.certificateDate) {
            obj.certificateDate = Utils.dateFormat(item.certificateDate, 'yyyy-MM-dd hh:mm:ss');
          }
          if (item.certificateDateEnd) {
            obj.certificateDateEnd = Utils.dateFormat(item.certificateDateEnd, 'yyyy-MM-dd hh:mm:ss');
          }
          bsCompanyQualificationList.push(obj);
        });
        if (bsCompanyQualificationList.length > 0) {
          var params = {
            bsCompanyQualificationList: bsCompanyQualificationList,
            companyId: this.value
          };
          this.api.saveBatchBsCompanyQualification(params).subscribe(res => {
            this.message.success('添加成功！');
            this.getQual();
          })
        }
      }
    });
  }
  //  添加资质 end

  //富文本图片 点击查看
  showImages(event) {
    if (event.target.nodeName == 'IMG') {
      window.open(event.target.currentSrc)
    }
  }

  // 注销公司
  openLogout(content) {
    this.modalService.create({
      nzTitle: '注销公司',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.compAddLogOut()
    });
  }
  compAddLogOut() {
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      return
    }
    let params = this.form.getRawValue();
    // 所属行业 start
    if (params.logoutDate != null) {
      params.logoutDate = Utils.dateFormat(params.logoutDate, 'yyyy-MM-dd hh:mm:ss');
    }
    params.companyId = this.companyInfo.id;
    this.api.compAddLogOut(params).subscribe(res => {
      this.message.success('注销成功！');
      this.getCompanyDetails();
    })
  }
  // 打开客户详情
  open(data) {
    const modal = this.drawerService.create<DrawerEntpriseServerComponent,
      {
        value: string,
        companyServerNo: string,
        manageName: string,
        chargeName: string,
        companyServerPeriod: string,
        companyServerStatus: string,
        serverType: number, //只有我负责的才能编辑
        typeFlag: boolean,
        detail: Object
      }, string>({
        nzWidth: 890,
        nzWrapClassName: '服务详情',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: DrawerEntpriseServerComponent,
        nzContentParams: {
          value: data.id,
          companyServerNo: data.companyServerNo,
          manageName: data.manageName,
          chargeName: data.chargeName,
          companyServerPeriod: data.companyServerPeriod,
          companyServerStatus: data.companyServerStatus,
          serverType: null,
          typeFlag: data.typeFlag,
          detail: data,
        }
      });
    modal.afterClose.subscribe(() => {
    })

  }

  // 档案
  checkOptionsOne = [
    { label: '出纳管理', value: '1', checked: true },
    { label: '付款及费用报销', value: '2', checked: false },
    { label: '税、票管理制度   ', value: '3', checked: false },
    { label: '固定资产管理   ', value: '4', checked: false },
    { label: '口仓储管理   ', value: '5', checked: false },
    { label: '口考勤制度   ', value: '6', checked: false },
  ];
  selectService(value: object[]): void {
    console.log(value);
  }
  params = {
    id: null,//公司id
    name: null,//公司名称
    contractStartDate: null,//合同开始日期
    contractEndDate: null,//合同结束日期
    startDate: null,//成立日期
    taxNum: null,//成立日期
    companyPersonCount: null,//员工人数
    scale: null,//公司性质
    scope: null,//经营范围
    registCapi: null,//注册资金
    stateTaxPwd: null,//国税申报密码
    incomeTaxPwd: null,//个税申报密码
    outDate: null,//出账日期
    yearReportDate: null,//工商年报申报日期
    yfAccount: null,//研发账
    serviceCycle: null,//上门服务周期(次/月)
    cashier: null,//出纳
    gxAccount: null,//高新账
    gzzdInfo: null,//规章制度
    jxzInfo: null,//进销存
    bsCompanyTaxCategoryList: null,//申报税种
    bsCompanyMonthReportList: null,//月度报表
    bsCompanyConsultServiceList: null,//顾问咨询服务内容
    mdsswsbInfo: null,//税务申请与指导
    seniorAccountantId: null,//资深会计
    itemTeacherId: null,//项目老师
    gjgwId: null,//高级顾问
    remark: null,//备注（记录其他特殊要求）
  }
  bsCompanyTaxCategoryList = []//申报税种
  taxCategory(): void {
    this.api.taxCategory({}).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        let obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].taxCategoryName;
        obj['checked'] = false;
        this.bsCompanyTaxCategoryList.push(obj)
      }
    })
  }
  bsCompanyMonthReportList = []//月度报表
  monthReport(): void {
    this.api.monthReport({}).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        let obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].monthReportName;
        obj['checked'] = false;
        this.bsCompanyMonthReportList.push(obj)
      }
    })
  }
  bsCompanyConsultServiceList = []//顾问咨询服务内容
  consultService(): void {
    this.api.consultService({}).subscribe(res => {
      let resp = res['data'];
      for (let i = 0; i < resp.length; i++) {
        let obj = {};
        obj['value'] = resp[i].id;
        obj['label'] = resp[i].consultServiceName;
        obj['checked'] = false;
        this.bsCompanyConsultServiceList.push(obj)
      }
    })
  }
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  companyAccountDetail(): void {
    this.api.companyAccountDetail(this.companyInfo.id).subscribe(res => {
      this.params = res['data'];
      for (let j = 0; j < this.params.bsCompanyTaxCategoryList.length; j++) {
        this.bsCompanyTaxCategoryList.some(item => {
          if (item.value == this.params.bsCompanyTaxCategoryList[j].taxCategoryId) {
            item.checked = true;
          }
        })
      }

      for (let j = 0; j < this.params.bsCompanyMonthReportList.length; j++) {
        this.bsCompanyMonthReportList.some(item => {
          if (item.value == this.params.bsCompanyMonthReportList[j].monthReportId) {
            item.checked = true;
          }
        })
      }
      for (let j = 0; j < this.params.bsCompanyConsultServiceList.length; j++) {
        this.bsCompanyConsultServiceList.some(item => {
          if (item.value == this.params.bsCompanyConsultServiceList[j].consultServiceId) {
            item.checked = true;
          }
        })
      }
    })
  }
  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }
  loading = false;
  // 提交财务信息
  userSubmit() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.params));
    params.id = this.companyInfo.id;
    // 申报税种
    var paramsTax = [];
    this.bsCompanyTaxCategoryList.map((item, index) => {
      if (item.checked) {
        var obj = {
          taxCategoryId: item.value,
          taxCategoryName: item.label,
        }
        paramsTax.push(obj)
      }
    })
    params.bsCompanyTaxCategoryList = paramsTax;
    // 月度报表
    var monthReportList = [];
    this.bsCompanyMonthReportList.map((item, index) => {
      if (item.checked) {
        var obj = {
          monthReportId: item.value,
          monthReportName: item.label,
        }
        monthReportList.push(obj)
      }
    })
    params.bsCompanyMonthReportList = monthReportList;
    // 公司顾问咨询
    var consultServiceList = [];
    this.bsCompanyConsultServiceList.map((item, index) => {
      if (item.checked) {
        var obj = {
          consultServiceId: item.value,
          consultServiceName: item.label,
        }
        consultServiceList.push(obj)
      }
    })
    params.bsCompanyConsultServiceList = consultServiceList;
    params.startDate = this.dataToStr(this.params.startDate)
    params.contractStartDate = this.dataToStr(this.params.contractStartDate)
    params.contractEndDate = this.dataToStr(this.params.contractEndDate)
    params.yearReportDate = this.dataToStr(this.params.yearReportDate)
    params.outDate = this.dataToStr(this.params.outDate)
    console.log(params)
    this.api.saveBsCompanyAccount(params).subscribe(res => {
      this.message.success('保存成功！');
      this.loading = false;
    }, err => {
      this.loading = false;
    })

  }


  // 客户等级start
  options = [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ];
  // 公司类型
  settlementStatusList = [
    { 'value': 0, 'name': '有限责任公司' },
    { 'value': 1, 'name': '股份有限公司' },
    { 'value': 2, 'name': '股份合作公司' },
    { 'value': 3, 'name': '国有企业' },
    { 'value': 4, 'name': '集体所有制' },
    { 'value': 5, 'name': '个体工商户' },
    { 'value': 6, 'name': '独资企业' },
    { 'value': 7, 'name': '有限合伙' },
    { 'value': 8, 'name': '普通合伙' },
    { 'value': 9, 'name': '外商投资企业' },
    { 'value': 10, 'name': '港、澳、台商投资企业' },
    { 'value': 11, 'name': '联营企业' },
    { 'value': 12, 'name': '私营企业' },
  ];
  // 资金是否自有 A轮、B轮、银行借款、股东借款
  selfOwnedFundsList = [
    { 'value': 0, 'name': 'A轮' },
    { 'value': 1, 'name': 'B轮' },
    { 'value': 2, 'name': '银行借款' },
    { 'value': 3, 'name': '股东借款' },
  ];
  // 开票类型
  invoiceTypeList = [
    { 'value': 1, 'name': '专票' },
    { 'value': 2, 'name': '普票' },
    { 'value': 3, 'name': '专票+普票' },
  ];
  // 客户的销售市场 （本市/省/全国/海外）
  saleMarketList = [
    { 'value': 0, 'name': '本市' },
    { 'value': 1, 'name': '省' },
    { 'value': 2, 'name': '全国' },
    { 'value': 3, 'name': '海外' },
  ];
  // 所处阶段 初创期/发展期/成熟期
  companyStageList = [
    { 'value': 0, 'name': '初创期' },
    { 'value': 1, 'name': '发展期' },
    { 'value': 2, 'name': '成熟期' },
  ];
  setServerDate(data) {
    this.form.controls.followContent.markAsDirty();
    this.form.controls.followContent.updateValueAndValidity();
    if (!this.form.valid) {
      return false;
    }
    let params = this.form.getRawValue();
    var _obj;
    _obj = {
      companyServerPeriodId: data.id,
      followContent: params.followContent,
    };
    this.api.addCompanyServerPeriodFollow(_obj).subscribe((res) => {
      this.message.success('添加成功！');
      this.form.reset()
    })


  }
  // 所属行业
  industryType_Data = [];
  getSysParametersTreeByCode() {
    this.api.getSysParametersTreeByCode({ code: 'COMPANY_INDUSTRY_TYPE' }).subscribe(res => {
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.industryType_Data = strs;
    });
  };
  industryTypeIdArr = [];
  industryModel(industrySearch) {
    this.modalService.create({
      nzTitle: '选择行业类型',
      nzContent: industrySearch,
      nzStyle: { top: '30px' },
      nzWidth: 300,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        let industryTypeIdArr = this.industryTypeIdArr;
        (this.industryType_Data || []).map(item => {
          if (industryTypeIdArr && industryTypeIdArr.length > 0) {
            if (item.id == industryTypeIdArr[0]) {
              this.companyInfo.industryTypeName = item.name;
              (item.children || []).map(itemChild => {
                console.log(itemChild.id)
                if (itemChild.id == industryTypeIdArr[1]) {
                  this.companyInfo.industryTypeName = itemChild.name;
                }
              })
            }
          }
        })
      }
    });
  };
  // 地址 start
  provinceList: [];    // 省份列表
  cityList: [];        // 城市列表
  // 获取省市列表
  getProvince(level, id?) {
    const params = {
      level: level,
    }
    id ? params['parentId'] = id : null;
    this.api.getCityList(params).subscribe((res) => {
      if (level == 1) {
        this.provinceList = res['data'];
      } else {
        this.cityList = res['data'];
      }
    })
  }

  // 查询城市列表
  // type = true 首次进入
  getCityList(type) {
    const id = this.companyInfo.provinceId;
    if (!type) {
      this.companyInfo.cityId = null;
    }
    for (var i in this.provinceList) {
      if (this.provinceList[i]['id'] == id) {
        this.companyInfo.province = this.provinceList[i]['name'];
        break;
      }
    }
    this.getProvince(2, id);
  }
  // 城市改变
  getCityCur() {
    const id = this.companyInfo.cityId;
    for (var i in this.cityList) {
      if (this.cityList[i]['id'] == id) {
        this.companyInfo.city = this.cityList[i]['name'];
        break;
      }
    }
  }
  // 地址 end
  isValidNumber(input) {
    // 正则表达式解释：
    // ^ 表示字符串的开始
    // (\d+) 表示一个或多个数字
    // (\.\d+)? 表示可选的小数点后跟一个或多个数字，整个小数部分是可选的，且只能出现一次
    // $ 表示字符串的结束
    if (input == null || input == '') {
      return true;
    } else {
      const regex = /^(\d+(\.\d+)?)$/;
      return regex.test(input);
    }

  }
  // 保存
  saveBase() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.companyInfo));
    // 股东人数
    params.bsCompanyShareholderList = this.bsCompanyShareholderList;
    // 所属行业 start
    // 保存赋值
    var industryTypeIdArr = params['industryTypeIdArr'];
    if (industryTypeIdArr && industryTypeIdArr.length > 0) {
      params.parentIndustryTypeId = industryTypeIdArr[0];
      params.industryTypeId = industryTypeIdArr[1] || null;
    }
    // 所属行业 end
    if (params.startDate != null) {
      params.startDate = Utils.dateFormat(params.startDate, 'yyyy-MM-dd hh:mm:ss');
    }
    if (params.openDate != null) {
      params.openDate = Utils.dateFormat(params.openDate, 'yyyy-MM-dd');
    }
    if (params.approvalDate != null) {
      params.approvalDate = Utils.dateFormat(params.approvalDate, 'yyyy-MM-dd');
    }
    if (params.name.length < 6) {
      this.message.error('公司名称不能少于六个文字', {
        nzDuration: 5000
      });
      this.loading = false;
      return
    }
    if (!this.isValidNumber(params.preYearOpenAmount)) {
      this.message.error('上年度开票额请输入正确的数字');
      this.loading = false;
      return
    }

    this.api.updateCompany(params).subscribe(res => {
      this.loading = false;
      this.message.success('公司信息编辑成功！');
    }, err => {
      this.loading = false;
    })


  }
  // 股东人数 start
  bsCompanyShareholderList: any[];
  // 添加股东
  addShareholderPersons(): void {

    this.bsCompanyShareholderList = [
      ...this.bsCompanyShareholderList,
      {
        shareholderName: null,
        shareholderRatio: null,
      }
    ];
    this.companyInfo.shareholderPersons = this.bsCompanyShareholderList.length;
  }
  // 删除股东
  deleteShareholderPersons(index: number): void {
    let newList = JSON.parse(JSON.stringify(this.bsCompanyShareholderList));
    newList.splice(index, 1)
    this.bsCompanyShareholderList = newList;
    this.companyInfo.shareholderPersons = this.bsCompanyShareholderList.length;
  }
  // 股东人数 end
  // 关联企业 start
  operEnterpriseList = [];
  getOperEnterprise() {
    this.api.operEnterprise(this.value).subscribe(res => {
      var resData = res['data'];
      this.operEnterpriseList = resData;
    }, err => {
    })
  }

  getRelevanceCompanyInfo() {
    this.loading = true;
    var data = {
      companyName: this.companyInfo.name,//公司名称
      faRen: this.companyInfo.operName,//企业法定代表人
    }
    this.api.getRelevanceCompanyInfo(data, this.value).subscribe(res => {
      this.message.success('同步成功');
      this.loading = false;
      this.companyInfo.faRenFlag = 1;
      this.getOperEnterprise();
    }, err => {
      this.loading = false;
    })
  }

  // 添加关联企业
  addAffiliates(id?) {
    const modal = this.modalService.create({
      nzTitle: '添加关联企业 ',
      nzContent: ModalAddAffiliatesComponent,
      nzComponentParams: {
        id: id,
        companyId: this.value
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.getOperEnterprise();
      }
    });
  }
  // 删除
  delAffiliates(id) {
    this.api.delOperEnterprise(id).subscribe(res => {
      this.message.success('删除成功');
      this.getOperEnterprise();
    }, res => {
      this.message.error('删除失败');
    })
  }
  // 关联企业 end
  // 联系人 start
  // 添加联系人
  decisionMakerList = [];
  // 获取联系人列表
  getDecisionMakerList() {
    this.api.decisionMakerList(this.value).subscribe(res => {
      var resData = res['data'];
      this.decisionMakerList = resData;
    }, err => {
    })
  }
  addContact(id?) {
    const modal = this.modalService.create({
      nzTitle: id ? '编辑联系人' : '添加联系人',
      nzContent: ModalAddContactComponent,
      nzComponentParams: {
        companyId: this.value,
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.getDecisionMakerList();
      }
    });
  }
  delContact(id) {
    this.api.decisionMakerDel(id).subscribe(res => {
      this.message.success('删除成功');
      this.getDecisionMakerList();
    }, res => {
      this.message.error('删除失败');
    })
  }
  // 联系人 end
  // 行业经营特征 start
  industryInfo = {
    id: '',
    bsCompanyIndustryAspectProductList: [],
    companyId: '',
    industryProfitRateRange: null,
    industryTaxBurdenRate: null,
    industryTaxCharacteristics: null,
    industryOperationCharacteristics: null,
    industryMajorEventsNews: null,
    industryBenchmarkEnterprises: null,
    industryDevelopmentTrend: null,
    industryCompetitors: null,
    mainProducts: null,
    businessMode: null,
    serviceProductSuppliers: null,
    companyRecentOperationChanges: null,
    industryRecentHotNews: null,
    industryStatus: null
  };
  bsCompanyIndustryAspectProductList: any[];
  // 添加产品
  addIndustryAspect(): void {

    this.bsCompanyIndustryAspectProductList = [
      ...this.bsCompanyIndustryAspectProductList,
      {
        productName: null,
        channelName: null,
        supplierName: null
      }
    ];
  }
  // 删除产品
  deleteIndustryAspect(index: number): void {
    let newList = JSON.parse(JSON.stringify(this.bsCompanyIndustryAspectProductList));
    newList.splice(index, 1)
    this.bsCompanyIndustryAspectProductList = newList;
  }
  // 保存
  saveIndustryAspect() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.industryInfo));
    if (!params.id) {
      delete params.id;
    }
    params.companyId = this.value;
    params.bsCompanyIndustryAspectProductList = this.bsCompanyIndustryAspectProductList;
    this.api.industryAspectAdd(params).subscribe(res => {
      this.message.success('行业经营特征编辑成功！');
      this.getIndustryAspect();
    }, err => {
      this.loading = false;
    })
  }
  // 获取信息
  getIndustryAspect() {
    this.api.industryAspectDetail(this.value).subscribe(res => {
      var resData = res['data'];
      if (resData) {
        for (const key in this.industryInfo) {
          if (resData.hasOwnProperty(key)) {
            this.industryInfo[key] = resData[key];
          } else {
            this.industryInfo[key] = null; // 确保所有字段默认为 null
          }
        }
        this.bsCompanyIndustryAspectProductList = resData.bsCompanyIndustryAspectProductList || [];
        this.loading = false;
      }
    }, err => {
    })
  }

  // 行业经营特征 end
  // 下户信息 start
  outInfoList = [];
  getCompanyOutInfoList() {
    this.api.companyOutInfoList({ companyId: this.value }).subscribe(res => {
      var resData = res['data'];
      this.outInfoList = resData;
    }, err => {
    })
  }

  // 添加下户
  addOutlook(data?) {
    const modal = this.modalService.create({
      nzTitle: '添加下户信息 ',
      nzContent: ModalAddOutlookComponent,
      nzComponentParams: {
        id: this.value,
        companyName: this.companyInfo.name,
        outId: data ? data['id'] : null
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.getCompanyOutInfoList();
      }
    });
  }
  // 下户信息 end

  // 财税档案 start
  companyTaxInfo = {
    id: '',
    companyId: '',
  };
  // 保存
  saveCompanyTax() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.companyTaxInfo));
    if (!params.id) {
      delete params.id;
    }
    params.companyId = this.value;
    params.salesInvoiceTimePoint = params.salesInvoiceTimePoint ? Utils.dateFormat(params.salesInvoiceTimePoint, 'yyyy-MM-dd') : null;
    params.purchaseInvoiceTimePoint = params.purchaseInvoiceTimePoint ? Utils.dateFormat(params.purchaseInvoiceTimePoint, 'yyyy-MM-dd') : null;
    params.accountingMonth = params.accountingMonth ? Utils.dateFormat(params.accountingMonth, 'yyyy-MM-dd') : null;
    this.api.companyTaxAdd(params).subscribe(res => {
      this.message.success('财税档案编辑成功！');
      this.getCompanyTaxt();
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }
  // 获取信息
  getCompanyTaxt() {
    this.api.companyTaxDetail(this.value).subscribe(res => {
      var resData = res['data'];
      if (resData) {
        this.companyTaxInfo = resData;
        this.loading = false;
      }
    }, err => {
    })
  }
  // 获取历史及周期
  // handoverType 0财税顾问1负责人(主办会计)
  handoverList0 = [];
  handover0 = '';
  handoverList1 = [];
  handover1 = '';
  getCompanyHandoverList(type) {
    var data = {
      handoverType: type,
      companyId: this.value
    };
    this.api.companyHandoverLists(data).subscribe(res => {
      var resData = res['data'];
      if (type == 0) {
        this.handoverList0 = resData;
        this.handover0 = resData.map(item => `${item.nowUserName} (${item.startDate || ''}-${item.endDate || ''})`).join('，');
      } else if (type == 1) {
        this.handoverList1 = resData;
        this.handover1 = resData.map(item => `${item.nowUserName} (${item.startDate || ''}-${item.endDate || ''})`).join('，');
      };
    }, err => {
    })
  }
  // 财税档案 end

  // 企服清单 start
  enterpriseServiceList = []
  // 获取信息
  getCompanyListServer() {
    this.api.companyListServer(this.value).subscribe(res => {
      var resData = res['data'];
      this.enterpriseServiceList = resData;
    }, err => {
    })
  }
  loadMoreOrderRecordList: any;
  //新增跟进策略
  openFollowUp(content, data) {
    this.modalService.create({
      nzTitle: '新增跟进策略',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => this.formLog.reset(),
      nzOnOk: () => {
        Object.keys(this.formLog.controls).map((key) => {
          this.formLog.controls[key].markAsDirty();
          this.formLog.controls[key].updateValueAndValidity();
        });
        if (!this.formLog.valid) {
          return false;
        }
        let params = this.formLog.getRawValue();
        var _obj;
        _obj = {
          companyId: this.value,
          logContent: params.logContent,
          serviceListTypeId: data.serviceListTypeId,
        };
        this.api.addCompamyLog(_obj).subscribe((res) => {
          this.message.success('添加成功！');
          this.saveCompanyServer();
          this.getCompanyListServer();
          this.formLog.reset()
        })
      }
    });
  }
  otherBuyServerList = []; // 已选择产品
  // 打开产品列表
  openProduct(data): void {
    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.otherBuyServerList = [...new Set([...this.otherBuyServerList, ...res])];
        let map = new Map();
        for (let item of this.otherBuyServerList) {
          map.set(item.id, item);
        }
        this.otherBuyServerList = [...map.values()];
        let obj = this.otherBuyServerList;
        const otherBuyServer = obj.map(item => `${item.name}`).join('，');
        this.otherBuyServerList = [];
        data.otherBuyServer = (data.otherBuyServer ? data.otherBuyServer + '，' : '') + otherBuyServer;
      }
    });
  }
  buyServerList = [];//已买的产品列表
  mapOfCheckedId: { [key: string]: boolean } = {};
  // 购买的产品明细
  getCompanyBuyProduct() {
    this.api.companyBuyProduct(this.value).subscribe(res => {
      var resData = res['data'];
      this.buyServerList = resData;
    }, err => {
    })
  }
  // 选择购买的产品明细
  openCompanyBuyProduct(content, data) {
    this.modalService.create({
      nzTitle: '购买的产品',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.mapOfCheckedId = {};
      },
      nzOnOk: () => {
        let checkedList = []; // 已选产品
        this.buyServerList
          .filter(item => this.mapOfCheckedId[item.cateId])
          .map(v => {
            checkedList.push(v)
          });

        if (checkedList.length === 0) {
          this.message.warning('请选择产品！');
          return
        };
        this.mapOfCheckedId = {};
        const buyServer = checkedList.map(item => `${item.categoryName}`).join('，');
        data.buyServer = (data.buyServer ? data.buyServer + '，' : '') + buyServer;
      }
    });
  }
  // 选择推荐的服务
  recommendServerList = []; // 已选择推荐产品
  // 打开产品列表
  openRecommendServer(data): void {
    const modal = this.modalService.create({
      nzTitle: '添加推荐产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.recommendServerList = [...new Set([...this.recommendServerList, ...res])];
        let map = new Map();
        for (let item of this.recommendServerList) {
          map.set(item.id, item);
        }
        this.recommendServerList = [...map.values()];
        let obj = this.recommendServerList;
        const recommendServer = obj.map(item => `${item.name}`).join('，');
        this.recommendServerList = [];
        data.recommendServer = (data.recommendServer ? data.recommendServer + '，' : '') + recommendServer;
      }
    });
  }
  // 保存
  saveCompanyServer() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.enterpriseServiceList));
    this.api.addCompanyListServer(params).subscribe(res => {
      this.message.success('企服清单编辑成功！');
      this.getCompanyListServer();
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }
  // 企服清单 end

  // 代账收费情况start
  accountInfo = {};
  getAccountInfo() {
    this.api.accountInfo(this.value).subscribe(res => {
      var resData = res['data'];
      this.accountInfo = resData;
    }, err => {
    })
  }
  // 代账收费情况end
  // 满意度回访start
  satisfaction = {
    companyScore: null,
    improve: null,
    improveOther: null,
    dissatisfied: null,
    recommendFlag: null,
    recommendDesc: null,
    encounterProblem: null,
    timelyResponseDesc: null,
    businessPriceDesc: null,
    accountMeetDesc: null,
    recommendWish: null,
    participateActivitiesFlag: null,
    otherDesc: null,
    id: null,
  };
  improvedList = [
    { value: 1, label: '账务处理正确性', checked: false },
    { value: 2, label: '报表、凭证资料的交付', checked: false },
    { value: 3, label: '问题的响应速度', checked: false },
    { value: 4, label: '会计的沟通及服务态度', checked: false },
    { value: 5, label: '经纪人的沟通及服务态度 ', checked: false },
  ];
  selectImprovedList(value: object[]): void {
    // console.log(value);
  }
  getSatisfaction() {
    this.api.satisfactionList(this.value).subscribe(res => {
      var resData = res['data'];
      if (resData) {
        for (let key in this.satisfaction) {
          if (this.satisfaction.hasOwnProperty(key)) {
            this.satisfaction[key] = resData[key];
          }
        }
        if (resData.improve) {
          const improve = JSON.parse(resData.improve);
          improve.map(item => {
            this.improvedList.map(v => {
              if (v.value == item) {
                v.checked = true;
              }
            })
          })
        }
      }
    }, err => {
    })
  }
  // 保存
  saveSatisfaction() {
    this.loading = true;
    var params = JSON.parse(JSON.stringify(this.satisfaction));
    params.improve = JSON.stringify(this.improvedList
      .filter(item => item.checked) // 筛选出checked为true的对象
      .map(item => item.value));
    params.companyId = this.value;
    this.api.addSatisfaction(params).subscribe(res => {
      this.message.success('满意度回访编辑成功！');
      this.getSatisfaction();
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }
  // 满意度回访end
  // 客户等级end
  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.value,
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => {
      this.getCompanyDetails();
    });
  }
}
