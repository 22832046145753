import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-drawer-article-look',
  templateUrl: './drawer-article-look.component.html',
  styleUrls: ['./drawer-article-look.component.less']
})
export class DrawerArticleLookComponent implements OnInit {


  @Input() value = '';
  @Input() type = '';
  @Input() shareID = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  listOfData = [];
  pageCtrl = { // 分页字段
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  /**
   * 翻页
   * @param page
   */
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getTaxArticleReadList();
  }

  params = {
    articleId: null,
    userShareArticleId: null,

  };

  articleInfo;
  ngOnInit() {
    if (this.type == 'all') {
      this.params['articleId'] = this.value;
    } else if (this.type == 'share') {
      this.params['userShareArticleId'] = this.shareID;
    }
    this.getArticleDetail();
    this.getTaxArticleReadList();
  }

  // 获取文章信息
  getArticleDetail() {
    this.api.articleDetail(this.value).subscribe(res => {
      this.articleInfo = res['data'];
    });
  }


  // 获取阅读列表
  getTaxArticleReadList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.api.taxArticleReadList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }
}
