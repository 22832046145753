import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

import * as wangEditor from '../../../../../node_modules/wangeditor/release/wangEditor.js';

import { AuthService } from '../../../auth/auth.service';
import { Api } from '@service/Api';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modal-deliverywangeditor',
  templateUrl: './modal-deliverywangeditor.component.html',
  styleUrls: ['./modal-deliverywangeditor.component.less']
})
export class ModalDeliveryWangeditorComponent implements OnInit {
  @Input() id: string;
  @Input() type: string;
  // @Output() onPostData = new EventEmitter();
  @ViewChild('editroElement', { static: false }) editroElement: ElementRef;

  private editor: any;

  // 客户跟进表单
  linkWay;
  likeAddress;

  // 任务跟进表单
  period;
  notice;

  constructor(
    private modal: NzModalRef,
    public api: Api,
    private auth: AuthService
  ) { }

  ngAfterViewInit() {

    const authToken = this.auth.getAuthorizationToken() || '';

    const editordom = this.editroElement.nativeElement;
    this.editor = new wangEditor(editordom);
    // this.editor.customConfig.uploadImgShowBase64 = true;
    // this.editor.customConfig.debug = true
    this.editor.customConfig.uploadFileName = 'file'; // 设置文件上传的参数名称
    this.editor.customConfig.uploadImgHeaders = {
      'Admin-Token': authToken
    }
    this.editor.customConfig.uploadImgServer = environment.baseUrl + this.api.uplaodAttachment(); // 设置上传文件的服务器路径

    this.editor.customConfig.uploadImgHooks = {
      customInsert: (insertImg, result, editor) => {
        const url = result.data.fileUrl;
        insertImg(url);
      }
    }
    this.editor.create();
  }

  clickHandle():any {
    let data = this.editor.txt.text();
    return data
  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    let data = {
      content: this.editor.txt.html(),
    };
    if (this.type == 'customer') {
      data['linkWay'] = this.linkWay;
      data['likeAddress'] = this.likeAddress;
    } else if (this.type === 'task') {
      data['period'] = this.period;
      data['notice'] = this.notice;
    }
    this.modal.destroy(data);

  }


  ngOnInit() {
  }

}
