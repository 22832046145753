import { Injectable } from '@angular/core';
import { Api } from '@service/Api';

@Injectable()
export class DataDimensionService {


  companyList = []; // 用户权限，公司列表
  deptList = []; // 用户权限，部门列表
  cityData = []; // 区域对象

  constructor(
    public api: Api,
  ) { }

  // 获取用户权限，公司或部门的权限
  getDataDimension() {

    // this.api.getOfficeDataDimension({type: 0}).subscribe((res) => {
    //   this.companyList = res['data'];
    // });

    // this.api.getOfficeDataDimension({type: 1}).subscribe((res) => {
    //   this.deptList = res['data'];
    // });

    this.api.getDimensionAreaData().subscribe(res => {
      this.cityData = res['data'];
    });
  }

  findDeptById(companyId) {
    if (null != companyId) {
      this.api.findDeptByCompanyId({companyId:companyId}).subscribe((res) => {
        this.deptList = res['data'];
      })
    }

  }

  findCompanyByAreaId(areaId) {
    this.companyList=null;
    this.deptList=null;
    if (null != areaId) {
      this.api.findCompanyByAreaId({areaId:areaId}).subscribe((res) => {
        this.companyList = res['data'];
      })
    }
  }


}
