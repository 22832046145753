import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { ModalDeliveryWangeditorComponent } from '../modal-deliverywangeditor/modal-deliverywangeditor.component';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd';
import { ModalCompanyComponent } from '../../component/modal-company/modal-company.component';

let cnm;
let apiTmp;
@Component({
  selector: 'drawer-task',
  templateUrl: './drawer-task.component.html',
  styleUrls: ['./drawer-task.component.less']
})
export class DrawerTaskComponent implements OnInit {
  @Input() value = '';
  @Input() index = 0;
  @Input() status = '';
  @Input() jobId: any;
  @Input() orderData: any;
  @Input() isSee: boolean;
  @Input() noButton: any;
  @Input() type: any;

  addTaskForm: FormGroup;

  form: FormGroup;
  taskInfo; // 客户信息
  productInfo = [];  // 产品信息
  refundRecord = []; // 退款信息
  activeList = [0];  // 已激活菜单
  taskList = [];     // 任务列表
  taskRecordList = []; // 任务跟进记录列表

  sysTaskRecordList = [] // 系统操作日志
  customerTaskRecord = [] // 交付记录
  companyData = []; //公司列表
  nzOptions = [];
  staffList: [];//关联员工列表
  isEdit = false; //编辑结算价
  orderTaskDetailList = [] //关联工单
  contractUrl: string;
  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
    public api: Api,
  ) {
    apiTmp = this.api;
    this.addTaskForm = fb.group({
      taskId: [null, Validators.required],
      period: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
    });
    this.form = fb.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      companyId: [null, Validators.required],
      remark: [null,],
      UserSelectedValue: [null]
    });
    this.contractUrl = this.api.storagesUploadWithController();
  }

  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getTaskRecordList();//跟进记录
        break;
      case 2:
        this.getTaskContinueListBySys();//操作记录
        break;
      case 3:
        this.getTaskContinueListById();//交付记录
        break;
      case 4:
        this.getOrderTaskLists();//关联工单
        break;
      case 5:
        this.findWorkServerItem();//服务记录
        break;
      default:
        break;
    }
  }

  serverRecordList: any = [];
  findWorkServerItem() {
    this.api.findWorkServerItems({
      current: 1,
      object: {
        orderTaskId: this.value,
      },
      size: 999
    }).subscribe(res => {
      this.serverRecordList = res['data'].records
    })
  }

  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMore = true;
  getTaskRecordList() {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        bindId: this.value,
        logType: 3,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.taskRecordList = [...this.taskRecordList, ...res['data'].records];
      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    })
  }
  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getTaskRecordList();
  }
  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'task',
        bindId: this.taskInfo.id,
        bindName: this.taskInfo.workOrderNo,
        customerId: this.taskInfo.customerId,
        customerName: this.taskInfo.customerName,
        companyId: this.taskInfo.companyId,
        companyName: this.taskInfo.companyName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.taskRecordList = [];
          this.getTaskRecordList();
        })
      }
    });
  }

  edit(id, clearingPrice) {
    this.isEdit = true;
  }
  save(id, clearingPrice) {
    let params = {
      id: id,
      clearingPrice: clearingPrice
    }
    this.api.editOrderTask(params).subscribe(res => {
      this.isEdit = false;
      this.getTaskRecordList();
    })
  }
  cancel() {
    this.isEdit = false;
    this.getTaskDetail();
  }
  deliveryEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加交付记录',
      nzContent: ModalDeliveryWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'task'
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        data['taskID'] = this.value;
        this.api.addDeliveryRecordList(data).subscribe(res => {
          this.customerTaskRecord = [];
          this.getTaskContinueListById();
        })
      }
    });
  }
  // 获取任务详情
  getTaskDetail() {
    this.api.getTaskDetail({ taskId: this.value }).subscribe(res => {
      this.taskInfo = res['data'];
    })
  }

  pageCtrl2 = {
    pageIndex: 1,
    pageSize: 5
  }
  sysLoadingMore = true;
  getTaskContinueListBySys() {
    const params = {
      current: this.pageCtrl2.pageIndex,
      size: this.pageCtrl2.pageSize,
      object: {
        taskId: this.value
      }
    }
    this.api.getTaskContinueListBySys(params).subscribe(res => {
      this.sysTaskRecordList = [...this.sysTaskRecordList, ...res['data'].records];

      if (this.pageCtrl2.pageIndex == res['data'].pages) {
        this.sysLoadingMore = false;
      }
    })

  }
  // 预览凭证
  preview(data) {
    window.open(data);
  }
  pageCtrl3 = {
    pageIndex: 1,
    pageSize: 5
  }
  getTaskContinueListById() {
    const params = {
      current: this.pageCtrl3.pageIndex,
      size: this.pageCtrl3.pageSize,
      object: {
        taskId: this.value
      }
    }
    this.api.getTaskContinueListById(params).subscribe(res => {
      this.customerTaskRecord = [...this.customerTaskRecord, ...res['data'].records];
      if (this.pageCtrl3.pageIndex == res['data'].pages) {
        this.customerLoadingMore = false;
      }
    })
  }



  // 是否显示加载更多
  onSysLoadMore() {
    this.pageCtrl2.pageIndex++;
    this.getTaskContinueListBySys();
  }

  customerLoadingMore = true;
  // 是否显示加载更多
  onCustomerLoadMore() {
    this.pageCtrl3.pageIndex++;
    this.getTaskContinueListById();
  }

  //选择跟进人
  relevancyStaffShow(content) {
    this.form.reset()
    this.getCompanyData();
    const modalService = this.modalService.create({
      nzTitle: '选择跟进人',
      nzContent: content,
      nzStyle: { top: '80px' },
      nzWidth: 900,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addJobJoint()
    });
    // modalService.afterClose.subscribe(res => {
    //   this.getCustomerDetail();
    // })
  }

  //获取公司信息
  getCompanyData() {
    let data = {
      type: 0,
    };
    this.api.getCompanyOrDept(data).subscribe(res => {
      this.companyData = res['data'];
    });
  }

  addJobJoint() {
    let data = {
      customerId: this.value,
      jobId: this.jobId,
      userId: this.form.value.UserSelectedValue,
    }
    this.api.addJobJoint(data).subscribe(res => {
      this.message.success('添加成功');
      this.drawerRef.close();

    });
  }

  loadDeptData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      let data = {
        type: 1,
      }
      if (index < 0) {
        if (cnm == null) {
          return;
        }
        data['companyId'] = cnm;
      } else {
        data['companyId'] = node.id;
      }
      apiTmp.getDept(data).subscribe(res => {
        node.children = res['data'];
        resolve();
      });
    });
  }

  //获取部门信息
  getDeptData() {
    cnm = this.form.controls.companyId.value;
    let data = {
      type: 1,
    }
    this.form.get('remark').setValue(null);
    this.api.getAuthorityDept(data).subscribe(res => {
      this.nzOptions = res['data'];
    });
  }
  validate(_index: number): boolean {
    return true;
  }


  depid;

  onDeptChanges(values): void {
    if (values == null) {
      return;
    }
    if (values.length > 1) {
      this.depid = values.slice(-1);
      let data = {
        deptId: this.depid
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    } else {
      let data = {
        deptId: values
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    }
  }

  UserSelectedChange(e) {
    //console.log(e)
  }
  ngOnInit() {
    this.getTaskDetail();
    this.changeTabs(this.index);
    this.getDeptData();
    console.log(this.orderData);
  }

  companySelect = [];//公司
  companyIds = null;
  companyNames = null;
  CompanySelectShow(obj, CompanyListTemplate) {
    console.log(obj)

    this.getCompanySelectByCusId(obj.customerId);
    this.modalService.create({
      nzTitle: '选择公司',
      nzContent: CompanyListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 700,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addParentNote(obj)
    });
  }

  addParentNote(obj) {
    let data = {
      companyId: this.companyIds,
      id: obj.id,
      companyName: this.companyNames
    }
    if (this.companyIds) {
      (this.companySelect || []).map(item => {
        if (item.id === this.companyIds) {
          this.companyNames = item.name
        }
        return
      })
    }
    data.companyName = this.companyNames
    this.api.updateOrderTaskCompanys(data).subscribe((res) => {
      this.getTaskDetail();
    })
  }
  // 获取公司列表
  getCompanySelectByCusId(customerId) {
    this.api.getCompanySelectByCusIds({ customerId: customerId }).subscribe((res) => {
      this.companySelect = res['data'];
    })
  }
  // 获取关联工单信息
  getOrderTaskLists() {
    let data = {
      orderId: this.orderData.orderId,
    }
    this.api.orderTaskLists(data).subscribe((res) => {
      console.log(res['data'])
      this.orderTaskDetailList = res['data'];
    })
  }

  // 打开任务详情
  // isSee true 关联工单打开，不能添加操作与支付记录
  open(id, index, jobId, orderData) {
    const modal = this.drawerService.create<DrawerTaskComponent, { value: string, index: string, jobId: any, orderData: object, isSee: boolean }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id,
        index: index,
        jobId: jobId,
        orderData: orderData,
        isSee: true
      }
    });
    modal.afterClose.subscribe((data) => {
      // this.getTaskList();
    });
  }

  //富文本图片 点击查看
  showImages(event) {
    if (event.target.nodeName == 'IMG') {
      window.open(event.target.currentSrc)
    }
  }

  // 编辑公司
  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => this.getTaskDetail());
  };


  orderTaskAttachments = [];
  // 回单
  uploadChangeHd(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        var params = { "attachmentUrl": '' };
        params.attachmentUrl = item.response.data.fileUrl
        attachmentUrls.push(params)
      })
      this.orderTaskAttachments = attachmentUrls;
    }
  }
  uploadTem(temp) {
    this.modalService.create({
      nzTitle: '修改附件',
      nzContent: temp,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        if (this.orderTaskAttachments.length == 0) {
          this.message.error('请上传附件');
          return false;
        }
        this.api.updateFinishTaskAttachment({
          taskId: this.value,
          orderTaskAttachments: this.orderTaskAttachments
        }).subscribe(res => {
          this.ngOnInit();
          this.orderTaskAttachments = [];
        })
      }
    });
  }

}
