import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-drawer-budget-finance',
  templateUrl: './drawer-budget-finance.component.html',
  styleUrls: ['./drawer-budget-finance.component.less']
})
export class DrawerBudgetFinanceComponent implements OnInit {


  @Input() value = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  listOfData = {};
  tabNum = 0;

  ngOnInit() {
    this.getTeamStatisticsDetail();
  }


  // 获取客户列表
  getTeamStatisticsDetail() {
    this.api.detailOfficeYearConfig(this.value).subscribe(res => {
      this.listOfData = res['data'];
    });
  }

}
