import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import {NzMessageService} from 'ng-zorro-antd/message';

let api_tmp;

@Component({
  selector: 'app-modal-add-thread-planname',
  templateUrl: './modal-add-thread-planname.component.html',
  styleUrls: ['./modal-add-thread-planname.component.less']
})
export class ModalAddThreadPlannameComponent implements OnInit {
  form: FormGroup;
  canSubmit = true;
  //显示的日期
  dateOfs = [];
  //数据集合
  list = [];
  //录入价格数据
  entryData=[];

  constructor(fb: FormBuilder,
    private api: Api,
    public message: NzMessageService,
    private modal: NzModalRef, ) {
    api_tmp = api;
    this.form = fb.group({
      title: ['', Validators.required],
      actualAmount: [0, Validators.required],//实际金额
      planAmount: [0, Validators.required],//计划金额
      planNumber: [0, Validators.required],//预计线索条数
      endDate: [null, Validators.required],
      startDate: [null, Validators.required],
      areaId: [null],
      remark: [null, Validators.required],
    });
  }
  // #region 提交修改
  submit() {
    console.log(this.entryData)
    this.canSubmit = false;
    if(!this.entryData || this.entryData.length<=0){
      this.canSubmit = true;
      this.cancel();
      return ;
    }
  
    this.entryData.map(item=>{
      console.log(item)
      for (let mealName of Object.values(item)) {
        console.log(mealName);
       for(var i=0;i<mealName["length"];i++){
         delete mealName[i].sourceFlag
       }
      }
    })
    const param={
      priceData:this.entryData,
      sourceFlag: "NUCLEAR_NAME"
    }
    console.log(this.entryData)
    this.api.inputPriceSave(param).subscribe(res=>{
      console.log(res)
      this.init();
      this.canSubmit = true;
      this.cancel();
    }, err=>{
      this.canSubmit = true;
    });
  }
  changePrice(data,areaId) {
    delete data.sourceFlag
    const prams=data
   prams.sourceFlag="NUCLEAR_NAME"
    if(data.id){
      this.api.inputPriceUpdate(data).subscribe(res=>{              
      });
      return ;
    }
    
    let isBreak = false;
    let isNew = true;
    for(let i=0; i<this.entryData.length; i++){
        //判断当前areaId是否已经存入
        if(this.entryData[i][areaId]){
            //判断当前日期是否已存入
            for(let j=0; j<this.entryData[i][areaId].length; j++){
                if(this.entryData[i][areaId][j].dateOf == data.dateOf){
                  //修改当前数据， 调用修改接口 
                  isBreak = true;
                  isNew = false;
                  break;
                }
            }
            if(!isBreak){
              //当前地区已存在，当天不存在，则将当天push进入数组，进行新增操作
              isNew = false
              this.entryData[i][areaId].push(data);
            }
            break;
        }
    }
    if(isNew){
      let tmp = {}
      tmp[areaId]=[data];
      this.entryData.push(tmp);
    }
  }

  // #endregion

  // #region 
  /**
   * 缓存城市
   * @param node 
   * @param index 
   */
  loadCityData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      const params = {}
      if (index < 0) {
        params['level'] = 1;
      } else if (index === 0) {
        params['level'] = 2;
        params['parentCode'] = node.code;
      }
      api_tmp.getCityList(params).subscribe(res => {
        node.children = res['data'];
        if (index === 0) {
          node.children.map((v) => {
            v['isLeaf'] = true;
          })
        }
        resolve();
      })
    });
  }


  cancel() {
    this.modal.destroy();
  }
  init(){
    //初始化录入价格表格
    this.dateOfs=[];
    this.api.getNearlyWeek({sourceFlag:"NUCLEAR_NAME"}).subscribe(res=>{
      this.list=res['data'];
      if(this.list.length>0){
          this.list[0].detail.forEach(item=>{
              this.dateOfs.push(item.dateOf);
          });
      }
      console.log(this.list);
      console.log(this.dateOfs);
  });
  }
  ngOnInit() {
    this.init();
  }

}
