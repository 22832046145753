import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable()
export class Global {
  public activePermission = new BehaviorSubject<Array<any>>([]);

  changemessage(message): void {
    this.activePermission.next(message);
  }
}
