import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DrawerOrderComponent } from '../../component/drawer-order/drawer-order.component';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { DrawerProductExamineComponent } from '../../component/drawer-product-examine/drawer-product-examine.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
@Component({
  selector: 'app-drawer-service-storages',
  templateUrl: './drawer-service-storages.component.html',
  styleUrls: ['./drawer-service-storages.component.less']
})
export class DrawerServiceStoragesComponent implements OnInit {
  @Input() value = '';
  storagesInfo;
  orderCommissionAmount;
  constructor(
    private modalService: NzModalService,
    private api: Api,
    private NzDrawerRef: NzDrawerRef<string>,
    private drawerService: NzDrawerService,
  ) { }
  title = {
    panelTitle1: '业务销售提成',
    panelTitle2: '工商交付提成',
    panelTitle3: '记账报税提成',
    panelTitle4: '管理及其他提成',
    panelTitle5: '暂存款/线索费用/订单退款',
    panelTitle6: '审核情况',
    panelTitle7: '个人额外提成',
  }
  small: string;
  settlementItemBusinesses = []
  sysUserSettlementRecommendOrderList = []
  ngOnInit() {
    this.api.getCommissinSettlementDetail({ settlementId: this.value }).subscribe(res => {
      this.storagesInfo = res['data']
      this.settlementItemBusinesses = this.storagesInfo.settlementItemBusinesses;
      this.sysUserSettlementRecommendOrderList = this.storagesInfo.sysUserSettlementRecommendOrderList;
      // 审核增加状态
      this.title.panelTitle6 =
        this.title.panelTitle6 + '：' +
        (this.storagesInfo.settlementStatus == 0 ? '待审核（' + (this.storagesInfo.checkUserName || '') + '）' :
          this.storagesInfo.settlementStatus == 1 ? '审核通过' :
            this.storagesInfo.settlementStatus == 17 ? '已撤回' :
              this.storagesInfo.settlementStatus == 2 ? '审核不通过' :
                '-')
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.storagesInfo.flowBusinessStepDTOList && this.storagesInfo.flowBusinessStepDTOList.length > 0) {
        this.storagesInfo.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
      // let id = this.storagesInfo.checkUserId
      // if(id){
      //   this.api.getSysUserInfos(id).subscribe(res=>{
      //    this.storagesInfo['checkUserCompanyName'] = res['data'].companyName;
      //    this.storagesInfo['checkUserDeptName'] = res['data'].deptName;
      //   })
      // }
      this.findByUserSettlementId();
      this.getOrderGrossProfitAmount();
    });
  }
  settlementGrossInfo;
  // 获取毛利提成
  findByUserSettlementId() {
    this.api.findByUserSettlementId(this.value).subscribe(res => {
      this.settlementGrossInfo = res['data'];
    })
  };
  //订单+甩单毛利计算
  getOrderGrossProfitAmount() {
    if (this.storagesInfo.newFlag == 0) {
      this.orderCommissionAmount = 0;
      var order = 0;
      (this.settlementItemBusinesses || []).map((item) => {
        order += Number(item.grossProfitAmount);
      })
      var flingOrder = 0;
      (this.sysUserSettlementRecommendOrderList || []).map((item) => {
        flingOrder += Number(item.actualProfitAmount);
      })
      this.orderCommissionAmount = order + flingOrder;
    } else if (this.storagesInfo.newFlag == 1) {
      this.orderCommissionAmount = 0;
      var order = 0, flingOrder = 0;
      this.settlementItemBusinesses.map(item => {
        // order += Number(item.grossProfitAmount);
        order += Number(item.profitAmount);
      })
      // this.relationFlingOrderList.map(item => {
      //   flingOrder += Number(item.actualProfitAmount);
      // })
      this.orderCommissionAmount = order + flingOrder;
    }
  }

  // 订单 - 产品提成明细 star
  listItemData = [];
  userSettlementItemBusinessId = null;
  //分页
  pageCtrl2 = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage2(page) {
    this.pageCtrl2.nzPageIndex = page;
    this.getProductLists();
  }

  // 
  openProduct(content, id) {
    this.userSettlementItemBusinessId = id;
    this.getProductLists();
    const modal = this.modalService.create({
      nzTitle: '产品列表',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 1250,
      nzMaskClosable: false,
      nzFooter: null
    });
  }

  getProductLists() {
    const params = {
      current: this.pageCtrl2.nzPageIndex,
      size: this.pageCtrl2.nzPageSize,
      object: {
        userSettlementItemBusinessId: this.userSettlementItemBusinessId
      }
    }
    this.api.commissionProductLists(params).subscribe(res => {
      this.listItemData = res['data'].records;
      this.pageCtrl2.total = res['data'].total;
    });
  }
  //END

  //会计提成明细 star
  serverPeriod = [];
  settlementMonth = null;
  serverPeriodCode = null;
  //分页
  pageCtrl3 = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage3(page) {
    this.pageCtrl3.nzPageIndex = page;
    this.getServerPeriodLists();
  }
  openServerPeriod(content, data, code) {
    this.settlementMonth = data.settlementMonth;
    this.serverPeriodCode = code;
    this.getServerPeriodLists();
    const modal = this.modalService.create({
      nzTitle: '记账报税提成明细',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskClosable: false,
      nzFooter: null
    });
  }

  getServerPeriodLists() {
    let params = {
      current: this.pageCtrl3.nzPageIndex,
      size: this.pageCtrl3.nzPageSize,
      object: {
        userId: this.storagesInfo['applyUserId'],
        typeNo: this.serverPeriodCode,
        settlementMonth: this.settlementMonth,
      }
    }
    this.api.getServerPeriodDetails(params).subscribe(res => {
      this.serverPeriod = res['data'].records;
      this.pageCtrl3.total = res['data'].total;
    })
  }

  //END

  // 打开订单信息详情
  openOrder(id) {
    let component;
    component = DrawerOrderComponent;

    this.drawerService.create<DrawerOrderComponent, { value: string, type: any }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: component,
      nzContentParams: {
        value: id,
        type: 0,
      }
    });
  }

  // 打开工单详情
  openTask(id) {
    const drawerRef = this.drawerService.create<DrawerTaskComponent, { value: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id
      }
    });
  }

  // 打开产品详情
  openProuduct(id) {
    const modal = this.drawerService.create<DrawerProductExamineComponent, { value: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerProductExamineComponent,
      nzContentParams: {
        value: id,
      }
    });
    modal.afterClose.subscribe(() => {
    })
  }

}
