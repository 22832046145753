import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from '../../auth/auth.service';
@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.less']
})
export class EnterpriseComponent implements OnInit {

  constructor(
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

    let data = {
      code: this.route.snapshot.queryParams['auth_code'],
    };

    this.authService.enterpriseLogin(data).subscribe(() => {
      if (this.authService.isLoggedIn) {
        let redirect = '/dashboard';
        this.router.navigateByUrl(redirect);
      }
    }, err => {
      console.log('登录失败')

    })

  }

}
