import { Component, OnInit, Input } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';


@Component({
  selector: 'app-drawer-other-income',
  templateUrl: './drawer-other-income.component.html',
  styleUrls: ['./drawer-other-income.component.less']
})
export class DrawerOtherIncomeComponent implements OnInit {
  @Input() value = '';
  constructor(
    public location: Location,
    public api: Api,
    public message: NzMessageService,
  ) {

  }

  otherIncomeDetail;
  ngOnInit() {
    this.api.detailBsOtherIncome(this.value).subscribe(res => {
      this.otherIncomeDetail = res['data'];
    })
  }
}
