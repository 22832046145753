import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { differenceInCalendarDays } from 'date-fns';
import { Utils } from '@service/Utils';
import { ModalAddFileComponent } from '../../component/modal-add-file/modal-add-file.component';
import { ModalCompanyComponent } from '../../component/modal-company/modal-company.component';

@Component({
  selector: 'app-modal-addtask',
  templateUrl: './modal-addtask.component.html',
  styleUrls: ['./modal-addtask.component.less']
})
export class ModalAddtaskComponent implements OnInit {
  @Input() id: string; // 产品id
  @Input() customerId: string; // 客户id
  @Input() type: string; // 类型
  @Input() commissionPrice: string; // 预估结算价
  @Input() productNature: number; // 是否长期产品
  @Input() detail: object; // 产品数据

  uploadUrl: string;
  form: FormGroup;
  loading = false;
  userList = [];
  customerList = [];
  departmentList = [];
  taskTypeList = []; // 任务列表
  companySelect = [];
  orderTaskAttachments = []; // 附件
  areaId: number; //地区ID
  name: string; //任务名称
  needCompany: number; //公司是否必填，0不必填，1必填
  period: number; //是否周期任务，0周期性，1一次性
  taskType: number; //任务类型,0工商类，1会计类
  workOrderAttr: string; // 工单属性
  loadDeptData: any;
  today = new Date();
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  city_Data = [];//区域
  companyData = []; // 公司列表
  isNoOrder = false;//是否非订单工单
  workOrderAttributeList = [];//工单属性
  priorityList = [
    { value: 'GENERAL', name: '一般' },
    { value: 'URGENT', name: '紧急' },
    { value: 'VERY_URGENT', name: '非常紧急' },
  ];
  //分页
  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getOrderTypeList();
  };
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.uploadUrl = this.api.uplaodAttachment();
    this.contractUrl = this.api.storagesUploadWithController();
    this.form = fb.group({
      officeId: [null],  // 部门id
      //period: [null, Validators.pattern(/^[0-9|.]*$/)],    // 总周期
      // taskId: [null, Validators.required],    // 任务id
      userId: [null],    // 指派人
      // startDate: [null, Validators.required],
      // endDate: [null, Validators.required],
      priority: [null],
      remark: [null],
      workOrderAttr: [null, Validators.required],
      orderProductId: [null],
      sOrderData: [null],
      companyId: [null],//客户公司ID
      companyName: [null],
      tempSettPrice: [null],
      startPeriod: [null],
      period: [null],
      documentaryCompanyId: [null],//同城，异地跟单公司
      documentaryAreaId: [null],//同城，异地跟单区域
      department: [null],//同城，异地跟单部门 提交赋值 officeId = department
    });
  }
  contractUrl: string;
  cancel() {
    this.modal.destroy();
  }
  // 提交任务
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    if (this.type == 'wfqd') {
      if (!params.workOrderAttr) {
        this.message.error('请选择工单属性')
        this.loading = false
        return
      }
      if (this.wordOrder) {
        if (!params.sOrderData) {
          this.message.error('请选择关联订单号')
          this.loading = false
          return
        }
        if (!params.orderProductId) {
          this.message.error('请选择关联产品')
          this.loading = false
          return
        }
      }
    }

    // params.taskId = this.signTeplateData[0].id;
    // params.endDate = this.dataToStr(params.endDate);
    // params.startDate = this.dataToStr(params.startDate);
    var officeId = JSON.parse(JSON.stringify(params.officeId))
    var documentaryAreaId = JSON.parse(JSON.stringify(params.documentaryAreaId))
    params.startPeriod = Utils.dateFormat(params.startPeriod, 'yyyy-MM');//月份

    if (this.differentPlaces || this.sameCity) {

      //同城，异地
      if (documentaryAreaId && documentaryAreaId.length > 1) {
        params.documentaryAreaId = documentaryAreaId[1]
      }
      params.officeId = params.department

      if (this.differentPlaces && !params.documentaryAreaId) {
        this.message.error('请选择区域')
        this.loading = false
        return
      }
      if (this.sameCity && !params.documentaryCompanyId) {
        this.message.error('请选择跟单公司')
        this.loading = false
        return
      }
      if (this.sameCity && !params.department) {
        this.message.error('请选择接单部门')
        this.loading = false
        return
      }
    } else {
      if (!officeId) {
        this.message.error('请选择接单部门')
        this.loading = false
        return
      }
      if (officeId.length > 1) {
        params.officeId = officeId[1]
      } else {
        params.officeId = officeId[0]
      }
    }

    if (params.companyId && !this.isNoOrder) {
      (this.customerList || []).map(item => {
        if (item.id === params.companyId) {
          params.companyName = item.name
        }
        return
      })
    } else {
      params.companyId = null;
    }

    if (this.isNoOrder && params.companyName == '' || params.companyName === null) {
      this.message.error('客户公司不能为空')
      this.loading = false
      return
    }

    //判断是否选择长期产品
    // if (this.productNature == 1) {
    //   if (params.startPeriod == '1970-01' || !params.startPeriod) {
    //     this.message.error('请选择服务开启月份')
    //     this.loading = false
    //     return
    //   }
    //   // if (!params.period) {
    //   //   this.message.error('请输入服务总周期(月)')
    //   //   this.loading = false
    //   //   return
    //   // }
    // }
    if (!this.productNature) {
      params.startPeriod = null
      params.period = null
    }

    //工单-我发起的工单
    if (this.type == 'wfqd') {
      params.customerId = this.selectOrderData ? this.selectOrderData[0].customerId : null;
      params.orderTaskAttachments = this.attr();
      this.api.addOrderTask(params).subscribe(res => {
        this.message.success('添加成功！');
        this.modal.destroy(params);
      }, err => {
        this.loading = false;
      })
    } else {
      //订单-新建工单
      params.customerId = this.customerId
      params.orderProductId = this.id
      params.orderTaskAttachments = this.attachmentUrl();
      this.api.addOrderTask(params).subscribe(res => {
        this.message.success('添加成功！');
        this.modal.destroy(params);
      }, err => {
        this.loading = false;
      })
    }
  }

  attr() {
    var attachmentUrls = [];
    (this.orderTaskAttachments || []).map((item, index) => {
      attachmentUrls.push(item.attachmentUrl)
    })
    return attachmentUrls
  }

  attachmentUrl() {
    var attachmentUrls = [];
    (this.orderTaskAttachments || []).map((item, index) => {
      let p = {
        attachmentUrl: item.attachmentUrl
      }
      attachmentUrls.push(p)
    })
    return attachmentUrls
  }
  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }

  sameCity = false;//同城
  differentPlaces = false;//异地
  wordOrder = false; //我发起的
  isEstimate = false;//暂估结算价
  isFile: boolean;//附件上传

  workOrderChange(e) {
    let params = this.form.getRawValue();
    //订单工单(内部)
    if (e == 'ORDER_WORK_ORDER_INSIDE') {
      // 关联订单号,关联产品
      this.wordOrder = true
      this.sameCity = false
      this.differentPlaces = false
      this.isEstimate = false
      this.isFile = true;
    }

    //同城 公司，部门，人
    if (e == 'ORDER_WORK_ORDER_LOCAL') {
      this.sameCity = true
      this.isFile = true;
      this.differentPlaces = false
      this.wordOrder = true
      this.isEstimate = false
      let info = JSON.parse(localStorage.getItem('userInfo'))
      let arr = [
        null,
        info.company['areaId']
      ]
      this.findCompanyByAreaId(arr)
    }

    //异地 区域 公司 部门 人
    if (e == 'ORDER_WORK_ANOTHER_PLACE') {

      this.differentPlaces = true
      this.sameCity = true
      this.wordOrder = true
      this.isFile = true;
      this.isEstimate = true
      //我的订单 发起工单，预估价赋值 
      if (this.type != 'wfqd') {
        this.form.get('tempSettPrice').setValue(this.commissionPrice);
        params.tempSettPrice = this.commissionPrice
      }
      this.loadCityData();
    }
    this.isNoOrder = false;

    //非订单工单(内部) 全部移除
    if (e == 'NOT_ORDER_WORK_ORDER_INSIDE') {
      this.wordOrder = false
      this.sameCity = false
      this.differentPlaces = false
      this.isEstimate = false
      this.isNoOrder = true;
      this.isFile = false;
    }
  }

  //区域获取 公司
  // 选择异地 查区域
  loadCityData() {
    this.api.getProvinciDataByNoRole().subscribe(res => {
      this.city_Data = res['data'];
    });
  }
  // 选择同城 查公司
  findCompanyByAreaId(areaId) {
    if (null != areaId) {
      this.api.findCompanyNoPerByAreaId({ areaId: areaId[1], requestSource: 1 }).subscribe((res) => {
        this.companyData = res['data'];
      })
    }
  }
  deptList = []; //部门列表
  findDeptById(companyId) {
    if (null != companyId) {
      this.api.findDeptByCompanyId({ companyId: companyId }).subscribe((res) => {
        this.deptList = res['data'];
      })
    }
  }
  //人员
  depid;
  onDeptChanges(values): void {
    if (values == null) {
      return;
    }
    if (values.length > 1) {
      this.depid = values.slice(-1);
      let data = {
        deptId: this.depid
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.userList = res['data'];
      })
    } else {
      let data = {
        deptId: values
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.userList = res['data'];
      })
    }
  }

  // 获取公司部门列表
  getDepartment() {
    this.api.findCompanyDeptTree().subscribe((res) => {
      this.departmentList = res['data'];
    })
  }

  // 获取任务列表
  getOrderTypeList() {
    const pamares = {
      current: this.pageCtrl.nzPageIndex,
      object: {
        areaId: this.areaId,
        name: this.name,
        needCompany: this.needCompany,
        period: this.period,
        taskType: this.taskType,
      },
      size: this.pageCtrl.nzPageSize
    }
    this.api.getTaskTypeList(pamares).subscribe((res) => {
      this.taskTypeList = res['data'].records;
      this.pageCtrl.total = res['data'].total
    })
  }

  signTeplateData;
  NoteListTemplateShow(NoteListTemplate) {
    this.modalService.create({
      nzTitle: '选择任务',
      nzContent: NoteListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 980,
      nzMaskClosable: false,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => this.addParentNote()
    });
  }
  addParentNote() {
    this.form.get('taskId').setValue(this.signTeplateData[0].name);
  }

  //选择任务模板
  mapOfCheckedId: { [key: string]: boolean } = {};
  refreshStatus(e, id): void {
    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.signTeplateData = this.taskTypeList.filter(item => item.id == id);
  }

  selectOrderData;
  addOrderList(orderListTemplate) {
    this.pageCtrl2.nzPageIndex = 1;
    this.modalService.create({
      nzTitle: '添加关联订单',
      nzContent: orderListTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 1200,
      nzMaskClosable: false,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzOnOk: () => this.addParentOrder()
    });
    this.getOrderList();
  }
  addParentOrder() {
    this.form.get('orderProductId').setValue(null);
    this.form.get('sOrderData').setValue(this.selectOrderData[0].orderNo);
    this.getOrderProductList(this.selectOrderData[0].id);
    this.getUserCompanyIdsList(this.selectOrderData[0].customerId)
  }
  orderMapOfCheckedId: { [key: string]: boolean } = {};
  orderRefreshStatus(e, id): void {
    for (let i in this.orderMapOfCheckedId) {
      this.orderMapOfCheckedId[i] = false;
    }
    this.orderMapOfCheckedId[id] = true;
    this.selectOrderData = this.orderList.filter(item => item.id == id);
  }
  params2 = {
    orderNo: null,
    customerName: null,
    // requestSource: 1,
    phoneNumber: null,
  };
  pageCtrl2 = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  orderList = [];
  workProductAttributeList = [];
  workProductProductList = [];
  getOrderList() {
    let params = {
      current: this.pageCtrl2.nzPageIndex,
      size: this.pageCtrl2.nzPageSize,
      object: this.params2,
    };
    // this.api.getMyOrderTaskList(params).subscribe(res => {
    this.api.getMyOrderList(params).subscribe(res => {
      this.orderList = res['data'].records;
      this.pageCtrl2.total = res['data'].total;
    });
  }

  toPage2(page) {
    this.pageCtrl2.nzPageIndex = page;
    this.getOrderList();
  };

  query() {
    this.getOrderList();
  }
  reset() {
    this.params2 = {
      orderNo: null,
      customerName: null,
      phoneNumber: null,
      // requestSource: 1,
    }
    this.getOrderList();
  }

  getOrderProductList(id) {
    this.workProductAttributeList = [];
    this.workProductProductList = [];
    this.api.getOrderProduct({ orderId: id }).subscribe(res => {
      this.workProductProductList = res['data'];
      let data = res['data'];
      (data || []).map(item => {
        let obj = {};
        obj['value'] = item.id;
        obj['name'] = item.productName;
        this.workProductAttributeList.push(obj)
      })
    })
  }
  hasCompanyId = false;
  //选择关联产品 ，暂估结算价赋值
  orderProductChange(e) {
    let params = this.form.getRawValue();
    (this.workProductProductList || []).map(item => {
      // if (e == item.id && this.isEstimate) {
      //   this.form.get('tempSettPrice').setValue(item.commissionPrice);
      //   params.tempSettPrice = item.commissionPrice

      //   console.log(this.productNature)
      //   return
      // }
      if (e == item.id) {
        this.productNature = item.productNature;
        if (item.productNature != 0) {
          //是否长期产品字段 赋值
          this.serverPeriod = item.serverPeriod;
        }
        // if (item.productNature != 0) {
        //   //是否长期产品字段 赋值
        //   this.serverPeriod = item.serverPeriod;
        // }
        // 赋值公司
        if (item.companyId) {
          this.form.get('companyId').setValue(item.companyId);
          this.hasCompanyId = true;
        } else {
          this.hasCompanyId = false;
        }
        console.log(this.hasCompanyId)
        if (this.isEstimate) {
          this.form.get('tempSettPrice').setValue(item.commissionPrice);
          params.tempSettPrice = item.commissionPrice
          return
        }
      }

    })
  }

  //查询，清空
  inquireList() {
    this.getOrderTypeList();
  }
  resetList() {
    this.areaId = null;
    this.name = '';
    this.needCompany = null;
    this.period = null;
    this.taskType = null;
    this.getOrderTypeList();
  }

  deptId;
  myDeptId;
  deptUserId;
  // 获取部门下用户Id列表
  getUserIdList(datas) {
    var officeId = JSON.parse(JSON.stringify(datas))
    var data = '';
    if (officeId.length > 1) {
      data = officeId[1]
    } else {
      data = officeId[0]
    }
    this.deptUserId = null;
    this.api.getUserListByDeptId({ deptId: data }).subscribe((res) => {
      this.myDeptId = JSON.parse(localStorage.getItem('userInfo')).dept.id;
      this.deptId = data;
      this.userList = res['data'];
    })
  }

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          attachmentUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
      this.orderTaskAttachments = attachmentUrls;
    }
  }
  serverPeriod;
  ngOnInit() {
    this.getDepartment();
    this.getOrderTypeList();
    if (this.type != 'wfqd') {
      this.getUserCompanyIdsList(this.customerId)
      this.workOrderAttributeList = [
        { 'value': 'ORDER_WORK_ORDER_INSIDE', 'name': '订单工单(内部)' },
        { 'value': 'ORDER_WORK_ORDER_LOCAL', 'name': '订单工单(同城)' },
        { 'value': 'ORDER_WORK_ANOTHER_PLACE', 'name': '订单工单(异地)' },
      ]
    } else {
      this.workOrderAttributeList = [
        { 'value': 'ORDER_WORK_ORDER_INSIDE', 'name': '订单工单(内部)' },
        { 'value': 'NOT_ORDER_WORK_ORDER_INSIDE', 'name': '非订单工单(内部)' },
        { 'value': 'ORDER_WORK_ORDER_LOCAL', 'name': '订单工单(同城)' },
        { 'value': 'ORDER_WORK_ANOTHER_PLACE', 'name': '订单工单(异地)' },
      ]
    }
    if (this.detail && this.detail['serverPeriod']) {
      this.serverPeriod = this.detail['serverPeriod']
    };
    if (this.detail && this.detail['companyId']) {
      this.form.get('companyId').setValue(this.detail['companyId']);
      this.hasCompanyId = true;
    } else {
      this.hasCompanyId = false;
    }
    console.log(this.hasCompanyId)
  }

  // 根据客户ID，选择公司信息 
  getUserCompanyIdsList(customerId) {
    this.api.getCompanySelectByCusIds({ customerId: customerId }).subscribe((res) => {
      this.customerList = res['data'];
    })
  }

  //调取附件
  fileShow() {
    if (this.type == 'wfqd' && !this.selectOrderData) {
      this.message.error('请先选择关联订单号')
      return
    }
    const modal = this.modalService.create({
      nzTitle: '上传附件',
      nzContent: ModalAddFileComponent,
      nzComponentParams: {
        customerId: this.type != 'wfqd' ? this.customerId : this.selectOrderData[0].customerId,
        type: 'task'
      },
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        console.log(res)
        //this.orderTaskAttachments = [...this.orderTaskAttachments,...res]
        this.orderTaskAttachments = [...new Set([...this.orderTaskAttachments, ...res])];
      }
    });
  }

  // 新建公司
  createComponentModal(): void {
    var customerId;
    if (this.type == 'wfqd') {
      if (!this.selectOrderData) {
        this.message.error('请先关联订单')
        return
      } else {
        customerId = this.selectOrderData[0].customerId
      }
    } else {
      customerId = this.customerId
    }
    const title = "添加公司";
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: customerId,
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe(() => this.getUserCompanyIdsList(customerId));
  }

}
