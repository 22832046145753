import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getAuthorizationToken() || '';
    if(req.url.substr(0,7) == "http://" ){
      var fullReq = req.clone({
        url:req.url
      });
    }else{
      var fullReq = req.clone({
        url: environment.baseUrl + req.url
      });
    }
    const authReq = fullReq.clone({ setHeaders: { 'Admin-Token': authToken } });
    return next.handle(authReq);
  }
}
