import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-delivery-year',
  templateUrl: './modal-delivery-year.component.html',
  styleUrls: ['./modal-delivery-year.component.less']
})
export class ModalDeliveryYearComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) { }

  ngOnInit() {
    this.deliverPeriodList();
  }

  params = {
    yearNo: new Date(),
    deliverMonth: Utils.dateFormat(new Date(), 'yyyy-MM'),
  }

  canSubmit = false;
  arrayList = [];
  periodNames = ['period1', 'period2', 'period3', 'period4', 'period5', 'period6', 'period7', 'period8', 'period9', 'period10', 'period11', 'period12'];
  month = null;
  save(value) {
    this.canSubmit = true;
    var params = [];
    var arrayList = this.arrayList;
    var accountCount = 0;//记账数量
    var taxCount = 0;//报税数量
    var voucherCount = 0;//凭证数量
    arrayList.map((item, i) => {
      var canPush = false;
      var data = {
        id: item.id,
        bsCompanyServerPeriodList: [],
      }
      this.periodNames.map((itemPerio, inx) => {
        var period = item[this.periodNames[inx]];
        // 检查那些月份有数据
        if (period) {
          if ((period['accountStatus'] == 1 && !period['accountStatusCheck']) || (period['taxStatus'] == 1 && !period['taxStatusCheck']) || (period['voucherStatus'] == 1 && !period['voucherStatusCheck'])) {
            // 当选中且不是历史数据才添加
            var periodList = {
              id: period['id'],
              accountStatus: period['accountStatus'] ? 1 : 0,
              taxStatus: period['taxStatus'] ? 1 : 0,
              voucherStatus: period['voucherStatus'] ? 1 : 0,
              period: period['period'],
              companyName: period['companyName'],
              companyId: period['companyId'],
            }
            data.bsCompanyServerPeriodList.push(
              periodList
            )
            period['accountStatus'] ? accountCount++ : null;
            period['taxStatus'] ? taxCount++ : null;
            period['voucherStatus'] ? voucherCount++ : null;
            canPush = true;
          }
        }
      })
      if (canPush) {
        params.push(data);
      }
    })
    if (!this.params.deliverMonth) {
      this.message.error('请选择结算月份！');
      this.canSubmit = false
      return
    }
    if (params.length <= 0) {
      this.message.error('当前交付数据为空哦！');
      this.canSubmit = false
      return
    }
    this.api.batchDeliver2({
      deliverMonth: Utils.dateFormat(this.params.deliverMonth, 'yyyy-MM'),
      bsCompanyServerPeriodList: params,
      accountCount: accountCount,
      taxCount: taxCount,
      voucherCount: voucherCount,
    }).subscribe(res => {
      this.modal.destroy();
      this.canSubmit = false
      this.message.success('交付成功！');
    }, () => this.canSubmit = false);
  }

  cancel() {
    this.modal.destroy();
  }
  // 转换时间格式
  dateToStr(date) {
    if (date) {
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      return date = year;
    }
    else {
      return date = null;
    }
  }

  selectService(value: object[]): void {
    console.log(value);
  };
  query() {
    this.deliverPeriodList();
  }

  deliverPeriodList() {
    var paramsData = JSON.parse(JSON.stringify(this.params))
    paramsData.yearNo = this.dateToStr(this.params.yearNo);
    if (!paramsData.yearNo) {
      this.message.error('请选择年份！');
      return
    }
    this.api.deliverPeriodList2(paramsData).subscribe(res => {
      var arrayList = res['data'];
      arrayList.map((item, i) => {
        this.periodNames.map((itemPerio, inx) => {
          var period = item[this.periodNames[inx]];
          if (period) {
            period['accountStatus'] = period['accountStatus'] == 1 ? true : false;
            period['accountStatusCheck'] = period['accountStatus'];
            period['taxStatus'] = period['taxStatus'] == 1 ? true : false;
            period['taxStatusCheck'] = period['taxStatus'];
            period['voucherStatus'] = period['voucherStatus'] == 1 ? true : false;
            period['voucherStatusCheck'] = period['voucherStatus'];
          }
        })
      })
      this.arrayList = arrayList;
    });
  }

}
