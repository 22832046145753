import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawerBugetUsedDetailComponent } from '../../component/drawer-buget-used-detail/drawer-buget-used-detail.component';
import { DrawerFinanceComponent } from '../../component/drawer-finance/drawer-finance.component';

@Component({
  selector: 'app-drawer-buget-manage',
  templateUrl: './drawer-buget-manage.component.html',
  styleUrls: ['./drawer-buget-manage.component.less']
})
export class DrawerBugetManageComponent implements OnInit {
  @Input() value = '';
  @Input() tabNums;
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  tabNum = 0;
  listOfData = {};

  ngOnInit() {
    this.getBudgetConfigDetail();
  }


  // 获取客户列表
  getBudgetConfigDetail() {
    this.api.budgetConfigDetail(this.value).subscribe(res => {
      this.listOfData = res['data'];
      this.tabNum = this.tabNums || 0;
    });
  }

  // 打开详情
  open(data?) {
    const modal = this.drawerService.create<DrawerBugetUsedDetailComponent, { value: string, budgetConfigId: string, nominalCate: any }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerBugetUsedDetailComponent,
      nzContentParams: {
        budgetConfigId: this.listOfData['id'],
        value: data ? data.nominalId : null,
        nominalCate: this.tabNum
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }
  changeTabs(e) {
    this.tabNum = e;
  }

  // 打开详情
  openFinance(businessCode, data?) {
    let [yearNo, monthNo] = this.listOfData['budgetMonth'].split("-");
    var params = {
      yearType: 0,
      cate: 1,
      yearNo: yearNo,
      monthNo: monthNo,
    };
    if (this.listOfData['deptId']) {
      params['officeIds'] = [this.listOfData['deptId']];
      params['type'] = 1;
    } else {
      params['officeIds'] = [this.listOfData['officeId']];
    }
    if (data && data.twoCateId) {
      // params.cateIdList = item.cateId.split(',');
      params['cateId'] = data.twoCateId;
    }
    this.drawerService.create<DrawerFinanceComponent, { params: object, financeItem: object }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-finance',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerFinanceComponent,
      nzContentParams: {
        params: params,
        financeItem: { topBusinessCode: businessCode },
      }
    });
  }
}
