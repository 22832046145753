import { Component, OnInit, Input } from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-order',
  templateUrl: './modal-userConfig.component.html',
  styleUrls: ['./modal-userConfig.component.less']
})
export class ModalUserConfigComponent implements OnInit {
  @Input() id: string; // id
  listOfData_userConfig = [];

  list_parameter = []; //用户配置数据字典
  loading = false;
  //编辑框数据
  editvalue: string | null;
  editremark: string | null;
  editCache: { [key: string]: any } = {};

  constructor(
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
  }

  getSysParameters() {
    const data = {
      parentCode: 'USER_CONFIG'
    };
    this.api.getSysParameters(data).subscribe(res => {
      this.list_parameter = res['data'];
      this.list_parameter.map(item => {
        item.value = Number(item.value);
        if (item.remark != null && item.remark != "") {
          item.remark = JSON.parse(item.remark);
        }
      });
      this.selectUserConfig();
    });
  }

  // #region
  //获取用户配置信息
  selectUserConfig() {
    const data = {
      userId: this.id,
    }
    this.api.selectUserConfig(data).subscribe((res) => {
      this.listOfData_userConfig = res['data'];
      console.log(this.listOfData_userConfig)
    });
  }

  /**
   * 新增用户配置
   * @param id
   */
  toAddUserConfig() {
    this.listOfData_userConfig = [
      ...this.listOfData_userConfig,
      {
        id: "",
        type: this.listOfData_userConfig.length,
        userId: this.id,
        value: "",
        remark: "请填入描述",
      }
    ];
  }

  /**
   * 保存用户配置信息
   * @param data
   */
  saveUserConfig(data) {
    console.log(data)
    if (data.type != 0 && data.value !== '0' && data.value !== 0 && data.value != '1') {
      this.message.error('请选择配置值！');
      return false;
    }
    if (data.type == 0 && data.value <= 0) {
      this.message.error('请输入正确的私人池配置！');
      return false;
    }
    data.remark = null;
    this.api.saveUserConfig(data).subscribe(res => {
      this.message.create("success", "保存成功~");
      this.editCache = [];
      this.selectUserConfig();
    });
  }
  /**
   * 删除用户配置
   * @param data
   */
  delUserConfig(data) {
    const param = {
      id: data
    }
    this.api.delUserConfig(param).subscribe(res => {
      this.selectUserConfig();
    });
  }
  changeEdit(e, i) {
    this.startEditValue(i, null, e)
  }
  // #endregion
  //用户配置编辑
  startEditValue(id: string, event: MouseEvent, types): void {
    console.log(types)
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    for (var i in this.list_parameter) {
      if (this.list_parameter[i]['value'] == types) {
        if (this.list_parameter[i]['valueType'] == 1) {
          this.editCache[id] = 2;
          this.listOfData_userConfig[id]['remark'] = this.list_parameter[i]['remark'];
        } else {
          this.editCache[id] = 1;
        }
      }
    }
    console.log(this.editCache)
  }

  startEditRemark(id: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.editremark = id;
  }

  ngOnInit() {
    this.getSysParameters();
  }

}
