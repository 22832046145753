import {Component, ElementRef, Input, OnInit, ViewChild,} from '@angular/core';

import {NzModalRef} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../../../auth/auth.service';
import * as wangEditor from '../../../../../../node_modules/wangeditor/release/wangEditor.js';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Api} from '@service/Api';
import {Utils} from '@service/Utils';
import {environment} from '../../../../../environments/environment';

let cnm;

@Component({
  selector: 'app-noticetemplate',
  templateUrl: './noticetemplate.component.html',
  styleUrls: ['./noticetemplate.component.less']
})

export class NoticetemplateComponent implements OnInit {
  @Input() content: string;

  form: FormGroup;
  noticeList = [];
  // @Output() onPostData = new EventEmitter();
  @ViewChild('editroElement', { static: false }) editroElement: ElementRef;

  private editor: any;
  companyData = []; // 公司列表
  nzOptions = []; // 部门列表

  startDate;
  endDate;
  companyShow = false;
  remarkShow = false;

  msgTypeList = [
    { id: 0, name: '全部' },
    { id: 2, name: '公司' },
    { id: 3, name: '部门' }
  ];

  constructor(
    fb: FormBuilder,
    private modal: NzModalRef,
    public api: Api,
    public utils: Utils,
    private auth: AuthService,
    private el: ElementRef,
    private message: NzMessageService,
  ) {
    this.form = fb.group({
      title: [null, Validators.required],
      msgType: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
      companyId: [null],
      remark: [null],
    });
  }

  dateFormat(a, fmt): string {
    const date = new Date(a);
    const obj = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(),      // 日
      'h+': date.getHours(),     // 小时
      'm+': date.getMinutes(),   // 分
      's+': date.getSeconds(),   // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      // RegExp.$1指的是与正则表达式匹配的第一个(以括号为标志，如:/(y+)/)子匹配字符串
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (const k in obj) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
          ? (obj[k])
          : (('00' + obj[k]).substr(('' + obj[k]).length)));
      }
    }
    return fmt;
  }

  starthandleDateOpenChange(e): void {
    this.startDate = this.dateFormat(e, 'yyyy-MM-dd hh:mm:ss');
  }
  endhandleDateOpenChange(e): void {
    this.endDate = this.dateFormat(e, 'yyyy-MM-dd hh:mm:ss');
  }

  handleDateOpenChange(e): void {
    console.log(e);
  }
  handleDatePanelChange(mode: string): void {
    console.log('handleDatePanelChange: ', mode);
  }

  ngAfterViewInit() {
    const authToken = this.auth.getAuthorizationToken() || '';
    let editordom = this.editroElement.nativeElement;
    // let editordom = this.el.nativeElement.querySelector('#editroElement')
    this.editor = new wangEditor(editordom);
    // this.editor.customConfig.uploadImgShowBase64 = true;
    // this.editor.customConfig.debug = true
    this.editor.customConfig.uploadFileName = 'file'; //设置文件上传的参数名称
    this.editor.customConfig.uploadImgHeaders = {
      'Admin-Token': authToken
    }
    this.editor.customConfig.uploadImgServer = environment.baseUrl + this.api.uplaodAttachment(); // 设置上传文件的服务器路径
    this.editor.customConfig.uploadImgHooks = {
      customInsert: function (insertImg, result, editor) {
        var url = result.data.fileUrl;
        insertImg(url)
      }
    }
    this.editor.create();
    this.editor.txt.html(this.content['content'])
  }

  clickHandle(): any {
    let data = this.editor.txt.text();
    return data
  }
  selectShowData(values): void {
    if (values == 2) {
      this.companyShow = true;
      this.remarkShow = false;
    } else if (values == 3) {
      this.companyShow = true;
      this.remarkShow = true;
    } else {
      this.companyShow = false;
      this.remarkShow = false;
    }
  }

  //获取部门信息
  getDeptData() {
    cnm = this.form.controls.companyId.value;
    let data = {
      type: 1,
      companyId: cnm,
    }
    this.form.get('remark').setValue(null);
    this.api.getDept(data).subscribe(res => {
      this.nzOptions = res['data'];
    });
  }

  // 获取公司信息
  getCompanyData() {
    const data = {
      type: 0,
    };
    this.api.getCompanyOrDept(data).subscribe(res => {
      this.companyData = res['data'];
    });
  }

  onDeptChanges(values): void {
    console.log(values);
  }

  submitForm() {
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      return;
    }
    let msgID = this.form.get('msgType').value;
    let toId;
    if (msgID == 2) {
      toId = this.form.get('companyId').value;
    } else if (msgID == 3) {
      toId = this.form.get('remark').value[0];
    } else {
      toId = 0;
    }
    const params = {
      content: this.editor.txt.html(),
      startTime: this.startDate,
      endTime: this.endDate,
      msgType: msgID,
      title: this.form.get('title').value,
      toId: toId
    }
    if (this.content == undefined || this.content == 'undefined') {
      this.api.getSysMessageAdd(params).subscribe(res => {
        this.cancel();
      })
    } else {
      params['id'] = this.content['id'];
      this.api.modificationSysMessage(params).subscribe(res => {
        this.cancel();
      })
    }
  }

  cancel() {
    this.modal.destroy();
  }

  ngOnInit() {
    this.getCompanyData();
    if (this.content == undefined || this.content == 'undefined') {

    } else {

      console.log(this.content)

      this.form.get('title').setValue(this.content['title']);
      this.form.get('startTime').setValue(this.content['startTime']);
      this.form.get('endTime').setValue(this.content['endTime']);
      this.form.get('msgType').setValue(this.content['toName']);

    }
  }

}
