import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { ModalAddOutlookcustComponent } from '../../component/modal-add-outlookcust/modal-add-outlookcust.component';

@Component({
  selector: 'app-modal-add-outlook',
  templateUrl: './modal-add-outlook.component.html',
  styleUrls: ['./modal-add-outlook.component.less']
})
export class ModalAddOutlookComponent implements OnInit {
  @Input() id: string; // 关联公司id
  @Input() companyName: string; // id
  @Input() outId: string; // id

  form: FormGroup;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      id: [null,],
      achievementsType: [0, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      poiAddress: [null, Validators.required],
      customerName: [null, Validators.required],
      customerId: [null],
      customerMobile: [null],
      companyName: [null, Validators.required],
      companyId: [null, Validators.required],
      // outQuestion: [null],
      outReason: [null],
      outSmall: [null],
      businessOpportunity: [null],
      companionsUserName: [null],
    });
  }
  businessSourceList = [
    { id: 1, name: '老客户转介绍' },
    { id: 2, name: '老客户再购买' },
    { id: 3, name: '自主开发' },
    { id: 4, name: '内部介绍' },
    { id: 5, name: '小红书' },
    { id: 6, name: '同行' },
    { id: 7, name: '渠道' },
  ]
  cancel() {
    this.modal.destroy();
  }
  loading = false;
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    params.startDate = Utils.dateFormat(params.startDate, 'yyyy-MM-dd hh:mm:ss');
    params.endDate = Utils.dateFormat(params.endDate, 'yyyy-MM-dd hh:mm:ss');
    // if ((params.outQuestion || []).length > 0) {
    //   params.outQuestionDesc = this.findName(params.outQuestion);
    //   params.outQuestion = JSON.stringify(params.outQuestion);
    // } else {
    //   params.outQuestion = null;
    // }
    this.api.saveOutInfo(params).subscribe(res => {
      this.message.success('编辑成功！');
      this.modal.destroy(res);
    }, err => {
      this.loading = false;
    })
  }

  // findName(data) {
  //   var parentId = data[0];
  //   var childId = data[1];
  //   const parent = this.outQuestionList.find(obj => obj['id'] === parentId);
  //   if (parent) {
  //     const child = parent.children.find(child => child.id === childId);
  //     if (child) {
  //       return `${parent['questionName']}-${child.questionName}`;
  //     }
  //   }
  //   return '未找到匹配的父级或子级';
  // }
  // outQuestionList = [];
  // getOutInfoQuestion() {
  //   this.api.outInfoQuestion({}).subscribe(res => {
  //     var strs = res['data'];
  //     (strs || []).map(item => {
  //       if (item.childList) {
  //         if (item.childList.length > 0) {
  //           item.children = item.childList;
  //           (item.children || []).map(child => {
  //             if (!child.childList) {
  //               child.isLeaf = true // 下级
  //             }
  //           })
  //         }
  //       } else {
  //         item.isLeaf = true
  //       }
  //     })
  //     this.outQuestionList = strs;
  //   }, err => {
  //   })
  // }
  openCustomer(e: MouseEvent): void {
    e.preventDefault();
    const modal = this.modalService.create({
      nzTitle: '选择客户',
      nzContent: ModalAddOutlookcustComponent,
      nzComponentParams: {
        companyId: this.id
      },
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.form.get('customerName').setValue(res[0].customerName);
        this.form.get('customerMobile').setValue(res[0].phoneNumber);
        this.form.get('customerId').setValue(res[0].customerId);
      }
    });
  }
  ngOnInit() {
    // this.getOutInfoQuestion();
    // this.form.get('companyName').setValue(this.companyName);
    this.form.patchValue({
      companyName: this.companyName,
      companyId: this.id,
    })
    if (this.outId) {
      this.api.ountInfoDetail(this.outId).subscribe(res => {
        this.form.patchValue(res['data']);
        this.form.get('startDate').setValue(new Date(res['data'].startDate));
        this.form.get('endDate').setValue(new Date(res['data'].endDate));
        // this.form.get('outQuestion').setValue(JSON.parse(res['data'].outQuestion));

      }, err => {
      })
    }
  }
}

