import { Component, Input,OnInit } from '@angular/core';
import {Api} from '@service/Api';
import {NzModalRef} from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-model-examine-component',
  templateUrl: './model-examine-component.component.html',
  styleUrls: ['./model-examine-component.component.less']
})
export class ModelExamineComponentComponent implements OnInit {
  @Input() params: any;
  constructor(
    private api : Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) { }

  loading = false;
  approveStatus = 'AGREE_EFFECTIVE';//业绩有效性
  paymentTime = null;//实际到账时间

  bankFlowNo = null;//银行流水
  reviewComment = null;//审核备注
  approveStatusList=[{
    type:"AGREE_EFFECTIVE",
    name:'有效业绩'
  },{
    type:"AGREE_INVALID",
    name:'无效-超时录单'
  }];

  changeApproveStatus(e){
    this.approveStatus = e;
  }

  addUserConfig():void{
    //this.loading = true;
    if (this.paymentTime == null ) {
      this.message.info('请选择实际到账时间！');
      this.loading = false;
      return
    }
    if (this.approveStatus == null) {
      this.message.info('请选择业绩有效性！');
      this.loading = false;
      return
    }

    this.params.paymentTime = Utils.dateFormat(this.paymentTime, 'yyyy-MM-dd');
    this.params.approveStatus = this.approveStatus;

    this.params.bankFlowNo = this.bankFlowNo;
    this.params.reviewComment = this.reviewComment;
    
    this.api.payVerify(this.params).subscribe(() => {
      this.loading = false;
      this.modal.destroy();
    })
  }
  cancel(){
    this.modal.destroy();
  }
  ngOnInit() {
    console.log(this.params)
  }

}
