import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';
import {DomSanitizer} from '@angular/platform-browser';
import PDFJS from 'pdfjs-dist';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-open-image',
  templateUrl: './modal-open-image.component.html',
  styleUrls: ['./modal-open-image.component.less'],
})
export class ModalOpenImageComponent implements OnInit {
  @Input() content: any;
  isImg: any;
  constructor(
    private sanitizer: DomSanitizer,
    public api: Api,
    private modal: NzModalRef) { }

  ngOnInit() {
    this.isImg = this.content.fileUrl.substring(this.content.fileUrl.length-3, this.content.fileUrl.length) === 'pdf' ? false: true;
    if(!this.isImg){
      this.content.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.fileUrl);
    }

    // this.api.getCourseDetails({ id: this.content.id }).subscribe(res => {
    //   document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0;
    //
    //   let url = this.PdfUrl + '/sys/attachment/domnFile/' + this.videoData.pdfId;
    //   console.log(url);
    //   let loadingTask = PDFJS.getDocument(url);
    //   loadingTask.promise.then(function (pdf) {
    //     for (var i = 1; i <= pdf.numPages; i++) {
    //       pdf.getPage(i).then(function (page) {
    //         var scale = 1;
    //         var viewport = page.getViewport({ scale: scale, });
    //         // var canvas = document.getElementById('the-canvas') as HTMLCanvasElement;
    //         var canvas = document.createElement('canvas');
    //         var context = canvas.getContext('2d');
    //         canvas.height = viewport.height;
    //         canvas.width = viewport.width;
    //         var renderContext = {
    //           canvasContext: context,
    //           viewport: viewport,
    //         };
    //         document.getElementById('canvasId').appendChild(canvas);
    //         page.render(renderContext);
    //       });
    //     }
    //   });
    // })

  }

  hiddenImg(){
    this.modal.destroy();
  }
}
