import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, TimeoutError, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { Utils } from './Utils';
import 'url-search-params-polyfill';
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public baseUrl: string; // api基本路径

  constructor(
    private http: HttpClient,
  ) { }

  public get(url: string, paramMap: any = null): Observable<Response> {
    paramMap = this.buildURLSearchParams(paramMap).toString();
    url = url + '?' + paramMap;
    return this.request('GET', url, paramMap);
  }

  /**
   * @description post请求
   * @param {string} url 请求的地址
   * @param {object} paramMap 请求的查询参数(body)
   *
   */
  public post(url: string, paramMap: any = null, head?): Observable<Response> {
    return this.request('POST', url, paramMap, head);
  }

  /**
   * @description post请求
   * @param {string} url 请求的地址
   * @param {object} paramMap 请求的查询参数(params)
   *
   */
  public postParams(url: string, paramMap: any = null, head?): Observable<Response> {
    let type = head || 'application/json';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': type
      }),
      params: paramMap
    };
    return this.http.post<any>(url, null, httpOptions);

  }

  public put(url: string, paramMap: any = null): Observable<Response> {
    return this.request('PUT', url, paramMap).pipe(
      catchError(this.handleError<any>())
    );

  }

  public request(method: string, url: string, paramMap: any = null, head?): Observable<Response> {

    // url = Utils.formatUrl(url.startsWith('http') ? url : this.baseUrl + url);
    // paramMap = this.buildURLSearchParams(paramMap).toString(); 后端不接收拼接好多参数，直接传入原始值
    let type = head || 'application/json';
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/x-www-form-urlencoded' // content-type修改为可配置
        'Content-Type': type
      }),
      body: paramMap
    };
    return this.http.request<any>(method, url, httpOptions);

  }

  private handleError<T>(err?: T) {

    return (error: any): Observable<T> => {

      // this.log(`${operation} failed: ${error.message}`);
      console.log('aaaaaaaaaaaaaaaaaaaa', err)
      return of(err as T);
    };

  }

  private buildURLSearchParams(paramMap): URLSearchParams {
    const params = new URLSearchParams();
    if (!paramMap) {
      return params;
    }
    Object.keys(paramMap).forEach(key => {
      const val = paramMap[key];
      // if (val instanceof Date) {
      //   val = val;
      //   // val = Utils.dateFormat(val, 'yyyy-MM-dd hh:mm:ss');
      // }
      params.set(key, val);
    });
    return params;
  }


  upload(formData,url):Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    }
    return this.http.post(url, formData, httpOptions);
  }

}