import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-selectmycompany',
  templateUrl: './modal-selectmycompany.component.html',
  styleUrls: ['./modal-selectmycompany.component.less']
})
export class ModalSelectmycompanyComponent implements OnInit {

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    public api: Api,
  ) { }

  companyList;         // 产品列表

  sreach() {
    this.getProductList();
  }
  reset() {
    this.pageCtrl.current = 1;
    this.params = {
      operName: null,
      operPhone: null,
      name: null,
    }
    this.getProductList();
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getProductList();
  }

  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    name: null,
  };
  total;
  params = {
    operName: null,
    operPhone: null,
    name: null,
  }

  // 获取产品列表
  getProductList() {
    const params = {
      current: this.pageCtrl.current,
      size: this.pageCtrl.size,
      object: this.params
    }
    this.api.myCompanyList(params).subscribe(res => {
      this.total = res['data'].total;
      this.companyList = res['data'].records;
    })
  }

  cancel() {
    this.modal.destroy();
  }
  submit() {
    if (this.signTeplateData.length === 0) {
      this.message.warning('请选择公司！');
      return
    };
    this.modal.destroy(this.signTeplateData);
  }



  ngOnInit() {
    this.getProductList();
  }

  mapOfCheckedId: { [key: string]: boolean } = {};
  signTeplateData;
  selectedCategory(e, id): void {

    for (let i in this.mapOfCheckedId) {
      this.mapOfCheckedId[i] = false;
    }
    this.mapOfCheckedId[id] = true;
    this.signTeplateData = this.companyList.filter(item => item.id == id);
  }

}
