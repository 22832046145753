import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// 第三方库
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeadComponent } from '../pages/head/head.component';
import { FiterCodeLengthPipe } from '../pipe/CodeLength.pipe';
import { StatusPipe } from '../pipe/status.pipe';
import { SearchFilterPipe } from '../pipe/search.pipe';

const THIRDMODULES = [NgZorroAntdModule];

// 组件、指令
const COMPONENTS = [HeadComponent];
const DIRECTIVES = [FiterCodeLengthPipe, StatusPipe, SearchFilterPipe];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ...THIRDMODULES,
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ...THIRDMODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
})
export class SharedModule { }
