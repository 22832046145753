import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-add-file',
  templateUrl: './modal-add-file.component.html',
  styleUrls: ['./modal-add-file.component.less']
})
export class ModalAddFileComponent implements OnInit {

  @Input() customerId: string; // 客户信息
  @Input() type: string; // 
  form: FormGroup;
  customerCompanyList = []
  fileList = []
  archivesCustomerAttachmentList = []//收款公司

  loading = false;
  selectedIndex = 0;

  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.form = fb.group({
      companyIdList: [null],
      archivesCustomerAttachmentList: [null],
      archivesType: [null, Validators.required],
      archivesName: [null, Validators.required],
    });
    this.contractUrl = this.api.storagesUploadWithController();
  }

  archivesTypeList = [
    { type: "ID_CARD", name: '身份证' },
    { type: "BUSINESS_LICENSE", name: '营业执照' },
    { type: "CONTRACT", name: '合同' },
    { type: "OTHER", name: '其他' },
    { type: "TAX", name: '税务' },
    { type: "ACCOUNT", name: '账务' },
    { type: "BANK", name: '银行' },
    { type: "QUALIFICATIONS", name: '资质' },
    { type: "TRADEMARK", name: '商标' },
    { type: "PATENT", name: '专利' },
    { type: "APPROVAL", name: '批文' },
  ];

  contractUrl: string;

  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  }

  archivesType = null;
  companyId = null;
  ArchivesFileList() {
    const pamares = {
      current: this.pageCtrl.nzPageIndex,
      object: {
        customerId: this.customerId,
        archivesType: this.archivesType,
        companyId: this.companyId,
      },
      size: this.pageCtrl.nzPageSize
    }

    this.api.ArchivesFileLists(pamares).subscribe(res => {
      const arr = res['data'].records;
      this.pageCtrl.total = res['data'].total;
      (arr || []).map(item => {
        let last = item.archivesUrl.lastIndexOf('.');
        let ext = item.archivesUrl.substr(last + 1);
        item.isLast = ext == 'png' || ext == 'jpg' || ext == 'jpeg' ? false : true
      })
      this.fileList = arr
    })
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.ArchivesFileList();
  }

  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.ArchivesFileList();
  }

  resetList() {
    this.archivesType = null;
    this.companyId = null;
    this.ArchivesFileList();
  }

  cancel() {
    this.modal.destroy()
  }
  ngOnInit() {
    // 获取公司列表
    this.api.getCompanyList({ id: this.customerId }).subscribe(res => {
      this.customerCompanyList = res['data'];
    })
    this.ArchivesFileList();
  }
  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        let p = {
          archivesUrl: item.response.data.fileUrl,
        }
        attachmentUrls.push(p)
      })
      this.archivesCustomerAttachmentList = attachmentUrls;
    }
  }

  isAllChecked = false; // 是否全选
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];

  refreshStatus(): void {
    //this.isIndeterminate =this.fileList.some(item => this.mapOfCheckedId[item.id]) && ! this.isAllChecked;
    this.numberOfChecked = this.fileList.filter(item => this.mapOfCheckedId[item.id]).length;
    var choiceList = this.fileList.filter(item => this.mapOfCheckedId[item.id])
    this.mapOfCheckedItem = choiceList;
  }

  submit() {
    this.loading = true;
    if (this.selectedIndex == 0) {
      //上传附件
      Object.keys(this.form.controls).map((key) => {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      });
      if (!this.form.valid) {
        this.loading = false;
        return
      }
      if (this.archivesCustomerAttachmentList.length == 0) {
        this.message.error('请上传附件!')
        this.loading = false;
        return
      }
      let value = this.form.value;
      value.customerId = this.customerId;
      value.archivesCustomerAttachmentList = this.archivesCustomerAttachmentList;
      this.api.customerArchivesAddInfos(value).subscribe(res => {
        this.message.success('上传成功!')
        if (this.archivesCustomerAttachmentList.length === 0) {
          this.modal.destroy()
        } else {
          this.handleAttr(this.archivesCustomerAttachmentList);
        }
      }, err => {
        this.loading = false;
      })
    } else {
      //附件调取
      if (this.type == 'task') {
        this.handleAttr(this.mapOfCheckedItem);
      } {
        this.modal.destroy()
      }
    }
  }

  handleAttr(arr) {
    var attachmentUrls = [];
    (arr || []).map((item, index) => {
      let p = {
        attachmentUrl: item.archivesUrl
      }
      attachmentUrls.push(p)
    })
    this.modal.destroy(attachmentUrls);
  }

}
