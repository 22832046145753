import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { Api } from '@service/Api';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

let localApi;
@Component({
  selector: 'app-modal-edit-task-model',
  templateUrl: './modal-edit-task-model.component.html',
  styleUrls: ['./modal-edit-task-model.component.less']
})
export class ModalEditTaskModelComponent implements OnInit {
  @Input() id: string;//id
  @Input() type: string; // 类型：1：新增， 2修改
  @Input() obj: any;
  form: FormGroup;
  loading = false;

  city_Data = [];

  name: string; //提成名称
  price: bigint;
  areaId: bigint;
  needCompany: bigint;
  areaCode: string;
  statusList = [
    { code: 1, value: "必填" },
    { code: 0, value: "不必填" },
  ]
  divideTypeList = [
    { value: '1', name: '百分比(%)' },
    { value: '0', name: '固定金额' },
  ]

  divide: any = null;
  commissionValue: any = null;
  constructor(
    fb: FormBuilder,
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      name: [null, Validators.required],
      //price: [null, [Validators.required,Validators.pattern(/^[0-9]*$/)]],
      areaCode: [null, Validators.required],
      needCompany: [null, Validators.required],
      commissionType: [null],
      // estimateCost: [null, Validators.required],
      amount: [null],
      price: [null],
      proportion: [null],

    });
    localApi = api;
  }

  cancel() {
    this.modal.destroy();
  }

  loadCityData() {
    this.api.getDimensionAreaData().subscribe(res => {
      this.city_Data = res['data'];
    });
  }
  divideTypeChange(e) {
    this.divide = e
  }
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();

    params = this.form.value;
    params.areaId = params.areaCode[1];
    params.areaCode = params.areaCode.join(',');
    params.id = this.id;

    //divide =  1 百分比(%)  , 0 固定金额
    if (this.divide == '1' && !params.proportion) {
      this.message.error('百分比不能为空!');
      this.loading = false;
      return false;
    } else if (this.divide == '1' && params.proportion) {
      this.commissionValue = params.proportion;
      params.amount = null
    }
    if (this.divide == '0' && !params.amount) {
      this.message.error('固定金额不能为空!');
      this.loading = false
      return false;
    } else if (this.divide == '0' && params.amount) {
      this.commissionValue = params.amount;
      params.proportion = null
    }
    params.price = this.commissionValue;
    this.api.addOrUpdateTaskCommission(params).subscribe(res => {
      this.cancel();
      this.loading = false;
      if (params.id != 0) {
        this.message.success('编辑成功！');
      } else {
        this.message.success('添加成功！');
      }
    });
  }

  // #region 获取省市列表
  getCityList(level, code?) {
    const params = {
      level,
    };
    params['parentCode'] = code ? code : null;
    this.api.getCityList(params).subscribe((res) => {
      return res['data'];
    });
  }
  // loadCityData(node: any, index: number): PromiseLike<any> {
  //   return new Promise(resolve => {
  //     const params = {};
  //     if (index < 0) {
  //       params['level'] = 1;
  //     } else if (index === 0) {
  //       params['level'] = 2;
  //       params['parentCode'] = node.code;
  //     }
  //     localApi.getCityList(params).subscribe(res => {
  //       node.children = res['data'];
  //       if (index === 0) {
  //         node.children.map((v) => {
  //           v['isLeaf'] = true;
  //         });
  //       }
  //       resolve();
  //     });
  //   });
  // }
  onAreaChanges(values: any): void {
  }

  ngOnInit() {
    this.loadCityData();

    if (this.type == '2') {
      this.api.getCommissionById({ id: this.id }).subscribe(res => {
        let data = res['data'];
        Object.keys(data).map((key) => {
          if (this.form.get(key) && key != 'areaCode') {
            this.form.get(key).setValue(data[key]);
          }
        })
        let a = data.areaCode.split(',').map(Number);
        this.form.get('areaCode').setValue(a);

        this.divide = data['commissionType'];
        if (this.divide == 1) {
          this.form.get('proportion').setValue(data['price']);
        } else {
          this.form.get('amount').setValue(data['price']);
        }
        if (data['commissionType'] === null) {
          this.form.get('commissionType').setValue(null);
        } else {
          this.form.get('commissionType').setValue(data['commissionType'].toString());
        }

      });

    } else {
      // 新增
    }
  }

}