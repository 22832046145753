import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-staff-info',
  templateUrl: './drawer-staff-info.component.html',
  styleUrls: ['./drawer-staff-info.component.less']
})
export class DrawerStaffInfoComponent implements OnInit {
  @Input() value = '';
  @Input() index = 0;

  customerInfo;        // 客户信息
  activeList: any = [0];    // 已激活菜单
  params: any = {}; //客户信息
  constructor(
    public message: NzMessageService,
    public router: Router,
    public api: Api,
  ) {
  }

  // 切换菜单
  changeTabs(e) {
    console.log(e)
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    // switch (e) {
    //   case 1:
    //     break;
    //   case 2:
    //     break;
    //   case 3:
    //     break;
    //   case 4:
    //     break;
    //   default:
    //     break;
    // }
  }

  // #region tab0: 客户基本信息
  // 获取客户详情
  getCustomerDetail() {
    this.api.getUserDetailInduction(this.value).subscribe(res => {
      let data = res['data'];
      this.params = data;
    })
  }

  // #region tab3: 跟进记录列表
  ngOnInit() {
    this.getCustomerDetail();
    this.changeTabs(this.index);
  }
}
