import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-select-relation-order',
  templateUrl: './modal-select-relation-order.component.html',
  styleUrls: ['./modal-select-relation-order.component.less']
})
export class ModalSelectRelationOrderComponent implements OnInit {
  orderList;

  @Input() value = '';
  @Input() orderChoiceList = [];
  @Input() type = '';// type : 'order' 关联订单， 'apply' 关联甩单

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
    public api: Api,
  ) { }

  params = {
    orderDateStart: null,
    orderDateEnd: null,
    phoneNumber: null,
    orderNo: null,
    customerName: null,
    serviceProviderId: null,
    finishStatus: null,
  }

  isAllChecked = false; // 是否全选
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};

  mapOfCheckedItem: any = [];

  dateList: any = null;
  stateList = [
    { value: '1', name: '已办结' },
    { value: '2', name: '待办结' },
    { value: '3', name: '待审核' },
    { value: '4', name: '审核不通过' },
  ];
  ngOnInit() {
    this.params.serviceProviderId = this.value
    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.orderProductId] = true;
      });
      this.mapOfCheckedId = newList;
    }
    this.mapOfCheckedItem = this.orderChoiceList;
    this.numberOfChecked = this.orderChoiceList.length;
    this.getOrderList();
  }
  sreach() {
    this.getOrderList();
  }
  toPageList(index) {
    this.pageCtrl.current = index;
    this.getOrderList();
  }
  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    total: 0,
    object: {}
  };

  // 翻页
  toPage(page) {
    this.pageCtrl.current = page;
    this.getOrderList();
  }
  selectedCategory(id) {
    var choiceList = this.orderList.filter(item => this.mapOfCheckedId[item.orderProductId])
    this.mapOfCheckedItem = choiceList;
  }

  refreshStatus(): void {
    this.isAllChecked = this.orderList.every(item => this.mapOfCheckedId[item.orderProductId]);
    this.isIndeterminate = this.orderList.some(item => this.mapOfCheckedId[item.orderProductId]) && !this.isAllChecked;
    this.numberOfChecked = this.orderList.filter(item => this.mapOfCheckedId[item.orderProductId]).length;
    var choiceList = this.orderList.filter(item => this.mapOfCheckedId[item.orderProductId])
    this.mapOfCheckedItem = choiceList;
  }

  //全选
  checkAll(value: boolean): void {
    // 过滤 unCompleteCount == 0可选中，否则不选 
    // data.status == 3 || data.status == 4
    this.orderList
      .filter(({ finishStatus }) => finishStatus == 1)
      .forEach(item => (this.mapOfCheckedId[item.orderProductId] = value));
    //this.orderList.forEach(item => (this.mapOfCheckedId[item.orderProductId] = value));
    this.refreshStatus();
  }

  // 获取产品列表
  getOrderList() {
    let params = this.params;
    this.pageCtrl.object = { ...params }
    // type : 'order' 关联订单， 'apply' 关联甩单
    if (this.type == 'order') {
      this.api.settlementListPage(this.pageCtrl).subscribe(res => {
        this.orderList = res['data'].records;
        this.pageCtrl.total = res['data'].total;
      })
      // this.api.getSettlementOrder(this.pageCtrl).subscribe(res => {
      //   this.orderList = res['data'].records;
      //   this.pageCtrl.total = res['data'].total;
      // })
      return
    }
    if (this.type == 'apply') {
      // this.api.getUserSettlementSingleOrders(this.pageCtrl).subscribe(res => {
      this.api.mySettlementList(this.pageCtrl).subscribe(res => {
        this.orderList = res['data'].records;
        this.pageCtrl.total = res['data'].total;
      })
      return
    }

  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    if (this.numberOfChecked == 0) {
      this.message.warning('请选择关联订单！');
      return
    }
    console.log(this.mapOfCheckedItem)
    let str = {
      type: this.type,
      data: this.mapOfCheckedItem
    }
    this.modal.destroy(str);
  }

  query() {
    if (this.dateList != null) {
      this.params.orderDateStart = Utils.dateFormat(this.dateList[0], 'yyyy-MM-dd 00:00:00');
      this.params.orderDateEnd = Utils.dateFormat(this.dateList[1], 'yyyy-MM-dd 23:59:59');
    }
    this.getOrderList()
  }
  reset() {
    this.params = {
      orderDateStart: null,
      orderDateEnd: null,
      phoneNumber: null,
      orderNo: null,
      customerName: null,
      serviceProviderId: null,
      finishStatus: null,
    }
    this.dateList = null;
    this.getOrderList()
  }

}
