import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-renew-down',
  templateUrl: './drawer-renew-down.component.html',
  styleUrls: ['./drawer-renew-down.component.less']
})
export class DrawerRenewDownComponent implements OnInit {
  public baseUrl; // api基本路径
  constructor(
    public api: Api,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.downLoadSee();
    this.getMyOrderList();
  }
  listOfData = [];
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = {
    customerName: null,
    customerMobile: null,
    companyName: null,
    successFlag: null,
  };

  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getMyOrderList();
  }
  reset() {
    this.params.customerName = null;
    this.params.customerMobile = null;
    this.params.companyName = null;
    this.params.successFlag = null;
    this.getMyOrderList();
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getMyOrderList();
  }
  queryList(type) {
    this.params.successFlag = type;
    this.query();
  }
  // 获取我的订单列表
  getMyOrderList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    };
    this.api.serverInfoExList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }
  /**
 * 导出 错误数据
 * @param data
 *
 */
  loading = false;
  downLoadFail() {
    this.loading = true;
    let date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var time = Y + M + D;
    var title = '失败数据明细';
    let urlApi = '/bsCompanyServerInfoEx/importExList';
    // 设置参数 start
    var data = {
      successFlag: this.params.successFlag
    };
    let url = this.baseUrl + urlApi;
    var httpRequest = new XMLHttpRequest();
    httpRequest.responseType = "arraybuffer";
    httpRequest.open("POST", url, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Admin-Token", localStorage.getItem("token"));
    httpRequest.setRequestHeader("responseType", "blob");

    httpRequest.send(JSON.stringify(data));
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState == 4 && httpRequest.status == 200) {
        var data = httpRequest.response;
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', title + '.xls');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    }
  }

  // downLoadCheck = false;
  resultNum: {
    successCount: 0,
    failCount: 0,
  }
  // 查看结果
  downLoadSee() {
    // this.downLoadCheck = false;
    this.resultNum = {
      successCount: 0,
      failCount: 0,
    }
    this.api.serverInfoResult({}).subscribe(res => {
      // this.downLoadCheck = true;
      this.resultNum = res['data'];
    })
  }
  // 批量导入
  noType = (file: File): boolean => {
    this.getExcelData(file)
    return false;
  }
  //获取Excel数据
  getExcelData(file) {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('sourceFlag', 'BUYING_CLUE');
    //上传文件 excel
    this.api.serverInfoImport(formData).subscribe(res => {
      // this.message.success('操作完成！');
      // this.downLoadCheck = true;
      this.resultNum = res['data'];
      this.loading = false;
      this.query();
    }, (error) => {
      // 处理请求错误，如果有需要的话
      this.loading = false;
    })
  }
  templateUpload() {
    window.location.href = 'https://duoyoumi-1251277880.cos.ap-guangzhou.myqcloud.com/document/20240104/1704347483383.xlsx';
  }
}
