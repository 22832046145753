import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';

@Component({
  selector: 'app-modal-add-affiliates',
  templateUrl: './modal-add-affiliates.component.html',
  styleUrls: ['./modal-add-affiliates.component.less']
})
export class ModalAddAffiliatesComponent implements OnInit {
  @Input() id: string; // 关联公司id
  @Input() companyId: string; // 

  form: FormGroup;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      id: [null,],
      companyName: [null, Validators.required],
      bussinessDes: [null],
      personCount: [null],
      useDesc: [null],
    });
  }
  cancel() {
    this.modal.destroy();
  }
  loading = false;
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    if (this.id) {
      params.id = this.id;
    }
    params.companyId = this.companyId;
    this.api.addOperEnterprise(params).subscribe(res => {
      this.message.success('公司信息编辑成功！');
      this.modal.destroy(res);
    }, err => {
      this.loading = false;
    })
  }
  getOperEnterpriseDetail() {
    this.api.operEnterpriseDetail(this.id).subscribe(res => {
      var resData = res['data'];
      this.form.patchValue(resData);
    }, err => {
    })
  };
  ngOnInit() {
    if (this.id) {
      this.getOperEnterpriseDetail();
    }
  }

}
