import { Component, OnInit,Input } from '@angular/core';
import {Api} from '@service/Api';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-create-property-distributors',
  templateUrl: './create-property-distributors.component.html',
  styleUrls: ['./create-property-distributors.component.less']
})
export class CreatePropertyDistributorsComponent implements OnInit {
  @Input() areaCateid : any;
  @Input() officeId : any;
  @Input() orderId : any;
    constructor(public api: Api,
      private modal: NzModalRef,) { }
  baseAreaPropertyDistributorsByCateId=[];
  list=[];

  changePrice(price){
    for(let i=1; i<this.list.length; i++){
        this.list[i]['price']=price*this.list[i]['priceRatio']/100;
        price=this.list[i]['price'];
    }
  }

  save(){
    console.log(this.list)
    this.api.saveDistributorsProperties(this.list).subscribe(res=>{
      this.modal.destroy();
    });
  }


  cancel() {
    this.modal.destroy();
  }


  ngOnInit() {
    this.api.getbaseAreaPropertyDistributorsByCateId({
      cateId:this.areaCateid,officeId:this.officeId
    }).subscribe(res=>{
        this.baseAreaPropertyDistributorsByCateId=res['data'];
        this.baseAreaPropertyDistributorsByCateId.forEach((e, index)=>{
          this.list.push({
            'propertyId': e.id,
            'propertyName':e.name,
            'priceRatio': e.price,
            'price':0,
            'sort':index,
            'orderId':this.orderId,
          });
        })
    });
  }

}
