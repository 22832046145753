import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class HttpIntercept implements HttpInterceptor {
  constructor(
    public router: Router,
    public message: NzMessageService,
  ) { }
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
  // tslint:disable-next-line:max-line-length
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    return <any>next.handle(req).pipe(

      mergeMap((event: any) => {
        if (event instanceof HttpResponse && event.body.code !== 200) {
          return Observable.create(observer => observer.error(event));
        }
        return Observable.create(observer => observer.next(event));
      }),
      catchError((res: HttpResponse<any>) => {
        console.log(res)
        switch (res.status) {
          case 200:
            // 业务层级错误处理
            this.handError(res.body);
            break;
          case 401:
            this.router.navigate(['login']);
            break;
          case 404:
            // this.nativeService.showToast('404, API不存在');
            break;
        }
        return throwError('http error!');
        // return Observable.create(observer => observer.next(null));
      })
    );

  }

  handError(body) {
    switch (body.code) {
      case 401: // token失效，重新登录
        // this.message.error(body.msg);
        this.router.navigate(['login']);
        break;
      case 403:
        // this.message.error('登录过期，请重新登录！');
        this.router.navigate(['login']);
        break;
      case 500:
        this.message.error('服务器异常，请稍后再试！');
        break;
      default: // 默认给出提示信息
        this.message.create(body.level == 'WARNING' ? 'warning' : body.level == 'INFO' ? 'info' : 'error', body.msg);
    }
  }
}
