import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drawer-card-allocation',
  templateUrl: './drawer-card-allocation.component.html',
  styleUrls: ['./drawer-card-allocation.component.less']
})
export class DrawerCardAllocationComponent implements OnInit {
  @Input() value = '';
  @Input() allServiceAmount = '';
  @Input() allFunctionAmount = '';
  @Input() extraAmount = '';
  public baseUrl; // api基本路径
  constructor(
    public api: Api,
    public global: Global,
    public message: NzMessageService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    this.getServiceStoragesList();
  };
  // 业务合计
  countYw = null;
  // 业务+职能合计
  countZnYw = null;
  // 额外分摊金额合计
  // extraAmount = null;
  listOfData = [];
  paramsData = {
    cardMonth: null,
    officeCardMonthId: null
  };
  month = null;
  // 计算总卡位 
  countAll() {
    this.countYw = 0;
    this.countZnYw = 0;
    // this.extraAmount = 0;
    // 计算总数
    (this.listOfData || []).map(item => {
      if (item.businessFlag == 1) {
        this.countYw += Number(item.cardNum || 0);
      }
      this.countZnYw += Number(item.cardNum || 0)
      // this.extraAmount += Number(item.extraAmount || 0)
    });
  };
  getServiceStoragesList() {
    this.paramsData.officeCardMonthId = this.value;
    if (this.month) {
      this.paramsData.cardMonth = Utils.dateFormat(this.month, 'yyyy-MM')
    }
    const params = this.paramsData;
    this.api.statisticsListDetail(params).subscribe((res) => {
      this.listOfData = res['data'];
      this.countAll();
    })
  }

}
