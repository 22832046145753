import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-select-fling-single',
  templateUrl: './modal-select-fling-single.component.html',
  styleUrls: ['./modal-select-fling-single.component.less']
})
export class ModalSelectFlingSingleComponent implements OnInit {
  orderList;
  @Input() value = '';
  @Input() orderChoiceList = [];
  @Input() initiatorOfficeId = '';
  @Input() initiatorSeOfficeId = '';
  @Input() acceptorOfficeId = '';
  @Input() acceptorSeOfficeId = '';
  @Input() orderType = '';
  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
    public api: Api,
  ) {
  }
  params = {
    requestSource: 1,
    orderNo: null,
    orderDateStart: null,
    orderDateEnd: null,
    customerName: null,
    customerPhone: null,
    customerCompanyName: null,
    initiatorOfficeId: null,
    initiatorSeOfficeId: null,
    acceptorOfficeId: null,
    acceptorSeOfficeId: null,
    orderType: null,
  }
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];
  dateList: any = [];

  ngOnInit() {
    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.orderId] = true;
      });
      this.mapOfCheckedId = newList;
    }
    this.mapOfCheckedItem = this.orderChoiceList;
    this.params.initiatorOfficeId = this.initiatorOfficeId;
    this.params.initiatorSeOfficeId = this.initiatorSeOfficeId;
    this.params.acceptorOfficeId = this.acceptorOfficeId;
    this.params.acceptorSeOfficeId = this.acceptorSeOfficeId;
    this.params.orderType = this.orderType;
    this.getOrderList();
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getOrderList();
  }
  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    object: {}
  };

  total;

  selectedCategory(orderId) {
    var choiceList = this.orderList.filter(item => {
      this.mapOfCheckedId[item.orderId];
      if (this.mapOfCheckedId[item.orderId]) {
        return item
      }
    })
    this.mapOfCheckedItem = choiceList;
  }
  // 翻页
  toPage(page) {
    this.pageCtrl.current = page;
    this.getOrderList();
  }
  // 获取产品列表 
  getOrderList() {
    let params = {
      current: this.pageCtrl.current,
      size: this.pageCtrl.size,
      object: this.params
    }
    this.api.getmyOrderSingleList(params).subscribe(res => {
      this.orderList = res['data'].records;
      this.total = res['data'].total;
    })

  }
  cancel() {
    this.modal.destroy();
  }

  submit() {
    if (this.mapOfCheckedItem.length === 0) {
      this.message.warning('请选择订单！');
      return
    }
    this.modal.destroy(this.mapOfCheckedItem);
  }

  query() {
    if (this.dateList.length != 0) {
      this.params.orderDateStart = Utils.dateFormat(this.dateList[0], 'yyyy-MM-dd 00:00:00');
      this.params.orderDateEnd = Utils.dateFormat(this.dateList[1], 'yyyy-MM-dd 23:59:59');
    } else {
      this.params.orderDateStart = '';
      this.params.orderDateEnd = '';
    }

    this.getOrderList();
  }
  reset() {
    this.params.orderNo = null;
    this.params.customerName = null;
    this.params.customerPhone = null;
    this.params.customerCompanyName = null;
    this.params.orderDateStart = null;
    this.params.orderDateEnd = null;
    this.dateList = [];
    this.getOrderList();
  }
}
