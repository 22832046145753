import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpService } from '../service/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoggedIn = false;
  redirectUrl: string;

  constructor(public http: HttpService) {
    this.isLoggedIn = this.getAuthorizationToken() ? true : false;
  }

  getCode(uuid) {
    return `${environment.baseUrl}/sys/user/getVerificationCode?uuid=${uuid}`
  }

  login(data): Observable<any> {

    const head = 'application/x-www-form-urlencoded';
    return this.http.postParams(`/sys/user/login`, data, head).pipe(
      tap(val => {
        this.isLoggedIn = true;
        localStorage.setItem('token', val['data'])
      }));
  }

  logout(): void {
    this.isLoggedIn = false;
    localStorage.removeItem('token');
  }

  getAuthorizationToken() {
    return localStorage.getItem('token');
  }

  enterpriseLogin(data): Observable<any> {
    const head = 'application/x-www-form-urlencoded';
    return this.http.postParams(`/sys/login/qw/user/workLogin`, data, head).pipe(
      tap(val => {
        this.isLoggedIn = true;
        localStorage.setItem('token', val['data'])
      }));
  }

  enterpriseLoginBand(data): Observable<any> {
    const head = 'application/x-www-form-urlencoded';
    return this.http.postParams(`/sys/login/qw/user/workLoginBand`, data, head).pipe(
      tap(val => {
        this.isLoggedIn = true;
      }));
  }


}
