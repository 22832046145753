import { Component, OnInit } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';


@Component({
  selector: 'app-public',
  templateUrl: './orderCountList.component.html',
  styleUrls: ['./orderCountList.component.less']
})
export class OrderCountList implements OnInit {

  listOfData = [];

  constructor(
    public api: Api,
    public message: NzMessageService,
    ) { }

  isAllChecked = false; // 是否全选
  isOperating = false;  // 
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  currentPageDataChange($event: []): void {
    this.listOfData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    console.log(this.mapOfCheckedId)
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.id]).length;
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  ngOnInit() {
    this.getOrderCountList();
  }

  // 翻页
  toPage(page) {
    console.log(page)
    this.pageCtrl.nzPageIndex = page;
    this.getOrderCountList();
  }
  // 获取日志列表列表
  getOrderCountList() {
    // todo: 目前无筛选条件，暂时传入空值
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {}
    }
    this.api.getOrderCountList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  



}
