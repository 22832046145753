import { Component, OnInit,Input} from '@angular/core';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-model-office-balancebill',
  templateUrl: './model-office-balancebill.component.html',
  styleUrls: ['./model-office-balancebill.component.less']
})
export class ModelOfficeBalancebillComponent implements OnInit {
  @Input() officeId:String;

  listOfData = [];
  typeList = [
    {name:'收入',value:'INCOME'},
    {name:'支出',value:'EXPENSES'},
  ];

  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  toPage(page){
    this.pageCtrl.nzPageIndex = page;
    this.getOfficeConsumptions();
  }
  params = {
    type: null,
    beginDate: null,
    endDate: null,
    dateList: null,
    officeId: null,
  }

  constructor(public api: Api,) { }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getOfficeConsumptions();
  }

  reset() {
    this.params.dateList = null,
    this.params.beginDate = null,
    this.params.endDate = null,
    this.params.type = null,
    this.getOfficeConsumptions();
  }
  getOfficeConsumptions() {
    if (this.params.dateList != null) {
      this.params.beginDate = Utils.dateFormat(this.params.dateList[0], 'yyyy-MM-dd');
      this.params.endDate = Utils.dateFormat(this.params.dateList[1], 'yyyy-MM-dd');
    }

    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    }
    this.api.getOfficeCompanyBalanceBill(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }
  ngOnInit() {
    this.params.officeId = this.officeId;
    this.getOfficeConsumptions();
  }

}
