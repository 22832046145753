import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawerArticleLookComponent } from '../../component/drawer-article-look/drawer-article-look.component';

@Component({
  selector: 'app-drawer-article-share',
  templateUrl: './drawer-article-share.component.html',
  styleUrls: ['./drawer-article-share.component.less']
})
export class DrawerArticleShareComponent implements OnInit {


  @Input() value = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  listOfData = [];
  pageCtrl = { // 分页字段
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  /**
   * 翻页
   * @param page
   */
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getTaxShareArticleList();
  }

  params = {
    articleId: null,
  };

  articleInfo;
  ngOnInit() {
    this.params['articleId'] = this.value;
    this.getArticleDetail();
    this.getTaxShareArticleList();
  }

  // 获取文章信息
  getArticleDetail() {
    this.api.articleDetail(this.value).subscribe(res => {
      this.articleInfo = res['data'];
      console.log(this.articleInfo)
    });
  }


  // 获取客户列表
  getTaxShareArticleList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.api.taxShareArticleList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  // 打开浏览人数
  openLook(data, type, shareID?) {
    const modal = this.drawerService.create<DrawerArticleLookComponent, { value: string, type: string, shareID: string }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerArticleLookComponent,
      nzContentParams: {
        value: data['id'],
        type: type,
        shareID: shareID
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }
}
