import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerEntpriseServerComponent } from '../../component/drawer-entprise-server/drawer-entprise-server.component';

@Component({
  selector: 'app-drawer-transfer-server',
  templateUrl: './drawer-transfer-server.component.html',
  styleUrls: ['./drawer-transfer-server.component.less']
})
export class DrawerTransferServerComponent implements OnInit {
  @Input() value = '';


  contractUrl: string;
  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) {
  }

  transferServer: any = {};
  bsCompanyServerInfoList = [];
  getServerDetail() {
    this.api.companyHandoverDetail(this.value).subscribe(res => {
      this.transferServer = res['data'];
      this.bsCompanyServerInfoList = this.transferServer.bsCompanyServerInfoList || [];
    });
  }
  orderHtmlIndex = 0;
  selectTabset(e) {
    this.orderHtmlIndex = e.index;
  }
  ngOnInit() {
    this.getServerDetail();
  }

  // 打开客户详情
  open(data) {
    const modal = this.drawerService.create<DrawerEntpriseServerComponent,
      {
        value: string,
        companyServerNo: string,
        manageName: string,
        chargeName: string,
        companyServerPeriod: string,
        companyServerStatus: string,
        serverType: number, //只有我负责的才能编辑
        typeFlag: boolean,
        detail: Object
      }, string>({
        nzWidth: 890,
        nzWrapClassName: '服务详情',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: DrawerEntpriseServerComponent,
        nzContentParams: {
          value: data.id,
          companyServerNo: data.companyServerNo,
          manageName: data.manageName,
          chargeName: data.chargeName,
          companyServerPeriod: data.companyServerPeriod,
          companyServerStatus: data.companyServerStatus,
          serverType: null,
          typeFlag: data.typeFlag,
          detail: data,
        }
      });

  }

}
