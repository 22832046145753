import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Utils } from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-reimburs-writeoff-add',
  templateUrl: './modal-reimburs-writeoff-add.component.html',
  styleUrls: ['./modal-reimburs-writeoff-add.component.less']
})
export class ModalReimbursWriteoffAddComponent implements OnInit {
  orderList;
  @Input() value = '';
  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,

    public api: Api,
  ) {
  }
  params = {
    applyNo:null,
    applySignedEnterpriseId:null
  }
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];
  dateList: any = [];
  orderChoiceList = [];
  ngOnInit() {
   
    console.log(this.value)
    this.params.applySignedEnterpriseId = this.value;

    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.id] = true;
      });
      this.mapOfCheckedId = newList;
    }
    this.mapOfCheckedItem = this.orderChoiceList;
    this.getOrderList();
  }

  toPageList(index) {
    this.pageCtrl.current = index;
    this.getOrderList();
  }
  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    object: {}
  };

  total;

  selectedCategory(id) {
    var choiceList = this.orderList.filter(item => this.mapOfCheckedId[item.id])
    this.mapOfCheckedItem = choiceList;
  }
  // 翻页
  toPage(page) {
    this.pageCtrl.current = page;
    this.getOrderList();
  }

  getOrderList() {
    let params = {
      current: this.pageCtrl.current,
      size: this.pageCtrl.size,
      object: this.params
    }
    this.api.findUserApplyCompanyListId(params).subscribe(res => {
      this.orderList = res['data'].records;
      this.total = res['data'].total;
    })
  }

  cancel() {
    this.modal.destroy();
  }

  submit() {
    if (this.mapOfCheckedItem.length === 0) {
      this.message.warning('请选择借款记录！');
      return
    }
    this.modal.destroy(this.mapOfCheckedItem);
  }

  query() {
    // if (this.dateList.length != 0) {
    //   this.params.orderDateStart = Utils.dateFormat(this.dateList[0], 'yyyy-MM-dd 00:00:00');
    //   this.params.orderDateEnd = Utils.dateFormat(this.dateList[1], 'yyyy-MM-dd 23:59:59');
    // } else {
    //   this.params.orderDateStart = '';
    //   this.params.orderDateEnd = '';
    // }
    this.getOrderList();
  }

  reset() {
    this.params = {
      applyNo:null,
      applySignedEnterpriseId:this.value,
    }
    //this.dateList = [];
    this.getOrderList();
  }
}
