import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';

@Component({
  selector: 'app-modal-add-contact',
  templateUrl: './modal-add-contact.component.html',
  styleUrls: ['./modal-add-contact.component.less']
})
export class ModalAddContactComponent implements OnInit {
  @Input() companyId: string; // id
  @Input() id: string; // id

  form: FormGroup;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
  ) {
    this.form = fb.group({
      id: [null],
      companyId: [null],
      concatType: [null, [Validators.required]],
      name: [null, [Validators.required]],
      phoneNumber: [null],
      sex: [null],
      age: [null],
      nativePlace: [null],
      positionFlag: [null],
      marriedFlag: [null],
      decisionMakerPower: [null],
      idCardNumber: [null],
      shareholdingRatio: [null],
      entrepreneurialExperience: [null],
      entrepreneurialYears: [null],
      hobby: [null],
      discPreference: [null],
      communicationStyle: [null],
      userCharacter: [null],
      evaluationOfOurService: [null]
    });
  }
  cancel() {
    this.modal.destroy();
  }
  loading = false;
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    params.companyId = this.companyId;
    if (this.id) {
      this.api.decisionMakerEdit(params).subscribe(res => {
        this.message.success('编辑成功');
        this.modal.destroy(res);
      }, res => {
        this.loading = false;
      })
    } else {
      delete params.id;
      this.api.decisionMakerAdd(params).subscribe(res => {
        this.message.success('添加成功');
        this.modal.destroy(res);
      }, res => {
        this.loading = false;
      })
    }
  }
  options = [
    { label: '已婚', value: 1 },
    { label: '未婚', value: 0 },
  ];
  sexList = [
    { label: '男', value: 1 },
    { label: '女', value: 0 },
  ];
  // 职务
  positionFlagList = [
    { 'value': 0, 'name': '老板' },
    { 'value': 1, 'name': '股东' },
    { 'value': 2, 'name': '法人' },
    { 'value': 3, 'name': '财务' },
    { 'value': 4, 'name': '人事' },
    { 'value': 5, 'name': '总监' },
    { 'value': 6, 'name': '销售' },
  ];
  // 联系人类型
  concatTypeList = [
    { 'value': 1, 'name': '对接人' },
    { 'value': 2, 'name': '决策人' },
    { 'value': 3, 'name': '创始人' },
    { 'value': 4, 'name': '其他' },
  ];

  getDecisionMakerDetail() {
    this.api.decisionMakerDetail(this.id).subscribe(res => {
      var resData = res['data'];
      this.form.patchValue(resData);
    }, err => {
    })
  };
  ngOnInit() {
    if (this.id) {
      this.getDecisionMakerDetail();
    }
  }

}
