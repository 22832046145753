import { param } from 'src/assets/ueditor/third-party/jquery-1.10.2';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { NzMessageService } from 'ng-zorro-antd/message';


let cnm;
let apiTmp;
let myMap;
@Component({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styleUrls: ['./modal-user.component.less']
})

export class ModalUserComponent implements OnInit {
  form: FormGroup;  // 新增用户表单
  referralUserType = '';
  companyData = []; //公司列表
  status_list = [
    { code: 1, value: "正常" },
    { code: 2, value: "失效" },
    { code: 3, value: "冻结" },
    { code: 4, value: "注销" },
    { code: 5, value: "离职" },
  ]
  canSubmit = true;
  id: number; // 客户id
  isEdit = false;
  deptId: any;
  nzOptions: any;
  worksList = [];//工号

  constructor(
    fb: FormBuilder,
    public api: Api,
    private modal: NzModalRef,
    public message: NzMessageService,
  ) {
    apiTmp = this.api;
    myMap = new Map();
    this.form = fb.group({
      companyId: [null, Validators.required],
      deptId: [null,],
      jobNo: [null, Validators.required],
      loginAccount: [null, Validators.required],
      passWord: ['a123456'],
      name: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      sex: [null, Validators.required],
      dateOfEntry: [null, Validators.required],
      email: [null,],
      status: [null, Validators.required],
      remark: [null, Validators.required],
    });
  }


  findDepartmentName(data, deptId) {
    for (const item of data) {
      if (item.id === deptId) {
        return item.name; // 返回找到的部门名称
      }
      if (item.child) {
        // 如果当前项有子项，递归查找子项中的匹配项
        const name = this.findDepartmentName(item.child, deptId);
        if (name) {
          return name; // 如果在子项中找到匹配项，则返回名称
        }
      }
    }
    return null; // 如果没有找到匹配项，则返回null
  }


  save() {
    var value = this.form.getRawValue();
    this.canSubmit = false;
    // 更新表单状态
    // if(this.worksList.length == 0){
    //   this.message.error("工号不存在，请输入正确的工号！");
    //   this.canSubmit = true;
    //   return
    // }
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.canSubmit = true;
      return
    }

    (this.companyData).map((item, index) => {
      if (item.id == value['companyId']) {
        value['companyName'] = item.name;
      }
    });

    value['deptId'] = value.remark[value.remark.length - 1]
    if (value['deptId'] != this.deptId) {
      //如果修改地区
      //value.remark = myMap.get(value['deptId']).name;
      this.canSubmit = true;
      if (myMap.get(value['deptId'])) {
        value['areaId'] = myMap.get(value['deptId']).areaId;
      }
    }
    value['deptName'] = this.findDepartmentName(this.nzOptions, value['deptId']);
    value.remark = JSON.stringify(value.remark);
    if (!this.isEdit) {
      value.dateOfEntry = Utils.dateFormat(value.dateOfEntry, 'yyyy-MM-dd hh:mm:ss');
      this.api.addUser(value).subscribe(res => {
        this.modal.destroy();
      }, err => {
        this.canSubmit = true;
      })
    } else {
      value['id'] = this.id;
      value.dateOfEntry = Utils.dateFormat(value.dateOfEntry, 'yyyy-MM-dd hh:mm:ss');
      this.api.updateUser(value).subscribe(res => {
        this.canSubmit = true;
        this.modal.destroy();
      }, err => {
        this.canSubmit = true;
      })

    }
  }

  cancal() {
    this.modal.destroy();
  }

  // 获取用户详细信息
  getUserDetail() {
    this.api.getUserDetail({ id: this.id }).subscribe(res => {
      const userInfo = res['data'];
      this.deptId = userInfo.deptId;
      Object.keys(userInfo).map((key) => {
        if (this.form.get(key)) {
          this.form.get(key).setValue(userInfo[key]);
        }
      })
      //获取部门信息，根据公司查询部门信息

      if (userInfo['remark']) {
        const remark = (JSON.parse(userInfo['remark']) || []).map(Number);
        this.form.get('remark').setValue(remark);
      } else {
        this.form.get('remark').setValue([userInfo['deptId']]);
      }
    })
  }

  //获取公司信息
  getCompanyData() {
    let data = {
      type: 0,
    };
    this.api.getCompanyOrDept(data).subscribe(res => {
      this.companyData = res['data'];
    });
  }
  //获取部门信息
  getDeptData() {
    cnm = this.form.controls.companyId.value;
    let data = {
      type: 1,
      companyId: cnm,
    }
    this.form.get('remark').setValue([]);
    this.form.get('deptId').setValue(null);
    // if (this.isEdit) { //如果是编辑
    this.api.getDept(data).subscribe(res => {
      this.nzOptions = res['data'];
    });
    // }
  }

  loadDeptData(node: any, index: number): PromiseLike<any> {
    return new Promise(resolve => {
      let data = {
        type: 1,
      }
      if (index < 0) {
        if (cnm == null) {
          return false;
        }
        data['companyId'] = cnm;
      } else {
        data['companyId'] = node.id;
      }
      apiTmp.getDept(data).subscribe(res => {
        node.children = res['data'];
        res['data'].forEach(element => {
          myMap.set(element.id, element);
        });
        resolve();
      });
    });
  }

  /**
   * 部门修改
   * @param values
   */
  onDeptChanges(values: any): void {

    //获取子机构

  }

  validate(_index: number): boolean {
    return true;
  }
  // 工号
  searchWorkNumber() {
    this.api.getDetailForCreateAccount({ workNumber: this.form.get('jobNo').value }).subscribe(res => {
      this.worksList = res['data'];
      // let sex = res['data'].sex == 0 ? false : true;
      let sex = res['data'].sex;
      this.form.get('name').setValue(res['data'].userName);
      this.form.get('sex').setValue(sex);
      this.form.get('phoneNumber').setValue(res['data'].mobile);
      this.form.get('dateOfEntry').setValue(res['data'].dateOfEntry);
    });
  }
  ngOnInit() {
    this.getCompanyData();
    if (this.id != 0) {
      this.isEdit = true;
      this.getUserDetail();
    } else {
      this.nzOptions = [];
      cnm = null;
      this.form.get('remark').setValue([]);
    }
  }
}
