import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';

import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { NzCascaderOption } from 'ng-zorro-antd';
import 'echarts/map/js/china';

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.less']
})
export class DashboardNewComponent implements OnInit {

  constructor(
    fb: FormBuilder,
    private modalService: NzModalService,
    private message: NzMessageService,
    private nzContextMenuService: NzContextMenuService,
    public api: Api,
  ) { }

  ngOnInit() {
    // this.anyROIFunction();
    this.getNewDashCompany();
    this.mapFunction();
    this.pieFunction();
    this.cate1Function();
    this.cate2Function();
    window.addEventListener('resize', () => {
      this.echartROIOpt.resize();
      this.echartPie.resize();
      this.echartcate1.resize();
      this.echartcate2.resize();
    }, true);
  }
  companyList = [];
  getNewDashCompany() {
    this.api.newDashCompany({}).subscribe(res => {
      this.companyList = res['data'];
    })
  }
  // 地图 start
  // json = { "code": 0, "msg": "", "data": [{ "name": "广东", "value": 1368, "code": "44" }, { "name": "山东", "value": 913, "code": "37" }, { "name": "江苏", "value": 866, "code": "32" }, { "name": "浙江", "value": 722, "code": "33" }, { "name": "福建", "value": 572, "code": "35" }, { "name": "上海", "value": 553, "code": "31" }, { "name": "河南", "value": 539, "code": "41" }, { "name": "四川", "value": 527, "code": "51" }, { "name": "河北", "value": 479, "code": "13" }, { "name": "湖北", "value": 428, "code": "42" }, { "name": "北京", "value": 422, "code": "11" }, { "name": "安徽", "value": 418, "code": "34" }, { "name": "湖南", "value": 347, "code": "43" }, { "name": "辽宁", "value": 315, "code": "21" }, { "name": "陕西", "value": 284, "code": "61" }, { "name": "江西", "value": 274, "code": "36" }, { "name": "云南", "value": 258, "code": "53" }, { "name": "山西", "value": 251, "code": "14" }, { "name": "广西", "value": 250, "code": "45" }, { "name": "重庆", "value": 239, "code": "50" }, { "name": "贵州", "value": 208, "code": "52" }, { "name": "黑龙江", "value": 200, "code": "23" }, { "name": "吉林", "value": 174, "code": "22" }, { "name": "天津", "value": 155, "code": "12" }, { "name": "内蒙古", "value": 152, "code": "15" }, { "name": "海南", "value": 145, "code": "46" }, { "name": "新疆", "value": 128, "code": "65" }, { "name": "甘肃", "value": 119, "code": "62" }, { "name": "宁夏", "value": 49, "code": "64" }, { "name": "青海", "value": 32, "code": "63" }, { "name": "西藏", "value": 20, "code": "54" }], "max": 1368, "min": 20 };
  json = { "code": 0, "msg": "", "data": [{ "name": "广东", "value": 28930, "code": "28930" }], "max": 28930, "min": 0 };
  //-------------------------柱状图开始
  roi = {
    //backgroundColor: '#031245',
    aria: {
      show: true
    },
    tooltip: {
      // formatter: '　{b}：{c} 家　'
      formatter: function (params) {
        // 检查当前数据项的值是否存在，如果不存在或者为NaN，则显示0
        var value = params.value;
        if (value === undefined || value === null || isNaN(value)) {
          return params.name + ': 0';
        } else {
          // 否则按照原来的格式显示
          return params.name + ': ' + value;
        }
      }
    },
    /*legend: {
    orient: 'vertical',
    left: 'left',
    data: ['categoryA', 'categoryB', 'categoryC']
    },*/
    visualMap: {
      min: 0,
      max: 0,
      left: 'left',
      top: 'bottom',
      text: ['高', '低'],
      //seriesIndex: [1, 2, 3],
      inRange: {
        color: ['#94D0FF', '#0A4D90'] //'#719af8', '#06266f'
      },
      textStyle: {
        color: '#FFFFFF',
      },
      realtime: false,
      calculable: true,
    },
    geo: {
      map: 'china',
      roam: true,
      zoom: 1.2,
      label: {
        normal: {
          show: true,
          textStyle: {
            fontSize: 12,
            color: 'rgba(255, 255, 255, 0.5)'
          }
        }
      },
      itemStyle: {
        normal: {
          borderColor: 'rgba(0, 0, 0, 0.3)'
        },
        emphasis: {
          color: null,
          areaColor: null,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 20,
          borderWidth: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    },
    series: [{
      name: name,
      type: 'map',
      geoIndex: 0,
      // tooltip: {show: false},
      // markPoint: {
      //   data: [{
      //     coord: [115.97, 29.71]
      //   }]
      // },
      data: []
    }]
  };
  //-------------------------柱状图结束

  //-----------------ROI柱状图方法
  echartROIOpt; //echart对象
  onROIChartInit(chartOpt) {
    this.echartROIOpt = chartOpt;
    this.anyROIFunction();
  }
  roiParams: any = {};
  jsonMax = [];
  //修改echart对象,触发事件即可
  anyROIFunction() {
    this.api.newDashCompanyCount({}).subscribe(res => {
      // this.roi['visualMap']['max'] = res['data'] || 0;
      if (res['data']) {
        this.jsonMax = res['data'].toString().split('').map(Number);
      }

    })
  }
  mapFunction() {
    this.api.newDashCompanyProvince({}).subscribe(res => {
      this.roi['series']['0']['data'] = res['data']['zczbDTOList'];
      this.roi['visualMap']['max'] = res['data']['max'];
      this.roi['visualMap']['min'] = res['data']['min'];
      this.echartROIOpt.setOption(this.roi, true); //resourceOption是echart的option配置对象
    })
  }
  // 地图 end

  // 扇形图 start
  echartPie;
  peiOption = {
    // color: ["#ffffff"],
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}：{c} 家({d}%)",
      axisPointer: {
        type: "shadow"
      }
    },
    textStyle: {
      fontFamily: 'Montserrat'
    },
    grid: {
      left: 35,
      right: 5,
      top: 25,
      bottom: 30,
      show: false,
      borderWidth: 0
    },
    color: ['#8fc31f', '#13b5b1', '#00b7ee', '#f19149', '#c490bf', '#f6b37f', '#448aca', '#f8b551'],
    series: [{
      name: "注册资本",
      type: "pie",
      selectedMode: 'single',
      center: ["50%", "50%"],
      radius: ['40%', '70%'],
      data: [],//数据源
      label: {
        show: true,
        position: "top",
        distance: 5,
        color: "rgba(255, 255, 255, 0.7)"
      }

    }]
  };
  pieChartInit(chartOpt) {
    this.echartPie = chartOpt;
  }
  pieFunction() {
    this.api.newDashZczbList({}).subscribe(res => {
      this.peiOption['series']['0']['data'] = res['data'];
      this.echartPie.setOption(this.peiOption, true); //resourceOption是echart的option配置对象
    })
  }
  // 扇形图 end

  // 柱状图 1 start
  echartcate1;
  cate1Option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
      top: "top",
      textStyle: {
        fontSize: 18
      },
      subtextStyle: {
        fontSize: 16
      },
      show: false
    },
    color: ["#ffffff"],
    tooltip: {
      trigger: "axis",
      formatter: "{a} <br/>{b}：{c} 家",
      axisPointer: {
        type: "shadow"
      }
    },
    textStyle: {
      fontFamily: 'Montserrat'
    },
    grid: {
      left: 5,
      right: 5,
      top: 25,
      bottom: 30,
      show: false,
      borderWidth: 0
    },
    xAxis: [{
      type: "category",
      data: [],//数据源
      axisLabel: {
        interval: 0, //强制显示文字
        show: true,
        rotate: -22,
        color: 'rgba(255, 255, 255, 0.7)'
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [{
      type: "value",
      show: false,
      axisLabel: {
        color: 'rgba(255, 255, 255, 0.7)'
      },
      splitLine: {
        show: false
      }
    }],
    series: [{
      name: "企业数",
      type: "bar",
      barWidth: "60%",
      data: [],//数据源
      itemStyle: {
        color: {
          "x": 0,
          "y": 0,
          "x2": 0,
          "y2": 1,
          "type": "linear",
          "global": false,
          "colorStops": [{
            "offset": 0,
            "color": "#00CFD6"
          }, {
            "offset": 1,
            "color": "#0068F1"
          }]
        }
      },
      label: {
        show: true,
        position: "top",
        distance: 5,
        color: "rgba(255, 255, 255, 0.7)"
      }

    }]
  };
  cate1ChartInit(chartOpt) {
    this.echartcate1 = chartOpt;
  }
  cate1Function() {
    this.api.newDashStatusList({}).subscribe(res => {
      this.cate1Option['xAxis']['0']['data'] = res['data'].map(item => item.name);
      this.cate1Option['series']['0']['data'] = res['data'].map(item => item.value);
      this.echartcate1.setOption(this.cate1Option, true); //resourceOption是echart的option配置对象
    })
  }
  // 柱状图 1 end

  // 柱状图 2 start
  echartcate2;
  cate2Option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
      top: "top",
      textStyle: {
        fontSize: 18
      },
      subtextStyle: {
        fontSize: 16
      },
      show: false
    },
    color: ["#ffffff"],
    tooltip: {
      trigger: "axis",
      formatter: "{a} <br/>{b}：{c} 家",
      axisPointer: {
        type: "shadow"
      }
    },
    textStyle: {
      fontFamily: 'Montserrat'
    },
    grid: {
      left: 0,
      right: 5,
      top: 25,
      bottom: 30,
      show: false,
      borderWidth: 0
    },
    xAxis: [{
      type: "category",
      data: [],
      axisLabel: {
        interval: 0, //强制显示文字
        show: true,
        rotate: -22,
        color: 'rgba(255, 255, 255, 0.7)'
      },
      splitLine: {
        show: false
      }
    }],
    yAxis: [{
      type: "value",
      axisLabel: {
        color: 'rgba(255, 255, 255, 0.7)'
      },
      splitLine: {
        show: false
      },
      show: false
    }],
    series: [{
      name: "企业数",
      type: "bar",
      barWidth: "60%",
      data: [],
      itemStyle: {
        color: {
          "x": 0,
          "y": 0,
          "x2": 0,
          "y2": 1,
          "type": "linear",
          "global": false,
          "colorStops": [{
            "offset": 0,
            "color": "#00CFD6"
          }, {
            "offset": 1,
            "color": "#0068F1"
          }]
        }
      },
      label: {
        show: true,
        position: "top",
        distance: 5,
        color: "rgba(255, 255, 255, 0.7)"
      }

    }]
  };
  cate2ChartInit(chartOpt) {
    this.echartcate2 = chartOpt;
  }
  cate2Function() {
    this.api.newDashYearList({}).subscribe(res => {
      this.cate2Option['xAxis']['0']['data'] = res['data'].map(item => item.name);
      this.cate2Option['series']['0']['data'] = res['data'].map(item => item.value);
      this.echartcate2.setOption(this.cate2Option, true); //resourceOption是echart的option配置对象
    })
  }
  // 柱状图 2 end
}
