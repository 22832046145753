import { Component, OnInit,Input } from '@angular/core';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-modal-office-consumptions-list',
  templateUrl: './modal-office-consumptions-list.component.html',
  styleUrls: ['./modal-office-consumptions-list.component.less']
})
export class ModalOfficeConsumptionsListComponent implements OnInit {
  @Input() officeId:String;

  listOfData = [];
  companyList = [];

  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  params = {
    dateList: null,
    beginDate: null,
    endDate: null,
    officeId:null,
  }
  
  constructor(public api: Api,) { }
// 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getOfficeConsumptions();
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    console.log(this.params)
    this.getOfficeConsumptions();
  }

  reset() {
    this.params.dateList = null,
    this.params.beginDate = null,
    this.params.endDate = null,
    this.getOfficeConsumptions();
  }
  getOfficeConsumptions() {
    if (this.params.dateList != null) {
      this.params.beginDate = Utils.dateFormat(this.params.dateList[0], 'yyyy-MM-dd');
      this.params.endDate = Utils.dateFormat(this.params.dateList[1], 'yyyy-MM-dd');
    }
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    }
    this.api.getOfficeCompanyConsumption(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }
  ngOnInit() {
    this.params.officeId = this.officeId;
    this.api.getOfficeDataDimension({ type: 0 }).subscribe((res) => {
      this.companyList = res['data'];
    })

    this.getOfficeConsumptions();
  }

  
}
