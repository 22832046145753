import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Location } from '@angular/common';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-different-workorder',
  templateUrl: './drawer-different-workorder.component.html',
  styleUrls: ['./drawer-different-workorder.component.less']
})
export class DrawerDifferentWorkorderComponent implements OnInit {

  @Input() value = '';

  constructor(
    private NzDrawerRef: NzDrawerRef<string>,
    public location: Location,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  // 0查看 1编辑
  type = 0;

  uploadParams = {};
  fileFJList = [];
  contractUrl: string;
  canSubmit = false;
  invoicegDetail;
  serverDetail :any = {
    id: '',
    relationOrders: [],
    bindingOrderType: '',
    serviceType: ''
  };
  ngOnInit() {
    this.getPaymentDetail();
  }
  // 获取甩单申请详情
  getPaymentDetail() {
    this.api.bsOrderTaskSettWorkOrderDetail(this.value).subscribe(res => {
      this.serverDetail = res['data'];

      this.api.workOrderTaskSettInvoiceApplyId(this.value).subscribe(res => {
        this.invoicegDetail = res['data'];

      })

    })
  }

}
