import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-select-work-order',
  templateUrl: './modal-select-work-order.component.html',
  styleUrls: ['./modal-select-work-order.component.less']
})
export class ModalSelectWorkOrderComponent implements OnInit {

  workorderList;

  @Input() value = '';
  @Input() orderChoiceList = [];
  @Input() type = '';

  constructor(
    private modal: NzModalRef,
    public message: NzMessageService,
    private drawerService: NzDrawerService,
    public api: Api,
  ) { }

  params = {
    phoneNumber: null,
    orderNo: null,
    workOrderNo: null,
    customerName: null,
    serviceProviderId: null,
  }

  isAllChecked = false; // 是否全选
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedItem: any = [];

  dateList: any = null;
  ngOnInit() {
    this.params.serviceProviderId = this.value
    if (this.orderChoiceList.length > 0) {
      let newList = {};
      this.orderChoiceList.forEach((item, index) => {
        newList[item.id] = true;
      });
      this.mapOfCheckedId = newList;
    }
    this.mapOfCheckedItem = this.orderChoiceList;
    this.getworkorderList();

  }
  sreach() {
    this.getworkorderList();
  }
  toPageList(index) {
    this.pageCtrl.current = index;
    this.getworkorderList();
  }
  pageCtrl = { // 分页控制
    current: 1,
    size: 10,
    object: {}
  };
  total;

  // 翻页
  toPage(page) {
    this.pageCtrl.current = page;
    this.getworkorderList();
  }
  selectedCategory(id) {
    var choiceList = this.workorderList.filter(item => this.mapOfCheckedId[item.id])
    this.mapOfCheckedItem = choiceList;
  }

  refreshStatus(): void {
    this.isAllChecked = this.workorderList.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate = this.workorderList.some(item => this.mapOfCheckedId[item.id]) && !this.isAllChecked;
    this.numberOfChecked = this.workorderList.filter(item => this.mapOfCheckedId[item.id]).length;
    var choiceList = this.workorderList.filter(item => this.mapOfCheckedId[item.id])
    this.mapOfCheckedItem = choiceList;
  }

  checkAll(value: boolean): void {
    this.workorderList.forEach(item => {
      // this.mapOfCheckedId[item.id] = value;
      if (!item.pid) {
        this.mapOfCheckedId[item.id] = value
      }
    });
    this.refreshStatus();
  }

  // 获取产品列表
  getworkorderList() {
    let params = this.params;
    this.pageCtrl.object = { ...params }

    if (this.type == 'gl') {
      this.api.getSettlementTask(this.pageCtrl).subscribe(res => {
        this.workorderList = res['data'].records;
        this.total = res['data'].total;
      })
      return
    }

    if (this.type == 'yd') {
      this.api.getSettlementYdTask(this.pageCtrl).subscribe(res => {
        this.workorderList = res['data'].records;
        this.total = res['data'].total;
      })
      return
    }
  }
  cancel() {
    this.modal.destroy();
  }
  submit() {
    this.modal.destroy(this.mapOfCheckedItem);
    if (this.numberOfChecked == 0) {
      this.message.warning('请选择工单！');
      return
    }
    this.modal.destroy(this.mapOfCheckedItem);
  }
  query() {
    this.getworkorderList()
  }
  reset() {
    this.params = {
      phoneNumber: null,
      orderNo: null,
      workOrderNo: null,
      customerName: null,
      serviceProviderId: null,
    }
    this.dateList = null;
    this.getworkorderList()
  }

}
