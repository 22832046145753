import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@service/Utils';
import { Api } from '@service/Api';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DrawerWorkReportComponent } from '../../component/drawer-work-report/drawer-work-report.component';
import { ModalWorkReportUserComponent } from '../../component/modal-work-report-user/modal-work-report-user.component';
import { DrawerWorkReportDetailsComponent } from '../../component/drawer-work-report-details/drawer-work-report-details.component';


@Component({
  selector: 'app-drawer-work-report-user',
  templateUrl: './drawer-work-report-user.component.html',
  styleUrls: ['./drawer-work-report-user.component.less']
})
export class DrawerWorkReportUserComponent implements OnInit {
  @Input() value = {};
  @Input() id = '';
  @Input() type;
  constructor(
    private drawerService: NzDrawerService,
    public api: Api,
    public global: Global,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
    this.getReportUser();
  }
  listOfData = [];
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getReportUser();
  }
  getReportUser() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: {
        reportNo: this.id
      },
    };
    switch (this.type) {
      case 1:
        this.api.reportUserAllList(params).subscribe((res) => {
          this.listOfData = res['data'].records;
          this.pageCtrl.total = res['data'].total;
        })
        break;
      case 2:
        this.api.reportedUserList(params).subscribe((res) => {
          this.listOfData = res['data'].records;
          this.pageCtrl.total = res['data'].total;
        })
        break;
      case 3:
        this.api.unReportUserList(params).subscribe((res) => {
          this.listOfData = res['data'].records;
          this.pageCtrl.total = res['data'].total;
        })
        break;

      default:
        break;
    }

  }
  // 打开详情
  open(item, id) {
    const modal = this.drawerService.create<DrawerWorkReportComponent, { value: object, id: string }, string>({
      nzWidth: 900,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerWorkReportComponent,
      nzContentParams: {
        value: item,
        id: id,
      }
    });
    modal.afterClose.subscribe((data) => {
    });
  }

  goScroe(id): void {
    const modal = this.modalService.create({
      nzTitle: '评价',
      nzContent: ModalWorkReportUserComponent,
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.getReportUser();
      }
    });
  }

  // 打开详情
  openCount(item, id, type) {
    const modal = this.drawerService.create<DrawerWorkReportDetailsComponent, { value: object, id: string, type: string }, string>({
      nzWidth: 900,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerWorkReportDetailsComponent,
      nzContentParams: {
        value: item,
        id: id,
        type: type
      }
    });
    modal.afterClose.subscribe((data) => {
    });
  }
}
