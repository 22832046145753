import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Api } from '@service/Api';

import { DataDimensionService } from '@service/DataDimensionService';

let localApi;

@Component({
  selector: 'app-modal-editproduct-all',
  templateUrl: './modal-editproduct-all.component.html',
  styleUrls: ['./modal-editproduct-all.component.less']
})
export class ModalEditproductAllComponent implements OnInit {
  @Input() type: string;
  @Input() data: object;

  form: FormGroup;


  formatterPercent = (value: number) => `${value}`;
  parserPercent = (value: string) => value.replace('', '');
  // formatterPercent = (value: number) => `${value} %`;
  // parserPercent = (value: string) => value.replace(' %', '');

  queryProdList = [];   // 产品列表: 查询参数


  constructor(
    public fb: FormBuilder,
    private modalService: NzModalService,
    public message: NzMessageService,
    public api: Api,
    public requstService: DataDimensionService,
  ) {
    localApi = this.api;
    this.form = fb.group({
      productName: ['', Validators.required],
      unifyAmount: [null, Validators.required],
      scale: [null, Validators.required],
      isHighTechnology: [null, Validators.required],
      cateIds: ['', Validators.required],
      productNature: ['', Validators.required],
      productType: ['', Validators.required],
      remark: [''],

    });
  }
  scaleOptions = [
    { label: '小规模', value: 0 },
    { label: '一般纳税人', value: 1 },
  ];

  options = [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ];

  // 获取[选择分类]
  getAllParameters() {
    this.api.getAllParameters({ parentCode: "CPFL" }).subscribe(res => {
      this.queryProdList = this.newsetTreeList(res['data'], false);
    });
  }
  // 递归改变树结构
  newsetTreeList(list, isLeaf) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let obj = {
        name: list[i].name,
        id: list[i].id,
        code: list[i].code,
        isLeaf: isLeaf
      }
      result.push(obj);
      if (list[i].parametersVO) {
        obj['children'] = this.newsetTreeList(list[i].parametersVO, true);
      }
    }
    return result;
  }

  // 递归改变树结构
  setTreeList(list) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let isLeaf = list[i].sysBaseCategoryList ? false : true;
      let obj = {
        label: list[i].name,
        value: list[i].id,
        isLeaf: isLeaf
      }
      result.push(obj);
      if (list[i].sysBaseCategoryList) {
        obj['children'] = this.setTreeList(list[i].sysBaseCategoryList);
      }
    }
    return result;
  }
  setData(data) {
    this.form.get('productName').setValue(data['productName']);
    this.form.get('unifyAmount').setValue(data['unifyAmount']);
    this.form.get('productType').setValue(data['productType']);
    this.form.get('scale').setValue(data['scale']);
    this.form.get('isHighTechnology').setValue(data['isHighTechnology']);
    this.form.get('productNature').setValue(data['productNature']);
    this.form.get('remark').setValue(data['remark']);
    this.form.get('cateIds').setValue([data['oneCategoryId'], data['twoCategoryId']]);
  }
  changeProd(e) {
  }

  // 获取[选择分类]
  getProductInfo(id) {
    this.api.getProductInfo(id).subscribe(res => {
      this.setData(res['data']);
    });
  }
  ngOnInit() {
    this.getAllParameters();
    this.requstService.getDataDimension();
    if (this.type == 'edit') {
      this.getProductInfo(this.data['id'])
    }
  }

}
