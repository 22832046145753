import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { EnterpriseComponent } from './pages/enterprise/enterprise.component';
import { EnterpriseBandComponent } from './pages/enterprise-band/enterprise-band.component';
const routes: Routes = [
  {
    path: 'forms',
    loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModule),
    canLoad: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'enterprise', component: EnterpriseComponent },
  { path: 'enterpriseBand', component: EnterpriseBandComponent },
  // {
  //   path: '',
  //   // component: PagesComponent,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  // },
  { path: '', redirectTo: '/dashboard/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
