import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { DrawerArticleLookComponent } from '../../component/drawer-article-look/drawer-article-look.component';
import { DrawerArticleShareComponent } from '../../component/drawer-article-share/drawer-article-share.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-drawer-article-manage',
  templateUrl: './drawer-article-manage.component.html',
  styleUrls: ['./drawer-article-manage.component.less']
})
export class DrawerArticleManageComponent implements OnInit {


  @Input() value = '';
  safeContent: any;
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
    private sanitizer: DomSanitizer
  ) {
  }
  articleInfo;
  ngOnInit() {
    this.getArticleDetail();
  }


  // 获取文章信息
  getArticleDetail() {
    this.api.articleDetail(this.value).subscribe(res => {
      this.articleInfo = res['data'];
      this.safeContent = this.sanitizer.bypassSecurityTrustHtml(this.articleInfo.content);
    });
  }

  // 打开浏览人数
  openLook(data, type) {
    const modal = this.drawerService.create<DrawerArticleLookComponent, { value: string, type: string }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerArticleLookComponent,
      nzContentParams: {
        value: data['id'],
        type: type
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }

  // 打开分享次数
  openShare(data) {
    const modal = this.drawerService.create<DrawerArticleShareComponent, { value: string }, string>({
      nzWidth: '60%',
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerArticleShareComponent,
      nzContentParams: {
        value: data['id'],
      }
    });
    modal.afterClose.subscribe((data) => {

    });
  }
}
