import { Component, ElementRef, ViewChild, Renderer, Output, EventEmitter, OnInit, Input, } from '@angular/core';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../../../auth/auth.service';
import * as wangEditor from '../../../../../../node_modules/wangeditor/release/wangEditor.js';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
import { environment } from '../../../../../environments/environment';
let cnm;

@Component({
  selector: 'app-knowledge-template',
  templateUrl: './knowledge-template.component.html',
  styleUrls: ['./knowledge-template.component.less']
})
export class KnowledgeTemplateComponent implements OnInit {
  @Input() content: string;

  form: FormGroup;
  noticeList = [];
  // @Output() onPostData = new EventEmitter();
  @ViewChild('editroElement', { static: false }) editroElement: ElementRef;

  private editor: any;

  itemClass: any; // 新增知识库分类

  constructor(
    fb: FormBuilder,
    private modal: NzModalRef,
    public api: Api,
    public utils: Utils,
    private auth: AuthService,
    private el: ElementRef,
    private message: NzMessageService,
  ) {
    this.form = fb.group({
      title: ['', Validators.required],
      keyword: ['', Validators.required],
      description: ['', Validators.required],
      cate: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    const authToken = this.auth.getAuthorizationToken() || '';
    let editordom = this.editroElement.nativeElement;
    let a = document.getElementById('editroElement');
    console.log(a);
    // let editordom = this.el.nativeElement.querySelector('#editroElement')
    this.editor = new wangEditor(editordom);
    // this.editor.customConfig.uploadImgShowBase64 = true;
    // this.editor.customConfig.debug = true
    this.editor.customConfig.uploadFileName = 'file'; //设置文件上传的参数名称
    this.editor.customConfig.uploadImgHeaders = {
      'Admin-Token': authToken
    }
    this.editor.customConfig.uploadImgServer = environment.baseUrl + this.api.uplaodAttachment(); // 设置上传文件的服务器路径
    this.editor.customConfig.uploadImgHooks = {
      customInsert: function (insertImg, result, editor) {
        var url = result.data.fileUrl;
        insertImg(url)
      }
    }
    this.editor.create();
    this.editor.txt.html(this.content['content'])
  }

  submitForm() {
    let params = {
      id: null,
      content: this.editor.txt.html()
    };
    if (this.content) {
      params['id'] = this.content['id'];
    }
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      return
    }
    else {
      params = Object.assign(params, this.form.value);
      if (this.content) {
        this.api.editKnowledgeBase(params).subscribe(res => {
          this.message.success("修改成功！");
          this.modal.destroy();
          this.form.reset();
        });
      } else {
        this.api.addKnowledgeBase(params).subscribe(res => {
          this.message.success("添加成功！");
          this.modal.destroy();
          this.form.reset();
        });
      }
    }
  }

  cancel() {
    this.modal.destroy();
  }

  ngOnInit() {
    let params = { parentCode: 'KNOWLEDGE_BASE' }
    this.api.getSysParameters(params).subscribe(res => {
      this.itemClass = res['data'];
    });

    if (this.content) {
      this.form.get('keyword').setValue(this.content['keyword']);
      this.form.get('title').setValue(this.content['title']);
      this.form.get('description').setValue(this.content['description']);
      this.form.get('cate').setValue(this.content['cate']);
    }
  }
}
