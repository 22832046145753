import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { DrawerUserComponent } from '../../component/drawer-user/drawer-user.component';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { ModalOrderComponent } from '../../component/modal-order/modal-order.component';

@Component({
  selector: 'app-drawer-customer-apply',
  templateUrl: './drawer-customer-apply.component.html',
  styleUrls: ['./drawer-customer-apply.component.less']
})
export class DrawerCustomerApplyComponent implements OnInit {
  @Input() value = '';
  @Input() type = '';
  @Input() applyStatus = '';

  customerList = [];
  customerParamList = [];
  customerId: string;
  customerIds: string;
  constructor(
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
    public api: Api,
  ) {
  }
  params = {
    name: null,
    phoneNumber: null,
    followStatus: null,
    customerApplyId: null,
  }

  statusList = [
    { 'value': '0', 'name': '未跟进' },
    { 'value': '1', 'name': '已跟进' },
  ]

  //分页
  pageCtrl = {
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getApproveSettlementList();
  }
  // 获取列表
  getApproveSettlementList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.api.customerApplyItemLists(params).subscribe(res => {
      this.customerList = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getApproveSettlementList();
  }

  reset() {
    this.params.name = null;
    this.params.phoneNumber = null;
    this.params.followStatus = null;
    this.getApproveSettlementList();
  }

  ngOnInit() {
    this.params.customerApplyId = this.value;
    this.getApproveSettlementList();
    this.getcustomerApplyParamLists();
  }

  getcustomerApplyParamLists() {
    this.api.getcustomerApplyParamList(this.value).subscribe(res => {
      this.customerParamList = res['data']
    });

  }

  // 打开客户信息详情
  openCustomer(id, index, jobId) {
    const customerDrawer = this.drawerService.create<DrawerUserComponent, { value: string, index: number, jobId: any, plateValue: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerUserComponent,
      nzContentParams: {
        value: id,
        index,
        jobId,
        plateValue: 'my-customer'
      }
    });
    // customerDrawer.afterClose.subscribe((data) => {
    //   this.getPubCustomerList();
    // });
  }
  //跟进记录
  openEditor(id, customerId) {
    this.customerId = customerId;
    this.customerIds = id;
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'customer'
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        data['customerId'] = this.customerId;
        data['id'] = this.customerIds;
        console.log(data)
        this.api.addCustomerJobRecords(data).subscribe(res => {
          this.getApproveSettlementList();
        })
      }
    });
  }

  // 新建订单
  toOrder(data?): void {
    var params = JSON.parse(JSON.stringify(data))
    params.id = params.customerId
    const modal = this.modalService.create({
      nzTitle: '新建订单',
      nzContent: ModalOrderComponent,
      nzComponentParams: {
        customerInfo: params,
        type: 'new'
      },
      nzStyle: { top: '40px' },
      nzWidth: 1200,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
  }

}
