import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawerTaskDepartmentStatisiticsComponent } from '../../component/drawer-task-department-statisitics/drawer-task-department-statisitics.component';

@Component({
  selector: 'app-drawer-department-statistics',
  templateUrl: './drawer-department-statistics.component.html',
  styleUrls: ['./drawer-department-statistics.component.less']
})
export class DrawerDepartmentStatisticsComponent implements OnInit {

  @Input() value = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  listOfData = [];

  ngOnInit() {
    this.getTeamStatisticsDetail();
  }


  // 获取客户列表
  getTeamStatisticsDetail() {
    this.api.saveTeamStatisticsDetail(this.value).subscribe(res => {
      this.listOfData = res['data'].sysUserGsStatisticsList;
    });
  }


  // 打开列表详情
  open(status, data, type) {
    const drawerRef = this.drawerService.create<DrawerTaskDepartmentStatisiticsComponent, { data: object, status: string, type: string }, string>({
      nzWidth: 1000,
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskDepartmentStatisiticsComponent,
      nzContentParams: {
        data: data,
        status: status,
        type: type
      }
    });

    drawerRef.afterClose.subscribe((data) => {
      this.getTeamStatisticsDetail();
    });
  }

  weekList = [];
  curweekItem = {};
  openWeek(item, content) {
    this.curweekItem = item;
    this.modalService.create({
      nzTitle: '按周统计',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => { }
    });
    var params = {
      statisticsMonth: item.statisticsMonth,
      userId: item.userId,
    }
    this.api.weekStatisticsDataList(params).subscribe(res => {
      this.weekList = res['data'];
    });
  }
}
