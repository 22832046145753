import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-selectqual',
  templateUrl: './modal-selectqual.component.html',
  styleUrls: ['./modal-selectqual.component.less']
})
export class ModalSelectqualComponent implements OnInit {

  constructor(
    public router: Router,
    public api: Api,
    // private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public global: Global,
    private modal: NzModalRef,
  ) { }

  ngOnInit() {

  }
  reset() {
    this.levelOne = null;
    this.mapOfCheckedId = {};
    this.mapOfCheckedItem = [];
  }

  qualList = [];
  modalQual;
  levelOne;
  openQual(searchQual) {
    this.reset();
    this.getQualListCate();
    this.sreach('');
    this.modalQual = this.modalService.create({
      nzTitle: '选择资质',
      nzContent: searchQual,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzCancelText: null,
      nzFooter: null
    });
  };
  delete(id) {
    let newList = [];
    this.qualList.forEach((item, index) => {
      if (item.id !== id) {
        newList.push(item);
      }
    });
    this.qualList = newList;
  }
  cancelQual() {
    this.modal.destroy();
  };
  // 选中资质
  submitQual() {
    this.modal.destroy(this.qualList);
  }
  // 选择资质 start
  productList;         // 产品列表
  categoryFirst = [];  // 一级类别

  sreachName: any = '';
  // 获取一级列表
  // 选择的id
  mapOfCheckedId: { [key: string]: boolean } = {};
  // 选中的列表项
  mapOfCheckedItem: any = [];
  // 选择框
  selectedCategory(id) {
    var choiceList = this.productList.filter(item => {
      this.mapOfCheckedId[item.id];
      if (this.mapOfCheckedId[item.id]) {
        return item
      }
    })
    this.mapOfCheckedItem = choiceList;
  }
  // 分类查看数据
  getcategorySecond(e) {
    this.mapOfCheckedId = {};
    this.mapOfCheckedItem = [];
    if (e) {
      var data = {
        id: e
      }
      this.api.getSysParametersChileList(data).subscribe(res => {
        this.productList = res['data'];
      })
    } else {
      this.productList = [];
    }
  }
  // 获取资质分类
  getQualListCate() {
    var data = {
      url: '/COMPANY_QUALIFICATION/1'
    }
    this.api.getSysParametersList(data).subscribe(res => {
      this.categoryFirst = res['data'];
    })
  }
  // 名字查看数组
  sreach(value) {
    this.sreachName = value;
    this.mapOfCheckedId = {};
    this.mapOfCheckedItem = [];
    if (this.sreachName) {
      var data = {
        name: this.sreachName
      }
      this.api.getSysParametersNameList(data).subscribe(res => {
        this.productList = res['data'];
      })
    } else {
      this.productList = [];
    }
  };

  cancel() {
    this.modalQual.destroy();
  };
  // 选择资质
  submit() {
    if (this.mapOfCheckedItem.length === 0) {
      this.message.warning('请选择资质！');
      return
    };
    this.qualList = [...this.qualList, ...this.mapOfCheckedItem];
    this.modalQual.destroy();
  }

  // 选择资质 end

}
