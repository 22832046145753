import { Component, OnInit,Input } from '@angular/core';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-payslip-info',
  templateUrl: './drawer-payslip-info.component.html',
  styleUrls: ['./drawer-payslip-info.component.less']
})
export class DrawerPayslipInfoComponent implements OnInit {
  @Input() value = '';
  @Input() type: any;

  constructor(
    public api: Api,
  ) { }
  
  userBusinessCommissionList = [];//提成 业务销售提成明细
  userBusinessCirclesList = [];//提成 工商交付提成明细
  userBusinessBookkeepingList = [];//提成 记账报税提成明细
  userBusinessAdminList = [];//提成 管理类及其他提成
  refundDataList = [];//订单退款
  userDeductionList = [];//其他扣款
  ngOnInit() {
    if(this.type == 'absenceDutyDeductionAmount'){
      //其他扣款
      this.userDeductionList = [
        {name:'本月暂存款',value:this.value['temporaryAmount']},
        {name:'线索费',value:this.value['clueAmount']},
        {name:'其他扣款',value:this.value['otherDeductionAmount']},
      ]
    }
    if(this.type == 'baseCommissionAmount'){
      this.api.getUserCommissionBusinessList(this.value['userSettlementId']).subscribe(res=>{
        this.userBusinessCommissionList = res['data'];
      })
      this.api.getUserCommissionAccountList(this.value['userSettlementId']).subscribe(res=>{
        this.userBusinessBookkeepingList = res['data'];
      })
      this.api.getUserCommissionCirclesList(this.value['userSettlementId']).subscribe(res=>{
        this.userBusinessCirclesList = res['data'];
      })
      this.api.getUserCommissionMnageList(this.value['userSettlementId']).subscribe(res=>{
        this.userBusinessAdminList = res['data'];
      })
    }
    if(this.type == 'refund'){
      this.api.getUserCommissionRefundList(this.value['userSettlementId']).subscribe(res=>{
        this.refundDataList = res['data']
      })
    }
  }
}
