import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-drawer-office-config',
  templateUrl: './drawer-office-config.component.html',
  styleUrls: ['./drawer-office-config.component.less']
})
export class DrawerOfficeConfigComponent implements OnInit {
  @Input() value = '';


  contractUrl: string;
  constructor(
    private drawerRef: NzDrawerRef<string>,
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
  }

  serverDetail: any = {};
  getServerDetail() {
    this.api.systemOfficeConfigDetail(this.value).subscribe(res => {
      this.serverDetail = res['data'];
      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.serverDetail.flowBusinessStepDTOList && this.serverDetail.flowBusinessStepDTOList.length > 0) {
        this.serverDetail.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }
    });
  }
  ngOnInit() {
    this.getServerDetail();
  }

}
