import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-drawer-task-department-statisitics',
  templateUrl: './drawer-task-department-statisitics.component.html',
  styleUrls: ['./drawer-task-department-statisitics.component.less']
})
export class DrawerTaskDepartmentStatisiticsComponent implements OnInit {
  public baseUrl; // api基本路径
  @Input() data = '';
  @Input() status = '';
  @Input() type = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      userId: [null, Validators.required],
    });
    this.baseUrl = environment.baseUrl;
  }
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  form: FormGroup;
  listOfData = [];
  params = {
    userId: null,
    status: null,
  }
  ngOnInit() {
    this.params.userId = this.data['userId'];
    this.params.status = this.status;
    this.getOrderTaskList();
  }

  // 翻页
  toPage(page) {
    console.log(page)
    this.pageCtrl.nzPageIndex = page;
    this.getOrderTaskList();
  }

  uncompleteList: [];
  dyNewList: []
  selectedIndex = 0;
  selectTabset(e) {
    this.selectedIndex = e.index;
  }
  // 获取工单列表
  getOrderTaskList() {
    var data = {};
    data['month'] = this.data['statisticsMonth'];
    data['documentaryCompanyId'] = this.data['officeId'];
    if (this.type == 'company') {
      // data['documentaryCompanyId'] = this.data['officeId'];
    } else if (this.type == 'user') {
      data['userId'] = this.data['userId'];
    } else if (this.type == 'officeId') {
      data['officeId'] = this.data['deptId'];
    } else if (this.type == 'dept') {
      data['deptId'] = this.data['deptId'];
    }
    if (this.data['statisticsWeek']) {
      data['statisticsWeek'] = this.data['statisticsWeek'];
    }
    switch (this.status) {
      case 'dyNewByCount':
        // 当月实际新增工单明细
        this.api.dyNewList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'dyCompleteByCount':
        // 当月完结本月工单
        this.api.dyCompleteList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'allInCount':
        // 全部在办工单量（含库存单量）
        this.api.inList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'byAllDeliverCount':
        // 本月所有交付工单明细
        this.api.byAllDeliverList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'completeDeliverVal':
        // 完结交付工单总产值
        this.api.completeDeliverValList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'sywwcCount':
        // 上月未完成工单量
        var newData = data;
        delete newData['month'];
        newData['nextMonth'] = this.data['statisticsMonth'];
        this.api.uncompleteList(newData).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'unOpenCount':
        // 未开启数量
        this.api.unOpenCountList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'byyblCount':
        // 本月应办理工单量（选项卡） = 上月未完成工单量 + 当月实际新增工单
        // 当月实际新增工单明细
        this.api.dyNewList(data).subscribe(res => {
          this.dyNewList = res['data'];
        });
        // 上月未完成工单量
        var newData = data;
        delete newData['month'];
        delete newData['deptId'];
        newData['nextMonth'] = this.data['statisticsMonth'];
        newData['officeId'] = this.data['deptId'];
        this.api.uncompleteList(newData).subscribe(res => {
          this.uncompleteList = res['data'];
        });
        break;
      case 'ydYyktDeliverCount':
        var newData = data;
        delete newData['month'];
        newData['unCompleteMonth'] = this.data['statisticsMonth'];
        // 剩余可交付工单
        this.api.uncompleteList(newData).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'ydStockCount':
        var newData = data;
        delete newData['month'];
        newData['stockMonth'] = this.data['statisticsMonth'];
        // 剩余库存工单
        this.api.stockList(newData).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'yjGross':
        // 业绩毛利
        this.api.grossAmountListTask(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      case 'dyRemainderCount':
        // 当周未完成工单明细
        this.api.dyWeekUnCompleteList(data).subscribe(res => {
          this.listOfData = res['data'];
        });
        break;
      default:
        break;
    }


  }

  // 打开客户信息详情
  open(id) {
    const drawerRef = this.drawerService.create<DrawerTaskComponent, { value: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id
      }
    });

    drawerRef.afterClose.subscribe((data) => {
    });
  }

  /**
   * 导出
   * @param data
   *
   */
  loading = false;
  downLoad() {
    this.loading = true;
    let date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var time = Y + M + D;
    var title = time + '报表明细'
    let urlApi = '';
    // 设置参数 start
    var data = {};
    data['month'] = this.data['statisticsMonth'];
    data['documentaryCompanyId'] = this.data['officeId'];
    if (this.type == 'company') {
      // data['documentaryCompanyId'] = this.data['officeId'];
    } else if (this.type == 'user') {
      data['userId'] = this.data['userId'];
    } else if (this.type == 'officeId') {
      data['officeId'] = this.data['deptId'];
    } else if (this.type == 'dept') {
      data['deptId'] = this.data['deptId'];
    }
    if (this.data['statisticsWeek']) {
      data['statisticsWeek'] = this.data['statisticsWeek'];
    }
    // 设置参数 end
    var newData;
    switch (this.status) {
      case 'byyblCount':
        switch (this.selectedIndex) {
          case 0:
            // 上月未完成工单量
            newData = data;
            delete newData['month'];
            delete newData['deptId'];
            newData['nextMonth'] = this.data['statisticsMonth'];
            newData['officeId'] = this.data['deptId'];
            urlApi = '/bsOrderTaskStatistics/importUncompleteList';
            break;
          case 1:
            newData = data;
            urlApi = '/bsOrderTaskStatistics/importDyNewList';
            break;
          default:
            break;
        }
        break;
      case 'ydYyktDeliverCount':
        // 剩余可交付工单
        newData = data;
        delete newData['month'];
        delete newData['deptId'];
        newData['unCompleteMonth'] = this.data['statisticsMonth'];
        newData['officeId'] = this.data['deptId'];
        urlApi = '/bsOrderTaskStatistics/importUncompleteList';
        break;
      case 'byAllDeliverCount':
        newData = data;
        urlApi = '/bsOrderTaskStatistics/importByAllDeliverList';
        break;
      default:
        break;
    }

    let url = this.baseUrl + urlApi;
    var httpRequest = new XMLHttpRequest();
    httpRequest.responseType = "arraybuffer";
    httpRequest.open("POST", url, true);
    httpRequest.setRequestHeader("Content-Type", "application/json");
    httpRequest.setRequestHeader("Admin-Token", localStorage.getItem("token"));
    httpRequest.setRequestHeader("responseType", "blob");

    httpRequest.send(JSON.stringify(newData));
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState == 4 && httpRequest.status == 200) {
        var data = httpRequest.response;
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8;' });
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', title + '.xls');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    }
  }
}
