import { Component, OnInit, Input } from '@angular/core';

import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalAddUserPerComponent } from '../modal-userDataPer/modal-userDataPer.component';


@Component({
  selector: 'app-modal-add-user-dataper',
  templateUrl: './modal-add-user-dataper.component.html',
  styleUrls: ['./modal-add-user-dataper.component.less']
})
export class ModalAddUserDataperComponent implements OnInit {
  @Input() id: string; // id
  listOfData_userData = [];


  constructor(public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,) { }


  //获取用户数据维度
  selectUserDataPer(isSel) {
    const data={
      userId:this.id,
    }
    //如果是切换tab则不进行更新查询
    if(this.listOfData_userData.length==0 || isSel){
      this.api.selectUserDataPer(data).subscribe((res)=>{
        this.listOfData_userData = res['data'];
        //console.log(this.listOfData);
      });
    }
  }

  //删除数据维度信息
  deleteUserDataPer(data) {
    const param={
      id:data,
    }
    this.api.deleteUserDataPer(param).subscribe((res)=>{
      this.selectUserDataPer(true);
    });
  }

  
  //新增或者修改数据维度
  toAddOrUpdate(id) {
    const modalAddUserPerComponent = this.modalService.create({
      nzTitle: '数据权限',
      nzWidth: 900,
      nzContent: ModalAddUserPerComponent,
      nzComponentParams: {
        id:id,
        userId: this.id,       
      },
      nzStyle: { top: '70px' },
      nzMaskClosable: false,
      nzFooter: null
    });

    
    modalAddUserPerComponent.afterClose.subscribe(res=>{
      this.selectUserDataPer(true);
    });
  }

  ngOnInit() {
    this.selectUserDataPer(false);
  }



}
