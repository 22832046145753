import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd';


@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.less']
})
export class HeadComponent implements OnInit {

  @Input() userInfo; // 用户信息
  MessageList = [];
  receiveClues: boolean; // 线索开关
  status = 0;

  constructor(
    public api: Api,
    public router: Router,
    public message: NzMessageService,
    private modalService: NzModalService,
  ) {
  }

  countNumber = 0;


  logOut() {
    this.api.logOut().subscribe(res => {
      localStorage.removeItem('token');
      this.router.navigate(['login']);
    }, err => {
      // 返回未知错误，直接跳转登录页面
      localStorage.removeItem('token');
      this.router.navigate(['login']);
    })
  }

  configStatus = null;

  ngOnInit() {
    //公告数量
    this.api.getMessageNumber().subscribe(res => {
      this.countNumber = res['data'];
      if (this.countNumber === 0) {
        this.countNumber = undefined;
      }
    })
    this.getUserInfo();
    let info = JSON.parse(localStorage.getItem('userQwConfig'))
    this.configStatus = info ? info.status : null;
  }

  // 获取用户信息
  getUserInfo(update?) {
    this.api.getUserInfo().subscribe(res => {
      this.userInfo = res['data'];
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
      this.receiveClues = this.userInfo.receiveClues;
      if (update) {
        if (this.receiveClues) {
          this.message.info('已打开线索接收')
        } else {
          this.message.info('已关闭线索接收')
        }
      }
    })
  }

  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }

  loadingMore = true;

  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getUserMessageDataList();
  }

  countNumberShowList(templatecontent): void {
    this.MessageList = [];
    this.modalService.create({
      nzTitle: '公告列表',
      nzContent: templatecontent,
      nzStyle: { top: '30px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzFooter: null,
      nzOnCancel: () => {
        this.countNumber = undefined;
      },
    });
    this.getUserMessageDataList();
  }

  //公告列表
  getUserMessageDataList() {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        status: this.status,
      }
    }
    this.api.getUserMessageDataList(params).subscribe(res => {
      this.MessageList = [...this.MessageList, ...res['data'].records];

      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    })
  }

  enterpriseWechatShow(enterpriseWechatContent): void {
    this.modalService.create({
      nzTitle: '企业微信授权',
      nzContent: enterpriseWechatContent,
      nzStyle: { top: '180px' },
      nzWidth: 1028,
      nzMaskClosable: false,
      nzFooter: null,
      nzOnCancel: () => {
        //this.countNumber = undefined;
      },
    });
  }

  routerLink() {
    this.modalService.closeAll();
    this.router.navigate(["/forms/add-wechat-settled"])
  }

  inquireList() {
    this.MessageList = [];
    this.getUserMessageDataList();
  }

  UserReadMessageNubmer(obj) {
    const params = {
      messageId: obj.id
    }
    this.api.UserReadMessageNubmer(params).subscribe(res => {
      //console.log(res)
    })
  }

  updateUserReceiveClues(id, receiveClues) {
    this.api.updateUserReceiveClues({ id: id, receiveClues: receiveClues }).subscribe(res => {
      this.getUserInfo(true);
    });
  }
  goFrist() {
    this.router.navigate(['dashboard/home']);
  }
}
