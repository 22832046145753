import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DataDimensionService } from '@service/DataDimensionService';
import {NzMessageService} from 'ng-zorro-antd/message';

import { Api } from '@service/Api';


@Component({
  selector: 'app-modal-distributors',
  templateUrl: './modal-distributors.component.html',
  styleUrls: ['./modal-distributors.component.less']
})
export class ModalDistributorsComponent implements OnInit {
  @Input() data: object;

  constructor(public api: Api,
    private modalService: NzModalService,
    public message: NzMessageService,
    public fb: FormBuilder,
    public requstService: DataDimensionService) { }

  // #region tab1 地区相关
  companyList: any;
  selectArea(data) {
    if (data) {
      const param = {
        areaId: data[data.length - 1],
        category: 2
      }
      this.api.findCompanyByAreaId(param).subscribe(res => {
        this.companyList = res['data'];
      });
    }
  }
  // #endregion


  // #region tab 渠道商提成模块
  distributorsContentFrom: FormGroup;
  addDistributorsContentList = [];
  distributorsContentList = [];
  distributorsPageCtrl = { // 渠道商提成控制
    total: 0,
    index: 1,
    size: 10
  };

  addDistributorsContent(content, data?) {
    if(data){
      try{
        console.log(data);
        if(data){
          let areaValue = JSON.parse(data['remark']);
          this.distributorsContentFrom.get("areaValue").setValue(areaValue);
          this.selectArea(areaValue);
        }
        console.log(this.distributorsContentFrom.get("areaValue"));
        this.distributorsContentFrom.get("companyValue").setValue(data['officeId']);
        this.distributorsContentFrom.get("ratio").setValue(data['ratio']);
      }catch(e){}
    }else{
        this.distributorsContentFrom.reset();
    }
    const modal = this.modalService.create({
      nzTitle: data ? '修改渠道商提成' : '新增渠道商提成',
      nzContent: content,
      nzStyle: { top: '50px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => {
      },
      nzOnOk: () => {
        // 更新表单状态
        Object.keys(this.distributorsContentFrom.controls).map((key) => {
          this.distributorsContentFrom.controls[key].markAsDirty();
          this.distributorsContentFrom.controls[key].updateValueAndValidity();
        });

        if (this.distributorsContentFrom.get("areaValue").value == null) {
          this.message.info("添加失败，请输入地区信息！");
          return
        }

        if (this.distributorsContentFrom.get("companyValue").value == null) {
          this.message.info("添加失败，请输入公司信息！");
          return
        }

        if (this.distributorsContentFrom.get("ratio").value == null) {
          this.message.info("添加失败，请输入提成比例信息！");
          return
        }

        if (!this.distributorsContentFrom.valid) {
          return
        }
        let distrId = null;
        if (data) {
          distrId = data.id;
        }
        this.saveBistributorsCommission(this.data['id'], distrId);
      }
    });
    modal.afterClose.subscribe(res => {
      this.getBistributorsCommissionByCateId(this.data['id']);
    });
  }


  toDistributorsPage(page) {
    this.distributorsPageCtrl.index = page;
    this.getBistributorsCommissionByCateId(this.data['id']);
  }
  //查询渠道商产品提成列表
  getBistributorsCommissionByCateId(cateId) {
    if (cateId) {
      const params = {
        current: this.distributorsPageCtrl.index,
        size: this.distributorsPageCtrl.size,
        object: cateId
      };
      this.api.getBistributorsCommissionByCateId(params).subscribe(res => {
        this.distributorsContentList = res['data'].records;
        this.distributorsPageCtrl.total = res['data'].total;
      });
    }
  }

  //保存渠道商产品信息
  saveBistributorsCommission(cateId, distrId) {
    const param={
      id:distrId,
      disOfficeId:this.distributorsContentFrom.get('companyValue').value,
      ratio: this.distributorsContentFrom.get('ratio').value,
      cateId:cateId,
      remark:JSON.stringify(this.distributorsContentFrom.get('areaValue').value),
    }
    this.api.saveBistributorsCommission(param).subscribe(res => {
      console.log("保存e,cate");
    });
    //对渠道商产品提成进行操作 
    this.addDistributorsContentList.forEach(e => {
      
    });
  }

  // #endregion

  // #region tab2 渠道商产品属性
  propertyData:any;
  propertyArray = [];
  addDistributorsPropetisList = [];
  distributorsPropetisList = [];
  distributorsPropetisPageCtrl = { // 渠道商提成控制
    total: 0,
    index: 1,
    size: 10
  };
  propertisParam={
  }
  addDistributorsPropetis(content, data?) {
    //初始化父级属性
    this.propertyData = this.initPropertyData(data);
    
    if(data){
      this.getPropertyDistributors(data.id);
    } else {
      this.propertyArray = [];
    }
    const modal = this.modalService.create({
      nzTitle: data ? '修改渠道商属性' : '新增渠道商属性',
      nzContent: content,
      nzStyle: { top: '50px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzOnCancel: () => {
      },
      
      nzOnOk: () => {
        if (this.propertyData.areaValue == '') {
          this.message.info("保存失败，请输入区域信息！");
          return
        }
        if (this.propertyData.companyValue == '') {
            this.message.info("保存失败，请输入公司信息！");
            return
        }
        if (this.propertyData.name == '') {
          this.message.info("保存失败，请输入初始字段信息！");
          return
        }
        if (this.propertyData.price == '') {
          this.message.info("保存失败，请输入初始值信息！");
          return
        }

        this.saveBaseAreaPropertyDistributors(data);
      }
    });
   
  }
 
  //初始化属性
  initPropertyData (data?){
    let areaValue=[];
    try{
      if(data){
        areaValue = JSON.parse(data['remark']);
        this.selectArea(areaValue);
      }
      
    }catch(e){}
    return {
      areaValue: areaValue,
      companyValue: data? data.disOfficeId:'',
      name: data? data.name:'',
      price: data? data.price:'',
      parentId: 0,
      arithmetic: 0,
    }
  }

  saveBaseAreaPropertyDistributors(data?) {
    const param = [{
      disOfficeId: this.propertyData.companyValue,
      areaCateId: this.data['id'],
      name: this.propertyData.name,
      price: this.propertyData.price,
      parentId: this.propertyData.parentId,
      arithmetic: this.propertyData.arithmetic,
      remark: JSON.stringify(this.propertyData.areaValue),
    }]
    if(data){
      //修改
      param[0]['id']=data.id;
      const params = {
        list:param,
        parentId:0
      }
      this.api.updateBaseAreaPropertyDistributors(params).subscribe(res=>{
        this.propertyArray.map(s=>{
          s['parentId']=data.id;
        });
        const params = {
          list:this.propertyArray,
          parentId:data.id
        }
        this.api.updateBaseAreaPropertyDistributors(params).subscribe(proRes=>{
          this.getPropertyDistributors();
        });
      });
    }else{
      const params = {
        list:param,
        parentId:0
      }
      this.api.saveBaseAreaPropertyDistributors(param).subscribe(res => {
        const id=res['data']['id'];
        if(id){
            this.propertyArray.map(data=>{
              data['parentId']=id;
            });
            const params = {
              list:this.propertyArray,
              parentId:id
            }
            this.api.saveBaseAreaPropertyDistributors(this.propertyArray).subscribe(proRes=>{
              this.getPropertyDistributors();
            });
        }
      });
    }
  }
  //增加子属性
  addProductChild() {
    this.propertyArray.push({
      disOfficeId: this.propertyData.companyValue,
      areaCateId: this.data['id'],
      name: '',
      price: 0,
      parentId: null,
      arithmetic: 0,
    });
  }
  //删除子属性
  deleteProductChild(i) {
    let newArray=[]
    this.propertyArray.forEach((data,index)=>{
      if(index!=i){
        newArray.push(data);
      }
    });
    this.propertyArray=newArray;
  }

  toPropertyPage(page) {
    this.distributorsPropetisPageCtrl.index = page;
    this.getPropertyDistributors();
  }

  //查询列表公司属性列表
  getPropertyDistributors(parentId?) {
    this.propertisParam={};
    this.propertisParam['cateId'] = this.data['id'];
    let params ;
    if(parentId){
      this.propertisParam['parentId'] = parentId;
      params = {
        current: this.distributorsPropetisPageCtrl.index,
        size: this.distributorsPropetisPageCtrl.size,
        object: this.propertisParam,
      };
    }else {
      params = {
        current: this.distributorsPropetisPageCtrl.index,
        size: this.distributorsPropetisPageCtrl.size,
        object: this.propertisParam,
      };
    }
    this.api.getPropertyDistributors(params).subscribe(res=>{
      if(parentId){
        this.propertyArray=res['data'].records;
      }else{
        this.distributorsPropetisList = res['data'].records;
        this.distributorsPropetisPageCtrl.total = res['data'].total;
      }
        
    });
  }
  // #endregion


  ngOnInit() {
    //渠道商
    this.distributorsContentFrom = this.fb.group({
      areaValue: [null, [Validators.required]],
      companyValue: [null],
      ratio: [0.1, Validators.pattern(/^[0-9]*$/)]
    });
    this.propertyData=this.initPropertyData;
    this.requstService.getDataDimension();
    this.getBistributorsCommissionByCateId(this.data['id']);
  }

}
