import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-modal-customer',
  templateUrl: './modal-customer.component.html',
  styleUrls: ['./modal-customer.component.less']
})
export class ModalCustomerComponent implements OnInit {
  @Input() id: string; // 公司id
  form: FormGroup;
  loading = false;
  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modal: NzModalRef
  ) {
    this.form = fb.group({
      name: [null, Validators.required],
      sex: [null, Validators.required],
      level: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      pubAreaId: [null,],
      pubAreaPro: [null,],
      weChat: [null],
      qqNumber: [null],
      email: [null],
      remark: [null],
      channelFlag: [null],
      id: [null],
      // city: [null, Validators.required],
      // province: [null, Validators.required],
      address: [null],
      referralObjId: [null],
      referralUserType: [null],
      sourceId: [null, Validators.required],
      maxSchoolId: [null],
      nativePlace: [null],
      permanentPlaceOfWork: [null],
      hobby: [null],
      userCharacterId: [null],
      marriedFlag: [null],
      childFlag: [null],
      childSchoolFlag: [null],
      birthday: [null],
    });
  }
  resourceList = []; //来源列表
  ngOnInit() {
    this.getResourceList();
    this.getUserLists();
    if (this.id) {
      this.getCustomerDetail();
    }
  }
  /**
    *  获取来源列表
    */
  getResourceList() {
    const params = {
      current: 1,
      size: 999,
      object: {}
    }
    this.api.getSourceConfigBaseList(params).subscribe(res => {
      this.resourceList = res['data'].records;
    });
  }
  customerInfo;
  // 获取用户详细信息
  getCustomerDetail() {
    this.api.getCustomerDetail({ id: this.id }).subscribe(res => {
      const customerInfo = res['data'];
      this.customerInfo = res['data'];
      Object.keys(customerInfo).map((key) => {
        if (this.form.get(key)) {
          this.form.get(key).setValue(customerInfo[key]);
        }
      })
    })
  }
  cancel() {
    this.modal.destroy();
  }
  // 保存申请
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });

    if (!this.form.valid) {
      this.loading = false;
      return
    }

    let params = this.form.getRawValue();

    if (this.id) {
      this.api.updateCustomer(params).subscribe(res => {
        this.message.success('客户信息编辑成功！');
        this.modal.destroy(params);
        this.loading = false;
      }, err => {
        this.loading = false;
      })
    } else {
      this.api.addCustomer(params).subscribe(res => {
        this.message.success('客户新建成功！');
        this.modal.destroy(res);
        this.loading = false;
      }, err => {
        this.loading = false;
      })
    }

  }

  sourceIdNb;
  changeResource(e) {
    if (e == 94) {
      this.sourceIdNb = true;
    } else {
      this.sourceIdNb = false;
    }
  }
  usersList = [];
  //查询用户信息  
  getUserLists() {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }

}
