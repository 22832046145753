import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-modal-select-old-user',
  templateUrl: './modal-select-old-user.component.html',
  styleUrls: ['./modal-select-old-user.component.less']
})
export class ModalSelectOldUserComponent implements OnInit {

  @Input() companyid = '';
  constructor(
    public api: Api,
    private modal: NzModalRef,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
  ) { }
  isAllChecked = false; // 是否全选
  isOperating = false;  //
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = { // 页面查询参数
    objId: null,
    status: 1,
    type: 0, //value = "0 公司 1 部门"
    disable: 0,
    loginAccount: null,
    name: '',
    phoneNumber: '',
    workNumber: '',
  };
  listOfData = [];

  ngOnInit() {
    this.getDept();
    this.getPubCustomerList();
  }
  refreshStatus(): void {
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.userId]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.userId]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.userId]).length;
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => (this.mapOfCheckedId[item.userId] = value));
    this.refreshStatus();
  }

  // 翻页
  toPage(page) {
    console.log(page);
    this.pageCtrl.nzPageIndex = page;
    this.getPubCustomerList();
  }

  deptList: [];
  department: null;
  getDept() {
    if (this.companyid) {
      this.api.findDeptByCompanyId({ companyId: this.companyid }).subscribe((res) => {
        this.deptList = res['data'];
      })
    }
  }
  onDeptChanges(values): void {
    if (values == null) {
      return;
    }
  }

  // 获取客户列表
  getPubCustomerList() {
    if (this.department) {
      this.params.type = 1;
      this.params.objId = this.department;
    } else {
      this.params.type = 0;
      this.params.objId = this.companyid;
    }
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params,
    }
    this.api.queryPerUserList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    })
  }
  reset() {
    this.params = { // 页面查询参数
      objId: null,
      status: 1,
      type: 0,
      disable: 0,
      loginAccount: null,
      name: '',
      phoneNumber: '',
      workNumber: '',
    };
    this.department = null;
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getPubCustomerList();
  }

  cancel() {
    this.modal.destroy();
  }
  submitForm() {
    let list = [];
    this.listOfData
      .filter(item => this.mapOfCheckedId[item.userId])
      .map(v => list.push(v));
    if (list.length === 0) {
      this.message.warning('请选择员工！');
      return
    };
    this.modal.destroy(list);
  }

}
