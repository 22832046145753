import { param } from 'src/assets/ueditor/third-party/jquery-1.10.2';
import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-modal-verify-order',
  templateUrl: './modal-verify-order.component.html',
  styleUrls: ['./modal-verify-order.component.less']
})
export class ModalVerifyOrderComponent implements OnInit {

  params: any;
  verifyMark = null;//审核备注
  actualPaymentDate = null;
  contractUrl: string;
  applyBxType = null; //报销类型
  realRefundAmount = null; //实际退款金额
  realRefundAmountMoney = false;
  applyBxTypeList = [
    { type: 'APPLY_TYPE_BX', name: '报销' },
    { type: 'APPLY_TYPE_JZ', name: '借支' },
    { type: 'APPLY_TYPE_YF', name: '预付' },
  ];

  constructor(
    private modal: NzModalRef,
    public api: Api,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }
  cancel() {
    this.modal.destroy();
  }

  submitForm(dy) {

    if (!this.params.agree) {
      if (!this.verifyMark) {
        this.message.error('请输入审核备注');
        return
      }
    } else {
      if (this.params.code == 'cashier') {
        if (this.startDate === null) {
          this.message.error('请选择日期');
          return
        }
        if (this.uploadReceiptUrls.length <= 0 && !this.realRefundAmountMoney) {
          this.message.error('请上传回单');
          return
        }
        if (this.realRefundAmountMoney && this.realRefundAmount == null) {
          this.message.error('请输入实际退款金额');
          return
        }
        if (this.uploadReceiptUrls.length <= 0 && this.params.type == 'sdjs') {
          this.message.error('请上传回单');
          return
        }
        this.actualPaymentDate = this.dataToStr(this.startDate);// 实际支付日期
        this.uploadReceiptUrls = this.uploadReceiptUrls;// 上传回单
        this.uploadReceiptUrlsFilingOrder = this.uploadReceiptUrlsFilingOrder;// 上传回单
      }
      // if (this.params.code == 'account' && this.params.type == 'bx') {
      //   if (this.applyBxType === null) {
      //     this.message.error('请选择报销类型');
      //     return
      //   }
      // }
    }
    switch (this.params.type) {
      case 'bx'://报销审核
        this.reimbursementCheck(dy);
        break;
      case 'bxhx'://报销核销审核
        this.writeOffCheck(dy);
        break;
      case 'sdjs'://甩单结算审核
        this.flingOrderCheck(dy);
        break;
      case 'khtjzk'://客户推荐转款审核
        this.transferRecommendCheck();
        break;
    }
  }

  //客户推荐转款审核
  transferRecommendCheck() {
    let params = {
      checkStatus: this.params.agree,
      checkDesc: this.verifyMark,
      bsCustomerReommendId: this.params.userApplyId,
      actTransferDate: this.actualPaymentDate,
      bsCustomerRecommendAttachmentList: this.uploadReceiptUrls,
    }
    this.api.transferRecommendCheck(params).subscribe(res => {
      this.message.success('操作成功！');
      this.modal.destroy(res);
    });
  }
  //报销审核

  reimbursementCheck(dy) {
    let params = {
      checkStatus: this.params.agree,
      checkDesc: this.verifyMark,
      userApplyId: this.params.userApplyId,
      // checkType: this.params.code,
      applyBxType: this.applyBxType,
      realPayDate: this.actualPaymentDate,
      receiptAttachmentList: this.uploadReceiptUrls,
    }
    this.api.reimbursementCheck(params).subscribe(res => {
      this.modal.destroy(res);
    });

    if (dy) {
      //报销 高级会计审核 增加打印
      if (this.params.code == 'high_account' && this.params.agree == 1 && this.params.type == "bx") {
        window.open("/forms/add-print-apply/?id=" + this.params.userApplyId);
      }
    }

  }

  //报销核销审核
  writeOffCheck(dy) {
    let params = {
      checkStatus: this.params.agree,
      checkDesc: this.verifyMark,
      userApplyVerificationId: this.params.userApplyId,
      // checkType: this.params.code,
      realRefundAmount: this.realRefundAmount,
      realPayDate: this.actualPaymentDate,
      receiptAttachmentList: this.uploadReceiptUrls,
    }
    console.log(params)
    this.api.writeOffCheck(params).subscribe(res => {
      this.modal.destroy(res);
    });

    if (dy) {
      //核销 高级会计审核 增加打印
      if (this.params.code == 'high_account' && this.params.agree == 1 && this.params.type == "bxhx") {
        window.open("/forms/add-writeoff/?id=" + this.params.userApplyId);
      }
    }
  }
  //甩单结算审核
  flingOrderCheck(dy) {
    let params = {
      checkStatus: this.params.agree,
      checkDesc: this.verifyMark,
      id: this.params.userApplyId,
      checkType: this.params.code,
      actualPaymentDate: this.actualPaymentDate,
      uploadReceiptUrls: this.uploadReceiptUrlsFilingOrder,
    }

    this.api.flingOrderCheck(params).subscribe(res => {
      this.modal.destroy();
    });

    if (dy) {
      //甩单 高级会计审核 增加打印
      if (this.params.code == 'SENIOR_ACCOUNTING_AUDIT' && this.params.agree == 1 && this.params.type == "sdjs") {
        window.open("/forms/add-print-fling/?id=" + this.params.userApplyId);
      }
    }
  }
  startDate = null;
  uploadReceiptUrls = []; // 上传回单
  uploadReceiptUrlsFilingOrder = []; // 甩单结算上传回单

  // 上传相关资料
  uploadChange(info) {
    var attachmentUrls = [];
    var filingOrderAttachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        var params = { "attachmentUrl": '' };
        params.attachmentUrl = item.response.data.fileUrl
        attachmentUrls.push(params)
        filingOrderAttachmentUrls.push(item.response.data.fileUrl)
      })
      this.uploadReceiptUrls = attachmentUrls;
      this.uploadReceiptUrlsFilingOrder = filingOrderAttachmentUrls;
    }
  }
  dataToStr(date) {
    if (date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d;
    }
  }
  ngOnInit() {

    if (this.params.type == 'bxhx') {
      this.realRefundAmountMoney = this.params.refundAmount;
    }
  }

}
