import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';

// import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      // { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: 'workplace', loadChildren: () => import('./workplace/workplace.module').then(m => m.WorkplaceModule) },
      { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
      { path: 'thread', loadChildren: () => import('./thread/thread.module').then(m => m.ThreadModule) },
      { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
      { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },
      { path: 'noteManage', loadChildren: () => import('./noteManage/note.module').then(m => m.NoteModule) },
      { path: 'electronicContract', loadChildren: () => import('./electronicContract/electronic.module').then(m => m.ElectronicModule) },
      { path: 'tutorial', loadChildren: ()=> import('./tutorial/tutorial.module').then(m => m.TutorialModule)},
      { path: 'personnel', loadChildren: ()=> import('./personnel/personnel.module').then(m => m.PersonnelModule)},
      { path: 'newsDashboard', loadChildren: ()=> import('./news-dashboard/news-dashboard.module').then(m => m.NewsDashboardModule)},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
