import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd';
import { Api } from '@service/Api';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-drawer-storages-examine',
  templateUrl: './drawer-storages-examine.component.html',
  styleUrls: ['./drawer-storages-examine.component.less']
})
export class DrawerStoragesExamineComponent implements OnInit {

  @Input() value = '';

  params = {
    payeeName: null,
    serviceProviderName: null,
    payeeAccount: null,
    payeeOpenBank: null,
  }
  contractUrl: string;
  small: string;
  storagesInfo;
  constructor(
    private api: Api,
    private NzDrawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.contractUrl = this.api.storagesUploadWithController();
  }

  ngOnInit() {
    this.storagesRequestDetails();
  }
  appendix = true
  storagesRequestDetails() {
    this.api.getStoragesRequestCustomerDetail({ providerId: this.value }).subscribe(res => {
      this.storagesInfo = res['data'];

      (this.storagesInfo.bsServiceProviderCheckVOS || []).map((item, index) => {
        let id = item.checkUserId
        if (id) {
          this.api.getSysUserInfos(id).subscribe(res => {
            this.storagesInfo.bsServiceProviderCheckVOS[index].checkUserCompanyName = res['data'].companyName
            this.storagesInfo.bsServiceProviderCheckVOS[index].checkUserDeptName = res['data'].deptName
          })
        }
      })

      //提成结算审核人ID 查询 审核人公司，部门，人
      if (this.storagesInfo.flowBusinessStepDTOList && this.storagesInfo.flowBusinessStepDTOList.length > 0) {
        this.storagesInfo.flowBusinessStepDTOList.map((item, index) => {
          (item.flowBusinessStepUserList || []).map((child, index) => {
            this.api.getSysUserInfos(child.userId).subscribe(res => {
              child['checkUserCompanyName'] = res['data'].companyName;
              child['checkUserDeptName'] = res['data'].deptName;
            })
          })

        })
      }

      let str = this.storagesInfo.bsServiceProviderAttachments.map(item => {
        if (item.attachmentType === 'COOPERATIONFRAMEWORK') {
          this.appendix = false
          return
        }
      })


      this.params.payeeName = this.storagesInfo.payeeName
      this.params.serviceProviderName = this.storagesInfo.serviceProviderName
      this.params.payeeAccount = this.storagesInfo.payeeAccount
      this.params.payeeOpenBank = this.storagesInfo.payeeOpenBank

    });
  }
  // 上传合作框架协议
  uploadChange(info, type) {
    if (info.file.status === 'done' && info.file.response.code === 200) {
      let params = {
        cooperationFrameworkAgreement: info.file.response.data.fileUrl,
        providerId: this.value
      }
      this.api.editProviderUploadImg(params).subscribe(res => {
        this.storagesRequestDetails();
      })
    }
  }
  title = {
    panelTitle1: '基础信息',
    panelTitle2: '相关服务区域',
    panelTitle3: '结算模式',
    panelTitle4: '收款信息',
    panelTitle5: '其他补充',
    panelTitle6: '审核情况',
  }

  editpayeeInfo(payeeInfoTemplate) {
    this.modalService.create({
      nzTitle: '编辑信息',
      nzContent: payeeInfoTemplate,
      nzStyle: { top: '30px' },
      nzWidth: 800,
      nzMaskClosable: false,
      //nzFooter:null,
      //nzOnCancel: () => this.form.reset(),
      nzOnOk: () => {
        let params = {
          id: this.value,
          payeeName: this.params.payeeName,
          serviceProviderName: this.params.serviceProviderName,
          payeeAccount: this.params.payeeAccount,
          payeeOpenBank: this.params.payeeOpenBank,
        }
        if (isNaN(params.payeeAccount)) {
          this.message.error('请输入正确的收款账户！');
          return false;
        }
        this.api.editeditProviderPayeeInfo(params).subscribe(res => {
          this.storagesRequestDetails();
        })
      }
    });
  }

  addParentNoteTask() {
    let params = {
      id: this.value,
      payeeName: this.params.payeeName,
      serviceProviderName: this.params.serviceProviderName,
      payeeAccount: this.params.payeeAccount,
      payeeOpenBank: this.params.payeeOpenBank,
    }
    if (isNaN(params.payeeAccount)) {
      this.message.error('请输入正确的收款账户！');
      return false;
    }
    this.api.editeditProviderPayeeInfo(params).subscribe(res => {
      this.storagesRequestDetails();
    })
  }


}
