import { Component, OnInit,Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { Api } from '@service/Api';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-edit-order-model',
  templateUrl: './modal-edit-order-model.component.html',
  styleUrls: ['./modal-edit-order-model.component.less']
})
export class ModalEditOrderModelComponent implements OnInit {
  @Input() id:string;//id
  @Input() type: string; // 类型：1：新增， 2修改
  @Input() obj: any;
  form: FormGroup;
  loading = false;

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');


  categoryFirst = [];  // 一级类别
  categorySecond = []; // 二级类别
  categoryThree:any[] = []; // 三级类别
  name:string; //提成名称
  code:string; 
  constructor(
    fb: FormBuilder,
    public api: Api,
    private modal: NzModalRef
  ) { 
    this.form = fb.group({
      areaCateId: [null, Validators.required],
      commission: [0, Validators.required],
      commissionPrice: [0, Validators.required],
    });
  }

  //产品信息
  getProductLevel(level, parentId?) {
    const params = {
      depth: level
    }
    parentId ? params['id'] = parentId : null;
    this.api.getLinkageCategory(params).subscribe((res) => {
      if(level == 0) {
        this.categoryFirst = res['data'];
      }  else if(level == 1 ) {
        this.categorySecond = res['data'];
      }else {
        this.categoryThree = res['data'];
      }
    })
  }

  setName(id){  
    for(var i in this.categoryThree){
      if(this.categoryThree[i].id == id){
        this.name=this.categoryThree[i].name;
        this.code=this.categoryThree[i].code;
        break;
      }
    }
  }



  cancel() {
    this.modal.destroy();
  }
  submit() {
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }
    let params = this.form.getRawValue();
    params['name'] = this.name;
    params['code'] = this.code;
    if(this.id!='0' && this.type=='1' ){
      params['parentId'] = this.id;
    }
    //console.log(params);
    this.api.addOrderCommissionConfig(params).subscribe(res=>{
      this.cancel();
    });
  }

  ngOnInit() {
    if(this.type == '2'){
        //修改
       
    } else {
      this.getProductLevel(0);
    }
  }

}
