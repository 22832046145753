import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-drawer-project-detail',
  templateUrl: './drawer-project-detail.component.html',
  styleUrls: ['./drawer-project-detail.component.less']
})
export class DrawerProjectDetailComponent implements OnInit {
  @Input() value = '';
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
  }
  listOfData = {};

  ngOnInit() {
    this.getBudgetProjectDetail();
    this.getBudgetProjectUserApply();
  }


  // 获取客户列表
  getBudgetProjectDetail() {
    this.api.budgetProjectDetail(this.value).subscribe(res => {
      this.listOfData = res['data'];
    });
  }
  projectUserApply = [];
  getBudgetProjectUserApply() {
    this.api.budgetProjectUserApply(this.value).subscribe((res) => {
      this.projectUserApply = res['data'];
    })
  }
}
