import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataDimensionService } from "@service/DataDimensionService";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { LoginComponent } from './pages/login/login.component';
import { EnterpriseComponent } from './pages/enterprise/enterprise.component';
import { EnterpriseBandComponent } from './pages/enterprise-band/enterprise-band.component';
import { PagesModule } from './pages/pages.module';
import { Api } from './service/Api';
import { AuthInterceptor } from './service/auth-interceptor';
import { Global } from './service/global';
import { HttpIntercept } from './service/http-intercept';
import { Utils } from './service/Utils';
import { SharedModule } from './shared/shared.module';


registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EnterpriseComponent,
    EnterpriseBandComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    PagesModule,

  ],
  providers: [
    Api,
    Global,
    Utils,
    DataDimensionService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
