import { Component, OnInit, Input } from '@angular/core';
import { Api } from '@service/Api';
import { Utils } from '@service/Utils';
@Component({
  selector: 'app-modal-thread-plan-customer-list',
  templateUrl: './modal-thread-plan-customer-list.component.html',
  styleUrls: ['./modal-thread-plan-customer-list.component.less']
})
export class ModalThreadPlanCustomerListComponent implements OnInit {
  @Input() id;
  @Input() sourceFlag;
  listOfData = [];

  constructor(public api: Api,) { }

  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };

  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getSysThreadPlanCustomerList();
  }

  query() {
    this.pageCtrl.nzPageIndex = 1;
    if (this.params.dateList != null) {
      this.params['beginDate'] = Utils.dateFormat(this.params.dateList[0], 'yyyy-MM-dd');;
      this.params['endDate'] = Utils.dateFormat(this.params.dateList[1], 'yyyy-MM-dd');;
    }
    this.getSysThreadPlanCustomerList();
  }

  reset() {
    this.params.jobName = null;
    this.params.contact = null;
    this.params.companyName = null;
    this.params.dateList = null;
    this.params['beginDate']=null;
    this.params['endDate']=null;
    this.getSysThreadPlanCustomerList();
  }

  params = { // 页面查询参数
    jobName:null,
    contact:null,
    companyName:null,
    dateList:null,
  }

  getSysThreadPlanCustomerList(){
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object:this.params,
    }
    params.object['areaId']=this.id;
  
    if(this.sourceFlag == "HUI_HEART"){
      params.object['sourceFlag']="HUI_HEART"//惠用心
    }else{
      params.object['sourceFlag']="PRECISE"
    }

    this.api.getSysThreadPlanCustomerList(params).subscribe(res=>{
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }
  ngOnInit() {
    this.getSysThreadPlanCustomerList();
  }

}
