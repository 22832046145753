import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Api } from '@service/Api';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-modal-salary-user',
  templateUrl: './modal-salary-user.component.html',
  styleUrls: ['./modal-salary-user.component.less']
})
export class ModalSalaryUserComponent implements OnInit {
  @Input() type = '';
  constructor(
    public api: Api,
    private modal: NzModalRef,
    private drawerService: NzDrawerService,
    public message: NzMessageService,
  ) { }
  isAllChecked = false; // 是否全选
  isOperating = false;  //
  isIndeterminate = false; // 是否部分选择/不确定状态
  numberOfChecked = 0; // 已选条数
  mapOfCheckedId: { [key: string]: boolean } = {};
  mapOfCheckedObj = {};// 选中的数组对象
  keysOfCheckedObj: string[];// 选中的数组对象页面展示
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = { // 页面查询参数
    workNumber: null,
    userName: null,
    phoneNumber: null,
    companyId: null,
    deptId: null,
    userStatus: null,
  };
  listOfData = [];
  ngOnInit() {
    this.getPubCustomerList();
    this.getOfficeDepartTreeWithPermissionsOn();
  }
  refreshStatus(id?): void {
    this.isAllChecked = this.listOfData
      .every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.listOfData.some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllChecked;

    this.numberOfChecked = this.listOfData.filter(item => this.mapOfCheckedId[item.id]).length;

    if (id) {
      this.opjMap(id);
    }
    this.keysOfCheckedObj = Object.values(this.mapOfCheckedObj);
    console.log(this.keysOfCheckedObj)
  }

  opjMap(id) {
    if (this.mapOfCheckedId[id]) {
      this.mapOfCheckedObj[id] = this.listOfData.find(item => item.id === id); // 添加
    } else {
      delete this.mapOfCheckedObj[id]; // 从mapOfCheckedObj中删除
    }
  }

  checkAll(value: boolean): void {
    this.listOfData.forEach(item => {
      (this.mapOfCheckedId[item.id] = value);
      this.opjMap(item.id);
    });
    this.refreshStatus();

  }

  // 翻页
  toPage(page) {
    console.log(page);
    this.pageCtrl.nzPageIndex = page;
    this.getPubCustomerList();
  }

  // 获取客户列表
  getPubCustomerList() {
    var objParams = JSON.parse(JSON.stringify(this.params))
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: objParams
    };
    this.api.employeesList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;

    });
  }
  reset() {
    this.params.workNumber = null;
    this.params.userStatus = null;
    this.params.userName = null;
    this.params.phoneNumber = null;
    this.params.companyId = null;
    this.params.deptId = null;
    this.companytStr = null;
    this.getPubCustomerList();
  }
  query() {
    this.pageCtrl.nzPageIndex = 1;
    if (this.companytStr) {
      this.params.companyId = this.companytStr[0]
      this.params.deptId = this.companytStr[1]
    }
    this.getPubCustomerList();
  }

  cancel() {
    this.modal.destroy();
  }
  submitForm() {
    if (this.keysOfCheckedObj.length === 0) {
      this.message.warning('请选择员工！');
      return
    };
    this.modal.destroy(this.keysOfCheckedObj);
  }


  companytStr = null;
  city_Data = [];//公司，部门
  //数据权限- 公司 - 部门
  getOfficeDepartTreeWithPermissionsOn() {
    this.api.getOfficeDepartTreeWithPermissions().subscribe(res => {
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.city_Data = strs;
    })
  }

  onChanges(values) {
    console.log(values)
  }
  stateList = [
    { value: 'TRIAL', name: '试用期' },
    { value: 'POSITIVE', name: '已转正' },
    { value: 'HAVE_LEFT', name: '已离职' },
  ];

}
