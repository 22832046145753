import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { ModalSelectqualComponent } from '../modal-selectqual/modal-selectqual.component';
import { Utils } from '@service/Utils';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerEntpriseServerComponent } from '../../component/drawer-entprise-server/drawer-entprise-server.component';

@Component({
  selector: 'app-drawer-relation-company',
  templateUrl: './drawer-relation-company.component.html',
  styleUrls: ['./drawer-relation-company.component.less']
})
export class DrawerRelationCompanyComponent implements OnInit {
  @Input() value = '';
  @Input() index = 0;
  form: FormGroup;
  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public router: Router,
    public api: Api,
    private drawerService: NzDrawerService,
  ) {
    this.form = fb.group({
      logoutDate: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getCompanyDetails();
    this.changeTabs(this.index);
  }

  companyInfo; //公司信息
  peopleList = []; //联系人信息
  activeList = [0];    // 已激活菜单
  // 切换菜单
  changeTabs(e) {
    console.log(e)
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }
    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getLabelList();
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  // 获取公司详情
  getCompanyDetails() {
    this.api.getCompanyDetails({ id: this.value }).subscribe(res => {
      this.companyInfo = res['data'];
      this.api.getCompanyCustomerList({ companyId: this.value }).subscribe(resp => {
        this.peopleList = resp['data'];
      })
    });
  }
  // 企业标签 start
  labelList = [];      // 标签列表
  userLabelList = [];  // 客户标签列表
  userParentLabelList = []; //选中多选的id
  saveLabel() {
    // 先保存多选id,再筛选出单选选中id
    const userLabelList = JSON.parse(JSON.stringify(this.userParentLabelList));
    for (let j = 0; j < this.labelList.length; j++) {
      if (this.labelList[j].radio && this.labelList[j].checkId) {
        userLabelList.push(this.labelList[j].checkId);
      }
    }
    const parmas = {
      companyId: this.value,
      labelId: userLabelList

    }
    this.api.saveSysCompanyTagRecord(parmas).subscribe(res => {
      this.message.success('添加成功！');
    })
  }
  // 查询标签列表
  getLabelList() {
    // 客户标签 tagType = 0
    // 企业标签 tagType = 1
    var params = {
      tagType: 1
    }
    this.api.getLabelList(params).subscribe(res => {
      this.labelList = res['data'];
      this.getUserLabel();
    })
  }

  // 获取客户标签
  getUserLabel() {
    this.api.getCompanyLabel({ companyId: this.value }).subscribe(res => {
      this.userLabelList = res['data'];
      this.setTag()
    })
  }
  setTag() {
    const len = this.labelList.length;
    for (let i = 0; i < len; i++) {

      const tagList = this.labelList[i]['sysCustomerTagConfigVOS'];

      if (!tagList) {
        continue
      }


      for (let j = 0; j < tagList.length; j++) {

        // 如果标签存在于客户标签数组，添加标记
        // userLabelList 选中的id
        if (this.userLabelList.includes(tagList[j].id)) {
          // 多选
          if (!this.labelList[i].radio) {
            tagList[j].checked = true;
            // 多选
            this.userParentLabelList.push(tagList[j].id);
          } else {
            // 单选
            this.labelList[i].checkId = tagList[j].id;
          }
        } else {
          tagList[j].checked = false;
        }
      }
    }
    console.log(this.labelList)

  }
  // 标记客户标签 多选
  checkChange(checked, id) {
    if (checked) {
      this.userLabelList.push(id);
      this.userParentLabelList.push(id);
    } else {
      this.userLabelList = this.userLabelList.filter(t => t !== id);
      this.userParentLabelList = this.userParentLabelList.filter(t => t !== id);
    }
  }
  // checkChangeRadio 单选
  checkChangeRadio(checked) {
    console.log(checked)
  }
  // 企业标签 end
}
