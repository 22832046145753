import { Component, OnInit } from '@angular/core';
import { Api } from '@service/Api';
import { Global } from '@service/global.ts';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
let cnm;
@Component({
  selector: 'app-finance-config',
  templateUrl: './finance-config.component.html',
  styleUrls: ['./finance-config.component.less']
})
export class FinanceConfigComponent implements OnInit {
  listOfData = [];
  constructor(
    public api: Api,
    private modalService: NzModalService,
    public message: NzMessageService,
    public global: Global,
  ) {
    // this.uploadUrl = this.api.uploadInvoiceAttachment();
  }
  contractUrl: string;
  pageCtrl = { // 分页控制
    total: 0,
    nzPageIndex: 1,
    nzPageSize: 10
  };
  params = { // 页面查询参数
    configName: null,
  }
  // 组织选择 satart
  configName: null;
  loadDeptData: any;
  city_Data: any = [];//公司，部门
  companytStr: any = null;

  //数据权限- 公司 - 部门
  getOfficeDepartTreeWithPermissionsOn() {
    this.api.getOfficeDepartTreeWithPermissions().subscribe(res => {
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.city_Data = strs;
    })
  }
  sysOfficeReportConfigItemList = [
    {
      companytStr: []
    }
  ]
  addParams() {
    this.sysOfficeReportConfigItemList = [
      ...this.sysOfficeReportConfigItemList,
      {
        companytStr: []
      }
    ]
  }
  delParams(index) {
    let newList = JSON.parse(JSON.stringify(this.sysOfficeReportConfigItemList));
    newList.splice(index, 1)
    this.sysOfficeReportConfigItemList = newList;
  }
  // 组织选择 end
  // 翻页
  toPage(page) {
    this.pageCtrl.nzPageIndex = page;
    this.getReportConfigList();
  }
  //  获取收款公司列表

  query() {
    this.pageCtrl.nzPageIndex = 1;
    this.getReportConfigList();
  }

  reset() {
    this.params = { // 页面查询参数
      configName: null,
    }
    this.getReportConfigList();
  }

  // 获取列表
  getReportConfigList() {
    const params = {
      current: this.pageCtrl.nzPageIndex,
      size: this.pageCtrl.nzPageSize,
      object: this.params
    }
    this.api.reportConfigList(params).subscribe(res => {
      this.listOfData = res['data'].records;
      this.pageCtrl.total = res['data'].total;
    });
  }

  // 新增
  addIncome(content, id?) {
    this.modalService.create({
      nzTitle: '新增组合配置',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskClosable: false,
      nzOnCancel: () => {
      },
      nzOnOk: () => {
        if (!this.configName) {
          this.message.error('配置名称不能为空！');
          return false
        }
        if (!this.sysOfficeReportConfigItemList) {
          this.message.error('组织不能为空！');
          return false
        }
        this.addBsOtherIncome()
      }
    });
  }
  // 新增
  addBsOtherIncome() {
    let params = {
      configName: this.configName,
      sysOfficeReportConfigItemList: []
    };
    var hasItem = true;
    (this.sysOfficeReportConfigItemList || []).map(parat => {
      var officeId = null;
      var officeName = null;
      if (parat.companytStr.length == 0) {
        hasItem = false;
        return;
      }
      if (parat.companytStr.length == 1) {
        officeId = parat.companytStr[0];
      } else if (parat.companytStr.length == 2) {
        officeId = parat.companytStr[1];
      }
      (this.city_Data || []).map(item => {
        if (item.id == officeId) {
          officeName = item.name;
        } else {
          (item.children || []).map(child => {
            if (child.id == officeId) {
              officeName = child.name;
            }
          })
        }
      });
      params.sysOfficeReportConfigItemList.push({
        officeId: officeId,
        officeName: officeName,
      })
    });
    if (!hasItem) {
      this.message.error('组织不能为空！');
      return false
    }
    this.api.addSreportConfigList(params).subscribe((res) => {
      this.message.success('提交成功！');
      this.getReportConfigList();
    })
  }

  delete(id) {
    this.api.delSreportConfigList(id).subscribe(res => {
      this.message.success('删除成功');
      this.getReportConfigList();
    });
  }
  onChanges(e, i, data) {
    console.log(e, i, data)
  }
  ngOnInit() {
    this.getReportConfigList();
    this.getOfficeDepartTreeWithPermissionsOn();
  }
}
